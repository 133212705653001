import { memo } from 'react'
import PropTypes from 'prop-types'

import { Chip } from '@mui/material'
import { CloudDoneOutlined, SaveAsOutlined } from '@mui/icons-material'

import { AnnouncementStatus, AnnouncementStatusDisplayName } from '../../enums'

const AnnouncementStatusToColorMap = {
  [AnnouncementStatus.Draft]: 'default',
  [AnnouncementStatus.Published]: 'primary',
}

const AnnouncementStatusToIconMap = {
  [AnnouncementStatus.Draft]: <SaveAsOutlined />,
  [AnnouncementStatus.Published]: <CloudDoneOutlined />,
}

const AnnouncementStatusChip = ({ status }) => {
  return (
    <Chip
      size='small'
      variant='outlined'
      sx={{ padding: 0.25 }}
      icon={AnnouncementStatusToIconMap[status]}
      color={AnnouncementStatusToColorMap[status]}
      label={AnnouncementStatusDisplayName[status]}
    />
  )
}

AnnouncementStatusChip.defaultProps = {
  status: AnnouncementStatus.Draft,
}

AnnouncementStatusChip.propTypes = {
  status: PropTypes.oneOf(Object.values(AnnouncementStatus)),
}

export default memo(AnnouncementStatusChip)
