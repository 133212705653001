import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { Assignment, Help, LineStyle, PlayArrow, Schedule } from '@material-ui/icons'
import { withStyles } from '@material-ui/core'

import { usersSelectors } from '@tabeeb/modules/../users'
import { galleryActions, gallerySelectors } from '@tabeeb/modules/gallery'
import { getTimeString } from '@tabeeb/modules/recording/services/helpers'

import { FormAnswer } from '@tabeeb/modules/shared/icons'
import UserAvatar from '@tabeeb/modules/shared/userAvatar'
import { fallbackUser } from '@tabeeb/modules/shared/users/common'

import { convertDateToString } from '../../../services'

import AnswerStatusBadge from './AnswerStatusBadge'
import InfoLine from '../InfoLine'
import PagePreview from '../PagePreview'

import styles from './styles'
import { getFormTitle } from '@tabeeb/modules/appConfigState/selectors'

const AnswerInfo = ({ classes, answer, pagePreview, formName, questionName }) => {
  const dispatch = useDispatch()

  const page = useSelector((state) => gallerySelectors.getGalleryItemById(state, { id: answer.PageId }))
  const answerer = useSelector((state) => usersSelectors.getUserById(state, { Id: answer.UserId }) || fallbackUser)

  const formTitle = useSelector(getFormTitle)

  const handleSelectPage = useCallback(() => {
    dispatch(galleryActions.onSelectGalleryItem({ pageId: answer.PageId, timestamp: answer.Timestamp }))
  }, [answer.PageId, answer.Timestamp])

  const tabs = answer.FormTabAnswers?.map((answerTab) => answerTab.FormTabName)

  const AnswererAvatar = useCallback(
    () => <UserAvatar className={classes.avatar} user={answerer} title='Answerer' />,
    [answerer]
  )

  return (
    <div className={classes.container}>
      {pagePreview && (
        <PagePreview
          url={page?.thumbnailUrl}
          pageTitle={page?.thumbnailCaption}
          onClick={handleSelectPage}
          notAllowed={!page}
        />
      )}
      <div className={classes.content}>
        <InfoLine icon={AnswererAvatar} value={answerer.displayName} title='Answerer' />
        {formName && <InfoLine icon={Assignment} value={answer.FormName} title={formTitle} />}
        {questionName && <InfoLine icon={Help} value={answer.Label} title='Question' />}
        {Boolean(tabs?.length) && <InfoLine icon={LineStyle} value={tabs} title='Tabs' />}
        {answer.Timestamp !== null && answer.Timestamp >= 0 && (
          <InfoLine
            icon={PlayArrow}
            value={getTimeString(answer.Timestamp * 1000)}
            title={`Proceed to ${getTimeString(answer.Timestamp * 1000)}`}
            onClick={handleSelectPage}
          />
        )}
        <InfoLine
          icon={FormAnswer}
          value={answer.Value}
          title='Answer'
          badge={<AnswerStatusBadge answer={answer} />}
          link
        />
        <InfoLine icon={Schedule} value={convertDateToString(answer.CreatedOn)} title='Answer time' />
      </div>
    </div>
  )
}

AnswerInfo.defaultProps = {
  formName: false,
  pagePreview: false,
  questionName: false,
}

AnswerInfo.propTypes = {
  classes: PropTypes.shape({
    avatar: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
  }).isRequired,
  answer: PropTypes.shape({
    CreatedOn: PropTypes.string.isRequired,
    FormName: PropTypes.string.isRequired,
    PageId: PropTypes.number.isRequired,
    UserId: PropTypes.number.isRequired,
    Value: PropTypes.string.isRequired,
    Label: PropTypes.string.isRequired,
    Timestamp: PropTypes.number,
    FormTabAnswers: PropTypes.arrayOf(
      PropTypes.shape({
        FormTabName: PropTypes.string.isRequired,
      }).isRequired
    ),
  }).isRequired,
  formName: PropTypes.bool.isRequired,
  pagePreview: PropTypes.bool.isRequired,
  questionName: PropTypes.bool.isRequired,
}

export default memo(withStyles(styles)(AnswerInfo))
