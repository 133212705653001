import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    paginationUl: {
      marginTop: theme.spacing(2),
      '& .MuiPagination-ul': {
        justifyContent: 'center',
      },
    },
  }
})
