import { memo } from 'react'
import PropTypes from 'prop-types'

const DebugTools = ({ children }) => {
  if (process.env.NODE_ENV !== 'development') {
    return null
  }

  return children
}

DebugTools.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  ]).isRequired,
}

export default memo(DebugTools)
