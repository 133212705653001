import { handleActions } from 'redux-actions'
import {
  adminSearchUsersSuccess,
  adminUpdateUser,
  adminUpdateUserDisabledState,
  resetPage,
  resetUserPasswordRequest,
} from '../actions'

const defaultState = []

export default handleActions(
  {
    [adminSearchUsersSuccess](state, action) {
      return action.response.data.map((user) => {
        user.isPasswordReset = false
        return user
      })
    },
    [resetUserPasswordRequest](state, action) {
      const userId = action.payload
      return state.map((user) => {
        if (user.Id === userId) {
          user.isPasswordReset = true
        }
        return user
      })
    },
    [adminUpdateUser](state, action) {
      const user = action.payload
      const result = state.map((i) => {
        if (i.Id === user.Id) {
          return user
        }
        return i
      })

      return result
    },
    [adminUpdateUserDisabledState](state, { payload: { isDisabled, userId } }) {
      return state.map((user) => {
        if (user.Id === userId) {
          return { ...user, IsDisabled: isDisabled }
        }
        return user
      })
    },
    [resetPage]() {
      return defaultState
    },
  },
  defaultState
)
