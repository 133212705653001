import { memo, Suspense } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { AnnotationType } from '@tabeeb/enums'
import { getAnnotationChild } from '@tabeeb/modules/annotations/selectors'

import { useAnnotationHover, useAnnotationSelection } from '../../../hooks'
import { getAnnotationComponent } from '../../../utils/components'

const Annotation = ({ annotation }) => {
  const childAnnotation = useSelector((state) => getAnnotationChild(state, { Id: annotation.Id }))

  const { hovered, onHoverEnter, onHoverLeave } = useAnnotationHover()
  const { selected, onSelect } = useAnnotationSelection({ id: annotation.Id })

  const Component = getAnnotationComponent(annotation.Type)
  if (!Component) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <Component
        annotation={annotation}
        hovered={hovered}
        onHoverEnter={onHoverEnter}
        onHoverLeave={onHoverLeave}
        selected={selected}
        onSelect={onSelect}
      >
        {childAnnotation && !annotation.Hidden ? <Annotation annotation={childAnnotation} /> : null}
      </Component>
    </Suspense>
  )
}

Annotation.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Type: PropTypes.oneOf(Object.values(AnnotationType)).isRequired,
    ParentAnnotationId: PropTypes.number,
    Hidden: PropTypes.bool,
  }).isRequired,
}

export default memo(Annotation)
