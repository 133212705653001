import selector from '../modules/shared/utils/selector'
import { applicationStore } from './createStore'

let _accessToken = ''
let _apiUrl = process.env.TABEEB_API_URL

export function getAccessToken() {
  const state = applicationStore.getState()
  const accessToken = selector.getAccessToken(state)
  return accessToken
}

export function setAccessToken(accessToken) {
  _accessToken = accessToken
}

export function getApiUrl(apiUrl) {
  return _apiUrl
}

export function setApiUrl(apiUrl) {
  _apiUrl = apiUrl
}
