/* eslint-disable react/no-array-index-key */
import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import _ from 'lodash'

import { getSquare } from '../../../utils/measurements'
import { toVector } from '../../../utils/vectors'
import { Defaults } from '../../../constants'
import { useAnnotationState } from '../../../hooks'
import { getUnit, getScale } from '../../../selectors'

import Label from '../Label'
import Line from '../Line'
import Plane from '../Plane'
import Points from '../Points'

const getLabelPosition = (points) => {
  return new Vector3(
    _.meanBy(points, (point) => point.x),
    _.meanBy(points, (point) => point.y),
    _.meanBy(points, (point) => point.z)
  )
}

const Area = ({ annotation: sourceAnnotation, hovered, onHoverEnter, onHoverLeave, selected, onSelect }) => {
  const unit = useSelector(getUnit)
  const scale = useSelector(getScale)
  const { annotation, onAddPoint, onPointDelete, onPointUpdate, onUpdateCommit } = useAnnotationState(sourceAnnotation)

  const points = annotation.Points.map(toVector)
  const visible = !annotation.Hidden

  const square = getSquare(points, unit, scale)

  return (
    <group
      visible={visible}
      rotation={Defaults.ROTATION}
      onPointerDown={onSelect}
      onPointerEnter={onHoverEnter}
      onPointerLeave={onHoverLeave}
    >
      <Points
        closed
        visible={visible && selected}
        points={points}
        onAdd={onAddPoint}
        onDelete={onPointDelete}
        onUpdate={onPointUpdate}
        onUpdateCommit={onUpdateCommit}
      />
      <Plane color={annotation.Color} points={points} />

      <Line points={points} closed color={annotation.Color} visible={visible} highlighted={hovered || selected} />

      <Label
        visible={visible && points.length > 2}
        position={getLabelPosition(points)}
        text={`${square.toFixed(Defaults.CALCULATIONS_PRECISION)} ${unit}²`}
      />
    </group>
  )
}

Area.defaultProps = {
  selected: false,
}

Area.propTypes = {
  annotation: PropTypes.shape({
    Color: PropTypes.string.isRequired,
    Hidden: PropTypes.bool,
    Points: PropTypes.arrayOf(
      PropTypes.shape({
        X: PropTypes.number.isRequired,
        Y: PropTypes.number.isRequired,
        Z: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
  hovered: PropTypes.bool,
  onHoverEnter: PropTypes.func,
  onHoverLeave: PropTypes.func,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default memo(Area)
