export const addMenuItems = {
  PDF: 'PDF',
  WordDocument: 'Document',
  Images: 'Images',
  VideoFile: 'Video Files',
  VideoFromUrl: 'Video Using URL',
  WebPageUsingUrl: 'Web Page',
  Add360View: '360° View',
  Add3dModel: '3D Model (Pix4D)',
  AddBentleyModel: '3D Model (ContextCapture)',
  PointCloudModel: 'Point Cloud Model',
  AutodeskFile: 'CAD Model',
  StructureModel: 'Analytics Report',
  BentleyAnalyticsReport: 'Analytics Report (ContextCapture)',
  TowerSideProfile: 'Tower Side Profile',
  TwoDLineDrawing: '2D Line Drawing',
  DrawingPage: 'Drawing Board',
  ClassificationAnnotations: 'Classification Annotations',
}

export const exportMenuItems = {
  PDF: 'PDF',
  Images: 'Images',
  CustomVideo: 'Video',
  ShareSession: 'Session',
  ReviewSession: 'For Review',
  MergeContents: 'Merge',
}

export const customVideoMenuItems = {
  AllPages: 'All Pages',
  AllApproved: 'All Approved',
  AllSubmitted: 'All Submitted',
}

export const shareSessionMenuItems = {
  AllPages: 1,
  ByForm: 2,
}
