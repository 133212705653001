import { ReportsCategory } from '@tabeeb/modules/reports/enums'

const rootUri = 'reporting-service/api/reports'

export const createReportsBySubdomainRequest = (data) => ({
  url: `${rootUri}`,
  method: 'post',
  data,
})

export const getGeneratedReportsRequest = (
  { skip, take, subdomain, category } = { skip: 0, take: 50, category: ReportsCategory.currentUser }
) => ({
  url: `${rootUri}/${subdomain}`,
  method: 'get',
  params: {
    skip,
    take,
    category,
  },
})

export const getReportReceiversRequest = ({ skip, take, reportId } = { skip: 0, take: 50 }) => ({
  url: `${rootUri}/${reportId}/receivers`,
  method: 'get',
  params: {
    skip,
    take,
  },
})

export const getReportDownloadUrlRequest = (reportId) => ({
  url: `${rootUri}/${reportId}/download/url`,
  method: 'get',
})

export const cancelReportGenerationRequest = (reportId) => ({
  url: `${rootUri}/${reportId}/cancel`,
  method: 'post',
})

export const revokeReportAccessRequest = ({ reportId, receiverId }) => ({
  url: `${rootUri}/${reportId}/revoke/${receiverId}`,
  method: 'post',
})

export const renewReportAccessRequest = ({ reportId, receiverId }) => ({
  url: `${rootUri}/${reportId}/renew/${receiverId}`,
  method: 'post',
})
