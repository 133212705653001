export const ReportStatus = {
  Queued: 1,
  Active: 2,
  Cancelled: 3,
  Finished: 4,
  Failed: 5,
}

export const ReportOrigin = {
  none: 0,
  activity: 1,
  formAnswers: 2,
  sessions: 3,
  lastActivity: 4,
  actionsOverUsers: 5,
  checkInCheckOut: 6,
  screeningLog: 7,
}

export const ReportsCategory = {
  currentUser: 1,
  tenant: 2,
  tenantScheduled: 3,
}

export const ReportReceiverType = {
  Email: 1,
  Ftp: 2,
}

export const ReportOutputConfig = {
  addHeader: 1,
  produceFlatOutput: 2,
}
