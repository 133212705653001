import { handleActions, combineActions } from 'redux-actions'

import {
  getDashboardsRequest,
  getDashboardRequest,
  getDashboardSuccess,
  getDashboardsSuccess,
  getDashboardFailed,
  getDashboardsFailed,
} from '../actions'

const initialState = false

export default handleActions(
  {
    [combineActions(getDashboardsRequest, getDashboardRequest)]: (state, action) => {
      return true
    },
    [combineActions(getDashboardsSuccess, getDashboardSuccess, getDashboardFailed, getDashboardsFailed)]: (
      state,
      action
    ) => {
      return false
    },
  },
  initialState
)
