import { memo, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { ArrowDropDown, Translate } from '@material-ui/icons'
import { Typography, MenuItem, Chip, Menu } from '@material-ui/core'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import { useChatContext } from '../../contexts'
import { TranslationLanguage } from '../../constans'

import { chatActions, chatSelectors } from '../..'

import useStyles from './styles'

const MenuProps = {
  elevation: 0,
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  PaperProps: {
    style: {
      border: '1px solid #d3d4d5',
      maxHeight: 400,
    },
  },
}

const TranslationLanguageSelector = () => {
  const chipRef = useRef()
  const classes = useStyles()
  const dispatch = useDispatch()

  const { language, setTranslationLanguage } = useChatContext()

  const languages = useSelector(chatSelectors.getTranslationLanguages)
  const isChatTranslationEnabled = useSelector(appConfigStateSelectors.getIsChatTranslationEnabled)

  useEffect(() => {
    if (isChatTranslationEnabled && Object.keys(languages).length === 0) {
      dispatch(chatActions.getTranslationLanguagesRequest())
    }
  }, [])

  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleSelectTranslationLanguage = (language) => () => {
    setTranslationLanguage(language)
    setIsOpen(false)
  }

  if (!isChatTranslationEnabled) {
    return null
  }

  return (
    <>
      <div className={classes.root}>
        <Typography variant='body2' component='span' color='textSecondary'>
          Translate to:
        </Typography>
        <Chip
          ref={chipRef}
          size='small'
          color='primary'
          className={classes.chip}
          icon={<Translate />}
          deleteIcon={<ArrowDropDown />}
          onClick={handleOpen}
          onDelete={handleOpen}
          label={languages[language] || 'Original'}
        />
      </div>
      <Menu
        anchorEl={chipRef.current}
        {...MenuProps}
        open={isOpen}
        onClose={handleClose}
        onSelect={handleSelectTranslationLanguage}
      >
        <MenuItem
          selected={language === TranslationLanguage.Original}
          onClick={handleSelectTranslationLanguage(TranslationLanguage.Original)}
        >
          Original
        </MenuItem>
        {_.map(languages, (languageName, languageShortcut) => {
          return (
            <MenuItem
              key={languageShortcut}
              value={languageShortcut}
              selected={language === languageShortcut}
              onClick={handleSelectTranslationLanguage(languageShortcut)}
            >
              {languageName}
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

export default memo(TranslationLanguageSelector)
