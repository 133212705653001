import { call, put, take, takeEvery, select, all, fork } from 'redux-saga/effects'
import { eventChannel, END } from 'redux-saga'

import ai from '@tabeeb/services/telemetryService'
import { uploadFile } from '@tabeeb/services/uploadService'
import { isHeicHeifImage, isImage } from '@tabeeb/modules/fileUploads/services'
import { rotateImage, convertHeicHeifFileToJpeg, getFileMetaInfo } from '@tabeeb/modules/gallery/services/fileMetaInfo'

import * as fileUploadsActions from '@tabeeb/modules/fileUploads/actions'

import selector from '@tabeeb/modules/shared/utils/selector'
import { getFileUploads } from '@tabeeb/modules/fileUploads/selectors'

import { FileUploadStatus } from '@tabeeb/modules/fileUploads/constants'
import { UploadContentType } from '@tabeeb/enums'

function* rotateFile({ file }) {
  const rotatedFile = yield call(rotateImage, file)
  return rotatedFile
}

function* convertHeicHeifToJpeg({ file }) {
  const convertedFile = yield call(convertHeicHeifFileToJpeg, file)
  return convertedFile
}

function* openPreprocessingThread({
  files,
  controlId,
  contentId,
  action,
  ignoreFileUploads,
  successPayload,
  failedPayload,
  allFilesLength,
  uploadContentType,
}) {
  const PARALLEL_COUNT = 8
  const threads = Array.from(new Array(PARALLEL_COUNT))

  let nextFileIndex = 0

  const processedFilesWithAbortControllers = []
  yield all(
    threads.map(() =>
      call(function* preprocessFiles() {
        while (nextFileIndex < files.length) {
          const fileWithAbortController = files[nextFileIndex]
          const { file, abortController, rotate, convert } = fileWithAbortController
          const index = nextFileIndex
          nextFileIndex++

          let resultFile = file

          if (convert) {
            resultFile = yield call(convertHeicHeifToJpeg, { file: resultFile })
          }

          if (rotate) {
            resultFile = yield call(rotateFile, { file: resultFile })
          }

          processedFilesWithAbortControllers[index] = { file: resultFile, abortController }
        }
      })
    )
  )

  yield call(openUploadingThread, {
    files,
    processedFiles: processedFilesWithAbortControllers,
    controlId,
    contentId,
    action,
    ignoreFileUploads,
    successPayload,
    failedPayload,
    allFilesLength,
    uploadContentType,
  })
}

async function uploadPageFile({ file, contentId, uploadContentType, abortController }) {
  if (file.size === 0) {
    throw new Error(`File '${file.name}' has zero size and cannot be uploaded`)
  }

  const { url: downloadUrl } = await uploadFile({
    file,
    contentId,
    abortController,
    uploadContentType,
  })

  return downloadUrl
}

function uploadFilesConcurrently({ files, contentId, uploadContentType, processedFiles }) {
  return eventChannel((emitter) => {
    let uploadedFiles = 0
    files.forEach(({ file, abortController }, index) => {
      uploadPageFile({
        file: processedFiles[index].file,
        contentId,
        uploadContentType,
        abortController,
      })
        .then(function onUpload(downloadUrl) {
          emitter({ file, downloadUrl, status: FileUploadStatus.Done })
          uploadedFiles++
          if (uploadedFiles === files.length) {
            emitter(END)
          }
        })
        .catch((error) => {
          if (error?.name !== 'AbortError') {
            ai.appInsights?.trackException({ exception: error, properties: { featureId: 'watchUploadFiles' } })
          }

          emitter({
            file,
            downloadUrl: null,
            status: error?.name === 'AbortError' ? FileUploadStatus.Canceled : FileUploadStatus.Failed,
            error,
          })
          uploadedFiles++
          if (uploadedFiles === files.length) {
            emitter(END)
          }
        })
    })
    return () => {}
  })
}

function* openUploadingThread({
  files,
  contentId,
  processedFiles,
  controlId,
  action,
  ignoreFileUploads,
  successPayload,
  failedPayload,
  allFilesLength,
  uploadContentType,
}) {
  const completedType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILES_COMPLETED')
  const successType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILES_SUCCESS')
  const failedType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILES_FAILED')

  const singleFileSuccessType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILE_SUCCESS')
  const singleFileCancelType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILE_CANCEL')
  const singleFileFailedType = action.type.replace('_UPLOAD_FILES', '_UPLOAD_FILE_FAILED')

  let notCancelledFiles = files
  if (!ignoreFileUploads) {
    const fileUploads = yield select(getFileUploads)
    notCancelledFiles = files.filter((fileToUpload) => {
      const fileUpload = fileUploads.find((fileUpload) => fileUpload.file === fileToUpload.file)
      return !fileUpload || fileUpload.status !== FileUploadStatus.Canceled
    })
  }

  const filesEventsChannel = yield call(uploadFilesConcurrently, {
    files: notCancelledFiles,
    contentId,
    uploadContentType,
    processedFiles,
  })

  yield fork(function* () {
    yield take(fileUploadsActions.clearFileUploads)
    filesEventsChannel.close()
    if (allFilesLength !== successPayload.length + failedPayload.length) {
      const actions = []
      if (successPayload.length > 0) {
        actions.push(put({ type: successType, payload: successPayload }))
      }

      if (failedPayload.length > 0) {
        actions.push(put({ type: failedType, payload: failedPayload }))
      }
      yield all(actions)

      yield put({ type: completedType })
    }
  })

  try {
    while (true) {
      const fileEvent = yield take(filesEventsChannel)
      const { file, downloadUrl, status, error } = fileEvent
      if (downloadUrl) {
        successPayload.push({ file, url: downloadUrl, controlId })
        yield put({
          type: singleFileSuccessType,
          payload: { file, url: downloadUrl, controlId, value: 100 },
        })
        if (!ignoreFileUploads) {
          yield put(fileUploadsActions.updateFileUploads([{ file, url: downloadUrl, value: 100, status }]))
        }
      } else {
        failedPayload.push({ file, url: downloadUrl, error })
        yield put({
          type: status === FileUploadStatus.Canceled ? singleFileCancelType : singleFileFailedType,
          payload: { file, url: downloadUrl, error },
        })
        if (!ignoreFileUploads) {
          yield put(fileUploadsActions.updateFileUploads([{ file, status }]))
        }
      }
    }
  } finally {
    if (allFilesLength === successPayload.length + failedPayload.length) {
      const actions = []
      if (successPayload.length > 0) {
        actions.push(put({ type: successType, payload: successPayload }))
      }

      if (failedPayload.length > 0) {
        actions.push(put({ type: failedType, payload: failedPayload }))
      }
      yield all(actions)

      yield put({ type: completedType })
    }
  }
}

function* uploadFiles(action) {
  const successPayload = []
  const failedPayload = []

  let {
    files = [],
    controlId = null,
    ignoreFileUploads = false,
    retry,
    contentId = null,
    uploadContentType = UploadContentType.Page,
  } = action.payload

  if (!Array.isArray(files)) {
    files = [...files]
  }

  const AbortController = yield call(() => import('@azure/abort-controller').then((i) => i.AbortController))

  const filesWithAbortControllers = files.map((file) => {
    const abortController = new AbortController()
    return { file, abortController }
  })

  if (!ignoreFileUploads) {
    const fileUploads = yield select(getFileUploads)
    yield put(fileUploadsActions.clearSuccessFileUploads())
    yield put(
      fileUploadsActions.addFileUploads(
        filesWithAbortControllers.map(({ file, abortController }, index) => {
          const fileUpload = fileUploads.find((fileUpload) => fileUpload.file === file)
          return {
            file,
            controlId,
            uploadContentType,
            index,
            value: 0,
            abortController,
            status: !retry && fileUpload && fileUpload.status ? fileUpload.status : FileUploadStatus.Pending,
          }
        })
      )
    )
  }
  const filesToProcess = []
  const filesToUpload = []
  for (const fileWithAbortController of filesWithAbortControllers) {
    const { file } = fileWithAbortController
    if (isImage(file)) {
      let meta = null
      try {
        meta = yield call(getFileMetaInfo, file)
      } catch (e) {
        console.error(e)
      }

      const needsRotation = meta != null && meta.orientation && meta.orientation !== 1
      const needsConvertation = isHeicHeifImage(file)
      if (needsConvertation || needsRotation) {
        fileWithAbortController.rotate = needsRotation
        fileWithAbortController.convert = needsConvertation

        filesToProcess.push(fileWithAbortController)
      } else {
        filesToUpload.push(fileWithAbortController)
      }
    } else {
      filesToUpload.push(fileWithAbortController)
    }
  }

  yield all([
    call(openUploadingThread, {
      files: filesToUpload,
      contentId,
      processedFiles: filesToUpload,
      controlId,
      action,
      ignoreFileUploads,
      successPayload,
      failedPayload,
      allFilesLength: files.length,
      uploadContentType,
    }),
    call(openPreprocessingThread, {
      files: filesToProcess,
      contentId,
      controlId,
      action,
      ignoreFileUploads,
      successPayload,
      failedPayload,
      allFilesLength: files.length,
      uploadContentType,
    }),
  ])
}

function* cancelFileUploads({ payload }) {
  const filesToCancel = payload
  filesToCancel.forEach((fileToCancel) => {
    fileToCancel.abortController && fileToCancel.abortController.abort()
  })
}

export default function* watchUploadFiles() {
  yield all([
    takeEvery((action) => /^.*_UPLOAD_FILES$/.test(action.type), uploadFiles),
    takeEvery(fileUploadsActions.cancelFileUploads, cancelFileUploads),
  ])
}
