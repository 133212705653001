import { createSelector } from 'reselect'
import oidcUserManager from '@tabeeb/services/oidcUserManager'
import { SystemOfMeasuresToGoogleUnitSystem } from '@tabeeb/enums'

export const getMe = (state) => state.account.me
export const getCurrentUserAvatar = (state) => state.account.me.AvatarUrl
export const getCurrentUserSignature = (state) => state.account.me.Signature

export const getCurrentUserFullName = createSelector(getMe, (me) => me.Name)

export const getCurrentUserUpdateProfile = createSelector([getMe], (me) => {
  const {
    Address,
    Email,
    FirstName,
    LastName,
    Institution,
    PhoneNumber,
    NotificationsSettings,
    DateOfBirth,
    Gender,
    SystemOfMeasure,
  } = me
  return {
    Address,
    Email,
    FirstName,
    LastName,
    Institution,
    PhoneNumber,
    NotificationsSettings,
    DateOfBirth,
    Gender,
    SystemOfMeasure,
  }
})

export const getRole = createSelector(getMe, (me) => me.RoleName)
export const getCurrentUserId = (state) => {
  const me = getMe(state)
  if (me) {
    return me.Id ? me.Id : null
  }

  return null
}
export const getCurrentUserGuid = (state) => {
  const me = getMe(state)
  return me?.IdentityGuid
}

export const getCurrentUserEmail = (state) => {
  const me = getMe(state)
  if (me) {
    return me.Email ? me.Email : null
  }

  return null
}

export const getIsIdentityAuthenticated = (state) => {
  const user = oidcUserManager.getUserSync()
  const accessToken = user?.access_token
  const isExpired = user?.expired
  return Boolean(accessToken) && !isExpired
}

export const getIsUserAuthorized = (state) => {
  const isAuthenticated = getIsIdentityAuthenticated()

  return Boolean(getCurrentUserId(state)) && isAuthenticated
}

export const getIsUserInAnyRole = (state, rolesList) => {
  return rolesList.includes(getRole(state))
}

export const getIsAppAccountCreated = (state) => Boolean(state.account.authState.isTenantAccountCreated)
export const getIsTenantAccountUnexpectedError = (state) =>
  Boolean(state.account.authState.isTenantAccountUnexpectedError)
export const getIsTenantAuthFlowCompleted = (state) => Boolean(state.account.authState.isAuthFlowCompleted)

export const getIsTenantAccountLocked = (state) => Boolean(state.account.authState.isTenantAccountLocked)
export const getIdentityMeSelector = (state) => state.account.identityMe.data
export const getIdentityMeFetchStatus = (state) => state.account.identityMe.fetchStatus

export const getCurrentUserSystemOfMeasure = createSelector(getMe, (me) => me.SystemOfMeasure)
export const getCurrentUserGoogleUnitSystem = createSelector(
  getCurrentUserSystemOfMeasure,
  (systemId) => SystemOfMeasuresToGoogleUnitSystem[systemId]
)
