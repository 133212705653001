import { put, takeEvery, take, all } from 'redux-saga/effects'

import { galleryActions } from '@tabeeb/modules/gallery'

import { annotationsActions } from '..'

function* restoreContentAnnotations(action) {
  const { annotations, ...rest } = action.payload

  yield put(galleryActions.addAnnotations(annotations))
  yield put(
    annotationsActions.restoreAnnotationsRequest({
      annotationIds: annotations.map((annotation) => annotation.Id),
      annotations,
      ...rest,
    })
  )

  const result = yield take([annotationsActions.restoreAnnotationsFailed, annotationsActions.restoreAnnotationsSuccess])
  if (result.type === annotationsActions.restoreAnnotationsFailed().type) {
    yield put(galleryActions.deleteAnnotations(annotations))
  }
}

function* restoreAnnotationSagas() {
  yield all([takeEvery(annotationsActions.restoreContentAnnotations, restoreContentAnnotations)])
}

export default restoreAnnotationSagas
