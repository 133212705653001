export const getCertificatesList = (state) => state.certificates.certificatesList.Certificates
export const getCertificatesTotalCount = (state) => state.certificates.certificatesList.TotalCount
export const getCertificatesFilters = (state) => state.certificates.certificatesList.Filters
export const getCertificatesSorting = (state) => state.certificates.certificatesList.Sorting
export const getCertificatesPageNumber = (state) => state.certificates.certificatesList.PageNumber
export const getCertificatesPageSize = (state) => state.certificates.certificatesList.PageSize
export const getCertificatesLoading = (state) => state.certificates.certificatesList.Loading

export const getCertificateToReview = (state) => state.certificates.certificateToReview.Certificate
export const getCertificateToReviewOwner = (state) => state.certificates.certificateToReview.Owner
