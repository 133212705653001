import { put, takeLatest, all, select, delay } from 'redux-saga/effects'

import { usersActions } from '..'

import { mobileZoomMin, ZoomResponseDelay } from '../common/mobileZoom'
import { signalrActions, signalrConstants, signalrEvents } from '../../modules/signalr'
import { contentStateSelectors } from '../../modules/shared/content'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { isAndroid } from 'react-device-detect'
import { getCurrentUser } from '../selectors'
import { ZOOM_MULTIPLIER, ZOOM_STEP } from '../common/mobileZoom'
import { updateMobileZoom } from '@tabeeb/modules/presentation/actions/tracks'

function* onMobileZoomIn({ payload, response }) {
  const contentId = yield select(contentStateSelectors.getContentId)
  const userId = payload

  yield put(signalrActions.invokeHubAction({ method: 'IncreaseImage', args: [contentId, userId] }))

  yield delay(ZoomResponseDelay)
}

function* onMobileZoomOut({ payload, response }) {
  const contentId = yield select(contentStateSelectors.getContentId)
  const userId = payload

  yield put(signalrActions.invokeHubAction({ method: 'DecreaseImage', args: [contentId, userId] }))

  yield delay(ZoomResponseDelay)
}

function* onImageIncreased({ payload }) {
  const [contentId, userId] = payload
  const currentUserId = yield select(getCurrentUserId)
  const currentContentId = yield select(getContentId)

  if (!isAndroid || currentUserId !== userId || currentContentId !== contentId) {
    return
  }

  const user = yield select(getCurrentUser)
  const zoomValue = parseInt(user.mobileZoomValue, 10) + ZOOM_STEP

  if (zoomValue > user.mobileZoomMax) {
    return
  }

  const updateZoomPayload = {
    userId,
    contentId,
    zoomValue: zoomValue / ZOOM_MULTIPLIER,
  }

  yield put(updateMobileZoom(updateZoomPayload))
}

function* onImageDecreased({ payload }) {
  const [contentId, userId] = payload
  const currentUserId = yield select(getCurrentUserId)
  const currentContentId = yield select(getContentId)

  if (!isAndroid || currentUserId !== userId || currentContentId !== contentId) {
    return
  }

  const user = yield select(getCurrentUser)
  const zoomValue = parseInt(user.mobileZoomValue, 10) - ZOOM_STEP

  if (zoomValue < mobileZoomMin) {
    return
  }

  const updateZoomPayload = {
    userId,
    contentId,
    zoomValue: zoomValue / ZOOM_MULTIPLIER,
  }

  yield put(updateMobileZoom(updateZoomPayload))
}

function* mobileZoomSaga() {
  yield all([
    takeLatest(usersActions.onMobileZoomIn, onMobileZoomIn),
    takeLatest(usersActions.onMobileZoomOut, onMobileZoomOut),
    // signalR
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onImageDecreased, onImageDecreased),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onImageIncreased, onImageIncreased),
  ])
}

export default mobileZoomSaga
