import { createAction } from 'redux-actions'

export const resetState = createAction('RESET_NOTIFICATIONS_STATE')
export const resetNotificationsList = createAction('RESET_NOTIFICATIONS_LIST')

export const loadNotifications = createAction('LOAD_NOTIFICATIONS')
export const getNotificationsRequest = createAction('GET_NOTIFICATIONS_REQUEST')
export const getNotificationsSuccess = createAction('GET_NOTIFICATIONS_SUCCESS')

export const getNotificationsCountRequest = createAction('GET_NOTIFICATIONS_COUNT_REQUEST')
export const getNotificationsCountSuccess = createAction('GET_NOTIFICATIONS_COUNT_SUCCESS')
export const getNotificationsCountFailed = createAction('GET_NOTIFICATIONS_COUNT_FAILED')

export const setNotificationsList = createAction('SET_NOTIFICATIONS_LIST')
export const changeNotificationsFilters = createAction('CHANGE_NOTIFICATIONS_FILTER')

export const addNewNotification = createAction('ADD_NEW_NOTIFICATION')
export const addNotification = createAction('ADD_NOTIFICATION')
export const updateNotification = createAction('UPDATE_NOTIFICATION')
export const removeNotification = createAction('REMOVE_NOTIFICATION')
export const deleteNotification = createAction('DELETE_NOTIFICATION')
export const replaceNotification = createAction('REPLACE_NOTIFICATION')

export const acceptNotification = createAction('ACCEPT_NOTIFICATION')

export const acceptNotificationRequest = createAction('ACCEPT_NOTIFICATION_REQUEST')
export const acceptNotificationSuccess = createAction('ACCEPT_NOTIFICATION_SUCCESS')
export const acceptNotificationFailed = createAction('ACCEPT_NOTIFICATION_FAILED')

export const rejectNotificationRequest = createAction('REJECT_NOTIFICATION_REQUEST')
export const rejectNotificationSuccess = createAction('REJECT_NOTIFICATION_SUCCESS')

export const revokeSessionSharingRequest = createAction('REVOKE_SESSION_SHARING_REQUEST')
export const revokeSessionSharingSuccess = createAction('REVOKE_SESSION_SHARING_SUCCESS')

export const setNotificationsListLoading = createAction('SET_NOTIFICATIONS_LIST_LOADING')

export const setBatchesCount = createAction('SET_BATCHES_COUNT')
export const setShouldLoadMore = createAction('SET_SHOULD_LOAD_MORE')

export const markAsViewed = createAction('MARK_AS_VIEWED')
export const markNotificationsAsViewed = createAction('MARK_NOTIFICATIONS_AS_VIEWED')
export const markNotificationsAsViewedRequest = createAction('MARK_NOTIFICATIONS_AS_VIEWED_REQUEST')

export const deleteReviewedNotificationManual = createAction('DELETE_REVIEWED_NOTIFICATION_MANUAL')
