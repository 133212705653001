import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    avatarGrid: {
      alignSelf: 'center',
      marginBottom: theme.spacing(1.25),
      maxWidth: '100%',
    },
    avatarBlock: {
      width: 120,
      height: 120,
      fontSize: 38,
    },
    flexCenteredGrid: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: '100%',
    },
    paginationUl: {
      marginTop: theme.spacing(2),
      '& .MuiPagination-ul': {
        justifyContent: 'center',
      },
    },
    grid: {
      maxWidth: '100%',
    },
  }
})
