import { Component } from 'react'
import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { AnnotationType } from '@tabeeb/enums'
import { accountSelectors } from '@tabeeb/modules/account'
import { contentStateSelectors } from '@tabeeb/shared/content'
import { playerActions, playerSelectors } from '@tabeeb/modules/player'
import { aiSelectors, aiActions } from '@tabeeb/modules/artificialIntelligence'

import HotspotDialog from '../components/HotspotDialog'

import { annotationsActions as annotationActions, annotationsSelectors } from '..'

class HotspotDialogContainer extends Component {
  state = {
    resumeVideoAfterClose: false,
  }

  componentDidMount() {
    const {
      isVideoPlaying,
      resumeVideoAfterClose,
      playerActions: { onPlayerPaused },
    } = this.props

    if (isVideoPlaying) {
      onPlayerPaused()
    }

    this.setState({ resumeVideoAfterClose: isVideoPlaying || resumeVideoAfterClose })
  }

  componentWillUnmount() {
    const { resumeVideoAfterClose } = this.state

    const {
      isVideoPlaying,
      playerActions: { onPlayerPlay },
    } = this.props

    if (resumeVideoAfterClose && !isVideoPlaying) {
      onPlayerPlay()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isOpen, childAnnotations } = this.props

    if (isOpen && prevProps.childAnnotations.length === 1 && childAnnotations.length === 0) {
      this._handleClose()
    }
  }

  _handleClose = (e) => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }

    const {
      annotation,
      childAnnotations,
      annotationActions: { closeHotspotDialog, deleteContentAnnotations },
    } = this.props

    closeHotspotDialog()

    if (childAnnotations.length === 0) {
      if (annotation.ParentAnnotationId) {
        deleteContentAnnotations({ annotationIds: [annotation.ParentAnnotationId] })
      } else {
        deleteContentAnnotations({ annotationIds: [annotation.Id] })
      }
    }
  }

  _addOrUpdateAnnotation = (model) => {}

  _handleReply = (data = {}, annotationType, editAnnotation) => {
    const {
      annotation,
      parentAnnotation,
      contentId,
      aiActions: { addUniqueAiObjectRequest },
      annotationActions: { addAnnotationRequest, updateContentAnnotation, addAudioAnnotationRequest },
    } = this.props

    if (annotationType === AnnotationType.Text) {
      const model = {
        Color: 'black',
        FontSize: 0,
        PageId: annotation.PageId,
        ParentAnnotationId: annotation.Id,
        Text: data,
        Timestamp: -1,
        Type: annotationType,
      }

      addAnnotationRequest(model)

      return
    }

    if (annotationType === AnnotationType.Audio) {
      addAudioAnnotationRequest({
        contentId,
        pageId: annotation.PageId,
        parentAnnotationId: annotation.Id,
        data,
      })

      return
    }

    if (annotationType === AnnotationType.AI) {
      const { selectedAiObject } = this.props
      const { newUniquieAiObjectName, selectedUniqueAiObject } = data

      // just ai object
      if (!selectedUniqueAiObject && !newUniquieAiObjectName) {
        const annotationText = `CV Class: ${selectedAiObject.Name}`

        const model = {
          Color: 'black',
          FontSize: 0,
          PageId: annotation.PageId,
          ParentAnnotationId: annotation.Id,
          Text: annotationText,
          Timestamp: -1,
          Type: annotationType,
          AIObjectId: selectedAiObject.Id,
        }

        if (!editAnnotation) {
          addAnnotationRequest(model)
        } else {
          model.Id = editAnnotation.Id
          model.UserId = editAnnotation.UserId
          updateContentAnnotation({ annotation: model })

          updateContentAnnotation({ annotation: { ...annotation, Color: selectedAiObject.Color } })
          if (parentAnnotation) {
            updateContentAnnotation({ annotation: { ...parentAnnotation, Color: selectedAiObject.Color } })
          }
        }

        return
      }

      // create new unique
      if (newUniquieAiObjectName && !selectedUniqueAiObject) {
        const { currentUserId } = this.props

        const model = {
          userId: currentUserId,
          name: newUniquieAiObjectName,
          contentId,
          aiObjectId: selectedAiObject.Id,
          parentAnnotationId: annotation.Id,
          annotationId: null,
        }

        if (editAnnotation) {
          model.annotationId = editAnnotation.Id
        }

        addUniqueAiObjectRequest(model)

        updateContentAnnotation({ annotation: { ...annotation, Color: selectedAiObject.Color } })
        if (parentAnnotation) {
          updateContentAnnotation({ annotation: { ...parentAnnotation, Color: selectedAiObject.Color } })
        }
        return
      }

      // selected unque ai object
      if (!newUniquieAiObjectName && selectedUniqueAiObject) {
        const annotationText = `CV Class: ${selectedAiObject.Name}<br> AI Object: ${selectedUniqueAiObject.Name}`

        const model = {
          Color: 'black',
          FontSize: 0,
          PageId: annotation.PageId,
          ParentAnnotationId: annotation.Id,
          Text: annotationText,
          Timestamp: -1,
          Type: annotationType,
          AIObjectId: selectedAiObject.Id,
          UniqueAIObjectId: selectedUniqueAiObject.Id,
        }

        if (!editAnnotation) {
          addAnnotationRequest(model)
        } else {
          model.Id = editAnnotation.Id
          model.UserId = editAnnotation.UserId
          updateContentAnnotation({ annotation: model })

          updateContentAnnotation({ annotation: { ...annotation, Color: selectedAiObject.Color } })
          if (parentAnnotation) {
            updateContentAnnotation({ annotation: { ...parentAnnotation, Color: selectedAiObject.Color } })
          }
        }
      }
    }
  }

  render() {
    const { isOpen, annotation, x, y, childAnnotations, uniqueAiObjects, selectedAiObject } = this.props
    if (!isOpen) {
      return null
    }

    const props = {
      annotation,
      x,
      y,
      childAnnotations,
      selectedAiObject,
      uniqueAiObjects,
      handleClose: this._handleClose,
      handleReply: this._handleReply,
    }

    return <HotspotDialog {...props} />
  }
}

HotspotDialogContainer.propTypes = {
  annotation: PropTypes.object,
}

const mapStateToProps = (state, { Id }) => {
  const isOpen = annotationsSelectors.getHotspotDialogAnnotationId(state) === Id
  if (!isOpen) {
    return { isOpen }
  }

  const resumeVideoAfterClose = annotationsSelectors.getHotspotDialogVideoResume(state)

  const contentId = contentStateSelectors.getContentId(state)
  const currentUserId = accountSelectors.getCurrentUserId(state)

  const annotation = annotationsSelectors.getAnnotationById(state, { Id })
  const parentAnnotation = annotationsSelectors.getAnnotationById(state, { Id: annotation.ParentAnnotationId })
  const childAnnotations = annotationsSelectors.getAnnotationChilds(state, { Id })

  const selectedAiObject = aiSelectors.getSelectedAiObject(state)
  const uniqueAiObjects = aiSelectors.getUniqueAIObjectsByAIObjectId(state, { Id: selectedAiObject.Id })

  const isVideoPlaying = playerSelectors.getIsVideoPlaying(state)

  return {
    isOpen,
    isVideoPlaying,
    contentId,
    annotation,
    parentAnnotation,
    currentUserId,
    childAnnotations,
    selectedAiObject,
    uniqueAiObjects,
    resumeVideoAfterClose,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    annotationActions: bindActionCreators(annotationActions, dispatch),
    playerActions: bindActionCreators(playerActions, dispatch),
    aiActions: bindActionCreators(aiActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HotspotDialogContainer)
