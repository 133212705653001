import { handleActions } from 'redux-actions'

import { annotationsActions } from '..'

const defaultState = {
  undo: [],
  redo: [],
}

export default handleActions(
  {
    [annotationsActions.addUndoAction](state, action) {
      return {
        ...state,
        undo: [...state.undo, action.payload],
      }
    },
    [annotationsActions.popUndoAction](state, action) {
      const updated = [...state.undo]
      updated.pop()

      return {
        ...state,
        undo: updated,
      }
    },
    [annotationsActions.addRedoAction](state, action) {
      return {
        ...state,
        redo: [...state.redo, action.payload],
      }
    },
    [annotationsActions.popRedoAction](state, action) {
      const updated = [...state.redo]
      updated.pop()

      return {
        ...state,
        redo: updated,
      }
    },
    [annotationsActions.resetRedoActionsQueue](state, action) {
      return {
        ...state,
        redo: [],
      }
    },
    [annotationsActions.resetUndoActionsQueue](state, action) {
      return {
        ...state,
        undo: [],
      }
    },
    [annotationsActions.resetAnnotationsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
