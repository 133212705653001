import { useContext, useEffect } from 'react'
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import { Html } from '@react-three/drei'
import { useThree } from '@react-three/fiber'

import { Card, CardContent, CardHeader, Fade, IconButton, Popper, ThemeProvider } from '@mui/material'
import { CloseOutlined, RefreshOutlined, SettingsOutlined } from '@mui/icons-material'
import { ThemeContext } from '@emotion/react'

import { usePopoverState } from '@tabeeb/shared/utils/hooks'
import { getSelectedGalleryItemId } from '@tabeeb/modules/gallery/selectors'

import ViewNavigation from './ViewNavigation'

import FieldOfViewSettings from './FieldOfViewSettings'
import PointSizeSettings from './PointSizeSettings'

import AnnotationsList from './AnnotationsList'
import UnitSettings from './UnitSettings'

import { getPointCloudSettings, setFieldOfView, setPointSize, setViewerBackground } from '../../actions'
import { Defaults } from '../../constants'
import { useModelBoundingBox } from '../../hooks'
import { setView } from '../../utils/controls'
import MeasurementsSettings from './MeasurementsSettings'

const Settings = ({ container }) => {
  const theme = useContext(ThemeContext)
  const store = useContext(ReactReduxContext)
  const dispatch = useDispatch()

  const modelBox = useModelBoundingBox()

  const { camera, controls, scene } = useThree()

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const pageId = useSelector(getSelectedGalleryItemId)

  useEffect(() => {
    dispatch(getPointCloudSettings.request({ pageId }))
  }, [dispatch, pageId])

  const onSettingsClick = () => {
    if (open) {
      onClose()
    } else {
      onOpen()
    }
  }

  const onFieldOfViewChange = (fov) => {
    camera.fov = fov
    camera.updateProjectionMatrix()
  }

  const onViewChange = (view) => {
    setView(controls, modelBox, view)
  }

  const onPointSizeChange = (size) => {
    scene.traverse((currentNode) => {
      const node = currentNode

      if (node.isPoints) {
        node.material.size = size
      }
    })
  }

  const resetSettings = () => {
    dispatch(setFieldOfView(Defaults.FIELD_OF_VIEW))
    onFieldOfViewChange(Defaults.FIELD_OF_VIEW)

    dispatch(setViewerBackground(Defaults.BACKGROUND))
    onViewChange(Defaults.VIEW)

    dispatch(setPointSize(Defaults.POINT_SIZE))
    onPointSizeChange(Defaults.POINT_SIZE)
  }

  return (
    <Html hidden>
      {createPortal(
        <ThemeProvider theme={theme}>
          <ReactReduxContext.Provider value={store}>
            <IconButton
              ref={anchorRef}
              size='small'
              sx={{ position: 'absolute', top: theme.spacing(), left: theme.spacing() }}
              title={open ? 'Close viewer settings' : 'Open viewer settings'}
              onClick={onSettingsClick}
            >
              <SettingsOutlined htmlColor='#bdbdbd' />
            </IconButton>
            <Popper
              sx={{
                zIndex: 8,
              }}
              open={open}
              anchorEl={anchorRef.current}
              disablePortal
              placement='right-start'
              transition
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps}>
                  <Card sx={{ maxWidth: 345, minWidth: 345 }}>
                    <CardHeader
                      action={
                        <>
                          <IconButton size='small' title='Reset settings' onClick={resetSettings}>
                            <RefreshOutlined />
                          </IconButton>
                          <IconButton size='small' title='Close settings' onClick={onClose}>
                            <CloseOutlined />
                          </IconButton>
                        </>
                      }
                      title='Settings'
                    />
                    <CardContent>
                      <AnnotationsList />
                      <UnitSettings />
                      <MeasurementsSettings />
                    </CardContent>
                  </Card>
                </Fade>
              )}
            </Popper>
            <ViewNavigation onChange={onViewChange} />
            <PointSizeSettings onChange={onPointSizeChange} />
            <FieldOfViewSettings onChange={onFieldOfViewChange} />
          </ReactReduxContext.Provider>
        </ThemeProvider>,
        container
      )}
    </Html>
  )
}

Settings.propTypes = {
  container: PropTypes.instanceOf(Element).isRequired,
}

export default Settings
