import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    bottomButtonsContainer: {
      justifyContent: 'space-between',
      marginTop: theme.spacing(2),
    },
    imagePreview: {
      maxHeight: 300,
      maxWidth: 300,
    },
    gridPadding: {
      padding: theme.spacing(),
    },
    rejectedCommentHeader: {
      textTransform: 'uppercase',
      fontWeight: theme.typography.fontWeightBold,
      paddingBottom: theme.spacing(),
    },
    divider: {
      margin: theme.spacing(),
    },
  }
})
