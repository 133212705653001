import { forwardRef, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as THREE from 'three'
import CameraControlsImpl from 'camera-controls'

import { extend, useFrame, useThree } from '@react-three/fiber'

import { KeyboardKeyHold } from 'hold-event'

const SmoothCameraControls = forwardRef((props, ref) => {
  useMemo(() => {
    CameraControlsImpl.install({ THREE })
    extend({ CameraControlsImpl })
  }, [])

  const { camera, domElement, makeDefault, onStart, onEnd, onChange, regress, ...restProps } = props

  const defaultCamera = useThree((state) => state.camera)
  const gl = useThree((state) => state.gl)
  const invalidate = useThree((state) => state.invalidate)
  const events = useThree((state) => state.events)
  const set = useThree((state) => state.set)
  const get = useThree((state) => state.get)
  const performance = useThree((state) => state.performance)

  const explCamera = camera || defaultCamera
  const explDomElement = domElement || events.connected || gl.domElement

  const controls = useMemo(() => new CameraControlsImpl(explCamera), [explCamera])

  useFrame((_, delta) => {
    if (controls.enabled) {
      controls.update(delta)
    }
  }, -1)

  useEffect(() => {
    controls.connect(explDomElement)

    return () => {
      controls.disconnect()
    }
  }, [explDomElement, controls])

  useEffect(() => {
    const callback = (e) => {
      invalidate()
      if (regress) {
        performance.regress()
      }

      if (onChange) {
        onChange(e)
      }
    }

    const onStartCb = (e) => {
      if (onStart) {
        onStart(e)
      }
    }

    const onEndCb = (e) => {
      if (onEnd) {
        onEnd(e)
      }
    }

    controls.addEventListener('update', callback)
    controls.addEventListener('controlstart', onStartCb)
    controls.addEventListener('controlend', onEndCb)

    // Keyboard controls

    const HOLD_INTERVAL = 1000 / 60
    const MOVE_SPEED = 0.02
    const ROTATION_SPEED = 0.1

    const moveRightKey = new KeyboardKeyHold('ArrowRight', HOLD_INTERVAL)
    const moveLeftKey = new KeyboardKeyHold('ArrowLeft', HOLD_INTERVAL)
    const moveForwardKey = new KeyboardKeyHold('ArrowUp', HOLD_INTERVAL)
    const moveBackKey = new KeyboardKeyHold('ArrowDown', HOLD_INTERVAL)

    const rotateRightKey = new KeyboardKeyHold('KeyD', 100)
    const rotateLeftKey = new KeyboardKeyHold('KeyA', 100)
    const rotateUpKey = new KeyboardKeyHold('KeyW', 100)
    const rotateDownKey = new KeyboardKeyHold('KeyS', 100)

    const moveForward = (event) => controls.dolly(MOVE_SPEED * event.deltaTime, HOLD_INTERVAL)
    const moveBack = (event) => controls.dolly(-MOVE_SPEED * event.deltaTime, HOLD_INTERVAL)
    const moveRight = (event) => controls.truck(MOVE_SPEED * event.deltaTime, 0, HOLD_INTERVAL)
    const moveLeft = (event) => controls.truck(-MOVE_SPEED * event.deltaTime, 0, HOLD_INTERVAL)

    const rotateLeft = (event) => controls.rotate(-ROTATION_SPEED * THREE.MathUtils.DEG2RAD * event.deltaTime, 0, true)
    const rotateRight = (event) => controls.rotate(ROTATION_SPEED * THREE.MathUtils.DEG2RAD * event.deltaTime, 0, true)
    const rotateUp = (event) => controls.rotate(0, -ROTATION_SPEED * THREE.MathUtils.DEG2RAD * event.deltaTime, true)
    const rotateDown = (event) => controls.rotate(0, ROTATION_SPEED * THREE.MathUtils.DEG2RAD * event.deltaTime, true)

    moveRightKey.addEventListener('holding', moveRight)
    moveLeftKey.addEventListener('holding', moveLeft)
    moveForwardKey.addEventListener('holding', moveForward)
    moveBackKey.addEventListener('holding', moveBack)

    rotateRightKey.addEventListener('holding', rotateRight)
    rotateLeftKey.addEventListener('holding', rotateLeft)
    rotateUpKey.addEventListener('holding', rotateUp)
    rotateDownKey.addEventListener('holding', rotateDown)

    return () => {
      controls.removeEventListener('update', callback)
      controls.removeEventListener('controlstart', onStartCb)
      controls.removeEventListener('controlend', onEndCb)

      moveRightKey.removeEventListener('holding', moveRight)
      moveLeftKey.removeEventListener('holding', moveLeft)
      moveForwardKey.removeEventListener('holding', moveForward)
      moveBackKey.removeEventListener('holding', moveBack)

      rotateRightKey.removeEventListener('holding', rotateRight)
      rotateLeftKey.removeEventListener('holding', rotateLeft)
      rotateUpKey.removeEventListener('holding', rotateUp)
      rotateDownKey.removeEventListener('holding', rotateDown)
    }
  }, [controls, onStart, onEnd, invalidate, regress, onChange, performance])

  useEffect(() => {
    if (makeDefault) {
      const old = get().controls
      set({ controls })
      return () => {
        set({ controls: old })
      }
    }

    return () => {}
  }, [makeDefault, controls, get, set])

  return <primitive ref={ref} object={controls} {...restProps} />
})

SmoothCameraControls.propTypes = {
  regress: PropTypes.bool,
  camera: PropTypes.instanceOf(THREE.Camera),
  domElement: PropTypes.instanceOf(HTMLElement),
  makeDefault: PropTypes.bool,
  onChange: PropTypes.func,
  onEnd: PropTypes.func,
  onStart: PropTypes.func,
}

export default SmoothCameraControls
