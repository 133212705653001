import { useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'
import PropTypes from 'prop-types'

import Routes from '@tabeeb/routes'
import { callAPIPromise } from '@tabeeb/state/sagas/watchRequest'

import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { updateAnnouncement } from '../../actions'
import { EditAnnouncementForm } from '../../components'
import { useAnnouncement } from '../../hooks'

const EditAnnouncementPage = ({ id }) => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async (editedAnnouncement) => {
      try {
        await callAPIPromise(updateAnnouncement.request(editedAnnouncement))
        onAddSuccessNotification({ message: 'Announcement successfully updated' })
        dispatch(push(Routes.announcementsManagement))
      } catch {
        onAddErrorNotification({ message: 'Failed to update announcement' })
      }
    },
    [dispatch]
  )

  const onError = useCallback(() => {
    dispatch(onAddErrorNotification({ message: 'Failed to load announcement' }))
    dispatch(push(Routes.announcementsManagement))
  }, [dispatch])

  const { loading, announcement } = useAnnouncement({ id, onError })
  if (loading || !announcement) {
    return <CircularProgressBar />
  }

  return <EditAnnouncementForm title='Edit announcement' initial={announcement} onSubmit={onSubmit} />
}

EditAnnouncementPage.propTypes = {
  id: PropTypes.number.isRequired,
}

export default memo(EditAnnouncementPage)
