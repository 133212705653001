import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    fullwidthField: {
      width: '100%',
    },
    rootCard: {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    rootGrid: {
      paddingTop: theme.spacing(2),
    },
    bottomButtonsContainer: {
      marginTop: theme.spacing(2),
    },
  }
})
