import { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { getFileUploadsCount } from '@tabeeb/modules/fileUploads/selectors'
import { TabPanel, TabPanelLocation } from '../../../../Enums'
import { sessionTabsActions, sessionTabsSelectors } from '../..'
import PanelButton from '../TabButton'
import { TabPanelLocationGroups } from '../../constants'

const Navbar = ({ location }) => {
  const dispatch = useDispatch()
  const selectedTab = useSelector((state) => sessionTabsSelectors.getSelectedPanel(state, location))

  const fileUploadsCount = useSelector(getFileUploadsCount)

  const onTabButtonClick = (tabName, position) => {
    dispatch(sessionTabsActions.switchPanel(tabName, position))
  }

  useEffect(() => {
    if (selectedTab === TabPanel.FileUploads && fileUploadsCount === 0) {
      dispatch(sessionTabsActions.switchPanel(TabPanel.Gallery, TabPanelLocation.Right))
    }
  }, [selectedTab, fileUploadsCount, dispatch])

  return (
    <ul
      className={classNames('tabeebSideBarNavbar', {
        'tabeebSideBarNavbar-bottom': location === TabPanelLocation.Bottom,
      })}
    >
      {TabPanelLocationGroups[location].map((tab, index) => (
        <PanelButton
          key={index}
          tabName={tab}
          location={location}
          onClick={onTabButtonClick}
          selectedTabName={selectedTab}
        />
      ))}
    </ul>
  )
}

Navbar.propTypes = {
  location: PropTypes.string.isRequired,
}

export default memo(Navbar)
