import { memo } from 'react'
import PropTypes from 'prop-types'

import { Popover, Paper } from '@material-ui/core'
import { TextWithLinks } from '@tabeeb/modules/shared/textWithLinks'
import { ContentType } from '@tabeeb/enums'
import { AzureMP } from '../../../shared/azureMediaPlayer'

import './styles.less'

const FormControlInfoWindow = ({ control, anchorEl, onCloseDialog, isOpen }) => {
  const anchorOrigin = { horizontal: 'right', vertical: 'center' }
  const transformOrigin = { horizontal: 'left', vertical: 'center' }

  const info = control.Info
  const contentType = info.Type
  const contentUrl = info.EncodedUrl || info.Url
  const controlLabel = control.Label
  const description = info.Description
  const fileName = info.FileName
  const isImage = contentType !== 0 ? contentType === ContentType.Image : false
  const isVideo =
    contentType !== 0 ? contentType === ContentType.Video || contentType === ContentType.UnencodedVideo : false

  let videoPlayerSrc = []
  if (contentUrl) {
    if (contentUrl.toLowerCase().endsWith('.ism/manifest')) {
      videoPlayerSrc = [
        {
          src: contentUrl,
          type: 'application/vnd.ms-sstr+xml',
        },
        {
          src: contentUrl.replace('.ism/manifest', '_H264_2000kbps_AAC_und_ch2_96kbps.mp4'),
        },
      ]
    } else {
      videoPlayerSrc = [
        {
          src: contentUrl,
          type: 'video/mp4',
        },
      ]
    }
  }

  const offsetTop = anchorEl.getBoundingClientRect().top
  const windowHeight = window.innerHeight

  const isWindowTooLow = windowHeight - offsetTop < 250

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      onClose={onCloseDialog}
      className='popover-class'
    >
      <div className='control-info-window-arrow' style={{ top: offsetTop + 3 }} />
      <div className='control-info-window-arrow-background' style={{ top: offsetTop + 3 }} />
      <Paper
        style={{
          bottom: isWindowTooLow ? 40 : 0,
          position: isWindowTooLow ? 'relative' : 'unset',
        }}
      >
        <div className='control-info'>
          <div className='control-info-content'>
            {isImage && <img className='control-info-content-img' draggable={false} src={contentUrl} alt={fileName} />}
            {isVideo && <AzureMP skin='amp-flush' src={videoPlayerSrc} options={{ controls: true }} />}
          </div>
          <span className='control-info-label'>{controlLabel}</span>
          <TextWithLinks>
            <div className='control-info-description'>{description}</div>
          </TextWithLinks>
        </div>
      </Paper>
    </Popover>
  )
}

FormControlInfoWindow.propTypes = {
  onCloseDialog: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.any,
}

export default memo(FormControlInfoWindow)
