import PropTypes from 'prop-types'
import { connect, useDispatch } from 'react-redux'

import { Dialog, Button, IconButton, DialogActions, DialogContent } from '@material-ui/core'
import ContentClear from '@material-ui/icons/Clear'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'
import TenantFormControlContainer from '../../containers/TenantFormControlContainer'
import { submitButtonStyles, closeButtonStyles } from './inlineStyles'
import { tenantFormsActions, tenantFormsSelectors } from '../..'

import './styles.less'

const TenantFormsDialog = ({ formControls, isOpen, loadingForms, isSubmitButtonDisabled, submitButtonName }) => {
  const dispatch = useDispatch()

  if (!isOpen) return null

  return (
    <Dialog className='tenant-forms-dialog' open={isOpen}>
      <IconButton
        aria-label='Close'
        style={closeButtonStyles}
        onClick={() => dispatch(tenantFormsActions.closeTenantFormsDialog())}
      >
        <ContentClear style={closeButtonStyles} />
      </IconButton>
      {loadingForms ? (
        <CircularProgressBar />
      ) : formControls.length > 0 ? (
        <>
          <DialogContent className='tenant-forms-dialog-content'>
            {formControls.map((formControl) => (
              <TenantFormControlContainer key={formControl.Id} formControl={formControl} />
            ))}
          </DialogContent>
          <DialogActions className='tenant-forms-dialog-actions'>
            <Button
              disabled={isSubmitButtonDisabled}
              onClick={() => dispatch(tenantFormsActions.submitTenantFormAnswers())}
              style={submitButtonStyles}
            >
              {submitButtonName}
            </Button>
          </DialogActions>
        </>
      ) : (
        <DialogContent className='tenant-forms-dialog-content'>
          <p className='no-available-forms-message'>
            There are no forms with the valid controls attached to the tenant
          </p>
        </DialogContent>
      )}
    </Dialog>
  )
}

TenantFormsDialog.propTypes = {
  formControls: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loadingForms: PropTypes.bool.isRequired,
  isSubmitButtonDisabled: PropTypes.bool.isRequired,
  submitButtonName: PropTypes.string,
}

function mapStateToProps(state) {
  const { getSncType, getFormControlsList, getIsTenantFormLoading, getIsSubmitButtonDisabled, getSubmitButtonName } =
    tenantFormsSelectors

  return {
    isOpen: Boolean(getSncType(state)),
    formControls: getFormControlsList(state),
    loadingForms: getIsTenantFormLoading(state),
    isSubmitButtonDisabled: getIsSubmitButtonDisabled(state),
    submitButtonName: getSubmitButtonName(state),
  }
}

export default connect(mapStateToProps)(TenantFormsDialog)
