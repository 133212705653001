import { memo } from 'react'
import PropTypes from 'prop-types'

import { Color } from 'three'

const StaticColor = ({ color }) => {
  return <color attach='background' args={[color]} />
}

StaticColor.propTypes = {
  color: PropTypes.instanceOf(Color).isRequired,
}

export default memo(StaticColor)
