import { memo } from 'react'

import { Vector3 } from 'three'
import { PerspectiveCamera } from '@react-three/drei'

import { Defaults } from '../../constants'

const Camera = () => {
  return (
    <PerspectiveCamera
      makeDefault
      args={[Defaults.FIELD_OF_VIEW, null, 0.0001, 100000]}
      position={new Vector3(0, 0, 1000)}
    />
  )
}

export default memo(Camera)
