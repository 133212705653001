import { handleActions, combineActions } from 'redux-actions'

import * as rawActions from '../actions'

const defaultState = {
  Name: '',
  Color: '#FB000E',
}

export default handleActions(
  {
    [rawActions.setAiClassToUpdate](state, action) {
      return action.payload
    },
    [combineActions(
      rawActions.setAiClassToAdd,
      rawActions.updateAiClassRequest,
      rawActions.addAiClassRequest,
      rawActions.closeEditAiClassDialog
    )](state, action) {
      return defaultState
    },
    [rawActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)
