import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'

import { Alert, Dialog, DialogTitle, DialogContent, DialogActions, Button, Box } from '@mui/material'

import { callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import { FormikTextField } from '@tabeeb/shared/forms'
import { requiredFieldWithName } from '@tabeeb/shared/utils/validationErrorMessages'
import { useApiRequest } from '@tabeeb/shared/utils/hooks'
import { getPageMeasurementSettings, updatePageMeasurementSettings } from '@tabeeb/modules/gallery/actions'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import { setMeasurementSettings, closeMeasurementCalibrationDialog } from '../../actions'
import { getMeasurementSettings, getMeasurementSettingsConfigurationDialogState } from '../../selectors'
import { getDistanceInPx, getDistanceInFt, INCHES_IN_FEET } from '../../helpers'

const validationSchema = Yup.object().shape({
  Feet: Yup.number().required(requiredFieldWithName('Feet')),
})

const MeasurementSettingsConfigurationDialog = () => {
  const dispatch = useDispatch()

  const settings = useSelector(getMeasurementSettings)
  const { open, annotation } = useSelector(getMeasurementSettingsConfigurationDialogState)

  const { loading } = useApiRequest({
    request: getPageMeasurementSettings.request,
    payload: annotation?.PageId,
    enabled: open && annotation?.PageId && settings.PageId !== annotation.PageId,
  })

  const distanceInPx = getDistanceInPx(annotation?.Points)

  const onClose = () => {
    dispatch(closeMeasurementCalibrationDialog())
  }

  const onSubmit = async (values) => {
    try {
      const dpi = distanceInPx / values.Feet / INCHES_IN_FEET

      await callApiAsync(updatePageMeasurementSettings.request({ PageId: annotation.PageId, Dpi: dpi }))
      dispatch(setMeasurementSettings({ PageId: annotation.PageId, Dpi: dpi }))
      onClose()
    } catch {
      dispatch(onAddErrorNotification({ message: 'Failed to update measurement settings' }))
    }
  }

  const initialValues = useMemo(() => {
    return {
      Feet: getDistanceInFt(distanceInPx, settings.Dpi).toFixed(2),
    }
  }, [distanceInPx, settings.Dpi])

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <Formik initialValues={initialValues} validationSchema={validationSchema} enableReinitialize onSubmit={onSubmit}>
        {({ values, dirty, isValid, isSubmitting }) => (
          <Form>
            <DialogTitle>Measurements calibration</DialogTitle>
            <DialogContent>
              <Alert severity='info'>
                Manually setting a value can serve as a reliable reference point, enhancing the accuracy of subsequent
                measurements on the page
              </Alert>
              <Box mt={2}>
                <Field
                  disabled={loading || isSubmitting}
                  name='Feet'
                  label='Feet'
                  component={FormikTextField}
                  size='small'
                  type='number'
                  InputProps={{
                    inputProps: {
                      step: 0.1,
                    },
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Close</Button>
              <Button
                disabled={loading || !isValid || !dirty || isSubmitting}
                type='submit'
                variant='contained'
                color='primary'
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default MeasurementSettingsConfigurationDialog
