import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Box3, Vector3 } from 'three'

import { getBoundingBox } from '../selectors'

const useModelBoudingBox = () => {
  const boundingBox = useSelector(getBoundingBox)

  const box = useMemo(
    () =>
      new Box3().setFromPoints([
        new Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z),
        new Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z),
      ]),
    [boundingBox.max.x, boundingBox.max.y, boundingBox.max.z, boundingBox.min.x, boundingBox.min.y, boundingBox.min.z]
  )

  return box
}

export default useModelBoudingBox
