import { contentTrashBinActions } from '@tabeeb/modules/contentTrashBin'
import * as contentTrashBinSelectors from '../selectors'

import { dialogItems } from './dialogItems'

const restoreMenuItems = [
  {
    title: 'Restore Selected',
    disabledSelector: contentTrashBinSelectors.makeGetIsNoSelectedPage,
    isVisibleSelector: () => () => true,
    action: () => contentTrashBinActions.setContentTrashBinDialogItem(dialogItems.restoreSelected),
  },
  {
    title: 'Restore All',
    disabledSelector: () => () => false,
    isVisibleSelector: contentTrashBinSelectors.makeGetIsFolderNotSelected,
    action: () => contentTrashBinActions.setContentTrashBinDialogItem(dialogItems.restoreAll),
  },
  {
    title: 'Restore Folder',
    disabledSelector: () => () => false,
    isVisibleSelector: contentTrashBinSelectors.makeGetSelectedFolderId,
    action: () => contentTrashBinActions.setContentTrashBinDialogItem(dialogItems.restoreFolder),
  },
]

export default restoreMenuItems
