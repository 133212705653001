import { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput, FormHelperText, Input } from '@material-ui/core'
import { get } from 'lodash'

const createInput = (variant, labelWidth, name, id, selectInputProps) => {
  if (variant !== 'standard') {
    return <OutlinedInput notched labelWidth={labelWidth} name={name} id={id} />
  }
  return <Input {...selectInputProps} name={name} id={id} />
}

const FormikSelect = ({
  options = [],
  field,
  form,
  label,
  id,
  name,
  variant,
  selectInputProps,
  size,
  disabled,
  required,
  ...props
}) => {
  const inputLabel = useRef(null)
  const [labelWidth, setLabelWidth] = useState(0)
  useEffect(() => {
    setLabelWidth(inputLabel.current?.offsetWidth)
  }, [])

  const { errors, touched } = form
  const error = get(touched, field.name) && get(errors, field.name)

  return (
    <FormControl
      disabled={disabled}
      variant={variant ?? 'outlined'}
      fullWidth
      error={!!error}
      size={size}
      required={required}
    >
      {label && (
        <InputLabel htmlFor={id} ref={inputLabel} shrink>
          {label}
        </InputLabel>
      )}
      <Select input={createInput(variant, labelWidth, name, id, selectInputProps)} {...field} {...props}>
        {options.map((option, index) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          )
        })}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

FormikSelect.defaultProps = {
  disabled: false,
  required: false,
  size: 'medium',
}

FormikSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }).isRequired
  ).isRequired,
  name: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  form: PropTypes.object.isRequired,
  variant: PropTypes.string,
  selectInputProps: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium']),
}

export default FormikSelect
