import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    rootGrid: {
      maxWidth: '100%',
    },
  }
})
