import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'

import { Remove } from '@material-ui/icons'
import { Box, Typography, withStyles } from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'

import { filters, getIconByNotificationType } from '../../services/notificationsPageFilters'

import { notificationsPageActions, notificationsPageSelectors } from '../..'

import styles from './styles'

const NotificationsPageFilter = ({ actions, classes, selectedFilters }) => {
  const handleFilter = (event, newFilters) => {
    actions.changeNotificationsFilters(newFilters)
  }

  return (
    <ToggleButtonGroup className={classes.container} value={selectedFilters} onChange={handleFilter}>
      {filters.map((filter) => {
        const isSelected = selectedFilters.includes(filter.Value)

        return (
          <ToggleButton
            className={classes.button}
            key={filter.Value}
            title={isSelected ? `Hide "${filter.Name}" notifications` : `Show "${filter.Name}" notifications`}
            value={filter.Value}
            color='red'
          >
            <Box position='relative'>
              {!isSelected && <Remove fontSize='large' color='error' className={classes.disabledIcon} />}
              {getIconByNotificationType(filter.Value)}
            </Box>
            <Typography variant='caption'>{filter.Name}</Typography>
          </ToggleButton>
        )
      })}
    </ToggleButtonGroup>
  )
}

NotificationsPageFilter.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    changeNotificationsFilters: PropTypes.func.isRequired,
  }).isRequired,
  selectedFilters: PropTypes.array.isRequired,
}

const mapStateToProps = (state) => {
  return {
    selectedFilters: notificationsPageSelectors.getNotificationsFilters(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(notificationsPageActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationsPageFilter))
