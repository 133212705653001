import PropTypes from 'prop-types'

import { Typography } from '@mui/material'

const HeadingLabel = ({ heading }) => {
  const label = `${Math.floor(heading)}°`

  return (
    <Typography variant='h6' color='inherit' title={label}>
      {label}
    </Typography>
  )
}

HeadingLabel.propTypes = {
  heading: PropTypes.number.isRequired,
}

export default HeadingLabel
