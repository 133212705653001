import { createSelector } from 'reselect'

import _ from 'lodash'

export const selectGeosearchPage = (state) => state.geosearch

const selectPointsState = createSelector(selectGeosearchPage, (page) => page.points)
export const selectPoints = createSelector(selectPointsState, (pointsState) => pointsState.data)

const selectOwnersState = createSelector(selectGeosearchPage, (page) => page.owners)
export const selectOwners = createSelector(selectOwnersState, (ownersState) => ownersState.data)

const selectContentsState = createSelector(selectGeosearchPage, (page) => page.contents)
export const selectContents = createSelector(selectContentsState, (contentsState) => contentsState.data)
export const selectContentsByIds = createSelector(
  [selectContents, (state, { contentsIds }) => contentsIds],
  (contents, contentsIds) => contentsIds.map((contentId) => contents[contentId])
)
export const selectContentFolderByContentId = createSelector(
  [selectContents, (state, { contentsIds }) => contentsIds],
  (contents, contentId) => _.get(contents, [contentId, 'FolderId'], null)
)

const selectPagesState = createSelector(selectGeosearchPage, (page) => page.pages)
export const selectPages = createSelector(selectPagesState, (pagesState) => pagesState.data)
export const selectPageById = createSelector(
  [selectPages, (state, { pageId }) => pageId],
  (pages, pageId) => pages[pageId]
)

export const selectGeosearchState = createSelector(selectGeosearchPage, (page) => page.geosearchState)
export const selectThumbnailsDialogContent = createSelector(
  selectGeosearchState,
  (geosearchState) => geosearchState.selectedSession
)
export const selectThumbnailsDialogPagesIds = createSelector(
  selectThumbnailsDialogContent,
  (content) => content.pagesIds
)
