import { handleActions } from 'redux-actions'

import _ from 'lodash'

import { getGeosearchPoints, resetState } from '../actions'
import { fetchingStatus } from '../constants'

const defaultValue = {
  data: {},
  fetchingStatus: fetchingStatus.idle,
}

export default handleActions(
  {
    [getGeosearchPoints.success](
      state,
      {
        response: {
          data: { Owners },
        },
      }
    ) {
      return {
        fetchingStatus: fetchingStatus.succeeded,
        data: _.keyBy(Owners, 'Id'),
      }
    },
    [getGeosearchPoints.failed](state) {
      return {
        ...state,
        fetchingStatus: fetchingStatus.failed,
      }
    },
    [getGeosearchPoints.cancel](state) {
      return {
        ...state,
        fetchingStatus: fetchingStatus.idle,
      }
    },
    [resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
