import { useCallback, useState } from 'react'

export default () => {
  const [hovered, setHovered] = useState(false)

  const onHoverEnter = useCallback(() => {
    setHovered(true)
  }, [])

  const onHoverLeave = useCallback(() => {
    setHovered(false)
  }, [])

  return {
    hovered,
    onHoverEnter,
    onHoverLeave,
  }
}
