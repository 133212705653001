import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { AnnotationType } from '@tabeeb/enums'

import {
  AIHotspotAnnotation,
  ArrowAnnotation,
  AreaAnnotation,
  ArrowBothAnnotation,
  LineAnnotation,
  CircleAnnotation,
  CircleFilledAnnotation,
  DistanceAnnotation,
  RectangleAnnotation,
  RectangleFilledAnnotation,
  HeightAnnotation,
  PolygonAnnotation,
  StrokeAnnotation,
  HotspotAnnotation,
} from '@tabeeb/shared/icons'

import useStyles from './styles'

const annotationData = {
  [AnnotationType.AIHotspot]: { icon: AIHotspotAnnotation, title: 'AI Hotspot' },
  [AnnotationType.Stroke]: { icon: StrokeAnnotation, title: 'Stroke' },
  [AnnotationType.Hotspot]: { icon: HotspotAnnotation, title: 'Hotspot' },
  [AnnotationType.Ellipse]: { icon: CircleAnnotation, title: 'Circle' },
  [AnnotationType.Ellipse_Filled]: { icon: CircleFilledAnnotation, title: 'Filled circle' },
  [AnnotationType.Line]: { icon: LineAnnotation, title: 'Line' },
  [AnnotationType.Polygon]: { icon: PolygonAnnotation, title: 'Polygon' },
  [AnnotationType.Rectangle]: { icon: RectangleAnnotation, title: 'Rectangle' },
  [AnnotationType.Rectangle_Filled]: { icon: RectangleFilledAnnotation, title: 'Filled rectangle' },
  [AnnotationType.ArrowEnd]: { icon: ArrowAnnotation, title: 'Arrow' },
  [AnnotationType.ArrowBoth]: { icon: ArrowBothAnnotation, title: 'Double arrow' },
  [AnnotationType.Distance]: { icon: DistanceAnnotation, title: 'Distance' },
  [AnnotationType.Height]: { icon: HeightAnnotation, title: 'Height' },
  [AnnotationType.Volume]: { icon: DistanceAnnotation, title: 'Volume' },
  [AnnotationType.Area]: { icon: AreaAnnotation, title: 'Area' },
}

const AnnotationTypeSelect = ({ annotations, color, enabled, selected, onSelect }) => {
  const classes = useStyles()

  const onSelectShape = useCallback(
    (type) => () => {
      onSelect(type)
    },
    [onSelect]
  )

  return (
    <div className={classes.container}>
      {annotations.map((type) => {
        const { icon: Icon, title } = annotationData[type]
        const annotationColor = type === selected && enabled ? color.toLowerCase() : null

        return (
          <div key={type} title={title} className={classes.icon}>
            <Icon
              size='small'
              color='action'
              fontSize='inherit'
              onClick={onSelectShape(type)}
              onKeyDown={onSelectShape(type)}
              className={classNames({
                [classes.bright]: annotationColor === '#ffffff',
              })}
              style={{ color: annotationColor }}
            />
          </div>
        )
      })}
    </div>
  )
}

AnnotationTypeSelect.propTypes = {
  annotations: PropTypes.arrayOf(PropTypes.oneOf(Object.values(AnnotationType))).isRequired,
  color: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  selected: PropTypes.number,
  onSelect: PropTypes.func.isRequired,
}

export default memo(AnnotationTypeSelect)
