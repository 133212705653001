import { memo } from 'react'

import { useThree } from '@react-three/fiber'
import { useCubeTexture } from '@react-three/drei'

const nx = new URL('@tabeeb/assets/images/textures/sky/nx.jpg', import.meta.url)
const ny = new URL('@tabeeb/assets/images/textures/sky/ny.jpg', import.meta.url)
const nz = new URL('@tabeeb/assets/images/textures/sky/nz.jpg', import.meta.url)
const px = new URL('@tabeeb/assets/images/textures/sky/px.jpg', import.meta.url)
const py = new URL('@tabeeb/assets/images/textures/sky/py.jpg', import.meta.url)
const pz = new URL('@tabeeb/assets/images/textures/sky/pz.jpg', import.meta.url)

const Skybox = () => {
  const scene = useThree((state) => state.scene)
  const texture = useCubeTexture([px, nx, py, ny, pz, nz], { path: '' })

  scene.background = texture

  return null
}

Skybox.propTypes = {}

export default memo(Skybox)
