import { all, put, takeLatest, select } from 'redux-saga/effects'

import { getThumbnailsByPageIds, openGeoserchThumbnailsDialog } from '../actions'
import { selectPages, selectThumbnailsDialogPagesIds } from '../selectors'

function* getPageThumbnails() {
  const pagesIds = yield select(selectThumbnailsDialogPagesIds)
  const currentPagesWithThumbnails = yield select(selectPages)
  const pagesIdsWithoutThumbnails = []

  pagesIds.forEach((pageId) => {
    if (!currentPagesWithThumbnails[pageId]) {
      pagesIdsWithoutThumbnails.push(pageId)
    }
  })

  if (pagesIdsWithoutThumbnails.length !== 0) {
    yield put(getThumbnailsByPageIds.request({ pageIds: pagesIdsWithoutThumbnails }))
  }
}

function* geoSearchSagas() {
  yield all([takeLatest(openGeoserchThumbnailsDialog, getPageThumbnails)])
}
export default geoSearchSagas
