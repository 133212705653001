import { handleActions, combineActions } from 'redux-actions'

import {
  serverRecordingState,
  onRecordingStarting,
  onRecordingStarted,
  onRecordingStopping,
  onRecordingStopped,
  onRecordingPausing,
  onRecordingPaused,
  onSetErrorState,
  resetRecordingState,
} from '../actions'
import { RecordingStatus } from '../../../Enums'

const defaultValue = false

export default handleActions(
  {
    [combineActions(
      serverRecordingState,
      onRecordingStarted,
      onRecordingPausing,
      onSetErrorState,
      onRecordingStarting,
      onRecordingPaused,
      onSetErrorState
    )](state, { payload }) {
      if (typeof payload.localRecordingStatus === 'undefined') {
        return state
      }

      return [RecordingStatus.started, RecordingStatus.pausing].includes(payload.localRecordingStatus)
    },
    [combineActions(onRecordingStopping, onRecordingStopped, onRecordingPaused, onRecordingStarting)](
      state,
      { payload }
    ) {
      return false
    },
    [resetRecordingState](state, { payload }) {
      return defaultValue
    },
  },
  defaultValue
)
