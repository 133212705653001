import { handleActions } from 'redux-actions'

import * as aiActions from '../actions'

const defaultState = []

export default handleActions(
  {
    [aiActions.setAiObjects](state, { payload }) {
      return payload
    },
    [aiActions.addAiClassSuccess](state, { response }) {
      return _addOrUpdateAIObject(state, response.data)
    },
    [aiActions.updateAiClassSuccess](state, { payload }) {
      return _addOrUpdateAIObject(state, payload)
    },
    [aiActions.deleteAiClass.success](state, { payload }) {
      return _deleteAIObject(state, payload)
    },
    [aiActions.resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)

function _addOrUpdateAIObject(state, AIObject) {
  const index = state.findIndex((aiObject) => aiObject.Id === AIObject.Id)
  let result
  if (index === -1) {
    result = [...state, AIObject]
  } else {
    result = [...state]
    result[index] = AIObject
  }
  return _sortObjects(result)
}

function _deleteAIObject(state, aiObjectId) {
  const index = state.findIndex((aiObject) => aiObject.Id === aiObjectId)

  const result = [...state]
  if (index > -1) {
    result.splice(index, 1)
  }

  return result
}

function _sortObjects(aiObjects) {
  return aiObjects.sort((a, b) => {
    if (a.Name < b.Name) {
      return -1
    }
    if (a.Name > b.Name) {
      return 1
    }
    return 0
  })
}
