import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import { Clear, CloudUpload, FileUpload } from '@mui/icons-material'
import { Button, Grid, IconButton, Typography } from '@material-ui/core'

import { Bold } from '@tabeeb/shared/uikit'
import { BYTES_IN_MEGABYTES } from '@tabeeb/shared/constants'

import useStyles from './styles'

const maximumSizeOfMegabytes = 30

const DragAndDropFiles = ({ file, buttonText, placeholder, onDropAccepted, onClearState }) => {
  const classes = useStyles()
  return (
    <Dropzone
      maxFiles={1}
      noClick
      noKeyboard
      maxSize={BYTES_IN_MEGABYTES * maximumSizeOfMegabytes}
      onDropAccepted={onDropAccepted}
      accept={{
        'image/jpeg': ['.jpeg', '.jpg'],
        'image/png': [],
        'image/bmp': [],
      }}
    >
      {({ getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open, fileRejections }) => (
        <Grid
          container
          wrap='nowrap'
          direction='column'
          spacing={1}
          {...getRootProps({
            className: classNames(
              classes.dragAndDropContainer,
              { [classes.focusedDragAndDropContainer]: isFocused },
              { [classes.acceptedDragAndDropContainer]: isDragAccept },
              { [classes.rejectedDragAndDropContainer]: isDragReject }
            ),
          })}
        >
          <input {...getInputProps()} />
          <CloudUpload />
          <Grid item>
            <Bold>{placeholder}</Bold>
          </Grid>
          <Grid item>
            {file ? (
              <div className={classes.acceptedFilesList}>
                <Typography className={classes.blackText}>{file.path}</Typography>
                <IconButton size='small' onClick={onClearState}>
                  <Clear />
                </IconButton>
              </div>
            ) : (
              <Button variant='outlined' onClick={open} startIcon={<FileUpload />}>
                {buttonText}
              </Button>
            )}
          </Grid>
          {fileRejections.length > 0 && (
            <Grid item>
              <Typography className={classes.redText}>
                {fileRejections[0].errors[0].code === 'file-too-large'
                  ? `File is too large, maximum size is ${maximumSizeOfMegabytes}MB`
                  : 'Unsupported image format'}
              </Typography>
            </Grid>
          )}
          <Grid item className={classes.dragAndDropContainerFooter}>
            <Typography variant='caption'>Possible formats: png, jpg, jpeg, bmp</Typography>
            <Typography variant='caption'>Maximum size: {maximumSizeOfMegabytes}MB</Typography>
          </Grid>
        </Grid>
      )}
    </Dropzone>
  )
}

DragAndDropFiles.defaultProps = {
  placeholder: 'Please select a certificate photo to upload',
  buttonText: 'Upload photo',
}

DragAndDropFiles.propTypes = {
  file: PropTypes.instanceOf(File),
  buttonText: PropTypes.string,
  placeholder: PropTypes.string,
  onDropAccepted: PropTypes.func.isRequired,
  onClearState: PropTypes.func.isRequired,
}

export default DragAndDropFiles
