import { handleActions } from 'redux-actions'

import * as certificatesActions from '@tabeeb/modules/certificates/actions'

const defaultState = {
  Certificate: {},
  Owner: {},
}

export default handleActions(
  {
    [certificatesActions.getCertificateById.success](state, action) {
      return { ...state, Certificate: action.response.data }
    },
    [certificatesActions.setCertificateToReviewOwner](state, action) {
      return { ...state, Owner: action.payload }
    },
    [certificatesActions.getCertificateById.resetState]() {
      return defaultState
    },
  },
  defaultState
)
