import { lazy, memo, Suspense } from 'react'
import PropTypes from 'prop-types'
import { ReactReduxContext } from 'react-redux'

import { ThemeContext } from '@emotion/react'

import { AdaptiveEvents, useContextBridge } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'

import SnippingTool from '@tabeeb/modules/contentViewer/components/SnippingTool'

import Annotations from '../Annotations'
import Autofit from '../Autofit'
import Axes from '../Axes'
import Background from '../Background'
import Camera from '../Camera'
import CameraControls from '../CameraControls'
import Compass from '../Compass'
import DebugTools from '../DebugTools'
import ProcessingTextHelper from '../ProcessingTextHelper'
import Focus from '../Focus'
import Light from '../Light'
import LoadingIndicator from '../LoadingIndicator'
import Model from '../Model'
import Perfomance from '../Perfomance'
import Settings from '../Settings'

import { useLoadingProgress, useModel } from '../../hooks'

const SourcePagesNavigation = lazy(() => import('../SourcePagesNavigation'))

const ModelViewer = ({ container, height, width }) => {
  const model = useModel()

  const { progress, onProgress } = useLoadingProgress({ id: model.id })

  const ContextBridge = useContextBridge(ReactReduxContext, ThemeContext)

  return (
    <ProcessingTextHelper model={model}>
      <Suspense fallback={<LoadingIndicator progress={progress} />}>
        <Canvas
          className='viewer-content'
          gl={{ alpha: true, antialias: true, preserveDrawingBuffer: true }}
          raycaster={{ params: { Points: { threshold: 0.01 } } }}
        >
          <ContextBridge>
            <Background />
            <Light />
            <Camera />
            <CameraControls rotation translation zooming />
            <Focus />
            <Autofit />
            {model.urls.map((url) => (
              <Model key={url} id={model.id} total={model.urls.length} url={url} focus onProgress={onProgress} />
            ))}
            <Settings container={container} />
            <Compass container={container} />
            <Annotations />
            <AdaptiveEvents />

            <Suspense fallback={null}>
              <SourcePagesNavigation container={container} />
            </Suspense>

            <DebugTools>
              <Axes />
              <Perfomance container={container} />
            </DebugTools>
          </ContextBridge>
        </Canvas>
      </Suspense>
      <SnippingTool height={height} width={width} />
    </ProcessingTextHelper>
  )
}

ModelViewer.propTypes = {
  container: PropTypes.instanceOf(Element).isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default memo(ModelViewer)
