import { TabPanel } from '@tabeeb/enums'
import { handleActions } from 'redux-actions'
import { sessionTabsActions } from '..'

let defaultPanelsState = {
  left: '',
  right: TabPanel.Gallery,
  bottom: '',
}

const initialState = {
  currentPanel: {
    ...defaultPanelsState,
  },
}

export default handleActions(
  {
    [sessionTabsActions.switchPanel](state, action) {
      return switchPanel(state, action.payload)
    },
    [sessionTabsActions.resetNavbarState]() {
      return { ...initialState, currentPanel: { ...defaultPanelsState } }
    },
    [sessionTabsActions.setNavbarDefaultState](state, action) {
      defaultPanelsState = action.payload
      return { ...initialState, currentPanel: { ...defaultPanelsState } }
    },
  },
  initialState
)

const switchPanel = (state, { panel, location }) => {
  const { currentPanel } = state
  currentPanel[location] = currentPanel[location] === panel ? '' : panel
  return {
    ...state,
  }
}
