import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { StraightenOutlined } from '@mui/icons-material'

import { AnnotationType } from '@tabeeb/enums'
import { usePopoverState } from '@tabeeb/shared/utils/hooks'

import { onChangePressedButton, onDisableDrawing, onEnableDrawing, onSetDrawingType } from '../../actions/drawing'
import { getSelectedColor, getDrawingAnnotationType, getIsButtonSelected, getIsDrawingEnabled } from '../../selectors'

import ToolbarButton from '../ToolbarButton'
import ToolbarPopover from '../ToolbarPopover'
import AnnotationTypeSelect from '../AnnotationTypeSelect'

const BUTTON_NAME = 'point-cloud-measurements'

const annotations = [AnnotationType.Area, AnnotationType.Distance, AnnotationType.Height]

const PointCloudMeasurementsButton = () => {
  const dispatch = useDispatch()

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const color = useSelector(getSelectedColor)
  const type = useSelector(getDrawingAnnotationType)
  const selected = useSelector((state) => getIsButtonSelected(state, BUTTON_NAME))
  const isDrawingEnabled = useSelector(getIsDrawingEnabled)

  const onClick = useCallback(() => {
    if (selected) {
      dispatch(onChangePressedButton({ pressedButton: '' }))
      dispatch(onDisableDrawing())
    } else {
      onOpen()
    }
  }, [dispatch, onOpen, selected])

  const onSelectShape = useCallback(
    (annotationType) => {
      dispatch(onChangePressedButton({ pressedButton: BUTTON_NAME }))

      dispatch(onSetDrawingType(annotationType))
      dispatch(onEnableDrawing())
    },
    [dispatch]
  )

  return (
    <>
      <ToolbarButton
        ref={anchorRef}
        Icon={StraightenOutlined}
        htmlColor={isDrawingEnabled && selected ? color : null}
        selected={selected || open}
        title='Add Measurement annotation'
        onClick={onClick}
      />
      <ToolbarPopover anchorEl={anchorRef.current} open={open} onClose={onClose}>
        <AnnotationTypeSelect
          annotations={annotations}
          color={color}
          selected={selected ? type : null}
          enabled={isDrawingEnabled}
          onSelect={onSelectShape}
        />
      </ToolbarPopover>
    </>
  )
}

export default memo(PointCloudMeasurementsButton)
