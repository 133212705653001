import { handleActions } from 'redux-actions'

import { calendarActions } from '..'

const defaultState = {
  open: false,
  event: null,
}

export default handleActions(
  {
    [calendarActions.openEditEventDialog](state, action) {
      return {
        open: true,
        event: action.payload,
      }
    },
    [calendarActions.closeEditEventDialog](state, action) {
      return {
        ...state,
        open: false,
      }
    },
    [calendarActions.resetCalendarState](state, action) {
      return defaultState
    },
  },
  defaultState
)
