import { handleActions, combineActions } from 'redux-actions'

import {
  onEnableDrawing,
  onDisableDrawing,
  onSetDrawingType,
  onSetDrawingWidth,
  onSetDrawingColor,
  onSetTransparency,
  onDisposeDrawing,
  onHideAnnotations,
  onEnableSelection,
  onDisableSelection,
} from '../../actions/drawing'
import { enableExpandedGallery } from '../../../gallery/actions'
import * as playerToolbarActions from '../../actions'

const defaultValue = {
  isDrawing: false,
  isSelecting: false,
  color: '#FB000E',
  type: 0,
  width: 3,
  transparency: 1,
}

export default handleActions(
  {
    [onEnableDrawing](state) {
      return { ...state, isDrawing: true, isSelecting: false }
    },
    [onDisableDrawing](state) {
      return { ...state, isDrawing: false }
    },
    [onEnableSelection](state) {
      return { ...state, isSelecting: true, isDrawing: false }
    },
    [onDisableSelection](state) {
      return { ...state, isSelecting: false }
    },
    [onSetDrawingColor](state, action) {
      return { ...state, color: action.payload }
    },
    [onSetDrawingType](state, action) {
      return { ...state, type: action.payload }
    },
    [onSetDrawingWidth](state, action) {
      return { ...state, width: action.payload }
    },
    [onSetTransparency](state, action) {
      return { ...state, transparency: action.payload }
    },
    [combineActions(onDisposeDrawing, enableExpandedGallery, onHideAnnotations)](state, action) {
      return defaultValue
    },
    [playerToolbarActions.resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
