import { createSelector } from 'reselect'
import { SessionPermission } from '@tabeeb/enums'
import { getIsChatEnabled, getIsVideoCallsEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { permissionsSelectors } from '@tabeeb/modules/permissions'
import { presentationSelectors } from '@tabeeb/modules/presentation'
import * as contentStateSelectors from '../../shared/content/selectors'

export const getIsCallProcessing = (state) => state.topNavBar.isCallProcessing
export const getHasStartCallPermission = (state) =>
  permissionsSelectors.hasSessionPermission(state, SessionPermission.StartCalls)
export const getHasSetVideobridgePermission = (state) =>
  permissionsSelectors.hasSessionPermission(state, SessionPermission.SetVideobridge)
export const getHasSendChatMessagesPermission = (state) =>
  permissionsSelectors.hasSessionPermission(state, SessionPermission.SendChatMessages)
export const getIsAppConfigSetVideobridgeEnabled = (state) => state.appConfigState.showVideobridgeRegionDropdown

export const getIsStartCallButton = createSelector(
  [
    contentStateSelectors.getIsCurrentUserPresenter,
    presentationSelectors.getIsCallStarted,
    getIsCallProcessing,
    getHasStartCallPermission,
    getIsVideoCallsEnabled,
  ],
  (isPresenter, isCallStarted, isCallProcessing, hasPermission, isVideoCallsEnabled) => {
    const isStartCallBtn = (isPresenter || hasPermission) && !isCallProcessing && !isCallStarted && isVideoCallsEnabled

    return isStartCallBtn
  }
)

export const getIsEndCallButton = createSelector(
  [contentStateSelectors.getIsCurrentUserPresenter, presentationSelectors.getIsCallStarted, getIsCallProcessing],
  (isPresenter, isCallStarted, isCallProcessing) => {
    const isEndCallBtn = isPresenter && !isCallProcessing && isCallStarted

    return isEndCallBtn
  }
)

export const getIsLeaveCallButton = createSelector(
  [contentStateSelectors.getIsCurrentUserPresenter, presentationSelectors.getIsCallStarted, getIsCallProcessing],
  (isPresenter, isCallStarted, isCallProcessing) => {
    const isLeaveCallBtn = !isPresenter && isCallStarted && !isCallProcessing

    return isLeaveCallBtn
  }
)

export const getIsVideobridgeSelectorVisible = createSelector(
  [
    contentStateSelectors.getIsCurrentUserPresenter,
    presentationSelectors.getIsCallStarted,
    getHasSetVideobridgePermission,
    getIsAppConfigSetVideobridgeEnabled,
    getIsVideoCallsEnabled,
  ],
  (isPresenter, isCallStarted, hasPermission, isConfigEnabled, isVideoCallsEnabled) => {
    const isVisible = isPresenter || (!isCallStarted && hasPermission)

    return isVisible && isConfigEnabled && isVideoCallsEnabled
  }
)

export const getIsMuteChatNotificationsVisible = createSelector(
  [getIsChatEnabled, getHasSendChatMessagesPermission],
  (isChatEnabled, hasSendChatMessagesPermission) => {
    return isChatEnabled && hasSendChatMessagesPermission
  }
)

export const getIsSessionSettingsButtonVisible = createSelector(
  [getIsVideobridgeSelectorVisible, getIsMuteChatNotificationsVisible],
  (isVideobridgeSelectorVisible, isMuteChatNotificationsVisible) => {
    return isVideobridgeSelectorVisible || isMuteChatNotificationsVisible
  }
)
