import { put, takeLatest, takeEvery, select, all } from 'redux-saga/effects'

import { TopicType } from '@tabeeb/enums'
import { accountSelectors } from '@tabeeb/modules/account'
import { contentStateSelectors, rawContentActions } from '@tabeeb/shared/content'

import { getChatUserId, addChatMessage } from './shared'
import { MessageAssetTypes, MessagesTake } from '../constans'

import { chatActions, chatSelectors } from '..'
import { convertServiceChatMessageToPluginChatMessage } from '../../../services/dataConverter'

import signalr from './signalr'
import unreadMessages from './unreadMessages'

function* onSendMessage(action) {
  const { message, receiverId, topicId, topicType } = action.payload

  const contentId = yield select(contentStateSelectors.getContentId)

  const data = {
    topicId,
    topicType,
    contentId,
    message,
    receiverId,
  }

  yield put(chatActions.sendChatMessageRequest(data))
}

function* onGetMessagesSuccess(action) {
  const { topicId, topicType, userId } = action.payload
  const { data } = action.response

  if (data.length === 0) {
    yield put(chatActions.setChatFullyLoaded({ topicId, topicType, userId }))
  }

  if (data && data.length) {
    const currentUserId = yield select(accountSelectors.getCurrentUserId)
    const lastReadedMessageId = yield select((state) =>
      chatSelectors.getLastReadedMessageIdForChat(state, {
        topicId,
        topicType,
        userId,
      })
    )

    const addedMessages = data.map((message) =>
      convertServiceChatMessageToPluginChatMessage(message, { currentUserId, lastReadedMessageId })
    )
    yield put(chatActions.setChatMessages({ topicId, topicType, userId, messages: addedMessages }))
  }
}

function* loadMessages(action) {
  const { topicId, topicType, userId = null } = action.payload
  if (!topicId) {
    return
  }

  const contentId = yield select(contentStateSelectors.getContentId)
  if (!contentId) {
    return
  }

  const messagesList = yield select((state) => chatSelectors.getChatMessagesList(state, { topicId, topicType, userId }))
  const unreadMessagesCount = yield select((state) =>
    chatSelectors.getUnreadMessagesCountForChat(state, { topicId, topicType, userId })
  )

  yield put(
    chatActions.getChatMessagesByContentIdRequest({
      topicId: topicId || contentId,
      topicType: topicType || TopicType.Session,
      contentId,
      userId,
      messagesSkip: messagesList.length,
      messagesTake: Math.max(MessagesTake, unreadMessagesCount),
    })
  )
}

function* onGetMessageTranslationSuccess(action) {
  const { id, topicId, topicType, userId, receiverId } = action.payload.message

  const chatUserId = yield getChatUserId(userId, receiverId)

  const message = {
    id,
    translations: {
      [action.payload.targetLanguage]: action.response.data,
    },
  }

  yield put(chatActions.updateChatMessage({ topicId, topicType, userId: chatUserId, message }))
}

function* sendChatMessageSuccess(action) {
  const model = action.response.data

  yield addChatMessage(model)
}

function* onUploadAudio(action) {
  const { Url: fileUrl } = action.response.data
  const { fileDuration, topicId, topicType, receiverId } = action.payload
  const contentId = yield select(contentStateSelectors.getContentId)

  yield put(
    chatActions.sendChatFileRequest({
      topicId,
      topicType,
      contentId,
      receiverId,
      type: MessageAssetTypes.Audio,
      url: fileUrl,
      duration: fileDuration,
    })
  )
}

function* getContentSuccess(action) {
  const contentId = yield select(contentStateSelectors.getContentId)

  yield put(chatActions.getChatsRequest({ contentId }))
}

function* chatSaga() {
  yield all([
    takeLatest(chatActions.sendMessage, onSendMessage),
    takeLatest(rawContentActions.getContentSuccess, getContentSuccess),
    takeLatest([rawContentActions.getContentSuccess, chatActions.loadMoreMessages], loadMessages),
    takeLatest(chatActions.getChatMessagesByContentIdSuccess, onGetMessagesSuccess),
    takeEvery(chatActions.getMessageTranslationSuccess, onGetMessageTranslationSuccess),
    takeLatest(chatActions.uploadAudioFileToChatBlobStorageSuccess, onUploadAudio),
    takeLatest(chatActions.sendChatMessageSuccess, sendChatMessageSuccess),
    unreadMessages(),
    signalr(),
  ])
}

export default chatSaga
