import { handleActions } from 'redux-actions'

import { chatActions } from '..'

const defaultState = {}

export default handleActions(
  {
    [chatActions.getTranslationLanguagesSuccess](state, action) {
      return action.response.data
    },
    [chatActions.resetChatState](state, action) {
      return defaultState
    },
  },
  defaultState
)
