import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { isPdfPage } from '@tabeeb/services/pageService'
import * as rawActions from '../actions/index'
import SearchBox from '../components/SearchBox/index'
import { gallerySelectors } from '..'

class SearchBoxContainer extends Component {
  _onTextChanged = (event) => {
    const text = event.target.value
    const {
      actions: { updateSearchText, searchInPdf, clearSelectedSearchResult },
    } = this.props
    updateSearchText(text)

    if (text.length > 1) {
      searchInPdf(text)
      clearSelectedSearchResult()
    }
  }

  render() {
    const {
      searchText,
      isVisible,
      actions: { showPdfSearchResults, hidePdfSearchResults },
      showPdfSearchResultsPage,
      searchMatchesCount,
    } = this.props

    if (!isVisible) return null

    const props = {
      searchText,
      onTextChanged: this._onTextChanged,
      showPdfSearchResults,
      hidePdfSearchResults,
      showPdfSearchResultsPage,
      searchMatchesCount,
    }
    return <SearchBox {...props} />
  }
}

SearchBoxContainer.propTypes = {
  searchText: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
  showPdfSearchResultsPage: PropTypes.bool.isRequired,
  searchMatchesCount: PropTypes.number,
  actions: PropTypes.shape({
    updateSearchText: PropTypes.func.isRequired,
    searchInPdf: PropTypes.func.isRequired,
    clearSelectedSearchResult: PropTypes.func.isRequired,
    showPdfSearchResults: PropTypes.func.isRequired,
    hidePdfSearchResults: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  const {
    gallery: {
      pdfSearch: { searchText, showPdfSearchResults, searchMatchesCount },
    },
  } = state
  const selectedGalleryItem = gallerySelectors.getSelectedGalleryItem(state)

  return {
    searchText,
    showPdfSearchResultsPage: showPdfSearchResults,
    searchMatchesCount,
    isVisible: selectedGalleryItem ? isPdfPage(selectedGalleryItem.contentType) : false,
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchBoxContainer)
