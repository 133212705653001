import { memo, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import moment from 'moment'
import { Formik, Form, Field } from 'formik'

import { Box, Grid, Paper } from '@mui/material'

import { uploadFile } from '@tabeeb/services/uploadService'
import { FormikDateRangePicker, FormikRichTextEditor, FormikSelect, FormikTextField } from '@tabeeb/shared/forms'
import { maxLengthWithName, noWhitespaces, requiredFieldWithName } from '@tabeeb/shared/utils/validationErrorMessages'

import PageHeader from '@tabeeb/modules/aiExpressions/components/PageHeader'

import { UploadContentType } from '@tabeeb/enums'
import {
  AnnouncementScope,
  AnnouncementStatus,
  AnnouncementSeverity,
  AnnouncementSeverityDisplayName,
} from '../../enums'
import AnnouncementSaveButton from '../AnnouncementSaveButton'

const announcementSchema = Yup.object().shape({
  Name: Yup.string()
    .strict()
    .trim(noWhitespaces)
    .max(256, maxLengthWithName('Name', 256))
    .required(requiredFieldWithName('Name')),
  Content: Yup.string().required(),
  Severity: Yup.number().oneOf(Object.values(AnnouncementSeverity)).required(),
  Status: Yup.number().oneOf(Object.values(AnnouncementStatus)).required(),
  Scope: Yup.number().oneOf(Object.values(AnnouncementScope)).required(),
  Date: Yup.object().shape({
    start: Yup.date().required('Start date is required'),
    end: Yup.date()
      .required('End date is required')
      .min(Yup.ref('start'), "End date can't be before start date")
      .min(new Date(), "End date can't be before the current date"),
  }),
})

const EditAnnouncementForm = ({ initial, title, scope, onSubmit }) => {
  const onUploadFile = useCallback(async ({ file, onProgress }) => {
    const { url, sas } = await uploadFile({
      file,
      uploadContentType: UploadContentType.AnnouncementImage,
      onProgress,
    })

    return `${url.split('?')[0]}${sas.sasRead}`
  }, [])

  const initialValues = useMemo(() => {
    return {
      Id: initial.Id,
      Name: initial.Name,
      Content: initial.Content,
      Severity: initial.Severity,
      Status: initial.Status,
      Scope: scope || initial.Scope,
      TenantId: initial.TenantId,
      Date: {
        start: initial.StartDate,
        end: initial.ExpiryDate,
      },
    }
  }, [initial, scope])

  const onSubmitForm = useCallback(
    (data) => {
      onSubmit({
        Id: data.Id,
        Name: data.Name,
        Content: data.Content,
        Severity: data.Severity,
        Status: data.Status,
        StartDate: data.Date.start,
        ExpiryDate: data.Date.end,
      })
    },
    [onSubmit]
  )

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={announcementSchema}
      onSubmit={onSubmitForm}
    >
      <Form style={{ display: 'flex', height: '100%', width: '100%' }} autoComplete='off'>
        <Box display='flex' height='100%' width='100%' flexDirection='column' p={2} pt={1} pb={1}>
          <Paper
            sx={{
              flexGrow: 1,
              p: 2,
              pt: 1,
              display: 'flex',
              alignItems: 'stretch',
              justifyContent: 'stretch',
              flexDirection: 'column',
            }}
          >
            <PageHeader title={title} actions={<Field name='Status' component={AnnouncementSaveButton} />} />
            <Grid container wrap='nowrap' direction='column' spacing={2} flexGrow={1}>
              <Grid item container direction='row' spacing={2}>
                <Grid item xs={12} md={9}>
                  <Field size='small' color='primary' name='Name' label='Name' component={FormikTextField} />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Field
                    id='announcement-severity-select'
                    size='small'
                    name='Severity'
                    label='Severity'
                    component={FormikSelect}
                    options={Object.values(AnnouncementSeverity).map((severity) => ({
                      name: AnnouncementSeverityDisplayName[severity],
                      value: severity,
                    }))}
                  />
                </Grid>
                <Grid item xs={6} lg={4}>
                  <Field
                    name='Date'
                    required
                    size='small'
                    variant='outlined'
                    component={FormikDateRangePicker}
                    minutesStep={60}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Field name='Content' component={FormikRichTextEditor} uploadsHandler={onUploadFile} />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Form>
    </Formik>
  )
}

EditAnnouncementForm.defaultProps = {
  initial: {
    Id: 0,
    Name: '',
    Content: '',
    Scope: AnnouncementScope.System,
    Severity: AnnouncementSeverity.Information,
    Status: AnnouncementStatus.Draft,
    TenantId: null,
    StartDate: moment().format(),
    ExpiryDate: moment().add(7, 'days').format(),
  },
}

EditAnnouncementForm.propTypes = {
  initial: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Content: PropTypes.string.isRequired,
    Scope: PropTypes.oneOf(Object.values(AnnouncementScope)).isRequired,
    Severity: PropTypes.oneOf(Object.values(AnnouncementSeverity)).isRequired,
    Status: PropTypes.oneOf(Object.values(AnnouncementStatus)).isRequired,
    TenantId: PropTypes.number,
    StartDate: PropTypes.string.isRequired,
    ExpiryDate: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
  scope: PropTypes.oneOf(Object.values(AnnouncementScope)),
  onSubmit: PropTypes.func.isRequired,
}

export default memo(EditAnnouncementForm)
