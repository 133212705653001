import { handleActions } from 'redux-actions'

import { getGeosearchPoints, resetState } from '../actions'
import { fetchingStatus } from '../constants'

const defaultValue = {
  data: [],
  fetchingStatus: fetchingStatus.idle,
}

export default handleActions(
  {
    [getGeosearchPoints.success](
      state,
      {
        response: {
          data: { Points },
        },
      }
    ) {
      return {
        fetchingStatus: fetchingStatus.succeeded,
        data: Points.map(({ PageId: pageId, ContentId: contentId, Longitude: lng, Latitude: lat }) => ({
          type: 'Feature',
          properties: {
            cluster: false,
            pageId,
            contentId,
          },
          geometry: {
            type: 'Point',
            coordinates: [parseFloat(lng), parseFloat(lat)],
          },
        })),
      }
    },
    [getGeosearchPoints.failed](state) {
      return {
        ...state,
        fetchingStatus: fetchingStatus.failed,
      }
    },
    [getGeosearchPoints.cancel](state) {
      return {
        ...state,
        fetchingStatus: fetchingStatus.idle,
      }
    },
    [resetState](state, action) {
      return defaultValue
    },
  },
  defaultValue
)
