import { useCallback, useMemo, useState } from 'react'

import { useCursor } from '@react-three/drei'
import { useThree } from '@react-three/fiber'

import { Defaults } from '../constants'
import { getIntersectionPoint } from '../utils/raycasting'

export default (id, onDrag, onStart, onEnd) => {
  const [active, setActive] = useState(false)

  const controls = useThree((state) => state.controls)
  const gl = useThree((state) => state.gl)
  const scene = useThree((state) => state.scene)
  const raycaster = useThree((state) => state.raycaster)

  useCursor(active, 'grab')

  const onPointerMove = useCallback(
    (event) => {
      event.stopPropagation()

      if (onDrag) {
        let point = getIntersectionPoint(raycaster, scene)
        if (point) {
          point = point.clone().applyEuler(Defaults.ROTATION_OFFSET)

          onDrag({ id, point })
        }
      }
    },
    [id, onDrag, raycaster, scene]
  )

  const onMouseDown = useCallback(
    (event) => {
      if (event.button !== 0) {
        return
      }

      event.stopPropagation()

      gl.domElement.addEventListener('mousemove', onPointerMove)
      setActive(true)

      if (controls) {
        controls.enabled = false
      }

      if (onStart) {
        onStart()
      }
    },
    [controls, gl.domElement, onPointerMove, onStart]
  )

  const onMouseUp = useCallback(
    (event) => {
      if (event.button !== 0) {
        return
      }

      event.stopPropagation()

      gl.domElement.removeEventListener('mousemove', onPointerMove)
      setActive(false)

      if (controls) {
        controls.enabled = true
      }

      if (onEnd) {
        onEnd()
      }
    },
    [controls, gl.domElement, onEnd, onPointerMove]
  )

  const bind = useMemo(() => ({ onMouseDown, onMouseUp }), [onMouseDown, onMouseUp])

  return bind
}
