import { memo, useState } from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

import { Autocomplete, TextField } from '@mui/material'

import { UserInfo } from '@tabeeb/uikit'
import { useUsers } from '@tabeeb/modules/../users/hooks'

import UsersChip from './UserChip'

const UsersMultiSelect = ({ disabled, field, strict, contentId, endAdornment }) => {
  const [text, setText] = useState('')
  const { users } = useUsers({ contentId, search: text })

  const { setFieldValue } = useFormikContext()

  const onInputChange = ({ target: { value } }) => {
    setText(value || '')
  }

  const onChange = (_, values, reason, { option: email }) => {
    if (reason === 'createOption') {
      if (values.some((value) => value.Email === email)) {
        values.pop()
      } else {
        // eslint-disable-next-line no-param-reassign
        values[values.length - 1] = { Email: email }
      }
    }

    setFieldValue(field.name, values)
    setText('')
  }

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      freeSolo={!strict}
      fullWidth
      size='small'
      onKeyDown={(event) => {
        if (event.key === ' ' && !strict) {
          event.preventDefault()

          if (event.target.value === '') {
            return
          }

          const option = { option: event.target.value }
          onChange(event, [...field.value, option], 'createOption', option)
        }
      }}
      inputValue={text}
      clearOnEscape
      value={field.value}
      options={users}
      filterOptions={(option) => option}
      filterSelectedOptions
      onChange={onChange}
      onInputChange={onInputChange}
      getOptionLabel={(option) => option.Email}
      renderOption={(props, option) => (
        <li {...props} key={option.Email}>
          <UserInfo item={option} />
        </li>
      )}
      renderTags={(value, getTagProps) =>
        value.map((user, index) => {
          return <UsersChip key={user.Email} user={user} index={index} getTagProps={getTagProps} />
        })
      }
      disableClearable
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{
            '& legend': { display: 'none' },
            '& fieldset': { top: 0 },
          }}
          style={{ minWidth: 100 }}
          InputProps={{ ...params.InputProps, endAdornment, style: { paddingRight: 6 } }}
          placeholder={field.value.length === 0 ? 'Search name or email...' : undefined}
        />
      )}
    />
  )
}

UsersMultiSelect.defaultValues = {
  disabled: false,
  endAdornment: null,
  strict: false,
}

UsersMultiSelect.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        Email: PropTypes.string.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  strict: PropTypes.bool,
  contentId: PropTypes.number,
  endAdornment: PropTypes.element,
}

export default memo(UsersMultiSelect)
