const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  content: {
    margin: `${theme.spacing() * 2}px auto`,
    padding: theme.spacing() * 2,
    height: 'max-content',
    minWidth: '902px',
    maxWidth: '902px',
    display: 'flex',
    flexDirection: 'column',
  },
  avatarBlock: {
    display: 'flex',
    flexDirection: 'column',
    width: '206px',
  },
})

export default styles
