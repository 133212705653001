import { handleActions } from 'redux-actions'

import { annotationsActions } from '..'

const defaultState = {
  annotationId: 0,
}

export default handleActions(
  {
    [annotationsActions.openTextAnnotationEditor](state, action) {
      const { annotationId } = action.payload

      return {
        annotationId,
      }
    },
    [annotationsActions.closeTextAnnotationEditor](state, action) {
      return defaultState
    },
    [annotationsActions.resetAnnotationsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
