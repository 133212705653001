import { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Typography, withStyles } from '@material-ui/core'

import { gallerySelectors } from '@tabeeb/modules/gallery'

import { contentViewerActions } from '../..'

import styles from './styles'

const NoPreviewAvailable = ({ classes, selectedGalleryItemId, contentViewerActions }) => {
  useEffect(() => {
    contentViewerActions.resetLoadingInProgress()
  }, [selectedGalleryItemId])

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>No preview available</Typography>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedGalleryItemId: gallerySelectors.getSelectedGalleryItemId(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    contentViewerActions: bindActionCreators(contentViewerActions, dispatch),
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NoPreviewAvailable))
