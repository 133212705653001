import { useEffect, useState } from 'react'
import _ from 'lodash'

import { callAPIPromise as callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import { useDebouncedValue } from '@tabeeb/shared/utils/hooks'

import { searchUsers } from '../actions'

const DEBOUNCE_TIME_MS = 500
const MAX_USERS_COUNT = 50
const MIN_SEARCH_LENGTH = 3

const EMPTY_SET = []

export default ({ contentId, search, roles = EMPTY_SET }) => {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState(EMPTY_SET)

  const query = useDebouncedValue(search, DEBOUNCE_TIME_MS)

  useEffect(() => {
    if (query.length < MIN_SEARCH_LENGTH) {
      setUsers(EMPTY_SET)

      return
    }

    const fetchUsers = async () => {
      setLoading(true)
      const {
        response: { data: fetchedUsers },
      } = await callApiAsync(searchUsers.request({ searchText: query, contentId, roles }))

      setLoading(false)

      setUsers(_.take(fetchedUsers, MAX_USERS_COUNT))
    }

    fetchUsers()
  }, [contentId, query, roles])

  return { loading, users }
}
