import { memo } from 'react'

import { Color } from 'three'

import StaticColor from '../StaticColor'

const White = () => {
  return <StaticColor color={new Color(255, 255, 255)} />
}

export default memo(White)
