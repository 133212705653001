import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { TextField, FormControl, InputLabel, Select, MenuItem, Typography, withStyles } from '@material-ui/core'

import { contentStateSelectors } from '@tabeeb/shared/content'
import { aiSelectors, aiActions } from '@tabeeb/modules/artificialIntelligence'

import { annotationsSelectors } from '../..'

import styles from './styles'

const maxUniqueAiObjectNameLength = 500

const AIAnnotationEditor = ({
  classes,
  aiActions,
  onChange,
  selectedAiObject,
  contentId,
  aiObjects,
  uniqueAiObjects,
}) => {
  const [aiObjectId, setAiObjectId] = useState(selectedAiObject.Id)
  const handleChangeAIObject = useCallback(
    (e) => {
      const aiObjectId = e.target.value
      const selectedAIObject = aiObjects.find((object) => object.Id === aiObjectId)

      setAiObjectId(aiObjectId)
      setUniqueAiObjectId(0)
      aiActions.setSelectedAiObject(selectedAIObject)
      aiActions.getUniqueAiObjectsRequest({ aiObjectId, contentId })
    },
    [aiObjects, contentId]
  )

  const [uniqueAiObjectId, setUniqueAiObjectId] = useState(0)
  const handleChangeUniqueAIObject = useCallback((e) => {
    setUniqueAiObjectId(e.target.value)
  }, [])

  const [uniquieAiObjectName, setUniquieAiObjectName] = useState('')
  const handleChangeText = useCallback((e) => {
    setUniquieAiObjectName(e.target.value)
  }, [])

  useEffect(() => {
    const isUniqueAiObjectAlreadyExists = uniqueAiObjects.some((item) => item.Name === uniquieAiObjectName)
    const isMaxLengthExceeded = uniquieAiObjectName.length > maxUniqueAiObjectNameLength
    if (isUniqueAiObjectAlreadyExists || isMaxLengthExceeded) {
      onChange(undefined)
      return
    }

    onChange({
      newUniquieAiObjectName: uniquieAiObjectName,
      selectedAiObject: aiObjects.find((item) => item.Id === aiObjectId),
      selectedUniqueAiObject: uniqueAiObjectId ? uniqueAiObjects.find((item) => item.Id === uniqueAiObjectId) : null,
    })
  }, [aiObjectId, uniqueAiObjectId, uniquieAiObjectName])

  const menuProps = {
    style: {
      maxWidth: 434,
      maxHeight: 434,
    },
  }

  const isUniqueAiObjectAlreadyExists = uniqueAiObjects.some((item) => item.Name === uniquieAiObjectName)
  const isMaxLengthExceeded = uniquieAiObjectName.length > maxUniqueAiObjectNameLength

  return (
    <>
      <FormControl size='small' variant='outlined' className={classes.formControl} fullWidth>
        <InputLabel>Select AI object</InputLabel>
        <Select MenuProps={menuProps} value={aiObjectId} label='Select AI object' onChange={handleChangeAIObject}>
          {aiObjects.map((item) => (
            <MenuItem key={item.Id} value={item.Id}>
              {item.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl size='small' variant='outlined' className={classes.formControl} fullWidth>
        <InputLabel className={classes.label}>Select {selectedAiObject.Name}</InputLabel>
        <Select
          MenuProps={menuProps}
          value={uniqueAiObjectId}
          disabled={Boolean(uniquieAiObjectName)}
          label={`Select ${selectedAiObject.Name}`}
          onChange={handleChangeUniqueAIObject}
        >
          <MenuItem value={0}>None</MenuItem>
          {uniqueAiObjects.map((item) => (
            <MenuItem key={item.Id} value={item.Id}>
              {item.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography variant='body2'>or create new one</Typography>
      <TextField
        fullWidth
        className={classes.textField}
        value={uniquieAiObjectName}
        size='small'
        variant='outlined'
        onChange={handleChangeText}
        disabled={Boolean(uniqueAiObjectId)}
        helperText={
          (isUniqueAiObjectAlreadyExists && 'AI object with the same name already exists') ||
          (isMaxLengthExceeded && `Max length is ${maxUniqueAiObjectNameLength}`)
        }
        error={isUniqueAiObjectAlreadyExists || isMaxLengthExceeded}
        placeholder='Enter object name here...'
      />
    </>
  )
}

AIAnnotationEditor.propTypes = {
  classes: PropTypes.shape({
    formControl: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    textField: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  contentId: PropTypes.number.isRequired,
  selectedAiObject: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
  }).isRequired,
  aiObjects: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ).isRequired,
  uniqueAiObjects: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ).isRequired,
  aiActions: PropTypes.shape({
    setSelectedAiObject: PropTypes.func.isRequired,
    getUniqueAiObjectsRequest: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state, { Id }) => {
  const selectedAiObject = aiSelectors.getSelectedAiObject(state)

  return {
    contentId: contentStateSelectors.getContentId(state),
    annotation: annotationsSelectors.getAnnotationById(state, { Id }),
    selectedAiObject,
    aiObjects: aiSelectors.getAIObjects(state),
    uniqueAiObjects: aiSelectors.getUniqueAIObjectsByAIObjectId(state, { Id: selectedAiObject.Id }),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    aiActions: bindActionCreators(aiActions, dispatch),
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AIAnnotationEditor))
