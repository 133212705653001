import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    divider: {
      marginBottom: `${theme.spacing(0.5)}px !important`,
      marginTop: `${theme.spacing(0.5)}px !important`,
    },
    menuItem: {
      paddingBottom: `${theme.spacing(0.25)}px !important`,
      paddingTop: `${theme.spacing(0.25)}px !important`,
    },
  }
})
