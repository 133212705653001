import { memo } from 'react'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import { AnnotationType } from '@tabeeb/enums'
import { getAnnotationComponent } from '../../../utils/components'

import Controls from '../Controls'

const getCanSubmitAnnotation = (annotation) => {
  if (annotation.Type === AnnotationType.Model) {
    return Boolean(annotation.Anchor)
  }

  if (annotation.Type === AnnotationType.Area) {
    return Boolean(annotation.Points?.length >= 4)
  }

  return Boolean(annotation.Points?.length >= 3)
}

const getControlsPosition = (annotation) => {
  if (annotation.Anchor) {
    return new Vector3(annotation.Anchor.X, annotation.Anchor.Y, annotation.Anchor.Z)
  }

  const point = annotation.Points[annotation.Points.length - 2]

  return new Vector3(point.X, point.Y, point.Z)
}

const PendingAnnotation = ({ annotation, onSubmit }) => {
  const canSubmit = getCanSubmitAnnotation(annotation)

  const Component = getAnnotationComponent(annotation.Type)
  if (!Component) {
    return null
  }

  return (
    <>
      {canSubmit && <Controls position={getControlsPosition(annotation)} onSubmit={onSubmit} />}
      <Component annotation={annotation} hovered />
    </>
  )
}

PendingAnnotation.propTypes = {
  annotation: PropTypes.shape({
    Type: PropTypes.number.isRequired,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(PendingAnnotation)
