import { useEffect, useState } from 'react'

import { FetchStatus } from '@tabeeb/enums'
import { callAPIPromise as callApiAsync } from '@tabeeb/state/sagas/watchRequest'

export default ({ defaultResponse = null, enabled = true, request, payload, onError }) => {
  const [{ status, response }, setState] = useState({ status: FetchStatus.Idle, response: defaultResponse })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prevState) => ({
          ...prevState,
          status: FetchStatus.Loading,
        }))

        const {
          response: { data },
        } = await callApiAsync(request(payload))

        setState((prevState) => ({
          ...prevState,
          status: FetchStatus.Loaded,
          response: data,
        }))
      } catch (e) {
        setState((prevState) => ({
          ...prevState,
          status: FetchStatus.Failed,
        }))

        if (onError) {
          onError(e)
        }
      }
    }

    if (enabled) {
      fetchData()
    }
  }, [enabled, onError, payload, request])

  return {
    idle: status === FetchStatus.Idle,
    loading: status === FetchStatus.Loading,
    loaded: status === FetchStatus.Loaded,
    failed: status === FetchStatus.Failed,
    status,
    response,
  }
}
