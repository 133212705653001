import { put, all, delay } from 'redux-saga/effects'

import { getChatUserId } from './shared'
import { takeLastMessageFromChat } from '../effects'

import { chatActions } from '..'

function* markAsRead(action) {
  const { id, topicId, topicType, userId, receiverId } = action.payload

  yield delay(1000)

  const chatUserId = yield getChatUserId(userId, receiverId)
  yield put(chatActions.markAsReadRequest({ topicId, topicType, userId: chatUserId, messageId: id }))
}

function* unreadMessagesSaga() {
  yield all([takeLastMessageFromChat(chatActions.markAsRead, markAsRead)])
}

export default unreadMessagesSaga
