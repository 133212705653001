import PropTypes from 'prop-types'
import './index.less'

const PowerBiDashboardItem = ({ dashboard, handleClick }) => {
  return (
    <div className='biDashboard-container' onClick={handleClick}>
      <div className='biDashboard-image-container'>
        <img className='biDashboard-image' alt={dashboard.Name} src={dashboard.Image} />
        <div className='biDashboard-title'>{dashboard.Name}</div>
      </div>
      <div className='biDashboard-description'>{dashboard.Description}</div>
    </div>
  )
}

PowerBiDashboardItem.propTypes = {
  handleClick: PropTypes.func.isRequired,
  dashboard: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Id: PropTypes.string.isRequired,
    Description: PropTypes.string.isRequired,
    Image: PropTypes.string.isRequired,
  }),
}

export default PowerBiDashboardItem
