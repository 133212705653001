import { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Cancel as CancelIcon } from '@material-ui/icons'
import { useSelector } from 'react-redux'
import RestoreFromTrash from '@material-ui/icons/RestoreFromTrash'
import Block from '@material-ui/icons/Block'

import VisibilitySensor from '@tabeeb/modules/shared/utils/imageUtils'
import { isVideoPage as videoPageCheck, getImageRatio, getThumbnailUrl } from '@tabeeb/services/pageService'
import { contentTrashBinSelectors } from '../..'
import { dialogItems } from '../../services'

import './styles.less'

const Item = ({ item, listRef, dialogPage, onPageClick }) => {
  const [thumbnailLoaded, setThumbnailLoaded] = useState(false)

  const isPageSelectedSelector = useMemo(contentTrashBinSelectors.makeGetIsPageSelected)
  const isPageSelected = useSelector((state) => isPageSelectedSelector(state, item.id))

  const isAvailable = item.canDelete
  const imageRatio = getImageRatio(item)
  const thumbnailUrl = getThumbnailUrl(item)
  const isVideoPage = videoPageCheck(item.contentType)

  const _addDefaultSrc = (e) => {
    e.target.src = '/Content/img/spinner-loader.png'
  }

  return (
    <VisibilitySensor once scrollDelay={50} containment={listRef && listRef.current ? listRef.current : null}>
      {({ isVisible }) => {
        return (
          <li className={classNames('gallery-item', { selected: isPageSelected })}>
            <figure>
              <div className={classNames('figure-content', { 'not-available': !isAvailable })}>
                {isAvailable ? (
                  <div className='gallery-item-control-container'>
                    <span className='gallery-item-control' onClick={() => dialogPage(item.id, dialogItems.restoreOne)}>
                      <RestoreFromTrash />
                    </span>
                    <span
                      className='gallery-item-control'
                      onClick={() => {
                        dialogPage(item.id, dialogItems.deleteOne)
                      }}
                    >
                      <CancelIcon color='inherit' fontSize='inherit' />
                    </span>
                  </div>
                ) : (
                  <Block className='block-icon' />
                )}
                {isVideoPage && <i className='play-button' />}
                <img
                  className={classNames({ loaded: isAvailable && thumbnailLoaded, blocked: !isAvailable })}
                  draggable='false'
                  src={isVisible || thumbnailLoaded ? thumbnailUrl : ' '}
                  alt={item.thumbnailCaption}
                  onLoad={() => setThumbnailLoaded(true)}
                  onError={_addDefaultSrc}
                  onClick={() => {
                    isAvailable && onPageClick(item.id, isPageSelected)
                  }}
                />
                <span className='image-placeholder' style={{ paddingTop: `${100 * imageRatio}%` }} />
              </div>
              <figcaption>
                <span className='gallery-item-span' title={item.thumbnailCaption}>
                  {item.thumbnailCaption}
                </span>
              </figcaption>
            </figure>
          </li>
        )
      }}
    </VisibilitySensor>
  )
}

Item.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    contentType: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    thumbnailCaption: PropTypes.string.isRequired,
    isAddedToSelection: PropTypes.bool,
    canDelete: PropTypes.bool.isRequired,
  }),
  listRef: PropTypes.shape({
    current: PropTypes.object,
  }),
  dialogPage: PropTypes.func.isRequired,
  onPageClick: PropTypes.func.isRequired,
}

export default Item
