import { certificatesPageSize } from '@tabeeb/modules/certificates/constants'

const certificatesRequests = {
  getMyCertificatesRequest: ({ pageNumber = 1, pageSize = certificatesPageSize, filters = {}, sorting = {} }) => ({
    url: `api/certificates`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      search: {
        Filtering: filters,
        Sorting: sorting,
      },
    },
  }),

  getCertificatesByUserIdRequest: ({
    userId,
    pageNumber = 1,
    pageSize = certificatesPageSize,
    filters = {},
    sorting = {},
  }) => ({
    url: `api/certificates/users/${userId}`,
    method: 'get',
    params: {
      pageNumber,
      pageSize,
      search: {
        Filtering: filters,
        Sorting: sorting,
      },
    },
  }),

  getCertificateByIdRequest: (certificateId) => ({
    url: `api/certificates/${certificateId}`,
    method: 'get',
  }),

  createCertificateRequest: (certificateToCreate) => ({
    url: `api/certificates`,
    method: 'post',
    data: certificateToCreate,
  }),

  updateMyCertificateRequest: (certificateToUpdate) => ({
    url: `api/certificates`,
    method: 'put',
    data: certificateToUpdate,
  }),

  reviewCertificateRequest: ({ data }) => ({
    url: `api/certificates/review`,
    method: 'put',
    data,
  }),

  deleteCertificateRequest: (certificateIdToDelete) => ({
    url: `api/certificates/${certificateIdToDelete}`,
    method: 'delete',
  }),
}

export default certificatesRequests
