import { handleActions } from 'redux-actions'

import { resetState, getThumbnailsByPageIds } from '../actions'
import { fetchingStatus } from '../constants'

const defaultValue = {
  data: {},
  fetchingStatus: fetchingStatus.idle,
}

const EmptyThumbnailUrl = new URL('@tabeeb/assets/images/image_placeholder.png', import.meta.url)

export default handleActions(
  {
    [getThumbnailsByPageIds.success](state, { response: { data: pages } }) {
      const newData = {
        ...state.data,
      }
      pages.forEach(({ Id, ThumbnailURL, AssetType }) => {
        newData[Id] = {
          Id,
          ThumbnailURL: ThumbnailURL ?? EmptyThumbnailUrl,
          AssetType,
        }
      })
      return {
        fetchingStatus: fetchingStatus.succeeded,
        data: newData,
      }
    },
    [getThumbnailsByPageIds.request](state) {
      return {
        ...state,
        fetchingStatus: fetchingStatus.loading,
      }
    },
    [resetState]() {
      return defaultValue
    },
  },
  defaultValue
)
