import PropTypes from 'prop-types'
import { TextField, withStyles } from '@material-ui/core'
import { get } from 'lodash'

import styles from './styles'

const FormikTextFieldComponent = ({ classes, field, form, ...props }) => {
  const { errors, touched } = form
  const error = get(touched, field.name) && get(errors, field.name)

  return (
    <TextField
      error={!!error}
      variant='outlined'
      fullWidth
      className={classes.textField}
      InputLabelProps={{ shrink: true }}
      helperText={error}
      {...field}
      {...props}
    />
  )
}

FormikTextFieldComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
}

export default withStyles(styles)(FormikTextFieldComponent)
