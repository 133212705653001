import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { annotationsActions as annotationActions, annotationsSelectors } from '..'
import { convertAnnotationObjectToComponent } from '../services/annotationsService'

import AnnotationContainer from './AnnotationContainer'
import Circle from '../components/Circle'

class CircleContainer extends AnnotationContainer {
  constructor(props) {
    super(props)

    this.selectionProps = {
      onClick: this._handleSelect,
    }

    this.hoverProps = {
      onMouseEnter: this._handleMouseEnter,
      onMouseLeave: this._handleMouseLeave,
    }

    this.contextMenuProps = {
      onContextMenu: this._handleContextMenu,
    }

    this.dragAndDropProps = {
      draggable: true,
      onDragStart: this._handleDragStart,
      onDragEnd: this._handleDragEnd,
    }

    this.transformationProps = {
      onTransformStart: this._handleTransformStart,
      onTransform: this._handleTransform,
      onTransformEnd: this._handleEndUpdate,
    }
  }

  _handleDragEnd = (e) => {
    const { target } = e
    const { annotation } = this.state

    const offsetX = target.x() - Circle.getXY(annotation).x
    const offsetY = target.y() - Circle.getXY(annotation).y

    const updatedCoordinates = annotation.Coordinates.map((coords) => ({
      Start: { X: coords.Start.X + offsetX, Y: coords.Start.Y + offsetY },
      End: { X: coords.End.X + offsetX, Y: coords.End.Y + offsetY },
    }))

    const newAnnotation = {
      ...annotation,
      Coordinates: updatedCoordinates,
    }
    this.setState({ annotation: newAnnotation })

    this._handleEndUpdate(e)
  }

  _handleTransform = (e) => {
    const node = this.state.annotationRef
    const scaleX = node.scaleX()
    const scaleY = node.scaleY()

    node.scaleX(1)
    node.scaleY(1)

    const { annotation } = this.state
    const newAnnotation = {
      ...annotation,
      Coordinates: [
        {
          Start: {
            X: node.parent.x() - (node.width() * scaleX) / 2,
            Y: node.parent.y() - (node.height() * scaleY) / 2,
          },
          End: { X: node.parent.x() + (node.width() * scaleX) / 2, Y: node.parent.y() + (node.height() * scaleY) / 2 },
        },
      ],
    }

    node.x(0)
    node.y(0)

    this.setState({ annotation: newAnnotation })
  }

  render() {
    const { annotation, hovered, selected } = this.state
    const { childAnnotation, opacity } = this.props

    const props = {
      annotation,
      annotationRef: this.annotationRef,
      opacity,
      hovered,
      selected,
      hoverProps: this.hoverProps,
      selectionProps: this.selectionProps,
      contextMenuProps: this.contextMenuProps,
      dragAndDropProps: this.dragAndDropProps,
      transformationProps: this.transformationProps,
      transformerRef: this.transformerRef,
    }

    return (
      <Circle {...props}>
        {convertAnnotationObjectToComponent(childAnnotation, {
          parentHovered: props.hovered,
          parentSelected: props.selected,
        })}
      </Circle>
    )
  }
}

CircleContainer.propTypes = {
  childAnnotation: PropTypes.object,
  currentAnnotation: PropTypes.object.isRequired,
}

const mapStateToProps = (state, { Id }) => {
  return {
    currentAnnotation: annotationsSelectors.getAnnotationById(state, { Id }),
    childAnnotation: annotationsSelectors.getAnnotationChild(state, { Id }),
    selected: annotationsSelectors.getIsAnnotationSelected(state, { Id }),
    opacity: annotationsSelectors.getAnnotationOpacity(state, { Id }),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    annotationActions: bindActionCreators(annotationActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CircleContainer)
