import { contentStateSelectors } from '@tabeeb/modules/shared/content'
import { put, takeLatest, all, select, take } from 'redux-saga/effects'

import { accountSelectors } from '@tabeeb/modules/account'
import { getContentSuccess } from '@tabeeb/modules/shared/content/actions'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import { galleryActions } from '@tabeeb/modules/gallery'
import { getSessionFoldersRequest } from '@tabeeb/modules/gallery/actions'
import { notificationActions } from '@tabeeb/modules/notification'
import { contentTrashBinActions, contentTrashBinSelectors } from '..'
import { convertServicePageToPluginPage } from '../../../services/dataConverter'

function* onGetContentSuccess() {
  const sessionId = yield select(contentStateSelectors.getContentId)

  yield put(contentTrashBinActions.getDeletedPagesRequest(sessionId))
  yield put(contentTrashBinActions.getDeletedSessionFoldersRequest(sessionId))
}

function* onGetDeletedPagesSuccess(action) {
  const response = action.response.data
  const contentId = yield select(contentStateSelectors.getContentId)
  const ownerId = yield select(contentStateSelectors.getOwnerId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const pageToPluginPageParams = {
    contentId,
    contentOwnerId: ownerId,
    currentUserId,
    isLoaded: true,
  }

  const pages = response.map((page) => convertServicePageToPluginPage({ ...pageToPluginPageParams, page }))

  yield put(contentTrashBinActions.setDeletedPages(pages))
}

function* onSubmitRestorePage() {
  const pageId = yield select(contentTrashBinSelectors.getOperatingPageId)

  yield put(contentTrashBinActions.restorePageRequest(pageId))
  yield put(contentTrashBinActions.resetOperatingPageId())
}

function* onSubmitRestoreSelectedPages() {
  const selectedPagesIds = yield select(contentTrashBinSelectors.getSelectedPages)

  yield put(contentTrashBinActions.restoreSelectedPagesRequest(selectedPagesIds))
}

function* onSubmitRestoreFolder() {
  const selectedFolderId = yield select(contentTrashBinSelectors.getSelectedFolderId)
  const operatingFolderId = yield select(contentTrashBinSelectors.getOperatingFolderId)

  const folderToRestoreId = operatingFolderId || selectedFolderId

  yield put(contentTrashBinActions.restoreContentTrashBinFolderRequest(folderToRestoreId))

  if (operatingFolderId) {
    yield put(contentTrashBinActions.resetOperatingFolderId())
  }
}

function* onSubmitRestoreAllPages() {
  const sessionId = yield select(contentStateSelectors.getContentId)

  yield put(contentTrashBinActions.restoreContentTrashBinRequest(sessionId))
}

function* onSubmitDeletePage() {
  const pageId = yield select(contentTrashBinSelectors.getOperatingPageId)

  yield put(contentTrashBinActions.deletePagePermanentlyRequest(pageId))
  yield put(contentTrashBinActions.resetOperatingPageId())
}

function* onSubmitDeleteSelectedPages() {
  const selectedPagesIds = yield select(contentTrashBinSelectors.getSelectedPages)

  yield put(contentTrashBinActions.deleteSelectedPagesPermanentlyRequest(selectedPagesIds))
}

function* onSubmitDeleteFolder() {
  const selectedFolderId = yield select(contentTrashBinSelectors.getSelectedFolderId)
  const operatingFolderId = yield select(contentTrashBinSelectors.getOperatingFolderId)

  const folderToDeleteId = operatingFolderId || selectedFolderId

  yield put(contentTrashBinActions.deleteContentTrashBinFolderRequest(folderToDeleteId))

  if (operatingFolderId) {
    yield put(contentTrashBinActions.resetOperatingFolderId())
  }
}

function* onSubmitDeleteAllPages() {
  const sessionId = yield select(contentStateSelectors.getContentId)

  yield put(contentTrashBinActions.emptyContentTrashBinRequest(sessionId))
}

function* onPagesRestored(action) {
  const [restoredIds] = action.payload

  const contentId = yield select(contentStateSelectors.getContentId)
  const deletedPages = yield select(contentTrashBinSelectors.getDeletedPages)
  const restoredPages = deletedPages.filter((page) => restoredIds.includes(page.id))

  yield put(contentTrashBinActions.filterDeletedPages(restoredIds))
  yield put(contentTrashBinActions.deselectContentTrashBinFolderIfNotExists())

  for (const page of restoredPages) {
    page.isLoaded = false
    yield put(galleryActions.addGalleryItem(page))
  }

  yield put(getSessionFoldersRequest(contentId))
}

function* onPagesDeletedPermanently(action) {
  const [deletedIds] = action.payload

  yield put(contentTrashBinActions.filterDeletedPages(deletedIds))
  yield put(contentTrashBinActions.deselectContentTrashBinFolderIfNotExists())
}

function* onFoldersDeletedPermanently(action) {
  yield put(contentTrashBinActions.deselectContentTrashBinFolderIfNotExists())
}

function* onFoldersRestored(action) {
  const contentId = yield select(contentStateSelectors.getContentId)

  yield put(getSessionFoldersRequest(contentId))
  yield put(contentTrashBinActions.deselectContentTrashBinFolderIfNotExists())
}

function* deselectContentTrashBinFolderIfNotExists() {
  const contentId = yield select(contentStateSelectors.getContentId)

  yield put(contentTrashBinActions.getDeletedSessionFoldersRequest(contentId))
  const deletedFoldersResult = yield take([contentTrashBinActions.getDeletedSessionFoldersSuccess])
  const deletedFolders = deletedFoldersResult.response.data

  const selectedFolderId = yield select(contentTrashBinSelectors.getSelectedFolderId)

  const isSelectedFolderInDeletedFolders = deletedFolders.find((folder) => folder.Id === selectedFolderId)

  if (selectedFolderId && !isSelectedFolderInDeletedFolders) {
    yield put(contentTrashBinActions.deselectContentTrashBinFolder())
    yield put(
      notificationActions.onAddInfoNotification({ message: 'Selected folder does no longer exist in the trash bin' })
    )
  }
}

function* contentTrashBinSaga() {
  yield all([
    takeLatest(getContentSuccess, onGetContentSuccess),
    takeLatest(contentTrashBinActions.getDeletedPagesSuccess, onGetDeletedPagesSuccess),
    takeLatest(contentTrashBinActions.submitRestorePage, onSubmitRestorePage),
    takeLatest(contentTrashBinActions.submitRestoreSelectedPages, onSubmitRestoreSelectedPages),
    takeLatest(contentTrashBinActions.submitRestoreFolder, onSubmitRestoreFolder),
    takeLatest(contentTrashBinActions.submitRestoreAllPages, onSubmitRestoreAllPages),
    takeLatest(contentTrashBinActions.submitDeletePage, onSubmitDeletePage),
    takeLatest(contentTrashBinActions.submitDeleteSelectedPages, onSubmitDeleteSelectedPages),
    takeLatest(contentTrashBinActions.submitDeleteFolder, onSubmitDeleteFolder),
    takeLatest(contentTrashBinActions.submitDeleteAllPages, onSubmitDeleteAllPages),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onPagesDeletedPermanently, onPagesDeletedPermanently),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onPagesRestored, onPagesRestored),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onFoldersDeletedPermanently, onFoldersDeletedPermanently),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onFoldersRestored, onFoldersRestored),
    takeLatest(
      contentTrashBinActions.deselectContentTrashBinFolderIfNotExists,
      deselectContentTrashBinFolderIfNotExists
    ),
  ])
}

export default contentTrashBinSaga
