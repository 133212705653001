import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { GetApp } from '@material-ui/icons'

import { AssetVideoTypes } from '@tabeeb/enums'
import { galleryActions, gallerySelectors } from '@tabeeb/modules/gallery'
import { isSupportExportAsWebPage } from '@tabeeb/services/pageService'

import { playerToolbarSelectors } from '../..'

import ToolbarButton from '../ToolbarButton'

const ExportButton = () => {
  const dispatch = useDispatch()

  const display = useSelector((state) => isSupportExportAsWebPage(gallerySelectors.getSelectedGalleryItemType(state)))
  const page = useSelector(gallerySelectors.getSelectedGalleryItem)
  const isAnnotationsHidden = useSelector(playerToolbarSelectors.getHideAnnotations)

  const onClick = useCallback(() => {
    if (!page) {
      return
    }

    const videos = page.assets.filter((asset) => {
      return AssetVideoTypes.includes(asset.Type) && asset.Url.toLowerCase().indexOf('.ism') < 0
    })

    if (videos.length > 0) {
      videos.forEach((asset) => window.open(asset.Url, '_blank'))
    } else {
      dispatch(galleryActions.getPagePosterLinkRequest({ pageId: page.id, withAnnotations: !isAnnotationsHidden }))
    }
  }, [dispatch, isAnnotationsHidden, page])

  return <ToolbarButton Icon={GetApp} display={display} onClick={onClick} title='Export' />
}

export default React.memo(ExportButton)
