import { Mail, SupervisorAccount, Share, Error, Alarm, TodayOutlined, Book } from '@material-ui/icons'

import { SwitchOwner } from '@tabeeb/shared/icons'
import { ApprovalRequestType, NotificationsFilterType } from '@tabeeb/enums'

export const filters = [
  { Name: 'Invite', Value: NotificationsFilterType.Invite },
  { Name: 'Session Approval', Value: NotificationsFilterType.SessionApproval },
  { Name: 'Change Owner', Value: NotificationsFilterType.ChangeOwner },
  { Name: 'Shared Sessions', Value: NotificationsFilterType.ShareSession },
  { Name: 'Alert Host', Value: NotificationsFilterType.AlertOwner },
  { Name: 'Reminders', Value: NotificationsFilterType.Reminder },
  { Name: 'Calendar events', Value: NotificationsFilterType.CalendarEvent },
  { Name: 'Certificates', Value: NotificationsFilterType.CertificatesEvent },
]

export const getIconByNotificationType = (notificationType, props = {}) => {
  switch (notificationType) {
    case ApprovalRequestType.Notification:
      return <Error {...props} />
    case ApprovalRequestType.ChangeOwner:
      return <SwitchOwner {...props} />
    case ApprovalRequestType.SessionApproval:
      return <SupervisorAccount {...props} />
    case ApprovalRequestType.ShareSession:
      return <Share {...props} />
    case ApprovalRequestType.Invitation:
      return <Mail {...props} />
    case ApprovalRequestType.Reminder:
      return <Alarm {...props} />
    case ApprovalRequestType.CalendarEvent:
      return <TodayOutlined {...props} />
    case ApprovalRequestType.CertificatesEvent:
      return <Book {...props} />
  }
}
