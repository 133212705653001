import { memo, useCallback, useEffect } from 'react'

import { useThree } from '@react-three/fiber'

import { getZoomBox } from '../../utils/raycasting'

const Focus = () => {
  const gl = useThree((state) => state.gl)
  const scene = useThree((state) => state.scene)
  const controls = useThree((state) => state.controls)
  const raycaster = useThree((state) => state.raycaster)

  const onDoubleClick = useCallback(() => {
    const box = getZoomBox(raycaster, scene)
    if (box) {
      controls.fitToBox(box, true)
    }
  }, [controls, raycaster, scene])

  useEffect(() => {
    gl.domElement.addEventListener('dblclick', onDoubleClick)

    return () => {
      gl.domElement.removeEventListener('dblclick', onDoubleClick)
    }
  }, [gl.domElement, onDoubleClick])

  return null
}

export default memo(Focus)
