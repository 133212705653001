import { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { trackingSelectors } from '@tabeeb/modules/tracking'
import { selectIsPointCloudGeoSearchOpen } from '@tabeeb/modules/pointCloudGeoSearch/selectors'
import CircularProgress from '@tabeeb/shared/tabeebCircularProgressBar'

import PresentationVideo from './PresentationVideo'
import ContentViewer from '../../contentViewer'
import { getIsLargeVideoMirrored } from '../selectors'
import { presentationSelectors } from '..'
import GridViewContainer, { gridViewSelectors } from '../../gridView'
import { usersSelectors } from '../../../users'

const Tracking = lazy(() => import('@tabeeb/modules/tracking/pages'))
const PointCloudGeoSearch = lazy(() => import('@tabeeb/modules/pointCloudGeoSearch/components/PointCloudGeoSearchMap'))

class PresentationContent extends Component {
  render() {
    const {
      isLargeVideo,
      isTrackingOpen,
      isPointCloudGeoSearchOpen,
      largeVideoUser,
      isVideoMuted,
      isVideoMirrored,
      isGridViewActive,
      webZoomValue,
    } = this.props

    return (
      <section className='tabeebPresentationContent'>
        <Suspense fallback={<CircularProgress />}>{isTrackingOpen && <Tracking isHidden={isLargeVideo} />}</Suspense>
        <Suspense fallback={<CircularProgress />}>{isPointCloudGeoSearchOpen && <PointCloudGeoSearch />}</Suspense>
        <PresentationVideo
          largeVideoUser={largeVideoUser}
          isVideoMuted={isVideoMuted}
          isVideoMirrored={isVideoMirrored}
          webZoomValue={webZoomValue}
        />
        {isGridViewActive && <GridViewContainer />}
        <ContentViewer />
      </section>
    )
  }
}

function mapStateToProps(state) {
  const largeVideoUser = presentationSelectors.getLargeVideoUser(state)
  const isTrackingOpen = trackingSelectors.getIsOpen(state)
  const isVideoMirrored = getIsLargeVideoMirrored(state)
  const isGridViewActive = gridViewSelectors.getIsGridViewActive(state)
  const webZoomValue = usersSelectors.getUserWebZoomValueById(state, largeVideoUser?.id)

  return {
    isVideoMuted:
      largeVideoUser !== null ? !presentationSelectors.getIsUserVideoPlaying(state, { Id: largeVideoUser.id }) : true,
    isLargeVideo: largeVideoUser !== null,
    isTrackingOpen,
    isPointCloudGeoSearchOpen: selectIsPointCloudGeoSearchOpen(state),
    largeVideoUser,
    isVideoMirrored,
    isGridViewActive,
    webZoomValue,
  }
}

PresentationContent.propTypes = {
  isLargeVideo: PropTypes.bool.isRequired,
  isTrackingOpen: PropTypes.bool.isRequired,
  isPointCloudGeoSearchOpen: PropTypes.bool.isRequired,
  largeVideoUser: PropTypes.object,
  isVideoMuted: PropTypes.bool.isRequired,
  isVideoMirrored: PropTypes.bool.isRequired,
  isGridViewActive: PropTypes.bool.isRequired,
  webZoomValue: PropTypes.number.isRequired,
}

export default connect(mapStateToProps)(PresentationContent)
