import { memo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { Popover, MenuItem, ListItemText } from '@material-ui/core'

import { SupportedPointCloudExtensions } from '@tabeeb/modules/pointCloud/constants'

import * as rawActions from '../../actions'

import { addMenuItems } from '../../services/galleryMenus'
import { PointCloudModelStatus } from '../../../../Enums'
import { getIsCurrentUserReviewer } from '../../../../users/selectors'
import { getIs3DModelCreationLimitEnabled, getIsPointCloudModelsEnabled } from '../../../appConfigState/selectors'

import './styles.less'

const AddButtonMenu = ({
  setAnchorEl,
  anchorEl,
  show360View,
  show3dModel,
  showBentleyModel,
  is3dModelAvailable,
  showWebPage,
  showPointCloud,
  showStructureModel,
  showTowerSideProfile,
  show2dLineDrawing,
  isAutodeskViewerEnabled,
  showBentleyAnalyticsReport,
  isClassificationAnnotationsCreationEnabled,
  actions: { addFilesToQueue, handleAddButtonMenuClick },
}) => {
  const inputRef = useRef()
  const [selectedMenuItem, setSelectedMenuItem] = useState(null)

  const inputFileMenuItems = [
    addMenuItems.WordDocument,
    addMenuItems.PDF,
    addMenuItems.Images,
    addMenuItems.VideoFile,
    addMenuItems.PointCloudModel,
    addMenuItems.AutodeskFile,
  ]

  const _renderMenuItem = (menuItemType, key) => {
    let title = null
    let disabled = false
    let isVisible = true

    switch (menuItemType) {
      case addMenuItems.Add3dModel:
        title = is3dModelAvailable ? null : '3D model already created'
        disabled = !is3dModelAvailable
        isVisible = show3dModel && is3dModelAvailable
        break
      case addMenuItems.AddBentleyModel:
        isVisible = showBentleyModel
        break
      case addMenuItems.StructureModel:
        isVisible = showStructureModel
        break
      case addMenuItems.TowerSideProfile:
        isVisible = showTowerSideProfile
        break
      case addMenuItems.TwoDLineDrawing:
        isVisible = show2dLineDrawing
        break
      case addMenuItems.WebPageUsingUrl:
        isVisible = showWebPage
        break
      case addMenuItems.Add360View:
        isVisible = show360View
        break
      case addMenuItems.PointCloudModel:
        isVisible = showPointCloud
        break
      case addMenuItems.AutodeskFile:
        isVisible = isAutodeskViewerEnabled
        break
      case addMenuItems.BentleyAnalyticsReport:
        isVisible = showBentleyAnalyticsReport
        break
      case addMenuItems.ClassificationAnnotations:
        isVisible = isClassificationAnnotationsCreationEnabled
        break
    }

    return isVisible ? (
      <MenuItem
        onClick={disabled ? null : () => _handleMenuClick(menuItemType)}
        disabled={disabled}
        title={title}
        key={key}
      >
        <ListItemText primary={menuItemType} />
      </MenuItem>
    ) : null
  }

  const _handleMenuClick = (menuItemType) => {
    setAnchorEl(null)
    setSelectedMenuItem(menuItemType)

    switch (menuItemType) {
      case addMenuItems.WordDocument:
        inputRef.current.accept = '.doc, .docx, .ppt, .pptx, .xls, .xlsx'
        break
      case addMenuItems.PDF:
        inputRef.current.accept = 'application/pdf'
        break
      case addMenuItems.Images:
        inputRef.current.accept = 'image/jpeg,image/gif,image/png,image/heic,image/heif'
        inputRef.current.multiple = true
        break
      case addMenuItems.VideoFile:
        inputRef.current.accept = 'video/*'
        inputRef.current.multiple = true
        break
      case addMenuItems.PointCloudModel:
        inputRef.current.accept = SupportedPointCloudExtensions.join(', ')
        break
      case addMenuItems.AutodeskFile:
        inputRef.current.accept = '.rvt, .dwg, .obj'
        break
      default:
        handleAddButtonMenuClick({ menuItemType })
        break
    }

    if (inputFileMenuItems.includes(menuItemType)) inputRef.current.click()
  }

  const _handleFileInputChange = (e) => {
    const files = [...e.target.files]
    e.target.value = null
    if (inputFileMenuItems.includes(selectedMenuItem)) addFilesToQueue({ files })
  }

  return (
    <>
      <Popover
        className='gallery-add-menu-popover'
        open={anchorEl !== null}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <div className='gallery-add-menu'>
          {Object.values(addMenuItems).map((menuItem, index) => _renderMenuItem(menuItem, index))}
        </div>
      </Popover>
      <input
        ref={inputRef}
        type='file'
        multiple={false}
        onChange={_handleFileInputChange}
        style={{ display: 'none' }}
      />
    </>
  )
}

AddButtonMenu.propTypes = {
  anchorEl: PropTypes.any,
  setAnchorEl: PropTypes.func.isRequired,
  show360View: PropTypes.bool,
  show3dModel: PropTypes.bool,
  showBentleyModel: PropTypes.bool,
  is3dModelAvailable: PropTypes.bool,
  showPointCloud: PropTypes.bool.isRequired,
  showStructureModel: PropTypes.bool.isRequired,
  showTowerSideProfile: PropTypes.bool.isRequired,
  show2dLineDrawing: PropTypes.bool.isRequired,
  showWebPage: PropTypes.bool.isRequired,
  isAutodeskViewerEnabled: PropTypes.bool.isRequired,
  isClassificationAnnotationsCreationEnabled: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    addFilesToQueue: PropTypes.func.isRequired,
    handleAddButtonMenuClick: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  const { contentState, appConfigState } = state
  const { pointCloudModelStatus } = contentState
  const {
    show360View,
    show3dModel,
    showBentleyModel,
    showStructureModel,
    showTowerSideProfile,
    show2dLineDrawing,
    isAutodeskViewerEnabled,
    showBentleyAnalyticsReport,
    isClassificationAnnotationsCreationEnabled,
    showMenuAI,
  } = appConfigState
  const isReviewer = getIsCurrentUserReviewer(state)
  return {
    show360View: show360View && !isReviewer,
    show3dModel: show3dModel && !isReviewer,
    showBentleyModel: showBentleyModel && !isReviewer,
    is3dModelAvailable:
      pointCloudModelStatus === PointCloudModelStatus.NotCreated || !getIs3DModelCreationLimitEnabled(state),
    showWebPage: !isReviewer,
    showPointCloud: getIsPointCloudModelsEnabled(state) && !isReviewer,
    showStructureModel: showStructureModel && !isReviewer,
    showTowerSideProfile: showTowerSideProfile && !isReviewer,
    show2dLineDrawing: show2dLineDrawing && !isReviewer,
    isAutodeskViewerEnabled: isAutodeskViewerEnabled && !isReviewer,
    showBentleyAnalyticsReport: showBentleyAnalyticsReport && !isReviewer,
    isClassificationAnnotationsCreationEnabled: showMenuAI && isClassificationAnnotationsCreationEnabled && !isReviewer,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(memo(AddButtonMenu))
