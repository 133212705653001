import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { SnippingTool } from '@tabeeb/shared/icons'

import { SessionPermission } from '@tabeeb/enums'
import { getSelectedGalleryItemSupportsSnippingTool } from '@tabeeb/modules/gallery/selectors'
import { hasSessionPermission } from '@tabeeb/modules/permissions/selectors'
import ToolbarButton from '../ToolbarButton'

import { drawingActions, playerToolbarSelectors } from '../..'

const ESC_KEY_CODE = 27

const SnippingToolButton = () => {
  const dispatch = useDispatch()

  const display = useSelector(
    (state) =>
      getSelectedGalleryItemSupportsSnippingTool(state) &&
      hasSessionPermission(state, SessionPermission.AddGalleryContent)
  )
  const enabled = useSelector(playerToolbarSelectors.getIsSnippingToolEnabled)

  useEffect(() => {
    function disableSnippingTool({ keyCode }) {
      if (keyCode !== ESC_KEY_CODE) {
        return
      }

      dispatch(drawingActions.onDisableSnippingTool())
    }

    if (!enabled) {
      return
    }

    window.addEventListener('keydown', disableSnippingTool)

    return () => {
      window.removeEventListener('keydown', disableSnippingTool)
    }
  }, [dispatch, enabled])

  const onClick = useCallback(() => {
    if (enabled) {
      dispatch(drawingActions.onDisableSnippingTool())
    } else {
      dispatch(drawingActions.onEnableSnippingTool())
    }
  }, [dispatch, enabled])

  return (
    <ToolbarButton
      onClick={onClick}
      display={display}
      selected={enabled}
      Icon={SnippingTool}
      title={enabled ? 'Disable snipping tool' : 'Enable snipping tool'}
    />
  )
}

export default React.memo(SnippingToolButton)
