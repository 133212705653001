import { memo } from 'react'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import { Html } from '@react-three/drei'

import { Add } from '@mui/icons-material'

import { Button, Z_INDEX } from './styles'

const AddPointButton = ({ position, onClick }) => {
  return (
    <Html prepend center position={position} zIndexRange={Z_INDEX}>
      <Button title='Add point' onClick={() => onClick(position)}>
        <Add fontSize='inherit' color='inherit' />
      </Button>
    </Html>
  )
}

AddPointButton.propTypes = {
  position: PropTypes.instanceOf(Vector3).isRequired,
  onClick: PropTypes.func.isRequired,
}

export default memo(AddPointButton)
