import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { updateContentAnnotation } from '@tabeeb/modules/annotations/actions'
import { Defaults } from '../constants'

const useTransformControls = ({ annotation }) => {
  const dispatch = useDispatch()
  const [object, setObject] = useState(null)
  const [enabled, setEnabled] = useState(false)

  const onSelect = useCallback((e) => {
    e.stopPropagation()
    setObject(e.eventObject)
    setEnabled((prevEnabled) => !prevEnabled)
  }, [])

  useEffect(() => {
    if (!enabled) {
      return () => {}
    }

    const disableTransformation = ({ keyCode }) => {
      if (keyCode !== 27) {
        return
      }

      setEnabled(false)
    }

    window.addEventListener('keydown', disableTransformation)

    return () => {
      window.removeEventListener('keydown', disableTransformation)
    }
  }, [enabled])

  const onUpdated = (e) => {
    const newPosition = e.target.object.position.clone().applyEuler(Defaults.ROTATION_OFFSET)

    dispatch(
      updateContentAnnotation({
        annotation: {
          ...annotation,
          Anchor: { X: newPosition.x, Y: newPosition.y, Z: newPosition.z },
          Url: annotation.Url.split('?')[0],
        },
      })
    )
  }

  return {
    enabled,
    object,
    onSelect,
    onUpdated,
  }
}

export default useTransformControls
