import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Box3 } from 'three'
import { useFrame, useLoader, useThree } from '@react-three/fiber'

import { Defaults } from '../../../constants'
import { PotreeLoader } from '../../../loaders'
import { useCacheCleanup, useBoxFocus } from '../../../hooks'

const THROTTLE_INTERVAL_MS = 15

const PotreeModel = ({ id, total, url, onProgress }) => {
  const pointCloud = useLoader(
    PotreeLoader,
    url,
    (loader) => {
      // eslint-disable-next-line no-param-reassign
      loader.pageId = id
    },
    onProgress
  )
  useCacheCleanup(PotreeLoader, url)

  pointCloud.material.vertexColors = true
  pointCloud.material.size = Defaults.POINT_SIZE

  const box = useMemo(() => {
    const min = pointCloud
      .localToWorld(pointCloud.pcoGeometry.tightBoundingBox.min.clone())
      .applyEuler(Defaults.ROTATION)

    const max = pointCloud
      .localToWorld(pointCloud.pcoGeometry.tightBoundingBox.max.clone())
      .applyEuler(Defaults.ROTATION)

    return new Box3().setFromPoints([min, max])
  }, [pointCloud])

  const camera = useThree((state) => state.camera)
  const renderer = useThree((state) => state.gl)

  const update = useMemo(() => {
    return _.throttle(() => {
      pointCloud.potree.updatePointClouds([pointCloud], camera, renderer)
    }, THROTTLE_INTERVAL_MS * total)
  }, [camera, pointCloud, renderer, total])

  useFrame(update)

  useBoxFocus(id, box)

  return <primitive object={pointCloud} />
}

PotreeModel.defaultProps = {
  total: 1,
}

PotreeModel.propTypes = {
  id: PropTypes.number.isRequired,
  total: PropTypes.number,
  url: PropTypes.string.isRequired,
  onProgress: PropTypes.func.isRequired,
}

export default memo(PotreeModel)
