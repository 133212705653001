import {
  Notifications,
  InsertChart,
  Apps,
  Home,
  Person,
  Book,
  AssignmentInd,
  Password,
  Security,
} from '@mui/icons-material'
import * as Yup from 'yup'

import { Errors } from '@tabeeb/shared/forms'
import { addQueriesToUrl } from '@tabeeb/shared/utils/helpers'
import domainService from '@tabeeb/services/domainService'

export const getTabs = ({ showHealthData, showNotifications, showCertificates }) => [
  { id: 0, label: 'Profile details', icon: <Person />, toDisplay: true },
  { id: 1, label: 'Address', icon: <Home />, toDisplay: true },
  { id: 2, label: 'Notifications', icon: <Notifications />, toDisplay: showNotifications },
  { id: 3, label: 'Health data', icon: <InsertChart />, toDisplay: showHealthData },
  { id: 4, label: 'Certificates', icon: <Book />, toDisplay: showCertificates },
  {
    id: 5,
    label: 'My account',
    url: `${process.env.TABEEB_IDENTITY_URL}/Manage`,
    icon: <AssignmentInd />,
    toDisplay: true,
    displayDividerBefore: true,
  },
  {
    id: 6,
    label: 'Change password',
    url: `${process.env.TABEEB_IDENTITY_URL}/Manage/ChangePassword`,
    icon: <Password />,
    toDisplay: true,
  },
  {
    id: 7,
    label: 'Multi-factor Authentication',
    url: `${process.env.TABEEB_IDENTITY_URL}/Manage/TwoFactorAuthentication`,
    icon: <Security />,
    toDisplay: true,
  },
  {
    id: 8,
    label: 'My tenants',
    url: `${process.env.TABEEB_IDENTITY_URL}/Manage/Tenants`,
    icon: <Apps />,
    toDisplay: true,
  },
]

export const getSetupMfaISUrl = async () => {
  const setupMfaPathNameAndSearch = addQueriesToUrl(
    [
      {
        key: 'returnUrl',
        value: domainService.createOriginWithSubdomain(`${domainService.subdomain}.`),
      },
    ],
    '/Manage/TwoFactorAuthentication'
  )

  return `${process.env.TABEEB_IDENTITY_URL}${setupMfaPathNameAndSearch.pathname}?${setupMfaPathNameAndSearch.search}`
}

export const loginInitialValues = {
  Username: '',
  Password: '',
}

export const loginSchema = Yup.object().shape({
  Username: Yup.string().required(Errors.empty),
  Password: Yup.string().required(Errors.empty),
})

export const registerSchema = Yup.object().shape({
  FirstName: Yup.string().trim().max(35, 'Maximum length is 35 characters').required('Required'),
  LastName: Yup.string().trim().max(35, 'Maximum length is 35 characters').required('Required'),
})
