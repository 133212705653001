import { Component, memo } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Layer, Rect } from 'react-konva'
import _ from 'lodash'

import { playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'

import { getPointerPosition } from '../services/annotationsService'

import { AnnotationDrawer, annotationsSelectors, annotationsActions } from '..'

import AnnotationsList from '../components/AnnotationsList'
import AnnotationMenu from '../components/AnnotationMenu'
import SelectionArea from '../components/SelectionArea'
import AnnotationsSelectionService from '../services/annotationsSelectionService'

const DELETE_KEY_CODE = 46
const SELECTION_AREA_UPDATE_THROTTLE_INTERVAL = 10
const SELECTION_CALCULATIONS_THROTTLE_INTERVAL = 100

class AnnotationsContainer extends Component {
  selectionService = new AnnotationsSelectionService()

  updateSelection = _.throttle((annotations, area) => {
    const {
      annotationsActions: { setSelectedAnnotationsIds },
    } = this.props

    const ids = this.selectionService.getIncludedAnnotationsIds(annotations, area)

    setSelectedAnnotationsIds(ids)
  }, SELECTION_CALCULATIONS_THROTTLE_INTERVAL)

  updateSelectionArea = _.throttle((position) => {
    this.setState((prevState) => ({
      ...prevState,
      width: position.X - prevState.x,
      height: position.Y - prevState.y,
    }))
  }, SELECTION_AREA_UPDATE_THROTTLE_INTERVAL)

  constructor(props) {
    super(props)

    this.state = {
      active: false,
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    }
  }

  componentDidMount() {
    window.addEventListener('keyup', this.onDeleteButtonPressed)
  }

  componentDidUpdate(_, prevState) {
    const { active, x, y, width, height } = this.state
    const { annotationsList } = this.props

    if (!active) {
      return
    }

    if (prevState.width !== width || prevState.height !== height) {
      this.updateSelection(annotationsList, { x, y, width, height })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.onDeleteButtonPressed)
  }

  onDeleteButtonPressed = ({ keyCode }) => {
    if (keyCode !== DELETE_KEY_CODE) {
      return
    }

    const {
      selectedAnnotationsIds,
      annotationsActions: { deleteContentAnnotations, resetSelectedAnnotationsIds },
    } = this.props

    if (selectedAnnotationsIds.length > 0) {
      deleteContentAnnotations({ annotationIds: selectedAnnotationsIds })
      resetSelectedAnnotationsIds()
    }
  }

  onMouseDown = ({ evt: { ctrlKey, shiftKey }, target }) => {
    const {
      selectedAnnotationsIds,
      isSelectionEnabled,
      isAnnotationHighlighted,
      annotationsActions: { resetSelectedAnnotationsIds, resetHighlightedAnnotationId },
    } = this.props

    if (!target.attrs.id && isSelectionEnabled) {
      const position = getPointerPosition(target)

      this.setState({ active: true, x: position.X, y: position.Y, width: 0, height: 0 })
      return
    }

    if (!(ctrlKey || shiftKey) && selectedAnnotationsIds.length > 0) {
      resetSelectedAnnotationsIds()
      return
    }

    if (isAnnotationHighlighted) {
      resetHighlightedAnnotationId()
    }
  }

  onMouseMove = ({ target }) => {
    const position = getPointerPosition(target)

    this.updateSelectionArea(position)
  }

  openSelectedAnnotationsMenu = ({ x, y }) => {
    const {
      selectedAnnotationsIds,
      annotationsActions: { openAnnotationMenu },
    } = this.props

    if (selectedAnnotationsIds.length === 0) {
      return
    }

    openAnnotationMenu({ x, y })
  }

  onMouseUp = ({ evt: { clientX, clientY } }) => {
    this.setState({ active: false })

    this.openSelectedAnnotationsMenu({
      x: clientX,
      y: clientY,
    })
  }

  onMouseLeave = ({ evt: { toElement, clientX, clientY } }) => {
    if (toElement.tagName !== 'CANVAS') {
      this.setState({ active: false })

      this.openSelectedAnnotationsMenu({
        x: clientX,
        y: clientY,
      })
    }
  }

  render() {
    const { hideAnnotations, annotationsList } = this.props
    const { active, x, y, width, height } = this.state

    return (
      <>
        <AnnotationMenu />
        <Layer
          name='annotations'
          visible={!hideAnnotations}
          onMouseDown={this.onMouseDown}
          onMouseMove={active && this.onMouseMove}
          onMouseUp={active && this.onMouseUp}
          onMouseLeave={active && this.onMouseLeave}
        >
          <Rect name='placeholder' fill='transparent' width={10000} height={10000} />
          <AnnotationsList annotations={annotationsList} />
          {active && <SelectionArea x={x} y={y} width={width} height={height} />}
        </Layer>
        <AnnotationDrawer />
      </>
    )
  }
}

AnnotationsContainer.propTypes = {
  hideAnnotations: PropTypes.bool.isRequired,
  selectedAnnotationsIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  isAnnotationHighlighted: PropTypes.bool.isRequired,
  isSelectionEnabled: PropTypes.bool.isRequired,
  annotationsList: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
    }).isRequired
  ).isRequired,
  annotationsActions: PropTypes.shape({
    deleteContentAnnotations: PropTypes.func.isRequired,
    resetSelectedAnnotationsIds: PropTypes.func.isRequired,
    resetHighlightedAnnotationId: PropTypes.func.isRequired,
    setSelectedAnnotationsIds: PropTypes.func.isRequired,
    openAnnotationMenu: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  return {
    hideAnnotations: playerToolbarSelectors.getHideAnnotations(state),
    annotationsList: annotationsSelectors.getSelectedPageAnnotationsWithTimestamp(state),
    selectedAnnotationsIds: annotationsSelectors.getSelectedAnnotationsIds(state),
    isAnnotationHighlighted: Boolean(annotationsSelectors.getHighlightedAnnotation(state)),
    isSelectionEnabled: playerToolbarSelectors.getIsSelectionEnabled(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    annotationsActions: bindActionCreators(annotationsActions, dispatch),
  }
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(AnnotationsContainer))
