import { memo } from 'react'
import PropTypes from 'prop-types'

import { Typography } from '@mui/material'

const ProcessingTextHelper = ({ model, children }) => {
  const showProcessingText = model.supportProcessing && !model.processed
  if (showProcessingText) {
    return (
      <Typography
        sx={{ zIndex: 1, maxWidth: (theme) => theme.breakpoints.values.sm }}
        variant='h6'
        color='HighlightText'
      >
        Please wait, we are processing 3D model for you
      </Typography>
    )
  }

  return children
}

ProcessingTextHelper.propTypes = {
  model: PropTypes.shape({
    id: PropTypes.number.isRequired,
    processed: PropTypes.bool.isRequired,
    supportProcessing: PropTypes.bool.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
}

export default memo(ProcessingTextHelper)
