import { memo } from 'react'
import { useSelector } from 'react-redux'

import Black from './Black'
import Skybox from './Skybox'
import White from './White'

import { Backgrounds } from '../../constants'
import { getViewerBackground } from '../../selectors'

const Background = () => {
  const background = useSelector(getViewerBackground)

  if (background === Backgrounds.Skybox) {
    return <Skybox />
  }

  if (background === Backgrounds.White) {
    return <White />
  }

  if (background === Backgrounds.Black) {
    return <Black />
  }

  return null
}

export default memo(Background)
