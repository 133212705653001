import { memo } from 'react'
import PropTypes from 'prop-types'

const AnnouncementText = ({ text }) => {
  const srcDoc = `<html>
      <head>
        <link
          type='text/css'
          rel='stylesheet'
          href='https://cdn.tiny.cloud/1/invalid-origin/tinymce/5.10.7-133/skins/ui/oxide/content.min.css'
          crossorigin='anonymous'
        />
        <link
          type='text/css'
          rel='stylesheet'
          href='https://cdn.tiny.cloud/1/invalid-origin/tinymce/5.10.7-133/skins/content/default/content.min.css'
          crossorigin='anonymous'
        />
      </head>
      <body id='tinymce' class='mce-content-body '>
        ${text}
      </body>
    </html>`

  return <iframe width='100%' height='100%' frameBorder='0' title='announcement' srcDoc={srcDoc} />
}

AnnouncementText.propTypes = {
  text: PropTypes.string.isRequired,
}

export default memo(AnnouncementText)
