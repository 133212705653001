import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import { AddOutlined, GroupOutlined, KeyboardArrowDownOutlined, PublicOutlined } from '@mui/icons-material'

import Routes from '@tabeeb/routes'
import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'

import { AnnouncementScope } from '../../enums'
import { getAvailableAnnouncementScopes } from '../../selectors'

const AnnouncementCreateButton = () => {
  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const scopes = useSelector(getAvailableAnnouncementScopes)

  const options = []
  if (scopes.includes(AnnouncementScope.System)) {
    options.push({
      id: 1,
      title: 'System announcement',
      icon: PublicOutlined,
      href: Routes.announcementCreateSystem,
    })
  }

  if (scopes.includes(AnnouncementScope.Tenant)) {
    options.push({
      id: 2,
      title: 'Tenant announcement',
      icon: GroupOutlined,
      href: Routes.announcementCreateTenant,
    })
  }

  if (options.length === 0) {
    return null
  }

  if (options.length === 1) {
    return (
      <Link to={options[0].href}>
        <Button startIcon={<AddOutlined color='primary' />} variant='outlined'>
          Create new
        </Button>
      </Link>
    )
  }

  return (
    <>
      <Button
        ref={anchorRef}
        endIcon={<KeyboardArrowDownOutlined color='primary' />}
        variant='outlined'
        onClick={onOpen}
      >
        Create new
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {options.map(({ id, href, icon: Icon, title }) => (
          <Link key={id} to={href}>
            <MenuItem>
              <ListItemIcon>
                <Icon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary={title} />
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </>
  )
}

export default memo(AnnouncementCreateButton)
