import { memo } from 'react'

import { Color } from 'three'

import StaticColor from '../StaticColor'

const Black = () => {
  return <StaticColor color={new Color(0, 0, 0)} />
}

export default memo(Black)
