import { useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import { push } from 'connected-react-router'

import Routes from '@tabeeb/routes'
import { callAPIPromise } from '@tabeeb/state/sagas/watchRequest'

import { onAddErrorNotification, onAddSuccessNotification } from '@tabeeb/modules/notification/actions'

import { createSystemAnnouncement } from '../../actions'
import { EditAnnouncementForm } from '../../components'
import { AnnouncementScope } from '../../enums'

const CreateSystemAnnouncementPage = () => {
  const dispatch = useDispatch()

  const onSubmit = useCallback(
    async (announcement) => {
      try {
        await callAPIPromise(createSystemAnnouncement.request(announcement))
        onAddSuccessNotification({ message: 'Announcement successfully created' })
        dispatch(push(Routes.announcementsManagement))
      } catch {
        onAddErrorNotification({ message: 'Failed to create announcement' })
      }
    },
    [dispatch]
  )

  return (
    <EditAnnouncementForm title='Create system announcement' scope={AnnouncementScope.System} onSubmit={onSubmit} />
  )
}

export default memo(CreateSystemAnnouncementPage)
