import { put, takeLatest, select, all } from 'redux-saga/effects'

import { galleryActions } from '@tabeeb/modules/gallery'
import { contentViewerActions } from '@tabeeb/modules/contentViewer'
import { convertAnnotationInfo } from '@tabeeb/services/dataConverter'

import { annotationsActions } from '..'

function* getAnnotationsByPage(action) {
  const pageId = action.payload
  const { selectedGalleryItemId } = yield select((state) => state.gallery.galleryState)

  if (selectedGalleryItemId === pageId) {
    yield put(annotationsActions.getAnnotationsByPageRequest(pageId))
  }
}

function* getAnnotationsByPageSuccess(action) {
  const annotations = action.response.data

  for (let i = 0; i < annotations.length; i++) {
    const annotation = annotations[i]
    yield put(galleryActions.addAnnotation(annotation))
  }
}

function* getAnnotationsByPageParallelSuccess({ response: parallelResponses }) {
  for (const { data: annotations } of parallelResponses) {
    yield put(galleryActions.addAnnotations(annotations))
  }
}

function* getAnnotationTextByCoordinatesSuccess(action) {
  const { data } = action.response

  if (data.length <= 0) {
    return
  }

  const mappedAnnotationInfo = data.map((annotationInfo) => convertAnnotationInfo(annotationInfo))
  yield put(contentViewerActions.setCoordinatesInfo(mappedAnnotationInfo))
}

function* getAnnotationSagas() {
  yield all([
    takeLatest(annotationsActions.getAnnotationsByPage, getAnnotationsByPage),
    takeLatest(annotationsActions.getAnnotationsByPageSuccess, getAnnotationsByPageSuccess),
    takeLatest(annotationsActions.getAnnotationsByPageParallelSuccess, getAnnotationsByPageParallelSuccess),
    takeLatest(annotationsActions.getAnnotationTextByCoordinatesSuccess, getAnnotationTextByCoordinatesSuccess),
  ])
}

export default getAnnotationSagas
