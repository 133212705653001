import { memo } from 'react'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import { Html } from '@react-three/drei'

import { DeleteOutlined } from '@mui/icons-material'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'

import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'

import { Defaults } from '../../../constants'
import { useDrag } from '../../../hooks'

import { Dot } from './styles'

const Point = ({ id, draggable, position, visible, onDelete, onUpdate, onUpdateCommit }) => {
  const dragAndDropProps = useDrag(id, onUpdate, null, onUpdateCommit)

  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  const onContextMenu = (e) => {
    e.preventDefault()
    onOpen()
  }

  if (!visible) {
    return null
  }

  return (
    <Html prepend center position={position} zIndexRange={Defaults.HTML_Z_INDEX}>
      <Dot ref={anchorRef} {...(draggable ? dragAndDropProps : {})} onContextMenu={onContextMenu} />

      {draggable && onDelete && (
        <Menu
          anchorEl={anchorRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={onClose}
          MenuListProps={{
            dense: true,
            disablePadding: true,
          }}
        >
          <MenuItem
            dense
            onClick={() => {
              onClose()
              onDelete({ id })
            }}
          >
            <ListItemIcon>
              <DeleteOutlined fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Delete point' />
          </MenuItem>
        </Menu>
      )}
    </Html>
  )
}

Point.defaultProps = {
  draggable: true,
  visible: true,
  onDelete: undefined,
  onUpdate: undefined,
  onUpdateCommit: undefined,
}

Point.propTypes = {
  id: PropTypes.number.isRequired,
  draggable: PropTypes.bool,
  position: PropTypes.instanceOf(Vector3).isRequired,
  visible: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onUpdateCommit: PropTypes.func,
}

export default memo(Point)
