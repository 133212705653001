import { memo } from 'react'
import PropTypes from 'prop-types'

import { LinearProgress } from '@mui/material'

const LoadingIndicator = ({ progress }) => {
  return (
    <LinearProgress
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
      }}
      variant='determinate'
      value={progress}
    />
  )
}

LoadingIndicator.propTypes = {
  progress: PropTypes.number.isRequired,
}

export default memo(LoadingIndicator)
