import { memo } from 'react'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import { Html } from '@react-three/drei'

import { Fab } from '@mui/material'
import { Check } from '@mui/icons-material'

import { Defaults } from '../../../constants'

import { Z_INDEX } from './styles'

const Controls = ({ position, onSubmit }) => {
  if (!position) {
    return null
  }

  return (
    <group rotation={Defaults.ROTATION}>
      <Html prepend center position={position} zIndexRange={Z_INDEX}>
        <Fab
          sx={{
            width: 22,
            height: 22,
            minHeight: 22,
          }}
          size='small'
          title='Save'
          onClick={onSubmit}
        >
          <Check color='primary' />
        </Fab>
      </Html>
    </group>
  )
}

Controls.propTypes = {
  position: PropTypes.instanceOf(Vector3).isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default memo(Controls)
