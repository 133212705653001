import { memo } from 'react'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import { useLoader } from '@react-three/fiber'

import { useObjectFocus } from '../../../hooks'

const GlbModel = ({ id, url, position, focus, onClick, onProgress }) => {
  const glb = useLoader(GLTFLoader, url, null, onProgress)

  useObjectFocus(id, glb.scene, focus)

  return <primitive position={position} object={glb.scene} onClick={onClick} />
}

GlbModel.propTypes = {
  id: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.instanceOf(Vector3).isRequired,
  focus: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onProgress: PropTypes.func.isRequired,
}

export default memo(GlbModel)
