import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    dragAndDropContainer: {
      alignItems: 'center',
      padding: theme.spacing(2),
      borderWidth: 2,
      borderRadius: theme.shape.borderRadius,
      borderColor: '#eeeeee',
      borderStyle: 'dashed',
      backgroundColor: '#fafafa',
      color: '#bdbdbd',
      outline: 'none',
      transition: 'border .24s ease-in-out',
    },
    focusedDragAndDropContainer: {
      borderColor: '#2196f3',
    },
    acceptedDragAndDropContainer: {
      borderColor: '#00e676',
    },
    rejectedDragAndDropContainer: {
      borderColor: '#ff1744',
    },
    acceptedFilesList: {
      display: 'flex',
    },
    redText: {
      color: '#EB3D26',
    },
    blackText: {
      color: 'black',
    },
    dragAndDropContainerFooter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
  }
})
