import { ControlType, FormItemType, FormControlType } from '../../../Enums'
import { generateId } from './generateTempId'

const defaultOptions = [
  { Id: 1, Value: 'Option 1', Order: 0, Conditions: null },
  { Id: 2, Value: 'Option 2', Order: 1, Conditions: null },
  { Id: 3, Value: 'Option 3', Order: 2, Conditions: null },
]

export const controlMap = {
  textbox: ControlType.Textbox,
  textarea: ControlType.Textarea,
  checkboxlist: ControlType.CheckboxList,
  radiobuttons: ControlType.Radiobuttons,
  dropdown: ControlType.Dropdown,
  date: ControlType.Date,
  datetime: ControlType.DateTime,
}

const controlsWithOptions = [ControlType.CheckboxList, ControlType.Radiobuttons, ControlType.Dropdown]

export function createControl(controlTypeKey, controlsList, mode, formId, tooltip, controlLabel, order, options) {
  const controlType = controlMap[controlTypeKey.toLowerCase().replace(' ', '')]
  const isControlWithOptions = controlsWithOptions.includes(controlType)
  const hasValidOptions = options && options.length > 0
  const isImport = mode === 'import'

  let resultOptions = []

  if (isControlWithOptions && hasValidOptions) {
    resultOptions = options
  } else if (isControlWithOptions && !hasValidOptions) {
    resultOptions = defaultOptions
  }

  let newControl = {}
  newControl.TempId = isImport ? order : generateId(controlsList)
  newControl.Label = isImport ? controlLabel : controlTypeKey
  newControl.FormItemType = FormItemType.Control
  newControl.InitialValue = ''
  newControl.IncrementValue = null
  newControl.IncrementPeriod = null
  newControl.Tooltip = tooltip || ''
  newControl.Placeholder = ''
  newControl.Order = isImport ? order : controlsList.length
  newControl.Type = FormControlType.Basic
  newControl.ControlType = controlType
  newControl.Options = resultOptions
  newControl.Conditions = null
  newControl.Info = null
  newControl.ProcessByVQAModel = controlType === ControlType.Radiobuttons || controlType === ControlType.Dropdown

  if (mode === 'edit') {
    newControl = {
      ...newControl,
      FormId: formId,
    }
  }

  return newControl
}
