import { memo } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'

import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'

import { UserRole } from '@tabeeb/enums'
import { withModernTheme } from '@tabeeb/uikit'

import { UserRoleSelectField } from '@tabeeb/modules/invites/forms'
import UsersMultiSelect from '@tabeeb/modules/invites/components/UsersMultiSelect'
import { validateEmail, validatePhone } from '@tabeeb/modules/../users/services/users'

import { addUsersToSelectedSessions } from '../../../actions'

const schema = Yup.object().shape({
  Role: Yup.number().strict().oneOf([UserRole.User, UserRole.Viewer, UserRole.SessionGuest]).required(),
  Users: Yup.array()
    .strict()
    .of(
      Yup.object({
        Email: Yup.string()
          .strict()
          .test('email_or_phone', 'Email / Phone is invalid', (value) => {
            return validateEmail(value) || validatePhone(value)
          }),
      }).required()
    )
    .min(1)
    .required(),
})

const initial = {
  Role: UserRole.User,
  Users: [],
}

const AddUsersToSessionsDialog = ({ open, sessions, onClose }) => {
  const dispatch = useDispatch()

  const onSubmit = ({ Users, Role }) => {
    dispatch(addUsersToSelectedSessions({ UserIds: Users.map((user) => user.Id), RoleId: Role }))

    onClose()
  }

  return (
    <Dialog open={open} fullWidth maxWidth='sm' onClose={onClose}>
      <Formik initialValues={initial} validateOnMount validationSchema={schema} onSubmit={onSubmit} enableReinitialize>
        {({ isValid, dirty }) => (
          <Form autoComplete='off'>
            <DialogTitle>Add users</DialogTitle>
            <DialogContent>
              <Field
                name='Users'
                component={UsersMultiSelect}
                endAdornment={<Field name='Role' component={UserRoleSelectField} />}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Close</Button>
              <Button disabled={!dirty || !isValid} color='primary' variant='contained' type='submit'>
                Add users
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

AddUsersToSessionsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  sessions: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Description: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(withModernTheme(AddUsersToSessionsDialog))
