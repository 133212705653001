import { useCallback, useContext } from 'react'
import { ReactReduxContext } from 'react-redux'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import { Html } from '@react-three/drei'

import { ThemeProvider } from '@mui/material'
import { ThemeContext } from '@emotion/react'

const Portal = ({ children, container }) => {
  const theme = useContext(ThemeContext)
  const store = useContext(ReactReduxContext)

  return (
    <Html hidden>
      {createPortal(
        <ThemeProvider theme={theme}>
          <ReactReduxContext.Provider value={store}>{children}</ReactReduxContext.Provider>
        </ThemeProvider>,
        container
      )}
    </Html>
  )
}

Portal.propTypes = {
  children: PropTypes.node.isRequired,
  container: PropTypes.instanceOf(Element).isRequired,
}

export default Portal
