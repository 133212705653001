import { getIsTopicSupportUnreadMessages } from '@tabeeb/modules/chat/services/chat'
import { getApiUrl } from '@tabeeb/state/constantsModule'
import { ContentType, AnnotationType, AssetVideoTypes, PageType } from '../Enums'
import {
  isExcelPage,
  isPowerPointPage,
  isVideoPage,
  isWordPage,
  isWebPage,
  is360ViewPage,
  isDrawingBoardPage,
  isPdfPage,
  isImageAsset,
  isPointCloudPage,
  isAutodeskPage,
} from './pageService'

export function convertRecordingStatusObject(recordingData) {
  const recordingStatusesObj = {
    recordId: recordingData.Id,
    selectedUserId: recordingData.SelectedUser,
    serverName: recordingData.Server,
    errorMessage: recordingData.Error,
    errorCode: recordingData.ErrorCode,
    session: recordingData.Session,
    serverRecordingStatus: recordingData.State,
    startDate: recordingData.StartDate * 1000,
    stopDate: recordingData.StopDate * 10000,
    recordingType: recordingData.RecordingType,
  }

  return recordingStatusesObj
}

export function getThumbnailFromPage(page, apiUrl) {
  if (!page) return ''

  let { thumbnailUrl } = page

  if (isVideoPage(page.contentType)) {
    if (page.thumbnailUrl) {
      thumbnailUrl = page.thumbnailUrl
    } else {
      thumbnailUrl = `Content/img/video-placeholder.png?pageId=${page.id}`
    }
  }

  if (page.contentType === ContentType.File) {
    thumbnailUrl = thumbnailUrl || `Content/img/download-file-placeholder.svg?pageId=${page.id}`
  }

  if (isWordPage(page.contentType)) {
    thumbnailUrl = thumbnailUrl || `Content/img/word-document-placeholder.png?pageId=${page.id}`
  }

  if (isExcelPage(page.contentType)) {
    thumbnailUrl = thumbnailUrl || `Content/img/excel-document-placeholder.png?pageId=${page.id}`
  }

  if (isPowerPointPage(page.contentType)) {
    thumbnailUrl = thumbnailUrl || `Content/img/ppt-document-placeholder.png?pageId=${page.id}`
  }

  if (isWebPage(page.contentType)) {
    thumbnailUrl = thumbnailUrl || `Content/img/web-page-placeholder.png?pageId=${page.id}`
  }

  if (is360ViewPage(page.contentType)) {
    thumbnailUrl = thumbnailUrl || `Content/img/spatial-view-placeholder.png?pageId=${page.id}`
  }

  if (isPointCloudPage(page.contentType)) {
    thumbnailUrl = thumbnailUrl || `Content/img/3d-model-placeholder.png?pageId=${page.id}`
  }

  if (isAutodeskPage(page.contentType)) {
    thumbnailUrl = thumbnailUrl || `Content/img/autodesk-file-placeholder.png?pageId=${page.id}`
  }

  if (page.pdfPageNumber) {
    thumbnailUrl = page.thumbnailUrl || `Content/img/spinner-loader.png?pageId=${page.id}`
  }

  if (isDrawingBoardPage(page.contentType)) {
    thumbnailUrl = thumbnailUrl || `Content/img/drawing-board-placeholder.png?pageId=${page.id}`
  }

  thumbnailUrl = thumbnailUrl || `Content/img/image_placeholder.png?pageId=${page.id}`
  return thumbnailUrl.startsWith('Content/') ? `/${thumbnailUrl}` : thumbnailUrl
}

function setContentInfoForPage(page, assets, pdfURL) {
  if (pdfURL) {
    page.contentUrl = pdfURL.split('?')[0]
    return
  }

  let type = page.type === PageType.Text ? ContentType.Text : ContentType.Image

  let url
  let originalCreatedOn = null

  if (assets.length > 0) {
    for (const asset of assets) {
      if (AssetVideoTypes.includes(asset.Type)) {
        page.durationVideoTime = asset.Duration
        page.size = asset.Size
        type = ContentType.Video
        url = asset.Url
        originalCreatedOn = asset.OriginalCreatedOn
        if (url && url.indexOf('mediaservices') >= 0) {
          break
        }
      }

      if (type !== ContentType.Video && isImageAsset(asset.Type)) {
        url = asset.Url
        originalCreatedOn = asset.OriginalCreatedOn
      }
    }
    page.contentUrl = url
  } else if (isVideoPage(page.contentType)) {
    page.contentUrl = `/Content/img/video-placeholder.png?pageId=${page.id}`
  } else {
    page.contentUrl = page.thumbnailUrl
  }

  page.originalCreatedOn = originalCreatedOn
}

export function convertServicePageToPluginPage({ page, contentOwnerId, currentUserId, isLoaded = false }) {
  const apiUrl = getApiUrl()

  const pageOwnerId = page.Owner != null ? page.Owner.Id : null
  const pageConverted = {
    id: page.Id,
    documentId: page.DocumentId,
    pdfURL: page.PdfURL || 'notPdf',
    pdfPageNumber: page.PdfPageIndex || null,
    type: page.Type,
    name: page.Name,
    text: page.Content,
    ownerUserId: pageOwnerId,
    ownerUserName: page.Owner != null ? page.Owner.Name : null,
    width: page.Width,
    height: page.Height,
    dateCreated: page.CreatedOn,
    order: page.Order,
    contentId: page.ContentId,
    annotations: page.Annotations || [],
    assets: page.Assets || [],
    folderId: page.FolderId,
    tags: page.Tags,

    canDelete: pageOwnerId === currentUserId || contentOwnerId === currentUserId,
    canRename: pageOwnerId === currentUserId || contentOwnerId === currentUserId,
    timestampCreated: +new Date(page.CreatedOn),
    thumbnailCaption: page.Name,
    timeZoneOffset: page.TimeZoneOffset,
    originalCreatedOn: null,
    isEmpty: false,
    latitude: null,
    longitude: null,
    contentUrl: '',
    contentType: page.ContentType,
    thumbnailUrl: page.ThumbnailUrl,
    timestampReceived: Date.now(),
    durationVideoTime: null,
    size: null,
    isLoaded,
    copyOfId: page.CopyOfId,
  }

  pageConverted.thumbnailUrl = getThumbnailFromPage(pageConverted, apiUrl) || '/Content/img/spinner-loader.png'

  for (const asset of page.Assets) {
    if (asset.Longitude && asset.Latitude) {
      pageConverted.latitude = asset.Latitude
      pageConverted.longitude = asset.Longitude
      break
    }
  }

  setContentInfoForPage(pageConverted, page.Assets, page.PdfURL)
  if (pageConverted.canDelete && isPdfPage(pageConverted.contentType)) {
    pageConverted.canDelete = pageConverted.pdfPageNumber === 1
  }

  return pageConverted
}

function getDateFromString(string) {
  let str = string
  if (typeof str === 'string') {
    if (str.substring(str.length - 1) !== 'Z') {
      str += 'Z'
    }

    return new Date(str)
  }

  return str
}

function _getInitials(name) {
  let displayName = name
  if (!displayName) {
    return 'AU'
  }

  const regex = /^([\a-z0-9\+\-_]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
  if (regex.test(displayName)) {
    return displayName[0].toUpperCase()
  }

  displayName = displayName.replace(/[^a-zA-ZА-Яа-я- \d']+/g, ' ')

  const nameSplits = displayName.split(' ')
  let initials = ''
  nameSplits
    .filter((item) => item.length > 0)
    .slice(0, 2)
    .forEach((str) => {
      initials += str.substring(0, 1).toUpperCase()
    })
  return initials
}

export function convertInviteUser(invite) {
  return {
    displayName: invite.RecipientEmail,
    initials: _getInitials(invite.RecipientEmail),
    email: invite.RecipientEmail,
  }
}

export function convertServiceUserToPluginUser(usermodel, content, currentUserId) {
  const model = {
    id: usermodel.Id,
    displayName: usermodel.Name,
    initials: _getInitials(usermodel.Name),
    avatarUrl: usermodel.AvatarUrl,
    annotationsMuted: false,
    thisUser: currentUserId === usermodel.Id,
    deviceType: usermodel.DeviceType,
    isFindByPhone: usermodel.IsFindByPhone,
    isOnline: false,
    isDeleted: usermodel.IsDeleted,
    isAccountDeleted: usermodel.IsAccountDeleted,
    chargeLevel: usermodel.chargeLevel || '100',
    mobileZoomValue: '100',
    mobileZoomMax: 800,
    connectionQuality: 0,
    bitrate: {
      download: null,
      upload: null,
    },
    email: usermodel.Email,
    phoneNumber: usermodel.PhoneNumber,
    role: usermodel.Role || usermodel.role || 0,
    chatNotificationsMuted: usermodel.ChatNotificationsMuted,
  }

  if (content && content.ownerId && currentUserId) {
    model.isOwner = content.ownerId === usermodel.Id
    model.canBeRemoved = !model.thisUser && content.ownerId === currentUserId
  }

  return model
}

function _convertServiceTextAnnotationToPluginAnnotation(annotation) {
  const annotationText = (annotation.Text || '').trim()
  // let annotationText = annotationText.replace(/<\/?[^>]+(>|$)/g, '')
  return {
    textInfo: {
      color: annotation.Color,
      width: annotation.Width,
      fontSize: annotation.FontSize,
      text: annotationText,
      point: annotation.TopLeft ? { x: annotation.TopLeft.X, y: annotation.TopLeft.Y } : null,
    },
    anchor: annotation.Anchor ? { x: annotation.Anchor.X, y: annotation.Anchor.Y } : null,
  }
}

function _convertServiceAIAnnotationToPluginAnnotation(annotation) {
  const annotationText = (annotation.Text || '').trim()
  return {
    text: annotationText,
  }
}

function _convertServiceAudioAnnotationToPluginAudio(annotation) {
  return {
    url: annotation.Url,
  }
}

function _convertServiceHotspotAnnotationToPluginAnnotation(annotation) {
  return {
    color: annotation.Color,
    anchor: { x: annotation.Anchor.X, y: annotation.Anchor.Y },
    contentCode: annotation.Text,
    stroke: {
      width: annotation.Width,
    },
  }
}

function _convertServiceAIHotspotAnnotationToPluginAnnotation(annotation) {
  return {
    color: annotation.Color,
    anchor: { x: annotation.Anchor.X, y: annotation.Anchor.Y },
    contentCode: annotation.Text,
    stroke: {
      width: annotation.Width,
    },
  }
}

function _convertServiceLinkedPageAnnotationToPluginAnnotation(annotation, page, apiUrl) {
  return {
    targetTimestamp: annotation.TargetTimestamp,
    color: annotation.Color,
    anchor: { x: annotation.Anchor.X, y: annotation.Anchor.Y },
    linkedPageId: annotation.LinkedPageId,
    imageUrl: getThumbnailFromPage(page, apiUrl),
  }
}

function _convertServiceStrokeToPluginStroke(stroke) {
  const convertedStroke = {
    stroke: {
      color: stroke.Color,
      width: stroke.Width,
      endpoints: stroke.Coordinates.map((endpoints) => {
        return {
          start: { x: endpoints.Start.X, y: endpoints.Start.Y },
          end: { x: endpoints.End.X, y: endpoints.End.Y },
        }
      }),
    },
  }

  return convertedStroke
}

function convertServerChatMessageAsset(chatMessageAsset) {
  if (!chatMessageAsset) {
    return null
  }

  return {
    id: chatMessageAsset.Id,
    type: chatMessageAsset.Type,
    url: chatMessageAsset.Url,
  }
}

export function convertServiceChatMessageToPluginChatMessage(chatModel, { currentUserId, lastReadedMessageId = null }) {
  const self = chatModel.UserId === currentUserId
  const supportsUnreadMessages = getIsTopicSupportUnreadMessages(chatModel.TopicType)

  return {
    id: chatModel.Id,
    userId: chatModel.UserId,
    receiverId: chatModel.ReceiverId,
    contentId: chatModel.ContentId,
    topicId: chatModel.TopicId,
    topicType: chatModel.TopicType,
    message: chatModel.Message,
    translations: chatModel.Translations,
    timeStamp: chatModel.CreatedOn,
    asset: convertServerChatMessageAsset(chatModel.Asset),
    self,
    unread: supportsUnreadMessages && !self && chatModel.Id > lastReadedMessageId,
  }
}

export function convertServerContent(content) {
  const dateCreated = getDateFromString(content.CreatedOn)
  const result = {
    activatedNumberTimes: content.ActivatedNumberTimes,
    contentStatus: content.ContentStatus,
    dateCreated,
    timestampCreated: +dateCreated,
    description: content.Description,
    grade: content.Grade,
    id: content.Id,
    isActive: content.IsActive,
    metadata: content.Metadata,
    presenterId: content.PresenterId,
    primaryUserId: content.PrimaryUserId,
    programId: content.ProgramId,
    role: content.Role,
    sessionMode: content.SessionMode,
    tenantId: content.TenantId,
    userId: content.UserId,
    descriptionCreatedUsingConvention: content.DescriptionCreatedUsingConvention,
  }

  return result
}

export function convertPoint(point) {
  const pointConverted = {
    x: point.X,
    y: point.Y,
  }

  if (point.Z) {
    pointConverted.z = point.Z
  }

  return pointConverted
}

export function convertMeasuringPoint(point) {
  const pointConverted = {
    id: point.Id,
    x: point.X,
    y: point.Y,
    assetUrl: point.AssetUrl,
    foundPointInstead: point.FoundPointInstead ? convertPoint(point.FoundPointInstead) : null,
    pointCloudPoint: point.PointCloudPoint ? convertPoint(point.PointCloudPoint) : null,
  }

  return pointConverted
}

export function convertAnnotationInfo(annotationInfo) {
  const annotationInfoConverted = {
    text: annotationInfo.Text,
    uniqueAIObjectId: annotationInfo.UniqueAIObjectId,
    hasProperties: annotationInfo.HasProperties,
    userName: annotationInfo.UserName,
    userInitials: _getInitials(annotationInfo.UserName),
    userAvatarUrl: annotationInfo.UserAvatarUrl,
  }

  return annotationInfoConverted
}

export function convertServiceAnnotationToPluginAnnotation(annotation, page, isOwner, currentUserId, apiUrl) {
  const dateCreated = getDateFromString(annotation.CreatedOn)
  const result = {
    id: annotation.Id,
    parentId: annotation.ParentAnnotationId,
    type: annotation.Type,
    layerId: annotation.UserId,
    userId: annotation.UserId,
    pageId: page.Id,
    timestamp: annotation.Timestamp,
    dateCreated,
    timestampCreated: +dateCreated,
    canDelete: currentUserId === annotation.UserId || isOwner,

    duration: annotation.Duration,

    user: null,
    pageName: null,
    displayTime: null,

    uniqueAIObjectId: annotation.UniqueAIObjectId,
    aiObjectId: annotation.AIObjectId,
  }

  let annotationResult = {}
  switch (annotation.Type) {
    case AnnotationType.Text:
      annotationResult = _convertServiceTextAnnotationToPluginAnnotation(annotation)
      break
    case AnnotationType.AI:
      annotationResult = _convertServiceAIAnnotationToPluginAnnotation(annotation)
      break
    case AnnotationType.AIHotspot:
      annotationResult = _convertServiceAIHotspotAnnotationToPluginAnnotation(annotation)
      break
    case AnnotationType.Audio:
      annotationResult = _convertServiceAudioAnnotationToPluginAudio(annotation)
      break
    case AnnotationType.Hotspot:
      annotationResult = _convertServiceHotspotAnnotationToPluginAnnotation(annotation)
      break
    case AnnotationType.LinkedPage:
      annotationResult = _convertServiceLinkedPageAnnotationToPluginAnnotation(annotation, page, apiUrl)
      break
    default:
      annotationResult = _convertServiceStrokeToPluginStroke(annotation)
      break
  }

  return { ...result, ...annotationResult }
}
