/* eslint-disable camelcase */
import { ShaderChunk } from 'three'

import alphahash_pars_fragment from './alphahash_pars_fragment'
import alphahash_fragment from './alphahash_fragment'

export default () => {
  ShaderChunk.alphahash_pars_fragment = alphahash_pars_fragment
  ShaderChunk.alphahash_fragment = alphahash_fragment
}
