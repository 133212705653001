import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as aiActions from '../actions'
import { annotationsActions } from '../../annotations'
import { AnnotationType } from '../../../Enums'

function* onAddUniqueAiObjectSuccess(action) {
  const { data } = action.response
  const { parentAnnotationId, annotationId } = action.payload

  const selectedAIObject = yield select((state) => state.artificialIntelligence.selectedAIObject)
  let aiObject = null

  if (data.AIObjectId === selectedAIObject.Id) {
    aiObject = selectedAIObject
  }

  const pageId = yield select((state) => state.gallery.galleryState.selectedGalleryItemId)
  const contentId = yield select((state) => state.contentState.contentId)

  if (!aiObject) {
    const aiObjects = yield select((state) => state.artificialIntelligence.aiObjects)
    aiObject = aiObjects.find((object) => object.Id === data.Id)
  }

  const annotationText = `CV Class: ${aiObject.Name} <br> AI Object: ${data.Name}`

  const annotation = {
    Id: annotationId || undefined,
    IsAIDetectCorrect: null,
    PageId: pageId,
    ParentAnnotationId: parentAnnotationId,
    Text: annotationText,
    Timestamp: -1,
    Type: AnnotationType.AI,
    UniqueAIObjectId: data.Id,
    UserFeedback: null,
    AIObjectId: data.AIObjectId,
    ContentId: contentId,
  }

  annotationId
    ? yield put(annotationsActions.updateAiAnnotationRequest(annotation))
    : yield put(annotationsActions.addAnnotationRequest(annotation))
}

function* onAddUniqueAiObjectFailed(action) {
  console.error('------------- onAddUniqueAiObjectFailed:', action)
}

function* getUniqueAIObjects() {
  yield all([
    takeLatest(aiActions.addUniqueAiObjectSuccess, onAddUniqueAiObjectSuccess),
    takeLatest(aiActions.addUniqueAiObjectFailed, onAddUniqueAiObjectFailed),
  ])
}

export default getUniqueAIObjects
