import { Skeleton, Grid, Fade } from '@mui/material'
import { times } from 'lodash'

const CertificatesProfileListSkeleton = () => (
  <Fade in>
    <div>
      <Grid item sx={{ display: 'flex', justifyContent: 'space-between', padding: 1 }}>
        <Skeleton width='10%' />
        <Skeleton width='15%' />
      </Grid>
      <Grid item container direction='row' spacing={2}>
        {times(9, (index) => (
          <Grid item xs={4} key={index}>
            <Skeleton sx={{ borderRadius: 1 }} variant='rectangular' height={150} />
          </Grid>
        ))}
      </Grid>
    </div>
  </Fade>
)

export default CertificatesProfileListSkeleton
