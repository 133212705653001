import { memo } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { GroupSharp, Lock } from '@material-ui/icons'
import { Chip, Typography } from '@material-ui/core'

import { usersSelectors } from '@tabeeb/modules/../users'

import { useChatContext } from '../../contexts'

import useStyles from './styles'

const Receiver = () => {
  const classes = useStyles()

  const { userId: receiverId } = useChatContext()
  const receiver = useSelector((state) => usersSelectors.getUserById(state, { Id: receiverId })?.displayName)

  const isPrivateMessage = Boolean(receiver)

  return (
    <div className={classes.root}>
      <Typography variant='body2' component='span' color='textSecondary'>
        To:
      </Typography>
      <Chip
        size='small'
        color='primary'
        className={classNames(classes.chip, { [classes.private]: isPrivateMessage })}
        icon={isPrivateMessage ? <Lock /> : <GroupSharp />}
        label={isPrivateMessage ? `${receiver}` : 'Everyone'}
        title={isPrivateMessage ? `Private message for ${receiver}` : 'Public message for everyone'}
      />
    </div>
  )
}

export default memo(Receiver)
