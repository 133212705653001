import { takeLatest, all, put } from 'redux-saga/effects'

import * as aiActions from '../actions'
import * as notificationActions from '../../notification/actions'

function* onGetUniqueAiObjectsFailed(action) {
  console.error('------------- onGetUniqueAIObjectsFailed:', action)
}

function* onGetUniqueAiObjectForEditSuccess(action) {
  const { data } = action.response

  if (!data.Properties) {
    yield put(notificationActions.onAddWarningNotification({ message: 'AI Object has no properties to be edited' }))
    return
  }

  yield put(aiActions.setUniqueAiObjectForEdit(data))
}

function* onGetUniqueAiObjectForEditFailed(action) {
  console.error('------------- onGetUniqueAiObjectForEditFailed:', action)
}

function* getUniqueAIObjects() {
  yield all([
    takeLatest(aiActions.getUniqueAiObjectsFailed, onGetUniqueAiObjectsFailed),
    takeLatest(aiActions.getUniqueAiObjectForEditSuccess, onGetUniqueAiObjectForEditSuccess),
    takeLatest(aiActions.getUniqueAiObjectForEditFailed, onGetUniqueAiObjectForEditFailed),
  ])
}

export default getUniqueAIObjects
