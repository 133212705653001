import { memo } from 'react'
import PropTypes from 'prop-types'

import { Stats } from '@react-three/drei'

import './styles.less'

const Perfomance = ({ container }) => {
  return <Stats className='three-js-perfomance-panel' parent={{ current: container }} />
}

Perfomance.propTypes = {
  container: PropTypes.instanceOf(Element).isRequired,
}

export default memo(Perfomance)
