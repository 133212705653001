import { memo } from 'react'
import PropTypes from 'prop-types'

import { Box, MenuItem, Pagination, Select, Typography } from '@mui/material'

/** @typedef {{
    current: Number,
    pages: Number,
    pageSize: Number,
    pageSizeOptions: Array<Number>,
    onPageNumberChange: (pageNumber: Number) => void,
    onPageSizeChange: (pageSize: Number) => void,
 }} TabeebPaginationProps */

/**
 * @param {TabeebPaginationProps} props
 */
const TabeebPagination = ({
  current,
  pages,
  pageSize,
  pageSizeOptions,
  pageSizeLabel,
  onPageNumberChange,
  onPageSizeChange,
}) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' p={0.5}>
      <Pagination
        shape='rounded'
        count={pages}
        page={current}
        onChange={(_, pageNumber) => {
          onPageNumberChange(pageNumber)
        }}
        showFirstButton
        showLastButton
      />
      <Box display='flex' alignItems='center'>
        <Typography variant='body2'>{pageSizeLabel}:&nbsp;</Typography>
        <Select
          size='small'
          SelectDisplayProps={{
            style: {
              paddingBottom: 0,
            },
          }}
          value={pageSize}
          disableUnderline
          onChange={({ target: { value: newPageSize } }) => {
            onPageSizeChange(newPageSize)
          }}
        >
          {pageSizeOptions.map((pageSizeOption) => (
            <MenuItem key={pageSizeOption} value={pageSizeOption}>
              {pageSizeOption}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  )
}

TabeebPagination.defaultProps = {
  pageSizeOptions: [10, 25, 50, 100],
  pageSizeLabel: 'Rows per page',
}

TabeebPagination.propTypes = {
  pages: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number.isRequired),
  pageSizeLabel: PropTypes.string,
  onPageNumberChange: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
}

export default memo(TabeebPagination)
