import { createApiActions } from '@tabeeb/modules/shared/utils/actions'
import { createAction } from 'redux-actions'

export const getUserByIdRequest = createAction('GET_USER_BY_ID_REQUEST')
export const getUserByIdSuccess = createAction('GET_USER_BY_ID_SUCCESS')
export const getUserByIdFailed = createAction('GET_USER_BY_ID_FAILED')

export const getUserByGuid = createApiActions('GET_USER_BY_GUID')

export const setUsers = createAction('SET_USERS')
export const addUser = createAction('ADD_USER')

export const onUserTracksStateChanged = createAction('ON_USER_TRACKS_STATE_CHANGED')

export const addOnlineUser = createAction('ADD_ONLINE_USER')
export const removeOnlineUser = createAction('REMOVE_ONLINE_USER')

export const setVideoLoadForUser = createAction('SET_VIDEO_LOAD_FOR_USER')
export const resetVideoLoadForUser = createAction('RESET_VIDEO_LOAD_FOR_USER')

export const removeUser = createAction('REMOVE_USER')

export const kickUser = createAction('KICK_USER')
export const kickUserRequest = createAction('KICK_USER_REQUEST')
export const kickUserSuccess = createAction('KICK_USER_SUCCESS')

export const updateChargeLevel = createAction('UPDATE_CHARGE_LEVEL')

export const updateBitrate = createAction('UPDATE_BITRATE')
export const updateQuality = createAction('UPDATE_QUALITY')
export const localStatsReceived = createAction('LOCAL_STATS_RECEIVED')
export const remoteStatsReceived = createAction('REMOTE_STATS_RECEIVED')

export const onMobileZoomIn = createAction('MOBILE_ZOOM_IN')
export const onMobileZoomOut = createAction('MOBILE_ZOOM_OUT')
export const onUpdateMobileZoomValue = createAction('UPDATE_MOBILE_ZOOM_VALUE')

export const onWebZoomIn = createAction('WEB_ZOOM_IN')
export const onWebZoomOut = createAction('WEB_ZOOM_OUT')

export const changeUserDeviceType = createAction('CHANGE_USER_DEVICE_TYPE')

export const updateUser = createAction('UPDATE_USER')

export const logout = createAction('LOGOUT')

export const switchContentUserRole = createAction('SWITCH_CONTENT_USER_ROLE')
export const switchContentUserRoleRequest = createAction('SWITCH_CONTENT_USER_ROLE_REQUEST')
export const switchContentUserRoleSuccess = createAction('SWITCH_CONTENT_USER_ROLE_SUCCESS')
export const switchContentUserRoleFailed = createAction('SWITCH_CONTENT_USER_ROLE_FAILED')

export const toggleChatNotificationsMuteRequest = createAction('TOGGLE_CHAT_NOTIFICATIONS_MUTE_REQUEST')
export const toggleChatNotificationsMuteSuccess = createAction('TOGGLE_CHAT_NOTIFICATIONS_MUTE_SUCCESS')
export const toggleChatNotificationsMuteFailed = createAction('TOGGLE_CHAT_NOTIFICATIONS_MUTE_FAILED')

export const updateContentUserRole = createAction('UPDATE_CONTENT_USER_ROLE')

export const searchUsers = createApiActions('SEARCH_USER')
