import { put, takeLatest, select, all } from 'redux-saga/effects'

import * as aiActions from '../actions'

function* onGetAiObjectsSuccess(action) {
  const { data } = action.response

  const selectedObject = yield select((state) => state.artificialIntelligence.selectedAIObject)
  const contentId = yield select((state) => state.contentState.contentId)

  if (data.length <= 0) {
    return
  }

  yield put(aiActions.setAiObjects(data))

  if (!selectedObject.Id) {
    yield put(aiActions.onSelectDrawingAIObject(data[0]))

    if (contentId) {
      yield put(aiActions.getUniqueAiObjectsRequest({ aiObjectId: data[0].Id, contentId }))
    }
  }
}

function* getAiObjects() {
  yield all([
    takeLatest(aiActions.getAiObjectsSuccess, onGetAiObjectsSuccess),
    takeLatest(aiActions.getAiObjectsWithStatsSuccess, onGetAiObjectsSuccess),
  ])
}

export default getAiObjects
