import { memo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Vector2 } from 'three'

import { useThree } from '@react-three/fiber'
import { useCursor } from '@react-three/drei'

import { getIntersectionPoint } from '../../../utils/raycasting'
import { Defaults } from '../../../constants'

const Raycaster = ({ enabled, onAddPoint, onUpdatePoint }) => {
  const { scene, raycaster, gl } = useThree()

  useCursor(enabled, 'crosshair')

  const onClick = useCallback(() => {
    if (!enabled) {
      return
    }

    let point = getIntersectionPoint(raycaster, scene)
    if (point) {
      point = point.clone().applyEuler(Defaults.ROTATION_OFFSET)

      onAddPoint(point)
    }
  }, [enabled, onAddPoint, raycaster, scene])

  const onMouseMove = useCallback(() => {
    let point = getIntersectionPoint(raycaster, scene)
    if (point) {
      point = point.clone().applyEuler(Defaults.ROTATION_OFFSET)

      onUpdatePoint(point)
    }
  }, [onUpdatePoint, raycaster, scene])

  useEffect(() => {
    if (!enabled) {
      return () => {}
    }

    const clickCoords = new Vector2()

    const onMouseDown = (e) => {
      clickCoords.x = e.clientX
      clickCoords.y = e.clientY
    }

    const onMouseUp = ({ clientX, clientY }) => {
      if (clickCoords.x === clientX && clickCoords.y === clientY) {
        onClick()
      }
    }

    gl.domElement.addEventListener('mousedown', onMouseDown)
    gl.domElement.addEventListener('mouseup', onMouseUp)

    return () => {
      gl.domElement.removeEventListener('mousedown', onMouseDown)
      gl.domElement.removeEventListener('mouseup', onMouseUp)
    }
  }, [enabled, gl.domElement, onClick])

  useEffect(() => {
    if (!enabled) {
      return () => {}
    }

    gl.domElement.addEventListener('mousemove', onMouseMove)

    return () => {
      gl.domElement.removeEventListener('mousemove', onMouseMove)
    }
  }, [enabled, gl.domElement, onMouseMove])

  return null
}

Raycaster.propTypes = {
  enabled: PropTypes.bool.isRequired,
  onAddPoint: PropTypes.func.isRequired,
  onUpdatePoint: PropTypes.func.isRequired,
}

export default memo(Raycaster)
