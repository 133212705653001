import { createSelector } from 'reselect'

export const getIsRecordingActive = (state) => state.recording.isRecordingActive
export const getSelectedUserId = (state) => state.recording.selectedUserId
export const getElapsedTime = (state) => state.recording.elapsedTime || 0
export const getRecordingStatus = (state) => state.recording.localRecordingStatus
export const getIsSwitchRecordedUserConfirmationDialogOpen = (state) =>
  state.recording.isSwitchRecordedUserConfirmationDialogOpen

export const getRecordingState = (state) => {
  const {
    recording: {
      recordId,
      selectedUserId,
      serverName,
      session,
      errorMessage,
      errorCode,
      serverRecordingStatus,
      startDate,
      stopDate,
    },
  } = state

  return {
    recordId,
    selectedUserId,
    serverName,
    session,
    errorMessage,
    errorCode,
    serverRecordingStatus,
    startDate,
    stopDate,
  }
}

export const makeGetIsUserRecordingActive = () =>
  createSelector(
    [getIsRecordingActive, getSelectedUserId, (_, { userId }) => userId],
    (active, recordingUserId, userId) => {
      return active && recordingUserId === userId
    }
  )
