import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'

import { getCurrentUserId } from '@tabeeb/modules/account/selectors'

import Certificate from '../Certificate'

const CertificatesList = ({
  certificates,
  setCertificateToEditing,
  setCertificateToView,
  direction,
  userId,
  readOnly,
}) => {
  const currentUserId = useSelector(getCurrentUserId)
  const isMyCertificates = currentUserId === userId
  return (
    <Grid container direction={direction} spacing={2}>
      {certificates.map((certificate) => (
        <Grid item xs={direction === 'row' ? 4 : true} key={certificate.Id}>
          <Certificate
            certificate={certificate}
            setCertificateToEditing={setCertificateToEditing}
            setCertificateToView={setCertificateToView}
            isMyCertificates={isMyCertificates}
            readOnly={readOnly}
          />
        </Grid>
      ))}
    </Grid>
  )
}

CertificatesList.defaultProps = {
  readOnly: false,
}

CertificatesList.propTypes = {
  certificates: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      IsDeleted: PropTypes.bool.isRequired,
      CreatedOn: PropTypes.string.isRequired,
      UserId: PropTypes.number.isRequired,
      VerificationStatusId: PropTypes.number.isRequired,
      UpdateOn: PropTypes.string,
      Title: PropTypes.string.isRequired,
      Authority: PropTypes.string.isRequired,
      FirstName: PropTypes.string.isRequired,
      LastName: PropTypes.string.isRequired,
      IssueDate: PropTypes.string,
      ExpirationDate: PropTypes.string,
      Comment: PropTypes.string,
      PictureUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  setCertificateToEditing: PropTypes.func,
  setCertificateToView: PropTypes.func,
  direction: PropTypes.oneOf(['row', 'column']),
  userId: PropTypes.number.isRequired,
  readOnly: PropTypes.bool,
}

export default CertificatesList
