import { memo } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'

import Routes from '@tabeeb/routes'

import AnnouncementsManagementPage from './AnnouncementsManagementPage'
import CreateSystemAnnouncementPage from './CreateSystemAnnouncementPage'
import CreateTenantAnnouncementPage from './CreateTenantAnnouncementPage'
import EditAnnouncementPage from './EditAnnouncementPage'

import { getAvailableAnnouncementScopes } from '../selectors'

const Announcements = () => {
  const display = useSelector((state) => getAvailableAnnouncementScopes(state).length > 0)
  if (!display) {
    return <Redirect to={Routes.home} />
  }

  return (
    <Switch>
      <Route
        key={Routes.announcementsManagement}
        path={Routes.announcementsManagement}
        render={() => <AnnouncementsManagementPage />}
      />
      <Route
        key={Routes.announcementCreateSystem}
        path={Routes.announcementCreateSystem}
        render={() => <CreateSystemAnnouncementPage />}
      />
      <Route
        key={Routes.announcementCreateTenant}
        path={Routes.announcementCreateTenant}
        render={() => <CreateTenantAnnouncementPage />}
      />
      <Route
        key={Routes.announcementEdit}
        path={Routes.announcementEdit}
        render={({
          match: {
            params: { id },
          },
        }) => <EditAnnouncementPage id={parseInt(id, 10)} />}
      />
    </Switch>
  )
}

export default memo(Announcements)
