const announcementRequests = {
  createSystemAnnouncementRequest: (data) => ({
    url: `api/announcements/system`,
    method: 'post',
    data,
  }),
  createTenantAnnouncementRequest: (data) => ({
    url: `api/announcements/tenant`,
    method: 'post',
    data,
  }),
  deleteAnnouncementRequest: ({ id }) => ({
    url: `api/announcements/${id}`,
    method: 'delete',
  }),
  updateAnnouncementRequest: (data) => ({
    url: `api/announcements/${data.Id}`,
    method: 'put',
    data,
  }),
  updateAnnouncementStatusRequest: ({ id, status }) => ({
    url: `api/announcements/${id}/status`,
    method: 'put',
    data: {
      status,
    },
  }),
  updateAnnouncementSeverityRequest: ({ id, severity }) => ({
    url: `api/announcements/${id}/severity`,
    method: 'put',
    data: {
      severity,
    },
  }),
  getAnnouncementRequest: ({ id }) => ({
    url: `api/announcements/${id}`,
    method: 'get',
  }),
  getAnnouncementsRequest: ({ skip, take } = { skip: 0, take: 50 }) => ({
    url: `api/announcements`,
    method: 'get',
    params: {
      skip,
      take,
    },
  }),
  getUnreadAnnouncementsRequest: () => ({
    url: `api/announcements/unread`,
    method: 'get',
  }),
  markAnnouncementsAsReadRequest: (data) => ({
    url: `api/announcements/markAsRead`,
    method: 'post',
    data,
  }),
}

export default announcementRequests
