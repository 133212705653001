import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { MainScreenContentType } from '@tabeeb/enums'
import { playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'

const ToolbarGate = ({ contentType, children }) => {
  const mainScreenContentType = useSelector(playerToolbarSelectors.getMainScreenContentType)
  if (mainScreenContentType !== contentType) {
    return null
  }

  return children
}

ToolbarGate.propTypes = {
  contentType: PropTypes.oneOf(Object.values(MainScreenContentType)),
  children: PropTypes.any.isRequired,
}

export default memo(ToolbarGate)
