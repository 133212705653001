import { handleActions } from 'redux-actions'

import { annotationsActions } from '..'

const defaultState = {
  isOpen: false,
  position: {
    x: 0,
    y: 0,
  },
}

export default handleActions(
  {
    [annotationsActions.openAnnotationMenu](state, action) {
      const { x, y } = action.payload

      return {
        isOpen: true,
        position: {
          x,
          y,
        },
      }
    },
    [annotationsActions.closeAnnotationMenu](state, action) {
      return defaultState
    },
    [annotationsActions.resetAnnotationsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
