import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const getMyCertificates = createApiActions('GET_MY_CERTIFICATES')
export const getCertificatesByUserId = createApiActions('GET_CERTIFICATES_BY_USER_ID')
export const getCertificateById = createApiActions('GET_CERTIFICATE_BY_ID')

export const createCertificate = createApiActions('CREATE_CERTIFICATE')

export const updateMyCertificate = createApiActions('UPDATE_MY_CERTIFICATE')
export const reviewCertificate = createApiActions('REVIEW_CERTIFICATE')

export const deleteCertificate = createApiActions('DELETE_CERTIFICATE')

export const resetCertificatesListState = createAction('RESET_CERTIFICATES_LIST_STATE')
export const setCertificatesPageNumber = createAction('SET_CERTIFICATES_PAGE_NUMBER')
export const setCertificatesFiltersStatusTypes = createAction('SET_CERTIFICATES_FILTERS_STATUS_TYPES')
export const setCertificatesFiltersSearchText = createAction('SET_CERTIFICATES_FILTERS_SEARCH_TEXT')
export const setCertificatesSorting = createAction('SET_CERTIFICATES_SORTING')

export const getCertificateToReviewOwner = createAction('GET_CERTIFICATE_TO_REVIEW_OWNER')
export const setCertificateToReviewOwner = createAction('SET_CERTIFICATE_TO_REVIEW_OWNER')
