import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'
import { callAPIPromise as callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import { getUnreadAnnouncements, markAnnouncementsAsRead } from '../actions'

const EMPTY_LIST = []

export default ({ onError } = {}) => {
  const dispatch = useDispatch()
  const [isSubmitInProgress, setIsSubmitInProgress] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const {
    loading,
    loaded,
    response: announcements,
  } = useApiRequest({
    defaultResponse: EMPTY_LIST,
    request: getUnreadAnnouncements.request,
    onError,
  })

  const onMarkAsRead = useCallback(async () => {
    try {
      setIsSubmitInProgress(true)
      await callApiAsync(markAnnouncementsAsRead.request({ AnnouncementIds: announcements.map((a) => a.Id) }))
      setIsSubmitted(true)
    } catch {
      dispatch(onAddErrorNotification({ message: 'Failed to mark announcements as read' }))
    } finally {
      setIsSubmitInProgress(false)
    }
  }, [announcements, dispatch])

  return {
    isSubmitInProgress,
    hasUnviewedAnnouncements: loaded && announcements.length > 0 && !isSubmitted,
    loading,
    announcements,
    onMarkAsRead,
  }
}
