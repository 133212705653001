import PropTypes from 'prop-types'

import ReadOnlyCertificateView from '../ReadOnlyCertificateView'
import ReviewCertificateControls from '../ReviewCertificateControls'

const ReviewCertificate = ({ certificate, onClose }) => (
  <>
    <ReadOnlyCertificateView certificate={certificate} />
    <ReviewCertificateControls certificate={certificate} onClose={onClose} />
  </>
)

ReviewCertificate.propTypes = {
  certificate: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IsDeleted: PropTypes.bool.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    UserId: PropTypes.number.isRequired,
    VerificationStatusId: PropTypes.number.isRequired,
    Title: PropTypes.string.isRequired,
    Authority: PropTypes.string,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    IssueDate: PropTypes.string,
    ExpirationDate: PropTypes.string,
    Comment: PropTypes.string,
    PictureUrl: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func,
}

export default ReviewCertificate
