import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const onLogin = createAction('ON_APP_LOGIN')
export const onLogout = createAction('ON_APP_LOGOUT')

export const getMeRequest = createAction('GET_ME_REQUEST')
export const getMeSuccess = createAction('GET_ME_SUCCESS')
export const getMeFailed = createAction('GET_ME_FAILED')

export const updateAccountRequest = createAction('UPDATE_ACCOUNT_REQUEST')
export const updateAccountSuccess = createAction('UPDATE_ACCOUNT_SUCCESS')
export const updateAccountFailed = createAction('UPDATE_ACCOUNT_FAILED')

export const setAvatarRequest = createAction('SET_AVATAR_REQUEST')
export const setAvatarSuccess = createAction('SET_AVATAR_SUCCESS')
export const setAvatarFailed = createAction('SET_AVATAR_FAILED')

export const removeAvatarRequest = createAction('REMOVE_AVATAR_REQUEST')
export const removeAvatarSuccess = createAction('REMOVE_AVATAR_SUCCESS')
export const removeAvatarFailed = createAction('REMOVE_AVATAR_FAILED')

export const deleteAccountRequest = createAction('DELETE_ACCOUNT_REQUEST')
export const deleteAccountSuccess = createAction('DELETE_ACCOUNT_SUCCESS')
export const deleteAccountFailed = createAction('DELETE_ACCOUNT_FAILED')

export const loginViaInvite = createApiActions('LOGIN_VIA_INVITE')

export const createProfile = createApiActions('CREATE_PROFILE')
export const getIdentityMe = createApiActions('GET_IDENTITY_ME')
export const tryActivateProfile = createApiActions('TRY_ACTIVATE_PROFILE')

export const updateUserSignature = createApiActions('UPDATE_USER_SIGNATURE')

export const tenantAuthFlowCompleted = createAction('TENANT_AUTH_FLOW_COMPLETED')
export const setTenantAccountCreated = createAction('SET_TENANT_ACCOUNT_CREATED')
export const setIsTenantAccountUnexpectedError = createAction('SET_TENANT_ACCOUNT_UNEXPECTED_ERROR')

export const tenantAuthenticate = createAction('TENANT_AUTHENTICATE')

export const resetTenantAuthenticate = createAction('RESET_TENANT_AUTHENTICATE')
export const tenantAuthenticateRetry = createAction('TENANT_AUTHENTICATE_RETRY')

export const lockTenantAccount = createAction('LOCK_TENANT_ACCOUNT')
export const onTenantAccountError = createAction('ON_TENANT_ACCOUNT_ERROR')
