import { put, takeEvery, select, all } from 'redux-saga/effects'

import selector from '@tabeeb/shared/utils/selector'

import { AnnotationType } from '@tabeeb/enums'

import { accountSelectors } from '@tabeeb/modules/account'
import { aiActions } from '@tabeeb/modules/artificialIntelligence'
import { playerActions } from '@tabeeb/modules/player'
import { contentStateSelectors } from '@tabeeb/shared/content'
import { galleryActions } from '@tabeeb/modules/gallery'

import { activityActions as activitiesActions } from '@tabeeb/modules/activities'

import { convertServiceAnnotationToPluginAnnotation } from '@tabeeb/services/dataConverter'

import { annotationsActions } from '..'

function* addAnnotationSuccess(action) {
  const annotation = action.response.data
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const ownerId = yield select(contentStateSelectors.getOwnerId)
  const apiHostName = yield select(selector.getApiUrl)
  const contentId = yield select(contentStateSelectors.getContentId)
  const contentForms = yield select((state) => state.forms.contentForms)
  const appConfigState = yield select((state) => state.appConfigState)

  const hasCounters = contentForms.some((cf) => cf.HasCounters || (cf.Counters && cf.Counters.length > 0))

  const isOwner = ownerId === annotation.UserId
  const convertedAnnotation = convertServiceAnnotationToPluginAnnotation(
    annotation,
    { Id: annotation.PageId },
    isOwner,
    currentUserId,
    apiHostName
  )

  if (action.payload.Type === AnnotationType.AI) {
    if (hasCounters) {
      yield put(aiActions.getAiCounterInfoRequest({ contentId }))
      yield put(aiActions.getAiObjectsCountRequest({ contentId }))

      if (appConfigState.isConditionsEnabled) {
        yield put(aiActions.checkConditionsRequest({ contentId }))
      }
    }
  }

  yield put(activitiesActions.addActivity(convertedAnnotation))
  yield put(galleryActions.addAnnotation(annotation))
  yield put(galleryActions.updateGalleryItemThumbnail({ pageId: annotation.PageId, thumbnailUrl: null }))

  if (action.payload.isHotspot) {
    if (annotation.Type === AnnotationType.Hotspot) {
      yield put(
        annotationsActions.openHotspotDialog({
          annotationId: annotation.Id,
          resumeVideoAfterClose: action.payload.isVideoPlaying,
        })
      )
      return
    }

    const model = {
      isHotspot: true,
      isVideoPlaying: action.payload.isVideoPlaying,
      Anchor: { X: 0, Y: 0 },
      Color: annotation.Color,
      PageId: annotation.PageId,
      ParentAnnotationId: annotation.Id,
      Timestamp: -1,
      Type: AnnotationType.Hotspot,
    }

    yield put(annotationsActions.addAnnotationRequest(model))
  }

  if (action.payload.isAiHotspot) {
    if (annotation.Type === AnnotationType.AIHotspot) {
      yield put(
        annotationsActions.openHotspotDialog({
          annotationId: annotation.Id,
          resumeVideoAfterClose: action.payload.isVideoPlaying,
        })
      )
      return
    }

    const model = {
      isAiHotspot: true,
      isVideoPlaying: action.payload.isVideoPlaying,
      Anchor: { X: 0, Y: 0 },
      Color: annotation.Color,
      PageId: annotation.PageId,
      ParentAnnotationId: annotation.Id,
      Timestamp: -1,
      Type: AnnotationType.AIHotspot,
    }

    yield put(annotationsActions.addAnnotationRequest(model))
  }

  if (!action.payload.isHotspot && !action.payload.isAiHotspot && action.payload.isVideoPlaying) {
    yield put(playerActions.onPlayerPlay())
  }
}

function* addAnnotationFailed(action) {
  if (action.payload.isVideoPlaying) {
    yield put(playerActions.onPlayerPlay())
  }
}

function* addAudioAnnotationSuccess(action) {
  const { Size, Url } = action.response.data

  const {
    data: { Duration },
  } = action.payload

  const model = {
    PageId: action.payload.pageId,
    ParentAnnotationId: action.payload.parentAnnotationId,
    Size,
    Duration,
    Timestamp: -1,
    Type: AnnotationType.Audio,
    Url,
  }

  yield put(annotationsActions.addAnnotationRequest(model))
}

function* addAnnotationSaga() {
  yield all([
    takeEvery(annotationsActions.addAnnotationSuccess, addAnnotationSuccess),
    takeEvery(annotationsActions.addAnnotationFailed, addAnnotationFailed),
    takeEvery(annotationsActions.addAudioAnnotationSuccess, addAudioAnnotationSuccess),
  ])
}

export default addAnnotationSaga
