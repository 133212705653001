import { createSelector } from 'reselect'
import { accountSelectors } from '@tabeeb/modules/account'
import * as gallerySelectors from '../../gallery/selectors'

export const getDeletedPages = (state) => state.contentTrashBin.deletedPages
export const getDeletedFolders = (state) => state.contentTrashBin.deletedFolders
export const getDialogItem = (state) => state.contentTrashBin.dialogItem
export const getOperatingPageId = (state) => state.contentTrashBin.operatingPageId
export const getOperatingFolderId = (state) => state.contentTrashBin.operatingFolderId
export const getSelectedPages = (state) => state.contentTrashBin.selectedPages

export const getIsTrahBinEmpty = createSelector(
  [getDeletedPages, getDeletedFolders],
  (deletedPages, deletedFolders) => {
    return deletedPages.length === 0 && deletedFolders.length === 0
  }
)

export const makeGetIsNoSelectedPage = () => (state) => !state.contentTrashBin.selectedPages.length
export const getIsNoSelectedPage = makeGetIsNoSelectedPage()

export const getSelectedFolder = (state) => state.contentTrashBin.selectedFolder

export const makeGetIsFolderNotSelected = () => (state) => state.contentTrashBin.selectedFolder?.Id === null
export const getIsFolderNotSelected = makeGetIsFolderNotSelected()

export const makeGetSelectedFolderId = () => (state) => state.contentTrashBin.selectedFolder?.Id
export const getSelectedFolderId = makeGetSelectedFolderId()

export const makeGetIsPageSelected = () =>
  createSelector([(state, pageId) => pageId, getSelectedPages], (pageId, pages) => {
    return pages.findIndex((selectedPage) => selectedPage.Id === pageId) !== -1
  })
export const getIsPageSelected = makeGetIsPageSelected()

export const getDeletedFoldersFromCurrent = createSelector(
  [getDeletedFolders, getSelectedFolder, getSelectedFolderId],
  (deletedFolders, currentFolder, isFolderSelected) => {
    return isFolderSelected
      ? deletedFolders.filter((folder) => folder.ParentId === currentFolder.Id)
      : deletedFolders.filter((folder) => !deletedFolders.map((folder) => folder.Id).includes(folder.ParentId))
  }
)

export const getCurrentFolderDeletedPages = createSelector(
  [getDeletedPages, getSelectedFolderId],
  (deletedPages, currentFolderId) => {
    return deletedPages.filter((page) => page.folderId === currentFolderId)
  }
)

export const getCanDeleteRestoreGallery = createSelector(
  [
    getDeletedPages,
    getDeletedFolders,
    gallerySelectors.getHasDeleteRenameAllGalleryContentPermission,
    gallerySelectors.getHasDeleteRenameOwnGalleryContentPermission,
    accountSelectors.getCurrentUserId,
  ],
  (pages, folders, hasDeleteRenameAllPermission, hasDeleteRenameOwnPermission, currentUserId) => {
    const canDeleteSomePage = pages.some((page) => page.canDelete)

    const canDeleteSomeFolder = folders.some((folder) => {
      const folderPages = pages.filter((page) => page.folderId === folder.Id)

      const isAllFolderPagesCanBeDeleted = folderPages
        .filter((page) => !page.pdfPageNumber || page.pdfPageNumber === 1)
        .every((page) => page.canDelete)

      const isFolderOwner = currentUserId === folder.UserId

      const canChange =
        hasDeleteRenameAllPermission || (isFolderOwner && hasDeleteRenameOwnPermission && isAllFolderPagesCanBeDeleted)

      return canChange
    })

    return canDeleteSomePage || canDeleteSomeFolder
  }
)

export const makeGetCanDeleteRestoreFolder = () =>
  createSelector(
    [
      getDeletedPages,
      getDeletedFolders,
      accountSelectors.getCurrentUserId,
      gallerySelectors.getHasDeleteRenameAllGalleryContentPermission,
      gallerySelectors.getHasDeleteRenameOwnGalleryContentPermission,
      (state, id) =>
        gallerySelectors.getAllNestedFoldersIdsForFolderId(state, { id, foldersList: getDeletedFolders(state) }),
      (_, folderId) => folderId,
    ],
    (
      deletedPages,
      deletedFolders,
      currentUserId,
      hasDeleteRenameAllPermission,
      hasDeleteRenameOwnPermission,
      nestedFoldersIds
    ) => {
      if (hasDeleteRenameAllPermission) {
        return true
      }

      const folderAndNestedFolders = deletedFolders.filter((folder) => nestedFoldersIds.some((id) => id === folder.Id))
      const isFolderOwner = folderAndNestedFolders.every((folder) => Boolean(currentUserId === folder.UserId))

      const folderPages = deletedPages.filter((page) => nestedFoldersIds.some((id) => id === page.folderId))
      const canDeleteEveryPage = folderPages
        .filter((page) => !page.pdfPageNumber || page.pdfPageNumber === 1)
        .every((page) => page.canDelete)

      return isFolderOwner && hasDeleteRenameOwnPermission && canDeleteEveryPage
    }
  )
export const getCanDeleteRestoreFolder = makeGetCanDeleteRestoreFolder()
