import { useDispatch, useSelector } from 'react-redux'
import { getDashboards, getIsDashboardsLoading } from '@tabeeb/modules/powerBiAnalyticsReports/selectors'
import PowerBiDashboardItemContainer from '@tabeeb/modules/powerBiAnalyticsReports/containers/PowerBiDashboardItemContainer'
import { Typography } from '@mui/material'
import './index.css'
import { useEffect } from 'react'
import { getDashboardsRequest } from '@tabeeb/modules/powerBiAnalyticsReports/actions'
import CircularProgressBar from '@tabeeb/shared/tabeebCircularProgressBar'

const PowerBiDashboardsList = () => {
  const dispatch = useDispatch()

  const dashboards = useSelector((state) => getDashboards(state))
  const isLoading = useSelector((state) => getIsDashboardsLoading(state))

  useEffect(() => {
    dispatch(getDashboardsRequest())
  }, [dispatch])

  return isLoading ? (
    <CircularProgressBar size={40} thickness={6} />
  ) : (
    <div className='dashboards-list'>
      {dashboards.length === 0 && (
        <Typography variant='h6' component='h6' gutterBottom>
          No dashboards found
        </Typography>
      )}
      {dashboards.map(
        (dashboard) => dashboard && <PowerBiDashboardItemContainer key={dashboard.Id} dashboard={dashboard} />
      )}
    </div>
  )
}

export default PowerBiDashboardsList
