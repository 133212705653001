export const Colors = [
  '#FFFFFF',
  '#D9D9D9',
  '#6D6D6D',
  '#000000',
  '#FB000E',
  '#F86601',
  '#FFFE09',
  '#0F7301',
  '#23FF07',
  '#1FFFFF',
  '#00006F',
  '#0002F9',
  '#6900CD',
  '#FF00FF',
  '#F8016C',
]
