import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { BarChart, Link as LinkIcon } from '@mui/icons-material'
import { Alert, Box, Divider, IconButton, ListSubheader, Typography } from '@mui/material'

import { debounce } from 'lodash'

import DiscussionDialog from '@tabeeb/modules/discussion/components/Dialogs/DiscussionDialog'
import { getContentId } from '@tabeeb/modules/shared/content/selectors'
import { updateUniqueAiObjectRequest } from '@tabeeb/modules/artificialIntelligence/actions'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import ReportTemplateEditor from '@tabeeb/modules/reportTemplateEditor'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'

import {
  getAIReport,
  getAIReportAiObjects,
  getAreAIObjectsLoading,
  getIsAIReportDialogOpen,
  getIsAnalysisLoading,
  getIsRefreshNeeded,
  getSelectedAiObjectId,
  getSelectedTemplate,
  getTemplates,
} from '../../selectors'
import {
  closeAiReportDialog,
  createPublishTemplate,
  deletePublishTemplate,
  getAiObjectAnalysis,
  getAiObjectsByContent,
  goToAnnotation,
  selectTemplate,
  updatePublishTemplate,
} from '../../actions'
import AIReportDialogHeader from '../AIReportDialogHeader'
import Tabs from '../Tabs'
import PublishSettingsDialog from '../PublishSettingsDialog'
import { DialogContentContainer, LinksMenu, LinksMenuItem } from './styles'

const AIReportDialog = () => {
  const dispatch = useDispatch()

  const [anchorPosition, setAnchorPosition] = useState({ left: 0, top: 0 })
  const [selectedLinks, setSelectedLinks] = useState(null)
  const [isInTemplateEditMode, setIsInEditMode] = useState(false)

  const isOpen = useSelector(getIsAIReportDialogOpen)
  const contentId = useSelector(getContentId)
  const userId = useSelector(getCurrentUserId)
  const aiObjects = useSelector(getAIReportAiObjects)
  const analysis = useSelector(getAIReport)
  const selectedAiObjectId = useSelector(getSelectedAiObjectId)
  const areAIObjectsLoading = useSelector(getAreAIObjectsLoading)
  const isAnalysisLoading = useSelector(getIsAnalysisLoading)
  const templates = useSelector(getTemplates)

  const selectedTemplate = useSelector(getSelectedTemplate)
  const isRefreshNeeded = useSelector(getIsRefreshNeeded)
  const hasGroupedColumns = selectedTemplate.columns?.some((c) => c.grouping > 0) || false
  const areHyperLinksHidden = isInTemplateEditMode || hasGroupedColumns
  const isDataEditingEnabled = !isInTemplateEditMode && !hasGroupedColumns && !isRefreshNeeded
  const showAlert = isRefreshNeeded

  const handleLinkMenuClick = (event, links) => {
    setAnchorPosition({ top: event.pageY, left: event.pageX })
    setSelectedLinks(links)
  }

  const dataGridColumns = useMemo(
    () => [
      ...(analysis?.columns?.map((c) =>
        c.Name === 'Name'
          ? {
              field: c.Name,
              filterable: false,
              sortable: false,
              width: 200,
              renderCell: (cell) => (
                <div>
                  {!areHyperLinksHidden && (
                    <IconButton onClick={(e) => handleLinkMenuClick(e, cell.row.Hyperlinks)} color='primary'>
                      <LinkIcon />
                    </IconButton>
                  )}

                  <span>{cell.row.UniqueAIObject[cell.field]}</span>
                </div>
              ),
              renderHeader: (params) => (
                <strong style={!areHyperLinksHidden ? { marginLeft: 48 } : {}}>
                  {params.colDef.headerName || params.field}
                </strong>
              ),
            }
          : {
              field: c.Name,
              editable: c.Editable,
              width: 200,
              filterable: false,
              sortable: false,
              renderHeader: (params) => <strong>{params.colDef.headerName || params.field}</strong>,
              valueGetter: (params) => params.row.UniqueAIObject[params.field],
            }
      ) || []),
    ],
    [analysis?.columns, areHyperLinksHidden]
  )

  const availableColumns = useMemo(
    () =>
      analysis?.availableColumns?.map((c) => ({
        field: c.Name,
      })) || [],
    [analysis?.availableColumns]
  )

  const onTemplateSet = (template) => {
    dispatch(selectTemplate(template))
  }

  const onTemplateAdd = (template) => {
    const templateModel = {
      AIObjectId: selectedAiObjectId,
      name: template.name,
      columns: template.columns.map((c) => ({
        property: c.field,
        sorting: c.sorting,
        filter: c.filter,
        grouping: c.grouping,
      })),
    }
    dispatch(createPublishTemplate.request({ template: templateModel }))
  }

  const onTemplateSave = (template) => {
    const templateModel = {
      name: template.name,
      id: template.id,
      columns: template.columns.map((c) => ({
        sorting: c.sorting,
        filter: c.filter,
        grouping: c.grouping,
        property: c.field,
        id: c.id,
      })),
    }
    onTemplateSet(template)
    dispatch(updatePublishTemplate.request({ template: templateModel }))
  }

  const handleLinkMenuClose = () => {
    setAnchorPosition({ top: 0, left: 0 })
  }

  const handleClose = (_, reason) => {
    if (reason && reason === 'backdropClick') {
      return
    }
    dispatch(closeAiReportDialog())
  }

  const handleProcessRowUpdate = useCallback(
    (newRow, row) => {
      const editableColumns = dataGridColumns.filter((c) => c.editable).map((c) => c.field)

      const properties = {}

      editableColumns.forEach((c) => {
        properties[c] = newRow[c]
      })

      const model = {
        ContentId: contentId,
        AIObjectId: selectedAiObjectId,
        Id: newRow.UniqueAIObjectId,
        Name: newRow.UniqueAIObject.Name,
        UserId: userId,
        Properties: JSON.stringify(properties),
        Source: AIReportDialog.name,
      }

      dispatch(updateUniqueAiObjectRequest(model))

      return { ...row, UniqueAIObject: { ...row.UniqueAIObject, ...properties } }
    },
    [dataGridColumns, contentId, dispatch, selectedAiObjectId, userId]
  )

  const handleSelectAnnotation = (link) => {
    dispatch(goToAnnotation(link))
    handleLinkMenuClose()
  }

  const rows = useMemo(() => analysis?.data || [], [analysis?.data])
  const isAnalysisEmpty = !rows.length && !analysis?.columns

  const previewReadProps = useMemo(
    () => ({
      editMode: 'row',
      processRowUpdate: handleProcessRowUpdate,
      onProcessRowUpdateError: (error) => console.error(error),
      experimentalFeatures: { newEditingApi: true },
      hideFooterPagination: true,
      hideFooterSelectedRowCount: true,
      isCellEditable: () => isDataEditingEnabled,
    }),
    [handleProcessRowUpdate, isDataEditingEnabled]
  )

  const debouncedAiObjectAnalysisRequest = useRef(
    debounce((query) => {
      dispatch(getAiObjectAnalysis.cancel())
      dispatch(getAiObjectAnalysis.request(query))
    }, 300)
  ).current

  const getAiObjectAnalysisDebounced = useCallback(
    (data) => {
      if (selectedAiObjectId) {
        debouncedAiObjectAnalysisRequest({
          contentId,
          aiObjectId: selectedAiObjectId,
          columns: selectedTemplate.columns?.map((c) => ({ ...c, property: c.field })),
          ...data,
        })
      }
    },
    [contentId, debouncedAiObjectAnalysisRequest, selectedAiObjectId, selectedTemplate.columns]
  )

  useEffect(() => {
    if (isOpen) {
      dispatch(getAiObjectsByContent.request({ contentId }))
    }
  }, [isOpen, dispatch, contentId])

  return (
    <DiscussionDialog
      allowBackgroundInteraction
      open={isOpen}
      onClose={handleClose}
      header={
        !isAnalysisEmpty &&
        !areAIObjectsLoading && (
          <AIReportDialogHeader
            isInEditMode={isInTemplateEditMode}
            getAiObjectAnalysisDebounced={getAiObjectAnalysisDebounced}
          />
        )
      }
      content={
        <>
          {(areAIObjectsLoading || (isAnalysisEmpty && isAnalysisLoading)) && <CircularProgressBar />}
          {isAnalysisEmpty && !areAIObjectsLoading && !isAnalysisLoading && (
            <Box m='auto' textAlign='center'>
              <BarChart sx={{ typography: 'h3' }} />
              <Typography>No data has been found...</Typography>
            </Box>
          )}
          {!isAnalysisEmpty && !areAIObjectsLoading && (
            <DialogContentContainer>
              {showAlert && !isAnalysisLoading ? (
                <Alert severity='warning'>
                  The data has been changed. To see actual data and proceed with further editing, kindly refresh the
                  report.
                </Alert>
              ) : null}
              <Box overflow='auto' flex={1}>
                <ReportTemplateEditor
                  rows={rows}
                  columns={dataGridColumns}
                  availableColumns={availableColumns}
                  previewReadProps={previewReadProps}
                  isInEditMode={isInTemplateEditMode}
                  setIsInEditMode={setIsInEditMode}
                  isLoading={isAnalysisLoading}
                  template={selectedTemplate}
                  templates={templates}
                  onTemplateSet={onTemplateSet}
                  onTemplateAdd={onTemplateAdd}
                  onTemplateSave={onTemplateSave}
                  onTemplateDelete={(template) => {
                    onTemplateSet({ id: 0, columns: null })
                    dispatch(deletePublishTemplate.request({ id: template.id }))
                  }}
                />
              </Box>
              {!isInTemplateEditMode && aiObjects.length > 0 && selectedAiObjectId && (
                <Tabs tabs={aiObjects} isLoading={false} />
              )}
              <LinksMenu
                anchorPosition={anchorPosition}
                open={Boolean(anchorPosition.left)}
                onClose={handleLinkMenuClose}
                anchorReference='anchorPosition'
              >
                <ListSubheader>
                  <Typography>Associated pages</Typography>
                  <Divider />
                </ListSubheader>
                {selectedLinks?.map((h) => (
                  <LinksMenuItem title={h.PageName} key={h.AnnotationId} onClick={() => handleSelectAnnotation(h)}>
                    <Typography noWrap>{h.PageName}</Typography>
                  </LinksMenuItem>
                ))}
              </LinksMenu>
              <PublishSettingsDialog />
            </DialogContentContainer>
          )}
        </>
      }
    />
  )
}

export default AIReportDialog
