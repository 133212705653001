import { memo, useEffect } from 'react'

import { useThree } from '@react-three/fiber'

import { View } from '../../constants'
import { setView } from '../../utils/controls'
import { useModelBoundingBox } from '../../hooks'

const Autofit = () => {
  const box = useModelBoundingBox()
  const controls = useThree((state) => state.controls)

  useEffect(() => {
    if (!controls) {
      return
    }

    setView(controls, box, View.Front)
  }, [box, controls])

  return null
}

export default memo(Autofit)
