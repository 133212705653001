import { memo, Fragment, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { PlayingAudioUrlProvider } from '@tabeeb/shared/audioPlayer'

import MessagesGroup from '../MessagesGroup'
import LoadingSpinner from '../LoadingSpinner'

import { DateBar, UnreadMessagesBar } from '../Bars'
import { NoMessagesPlaceholder } from '../Placeholders'

import { useChatContext } from '../../contexts'
import { groupMessages } from '../../services/chat'

import { chatActions, chatSelectors } from '../..'

import useStyles from './styles'

const ChatMessageList = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const listRef = useRef()
  const bottomRef = useRef()
  const unreadRef = useRef()

  const { userId, topicId, topicType } = useChatContext()

  const chatId = { userId, topicId, topicType }
  /// state

  const messages = useSelector((state) => chatSelectors.getChatMessagesList(state, chatId))
  const isLoading = useSelector((state) => chatSelectors.getIsChatLoading(state, chatId))
  const isFullyLoaded = useSelector((state) => chatSelectors.getIsChatFullyLoaded(state, chatId))
  const isInitialized = useSelector((state) => chatSelectors.getIsChatInitialized(state, chatId))
  const lastReadedMessageId = useSelector((state) => chatSelectors.getLastReadedMessageIdForChat(state, chatId))
  const lastMessage = _.last(messages)

  const hasUnreadedMessages = useSelector((state) => chatSelectors.getUnreadMessagesCountForChat(state, chatId) > 0)

  /// state

  // last readed

  const [showUnreadedMessagesBar, setShowUnreadedMessagesBar] = useState(hasUnreadedMessages)
  const [lastReadedMessageBarId, setLastReadedMessageBarId] = useState(lastReadedMessageId)

  useEffect(() => {
    setShowUnreadedMessagesBar(hasUnreadedMessages)
    setLastReadedMessageBarId(lastReadedMessageId)
  }, [userId, topicId, topicType])

  useEffect(() => {
    if (showUnreadedMessagesBar && lastMessage?.self) {
      setShowUnreadedMessagesBar(false)
    }
  }, [lastMessage])

  // last readed

  const scrollToBottom = (behavior = 'auto') => {
    if (bottomRef.current) {
      const scrollElement = unreadRef.current || bottomRef.current

      listRef.current.scrollTo({ top: scrollElement.offsetTop, behavior })
    }
  }

  const onScroll = () => {
    const { scrollTop } = listRef.current
    const { scrollHeight } = listRef.current
    const listHeight = listRef.current.offsetHeight

    if (scrollTop === 0 && listHeight < scrollHeight) {
      listRef.current.scrollTop = 1
      if (!isFullyLoaded && !isLoading) {
        dispatch(chatActions.loadMoreMessages({ userId, topicId, topicType }))
      }
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [userId, topicId, topicType])

  useEffect(() => {
    scrollToBottom('smooth')
  }, [lastMessage?.id])

  useEffect(() => {
    if (!isInitialized && !isFullyLoaded) {
      dispatch(chatActions.loadMoreMessages({ userId, topicId, topicType }))
    }
  }, [dispatch, userId, topicId, topicType, isInitialized, isFullyLoaded])

  const messagesListIsEmpty = messages.length <= 0

  const messagesGroups = groupMessages(messages, lastReadedMessageBarId)

  return (
    <div className={classes.container}>
      <LoadingSpinner loading={isLoading} />
      {messagesListIsEmpty && !isLoading && <NoMessagesPlaceholder />}
      <PlayingAudioUrlProvider>
        <ul ref={listRef} className={classes.list} onScroll={onScroll}>
          {_.map(messagesGroups, (messagesGroup) => {
            const firstMessage = messagesGroup.list[0]

            return (
              <Fragment key={firstMessage.id}>
                {messagesGroup.newDate && <DateBar date={firstMessage.timeStamp} />}
                {showUnreadedMessagesBar && messagesGroup.firstUnread && <UnreadMessagesBar ref={unreadRef} />}
                <MessagesGroup containmentRef={listRef.current} messages={messagesGroup.list} />
              </Fragment>
            )
          })}
          <div className={classes.bottomBar} ref={bottomRef} />
        </ul>
      </PlayingAudioUrlProvider>
    </div>
  )
}

export default memo(ChatMessageList)
