export const certificatesPageSize = 9

export const CertificatesPopoverActionOptions = {
  Edit: 1,
  Delete: 2,
}

export const CertificatesPopoverSortingOptions = {
  LastCreated: 1,
  LastUpdated: 2,
}

export const ReviewActionOptions = {
  Approve: 1,
  Reject: 2,
}
