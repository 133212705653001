import { alpha, styled } from '@mui/material'

export const Container = styled('div')(({ theme }) => {
  return {
    background: alpha(theme.palette.background.paper, 0.9),
    borderRadius: theme.shape.borderRadius,
    paddingBlock: theme.spacing(0.25),
    paddingInline: theme.spacing(0.5),
  }
})

export const Z_INDEX = [1, 1]
