import { put, takeLatest, all, select } from 'redux-saga/effects'

import { rawContentActions } from '@tabeeb/modules/shared/content'
import * as sessionActions from '@tabeeb/modules/../state/actions/sessionActions'
import selector from '@tabeeb/shared/utils/selector'

import { permissionsActions } from '..'

export function* getTenantPermissions() {
  const isLoggedIn = yield select((state) => Boolean(selector.getAccessToken(state)))
  if (isLoggedIn) {
    yield put(permissionsActions.getTenantPermissionsRequest())
  }
}

function* getSessionPermissions(action) {
  const { contentId } = action.payload

  yield put(permissionsActions.getSessionPermissionsRequest({ contentId }))
}

function* permissionsSaga() {
  yield all([
    takeLatest(rawContentActions.getContentSuccess, getSessionPermissions),
    takeLatest(sessionActions.onSetsessionSate, getTenantPermissions),
  ])
}

export default permissionsSaga
