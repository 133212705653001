import { put, all, select, takeEvery } from 'redux-saga/effects'

import { accountSelectors } from '@tabeeb/modules/account'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'
import { contentStateSelectors } from '@tabeeb/shared/content'
import { convertServiceChatMessageToPluginChatMessage } from '@tabeeb/services/dataConverter'

import { getChatUserId, addChatMessage } from './shared'

import { chatActions, chatSelectors } from '..'

function* onChatMessage(action) {
  const [model] = action.payload

  yield addChatMessage(model)
}

function* onChatMessageUpdated(action) {
  const [model] = action.payload

  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const contentId = yield select(contentStateSelectors.getContentId)
  if (contentId !== model.ContentId) {
    return
  }

  const chatUserId = yield getChatUserId(model.UserId, model.ReceiverId)

  const lastReadedMessageId = yield select((state) =>
    chatSelectors.getLastReadedMessageIdForChat(state, {
      topicId: model.TopicId,
      topicType: model.TopicType,
      userId: chatUserId,
    })
  )

  const message = convertServiceChatMessageToPluginChatMessage(model, { currentUserId, lastReadedMessageId })

  yield put(
    chatActions.updateChatMessage({
      topicId: message.topicId,
      topicType: message.topicType,
      userId: chatUserId,
      message,
    })
  )
}
function* chatSignalRSagas() {
  yield all([
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onChatMessage, onChatMessage),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onChatMessageUpdated, onChatMessageUpdated),
  ])
}

export default chatSignalRSagas
