import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

import { MuiThemeProvider } from '@material-ui/core'

import { tabeebTheme } from '@tabeeb/modules/shared/uikit'
import FieldOfViewButton from '@tabeeb/modules/playerToolbar/components/FieldOfViewButton'

import { setFieldOfView } from '../../../actions'
import { getFieldOfView } from '../../../selectors'

const FieldOfViewSettings = ({ onChange }) => {
  const dispatch = useDispatch()
  const fov = useSelector(getFieldOfView)

  const onSubmit = useCallback(
    (value) => {
      dispatch(setFieldOfView(value))
    },
    [dispatch]
  )

  const container = document.getElementById('camera-field-of-view-button-container')
  if (!container) {
    return null
  }

  return createPortal(
    <MuiThemeProvider theme={tabeebTheme}>
      <FieldOfViewButton defaultFov={fov} onChange={onChange} onSubmit={onSubmit} />
    </MuiThemeProvider>,
    container
  )
}

FieldOfViewSettings.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default memo(FieldOfViewSettings)
