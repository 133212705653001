import { memo } from 'react'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import GlbModel from './GlbModel'
import ObjModel from './ObjModel'
import PcdModel from './PcdModel'
import PlyModel from './PlyModel'
import PotreeModel from './PotreeModel'
import SplatModel from './SplatModel'
import StlModel from './StlModel'

import { Extensions } from '../../constants'
import { useCachedUrl } from '../../hooks'

const getModelRendererByExtension = (extension) => {
  switch (extension) {
    case Extensions.Glb:
      return GlbModel
    case Extensions.Obj:
      return ObjModel
    case Extensions.Pcd:
      return PcdModel
    case Extensions.Ply:
      return PlyModel
    case Extensions.Potree:
    case Extensions.PotreeLegacy:
      return PotreeModel
    case Extensions.Stl:
      return StlModel
    case Extensions.Splat:
      return SplatModel
    default:
      return null
  }
}

const getModelExtension = (url) => {
  const { pathname } = new URL(url)

  return `.${pathname.split('.').pop()}`.toLowerCase()
}

const getModelRendererByUrl = (url) => {
  const extension = getModelExtension(url)

  return getModelRendererByExtension(extension)
}

const Model = ({ id, total, azimuth, url, focus, points, position, tilt, extension, onClick, onProgress }) => {
  const modelUrl = useCachedUrl(url)

  const ModelRenderer = getModelRendererByExtension(extension) || getModelRendererByUrl(modelUrl)
  if (!ModelRenderer) {
    return null
  }

  return (
    <ModelRenderer
      id={id}
      azimuth={azimuth}
      url={modelUrl}
      focus={focus}
      position={position}
      points={points}
      tilt={tilt}
      total={total}
      onClick={onClick}
      onProgress={onProgress(url)}
    />
  )
}

Model.defaultProps = {
  id: 0,
  azimuth: 0,
  focus: false,
  position: new Vector3(0, 0, 0),
  points: false,
  tilt: 90,
  total: 1,
  onProgress: () => {},
}

Model.propTypes = {
  id: PropTypes.number,
  azimuth: PropTypes.number,
  extension: PropTypes.oneOf(Object.values(Extensions)),
  url: PropTypes.string.isRequired,
  focus: PropTypes.bool,
  position: PropTypes.instanceOf(Vector3),
  points: PropTypes.bool,
  tilt: PropTypes.number,
  total: PropTypes.number,
  onClick: PropTypes.func,
  onProgress: PropTypes.func,
}

export default memo(Model)
