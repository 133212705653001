import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Cursor } from '@tabeeb/shared/icons'

import { annotationsActions } from '@tabeeb/modules/annotations'

import ToolbarButton from '../ToolbarButton'
import { drawingActions, playerToolbarSelectors } from '../..'

const ESC_KEY_CODE = 27

const CursorButton = () => {
  const dispatch = useDispatch()

  const selected = useSelector(playerToolbarSelectors.getIsSelectionEnabled)

  useEffect(() => {
    function resetSelection({ keyCode }) {
      if (keyCode !== ESC_KEY_CODE) {
        return
      }

      dispatch(annotationsActions.resetSelectedAnnotationsIds())
    }

    if (!selected) {
      return
    }

    window.addEventListener('keyup', resetSelection)

    return () => {
      window.removeEventListener('keyup', resetSelection)
    }
  }, [selected, dispatch])

  const onClick = useCallback(() => {
    if (selected) {
      dispatch(drawingActions.onDisableSelection())
    } else {
      dispatch(drawingActions.onEnableSelection())
    }
  }, [dispatch, selected])

  return <ToolbarButton Icon={Cursor} onClick={onClick} title='Enable cursor' selected={selected} />
}

export default React.memo(CursorButton)
