import { all, takeLatest, takeEvery, put } from 'redux-saga/effects'

import * as aiActions from '../actions'
import * as notificationActions from '../../notification/actions'
import { signalrEvents, signalrConstants } from '../../signalr'
import { getAiAnnotationPropertiesText } from '../../annotations/services/annotationsService'
import { galleryActions } from '../../gallery'

function* onUpdateUniqueAiObjectSuccess(action) {
  yield put(notificationActions.onAddInfoNotification({ message: 'Properties successfully updated' }))
  const uniqueAiObject = action.response.data

  if (!(uniqueAiObject.Properties && typeof uniqueAiObject.Properties === 'string')) {
    return
  }

  const propertiesText = getAiAnnotationPropertiesText(uniqueAiObject.Properties)
  yield put(galleryActions.updateAiAnnotationsText({ uniqueAiObjectId: uniqueAiObject.Id, propertiesText }))
}

function* onUpdateUniqueAiObjectFailed(action) {
  console.error('------------- updateUniqueAiObjectFailed:', action)
}

function* onUniqueAIObjectUpdated(action) {
  const [uniqueAiObject] = action.payload

  let propertiesText = ''
  if (uniqueAiObject.Properties && typeof uniqueAiObject.Properties === 'string') {
    propertiesText = getAiAnnotationPropertiesText(uniqueAiObject.Properties)
  } else {
    return
  }

  yield put(galleryActions.updateAiAnnotationsText({ uniqueAiObjectId: uniqueAiObject.Id, propertiesText }))
}

function* updateUniqueAIObject() {
  yield all([
    takeLatest(aiActions.updateUniqueAiObjectSuccess, onUpdateUniqueAiObjectSuccess),
    takeLatest(aiActions.updateUniqueAiObjectFailed, onUpdateUniqueAiObjectFailed),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onUniqueAIObjectUpdated, onUniqueAIObjectUpdated),
  ])
}

export default updateUniqueAIObject
