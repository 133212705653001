import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { IconButton, Typography } from '@material-ui/core'
import { Call, CallEnd } from '@material-ui/icons'
import PropTypes from 'prop-types'

import routes from '@tabeeb/routes'
import { rawContentActions } from '@tabeeb/shared/content'
import UserAvatar from '@tabeeb/modules/shared/userAvatar'
import { defaultAvatar } from '../../../account/constants'
import { callNotificationActions } from '../..'

import './styles.less'

const Notification = ({ notification, tenantName, windowFocused }) => {
  const dispatch = useDispatch()

  const { avatarUrl, userName, sessionName, sessionId, isSystemNotified } = notification

  const relativeLink = `${routes.session}?sessionId=${sessionId}`
  const absoluteLink = `${window.location.origin}${window.location.pathname}#${relativeLink}`

  const acceptCall = () => {
    dispatch(callNotificationActions.acceptCallNotification(sessionId))
    dispatch(rawContentActions.leaveContent({ redirectLocation: relativeLink }))
  }

  const declineCall = () => {
    dispatch(callNotificationActions.declineCallNotification(sessionId))
  }

  const onSystemNotificationClick = (event) => {
    event.preventDefault()
    dispatch(callNotificationActions.hideCallNotification(sessionId))
    window.open(absoluteLink, '_blank')
  }

  useEffect(() => {
    if (sessionId && !windowFocused && !isSystemNotified) {
      const notificationOptions = {
        sessionId,
        title: tenantName,
        body: `${userName} has started call in '${sessionName}`,
        icon: avatarUrl || defaultAvatar,
        onClickHandler: onSystemNotificationClick,
      }

      dispatch(callNotificationActions.showSystemCallNotification(notificationOptions))
    }
  }, [notification, windowFocused])

  return (
    <div>
      <div className='notification-content'>
        <div className='content-avatar'>
          <UserAvatar user={{ Name: userName, AvatarUrl: avatarUrl }} />
        </div>
        <div className='content-description'>
          <Typography className='description-text' title={`${userName} has started call in '${sessionName}'`}>
            {userName} has started call in '{sessionName}'
          </Typography>
        </div>
      </div>
      <div className='notification-buttons'>
        <IconButton title='Accept call' onClick={acceptCall}>
          <Call style={{ color: '#106b00' }} />
        </IconButton>

        <IconButton title='Decline call' onClick={declineCall}>
          <CallEnd style={{ color: '#820004' }} />
        </IconButton>
      </div>
    </div>
  )
}

Notification.propTypes = {
  notification: PropTypes.shape({
    avatarUrl: PropTypes.string,
    userName: PropTypes.string.isRequired,
    sessionName: PropTypes.string.isRequired,
    sessionId: PropTypes.number.isRequired,
    isSystemNotified: PropTypes.bool,
  }),
  tenantName: PropTypes.string.isRequired,
  windowFocused: PropTypes.bool.isRequired,
}

export default Notification
