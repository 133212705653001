import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { KeyboardDateTimePicker } from '@material-ui/pickers'

const DatePickerField = ({ form, field, size, ...rest }) => {
  const { setFieldValue } = useFormikContext()

  const fieldValue = (field.value && new Date(field.value)) || null

  return (
    <KeyboardDateTimePicker
      {...field}
      {...rest}
      size={size}
      value={fieldValue}
      onChange={(date) => setFieldValue(field.name, date)}
      inputVariant='outlined'
      KeyboardButtonProps={{ size }}
    />
  )
}

DatePickerField.defaultProps = {
  size: 'medium',
}

DatePickerField.propTypes = {
  size: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  form: PropTypes.object.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.instanceOf(Date).isRequired,
  }).isRequired,
}

export default DatePickerField
