import { SupportedPointCloudExtensions } from '@tabeeb/modules/pointCloud/constants'

function isDigit(char) {
  return char >= '0' && char <= '9'
}

export function numericFileNamesSorting(fileA, fileB) {
  let firstFileName = fileA.name.toLowerCase()
  let secondFileName = fileB.name.toLowerCase()

  const minFileNameLength = Math.min(firstFileName.length, secondFileName.length)
  let i = 0

  let isPrevCharIsNumber = false
  let lastNumberStartIndex = -1
  while (firstFileName.charAt(i) === secondFileName.charAt(i) && i < minFileNameLength) {
    const isNumericChar = isDigit(firstFileName.charAt(i)) && isDigit(secondFileName.charAt(i))

    if (!isPrevCharIsNumber) {
      if (isDigit(firstFileName.charAt(i)) && isDigit(secondFileName.charAt(i))) {
        lastNumberStartIndex = i
      }
    }

    isPrevCharIsNumber = isNumericChar
    i++
  }

  if (lastNumberStartIndex === -1) {
    firstFileName = firstFileName.substring(i, firstFileName.length)
    secondFileName = secondFileName.substring(i, secondFileName.length)
  } else {
    firstFileName = firstFileName.substring(lastNumberStartIndex, firstFileName.length)
    secondFileName = secondFileName.substring(lastNumberStartIndex, secondFileName.length)
  }

  const numberInFirstName = Number(firstFileName.replace(/(^\d+)(.+$)/i, '$1'))
  const numberInSecondName = Number(secondFileName.replace(/(^\d+)(.+$)/i, '$1'))

  if (isNaN(numberInFirstName - numberInSecondName)) {
    return firstFileName < secondFileName ? -1 : 1
  }

  return numberInFirstName - numberInSecondName
}

export function isPdf(file) {
  if (file.type === 'application/pdf') {
    return true
  }

  return false
}

export function isHeicHeifImage(file) {
  const fileExtension = file.name.split('.').pop().toLowerCase()

  if (
    file.type === 'image/heic' ||
    file.type === 'image/heif' ||
    fileExtension === 'heic' ||
    fileExtension === 'heif'
  ) {
    return true
  }

  return false
}

export function isImage(file) {
  if (isHeicHeifImage(file) || file.type === 'image/jpeg' || file.type === 'image/gif' || file.type === 'image/png') {
    return true
  }

  return false
}

export function isVideo(file) {
  if (file.type.startsWith('video/')) {
    return true
  }

  return false
}

export function isDocument(file) {
  const fileExtension = file.name.split('.').pop().toLowerCase()

  if (
    fileExtension === 'doc' ||
    fileExtension === 'docx' ||
    fileExtension === 'ppt' ||
    fileExtension === 'pptx' ||
    fileExtension === 'xls' ||
    fileExtension === 'xlsx'
  ) {
    return true
  }

  return false
}

export function isPointCloudModel(file) {
  const fileName = file.name.toLowerCase()

  return SupportedPointCloudExtensions.some((extension) => fileName.endsWith(extension))
}

// todo: add more supported extensions
export function isAutodeskFile(file) {
  const fileExtension = file.name.split('.').pop().toLowerCase()

  if (fileExtension === 'rvt' || fileExtension === 'dwg' || fileExtension === 'obj') {
    return true
  }

  return false
}

export const readUploadedFileAsText = (inputFile) => {
  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort()
      reject(new DOMException('Problem parsing input file.'))
    }

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result)
    }
    temporaryFileReader.readAsText(inputFile)
  })
}

export const readUploadedFileAsDataUrl = (inputFile) => {
  const temporaryFileReader = new FileReader()

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = (event) => {
      temporaryFileReader.abort()
      reject(event)
    }

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result)
    }
    temporaryFileReader.readAsDataURL(inputFile)
  })
}
