import { combineReducers } from 'redux'
import dashboard from './dashboard'
import dashboards from './dashboards'
import isDashboardsLoading from './isDashboardsLoading'

export default combineReducers({
  dashboard,
  dashboards,
  isDashboardsLoading,
})
