import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Pagination, Typography } from '@mui/material'
import { Grid, Button } from '@material-ui/core'
import { Add } from '@mui/icons-material'

import { getCurrentUserId } from '@tabeeb/modules/account/selectors'

import CertificatesList from '../CertificatesList'
import { getCertificatesPageSize } from '../../selectors'

import useStyles from './styles'

const CertificatesProfileList = ({
  certificates,
  totalCount,
  onOpenAddEditCertificate,
  setCertificateToEditing,
  pageNumber,
  setPageNumber,
}) => {
  const classes = useStyles()
  const certificatesPageSize = useSelector(getCertificatesPageSize)
  const currentUserId = useSelector(getCurrentUserId)
  const countOfPages = Math.ceil(totalCount / certificatesPageSize)
  return (
    <>
      <Grid item>
        <div className={classes.header}>
          <Typography>Main ({totalCount})</Typography>
          <Button startIcon={<Add />} variant='text' color='primary' onClick={onOpenAddEditCertificate}>
            New certificate
          </Button>
        </div>
      </Grid>
      <Grid item>
        <CertificatesList
          certificates={certificates}
          setCertificateToEditing={setCertificateToEditing}
          direction='row'
          userId={currentUserId}
        />
      </Grid>
      {totalCount > certificatesPageSize && (
        <Grid item>
          <Pagination
            className={classes.paginationUl}
            shape='rounded'
            color='primary'
            count={countOfPages || 1}
            disabled={countOfPages === 0}
            page={pageNumber}
            onChange={(event, page) => setPageNumber(page)}
          />
        </Grid>
      )}
    </>
  )
}

CertificatesProfileList.propTypes = {
  certificates: PropTypes.arrayOf(
    PropTypes.shape({
      Id: PropTypes.number.isRequired,
      IsDeleted: PropTypes.bool.isRequired,
      CreatedOn: PropTypes.string.isRequired,
      UserId: PropTypes.number.isRequired,
      VerificationStatusId: PropTypes.number.isRequired,
      UpdateOn: PropTypes.string,
      Title: PropTypes.string.isRequired,
      Authority: PropTypes.string.isRequired,
      FirstName: PropTypes.string.isRequired,
      LastName: PropTypes.string.isRequired,
      IssueDate: PropTypes.string,
      ExpirationDate: PropTypes.string,
      Comment: PropTypes.string,
      PictureUrl: PropTypes.string.isRequired,
    })
  ).isRequired,
  totalCount: PropTypes.number.isRequired,
  onOpenAddEditCertificate: PropTypes.func.isRequired,
  setCertificateToEditing: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  setPageNumber: PropTypes.func.isRequired,
}

export default CertificatesProfileList
