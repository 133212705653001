import { Vector3 } from 'three'

import { View } from '../constants'

/**
 * @param {import('camera-controls').default} controls
 * @param {import('three').Box3} box
 * @param {Number} view
 * @returns {void}
 */
export const setView = (controls, box, view = View.Front) => {
  const center = box.getCenter(new Vector3(0, 0, 0))
  const distance = controls.getDistanceToFitBox(box.max.x - box.min.x, box.max.y - box.min.y, box.max.z - box.min.z)

  if (view === View.Top) {
    controls.setLookAt(center.x, center.y + distance, center.z, center.x, center.y, center.z, true)
  } else if (view === View.Bottom) {
    controls.setLookAt(center.x, center.y - distance, center.z, center.x, center.y, center.z, true)
  } else if (view === View.Right) {
    controls.setLookAt(center.x + distance, center.y, center.z, center.x, center.y, center.z, true)
  } else if (view === View.Left) {
    controls.setLookAt(center.x - distance, center.y, center.z, center.x, center.y, center.z, true)
  } else if (view === View.Front) {
    controls.setLookAt(center.x, center.y, center.z + distance, center.x, center.y, center.z, true)
  } else if (view === View.Back) {
    controls.setLookAt(center.x, center.y, center.z - distance, center.x, center.y, center.z, true)
  }
}
