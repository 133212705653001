const powerBiRequests = {
  getDashboardRequest: (id) => ({
    url: `api/powerbi/dashboards/${id}`,
    method: 'get',
  }),
  getDashboardsRequest: () => ({
    url: `api/powerbi/dashboards`,
    method: 'get',
  }),
  getPowerBiEmbedTokenRequest: (id) => ({
    url: `api/powerbi/dashboards/token?id=${id}`,
    method: 'get',
  }),
}

export default powerBiRequests
