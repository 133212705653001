import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { SessionPermission, TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as rawActions from '../actions'
import Folder from '../components/Folder'
import {
  getIsFolderAddedToCustomVideo,
  getIsFolderAddedToSpatialModel,
  getIsFolderAddedTo3dModel,
  getIsFolderAddedToBentleyModel,
  getFolderById,
  getIsFolderAddedForSharing,
  getIsFolderAddedToStructureModel,
  getIsFolderAddedForReviewing,
  getIsFolderAddedToClassificationAnnotations,
  getCanDeleteGalleryFolder,
  getCanRenameGalleryFolder,
} from '../selectors'
import { accountSelectors } from '../../account'
import { contentStateSelectors } from '../../shared/content'
import { presentationSelectors } from '../../presentation'
import { getIsPageFolderAuthorizationEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { hasSessionPermission } from '@tabeeb/modules/permissions/selectors'

class FolderContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: props.folder.Name,
      isDragOn: false,
      isRenaming: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.isRenaming && prevState.value !== nextProps.folder.Name) {
      return {
        ...prevState,
        value: nextProps.folder.Name,
      }
    }
    return null
  }

  textInput = null

  setTextInputRef = (element) => {
    this.textInput = element
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this._handleSubmitRename()
    }
    if (e.key === 'Escape') {
      this._handleCancelRenaming()
    }
  }

  _handleChange = (e) => {
    const { value } = e.target
    this.setState({ value })
  }

  _handleStartRenaming = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const { value } = this.state

    this.setState({ isRenaming: true })

    if (this.textInput) {
      this.textInput.value = value

      this.textInput.focus()
    }
  }

  _handleCancelRenaming = () => {
    this.setState({ isRenaming: false })
  }

  _handleSubmitRename = () => {
    const { value, isRenaming } = this.state
    const {
      folder,
      actions: { renameSessionFolderRequest },
    } = this.props

    if (isRenaming && value !== folder.Name) {
      const model = { Id: folder.Id, Name: value }
      renameSessionFolderRequest(model)
    }

    this.setState({ isRenaming: false })
  }

  _handleDeleteFolder = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      actions: { setDeleteSessionFolderId },
      id,
    } = this.props

    setDeleteSessionFolderId(id)
  }

  _handleSelectFolder = () => {
    const {
      actions: { selectSessionFolder },
      id,
    } = this.props

    selectSessionFolder({ folderId: id })
  }

  _handleAddFolderToSpatialView = () => {
    const {
      actions: { addFolderToSpatialModel },
      id,
    } = this.props

    addFolderToSpatialModel({ folderId: id })
  }

  _handleAddFolderToCustomVideo = () => {
    const {
      actions: { addFolderToCustomVideo },
      id,
    } = this.props

    addFolderToCustomVideo({ folderId: id })
  }

  _handleAddFolderTo3dModel = () => {
    const {
      actions: { addFolderTo3dModel },
      id,
    } = this.props

    addFolderTo3dModel({ folderId: id })
  }

  _handleAddFolderToBentleyModel = () => {
    const {
      actions: { addFolderToBentleyModel },
      id,
    } = this.props

    addFolderToBentleyModel({ folderId: id })
  }

  _handleAddFolderForSharing = () => {
    const {
      actions: { addFolderForSharing },
      id,
    } = this.props

    addFolderForSharing({ folderId: id })
  }

  _handleAddFolderForReviewing = () => {
    const {
      actions: { addFolderForReviewing },
      id,
    } = this.props

    addFolderForReviewing({ folderId: id })
  }

  _handleAddFolderToStructureModel = () => {
    const {
      actions: { addFolderToStructureModel },
      id,
    } = this.props
    addFolderToStructureModel({ folderId: id })
  }

  _handleAddFolderToClassificationAnnotations = () => {
    const {
      actions: { addFolderToClassificationAnnotations },
      id,
    } = this.props
    addFolderToClassificationAnnotations({ folderId: id })
  }

  _onDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  _onDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
      isDragOn: false,
    })

    const {
      id,
      isDragAvailable,
      actions: { updatePageSessionFolderRequest },
    } = this.props

    if (!isDragAvailable) {
      return
    }

    const selection = JSON.parse(e.dataTransfer.getData('text/plain'))

    const data = {
      pageIds: selection,
      folderId: id,
    }

    updatePageSessionFolderRequest(data)
  }

  _onDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const { isDragAvailable } = this.props

    if (!isDragAvailable) {
      return
    }

    this.setState({
      isDragOn: true,
    })
  }

  _onDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const { isDragAvailable } = this.props

    if (!isDragAvailable) {
      return
    }

    this.setState({
      isDragOn: false,
    })
  }

  _handleEdit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const {
      actions: { openEditFolderDialog },
      folder,
      isPageFolderAuthorizationEnabled,
      canManagePermission,
    } = this.props

    if (isPageFolderAuthorizationEnabled && canManagePermission && !folder.ParentId) {
      openEditFolderDialog(folder)
      return
    }

    this._handleStartRenaming(e)
  }

  render() {
    const {
      folder,
      isSpatialModelOpen,
      isSpatialImageAdded,
      isCustomVideoOpen,
      isCustomVideoAdded,
      is3dModelOpen,
      is3dModelAdded,
      isBentleyModelOpen,
      isBentleyModelAdded,
      isSessionSharingPagesListOpen,
      isSessionSharingPagesListAdded,
      isStructureModelOpen,
      isStructureModelAdded,
      canRename,
      isExpanded,
      isSessionReviewingPagesListOpen,
      isSessionReviewingPagesListAdded,
      isClassificationAnnotationsOpen,
      isClassificationAnnotationsPagesListAdded,
      canDelete,
    } = this.props

    const { isDragOn, isRenaming } = this.state

    const props = {
      folder,

      canRename,
      canDelete,
      handleSelectFolder: this._handleSelectFolder,
      handleDeleteFolder: this._handleDeleteFolder,
      handleEdit: this._handleEdit,
      renaming: {
        isRenaming,
        inputRef: this.setTextInputRef,
        start: this._handleStartRenaming,
        cancel: this._handleCancelRenaming,
        submit: this._handleSubmitRename,
        onChange: this._handleChange,
        onKeyDown: this._handleKeyDown,
      },
      spatialView: {
        isOpen: isSpatialModelOpen,
        isAdded: isSpatialImageAdded,
        handleAddFolder: this._handleAddFolderToSpatialView,
      },
      customVideo: {
        isOpen: isCustomVideoOpen,
        isAdded: isCustomVideoAdded,
        handleAddFolder: this._handleAddFolderToCustomVideo,
      },
      d3dModel: {
        isOpen: is3dModelOpen,
        isAdded: is3dModelAdded,
        handleAddFolder: this._handleAddFolderTo3dModel,
      },
      bentleyModel: {
        isOpen: isBentleyModelOpen,
        isAdded: isBentleyModelAdded,
        handleAddFolder: this._handleAddFolderToBentleyModel,
      },
      shareSessionMode: {
        isOpen: isSessionSharingPagesListOpen,
        isAdded: isSessionSharingPagesListAdded,
        handleAddFolder: this._handleAddFolderForSharing,
      },
      structureModel: {
        isOpen: isStructureModelOpen,
        isAdded: isStructureModelAdded,
        handleAddFolder: this._handleAddFolderToStructureModel,
      },
      dragAndDrop: {
        isdragon: `${isDragOn}`,
        onDrop: this._onDrop,
        onDragOver: this._onDragOver,
        onDragEnter: this._onDragEnter,
        onDragLeave: this._onDragLeave,
      },
      reviewSessionMode: {
        isOpen: isSessionReviewingPagesListOpen,
        isAdded: isSessionReviewingPagesListAdded,
        handleAddFolder: this._handleAddFolderForReviewing,
      },
      classificationAnnotations: {
        isOpen: isClassificationAnnotationsOpen,
        isAdded: isClassificationAnnotationsPagesListAdded,
        handleAddFolder: this._handleAddFolderToClassificationAnnotations,
      },
      isExpanded,
    }

    return <Folder {...props} />
  }
}

FolderContainer.propTypes = {
  id: PropTypes.number.isRequired,
  currentUserId: PropTypes.number.isRequired,
  isCustomVideoOpen: PropTypes.bool.isRequired,
  isCustomVideoAdded: PropTypes.bool.isRequired,
  isSpatialModelOpen: PropTypes.bool.isRequired,
  isSpatialImageAdded: PropTypes.bool.isRequired,
  is3dModelOpen: PropTypes.bool.isRequired,
  is3dModelAdded: PropTypes.bool.isRequired,
  isBentleyModelOpen: PropTypes.bool.isRequired,
  isBentleyModelAdded: PropTypes.bool.isRequired,
  canRename: PropTypes.bool.isRequired,
  folder: PropTypes.object.isRequired,
  selection: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    setDeleteSessionFolderId: PropTypes.func.isRequired,
    selectSessionFolder: PropTypes.func.isRequired,
    addFolderToSpatialModel: PropTypes.func.isRequired,
    addFolderToCustomVideo: PropTypes.func.isRequired,
    addFolderTo3dModel: PropTypes.func.isRequired,
    addFolderToBentleyModel: PropTypes.func.isRequired,
    addFolderForSharing: PropTypes.func.isRequired,
    addFolderForReviewing: PropTypes.func.isRequired,
    addFolderToStructureModel: PropTypes.func.isRequired,
    addFolderToClassificationAnnotations: PropTypes.func.isRequired,
    renameSessionFolderRequest: PropTypes.func.isRequired,
    updatePageSessionFolderRequest: PropTypes.func.isRequired,
    openEditFolderDialog: PropTypes.func.isRequired,
  }).isRequired,
  isSessionSharingPagesListOpen: PropTypes.bool.isRequired,
  isSessionSharingPagesListAdded: PropTypes.bool.isRequired,
  isStructureModelOpen: PropTypes.bool.isRequired,
  isStructureModelAdded: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isSessionReviewingPagesListOpen: PropTypes.bool.isRequired,
  isSessionReviewingPagesListAdded: PropTypes.bool.isRequired,
  isClassificationAnnotationsOpen: PropTypes.bool.isRequired,
  isClassificationAnnotationsPagesListAdded: PropTypes.bool.isRequired,
  isDragAvailable: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  isPageFolderAuthorizationEnabled: PropTypes.bool.isRequired,
  canManagePermission: PropTypes.bool.isRequired,
}

const mapStateToProps = (state, props) => {
  const isSpatialImageAdded = getIsFolderAddedToSpatialModel(state, props)
  const isCustomVideoAdded = getIsFolderAddedToCustomVideo(state, props)
  const is3dModelAdded = getIsFolderAddedTo3dModel(state, props)
  const isBentleyModelAdded = getIsFolderAddedToBentleyModel(state, props)
  const isSessionSharingPagesListAdded = getIsFolderAddedForSharing(state, props)
  const isStructureModelAdded = getIsFolderAddedToStructureModel(state, props)
  const folder = getFolderById(state, props)
  const isSessionReviewingPagesListAdded = getIsFolderAddedForReviewing(state, props)
  const isClassificationAnnotationsPagesListAdded = getIsFolderAddedToClassificationAnnotations(state, props)
  const canDelete = getCanDeleteGalleryFolder(state, props)
  const canRename = getCanRenameGalleryFolder(state, props)
  const canManagePermission = hasSessionPermission(state, SessionPermission.ManagePageFolderPermissions)

  const presenterId = contentStateSelectors.getPresenterId(state)
  const currentUserId = accountSelectors.getCurrentUserId(state)
  const isCallStarted = presentationSelectors.getIsCallStarted(state)
  const isPageFolderAuthorizationEnabled = getIsPageFolderAuthorizationEnabled(state)

  return {
    folder,
    isSpatialImageAdded,
    isCustomVideoAdded,
    is3dModelAdded,
    isBentleyModelAdded,
    isSessionSharingPagesListAdded,
    isStructureModelAdded,
    isDragAvailable: isCallStarted ? presenterId === currentUserId : true,
    currentUserId,
    canRename,
    isSessionReviewingPagesListAdded,
    isClassificationAnnotationsPagesListAdded,
    canDelete,
    isPageFolderAuthorizationEnabled,
    canManagePermission,
    selection: state.gallery.selection,
    isCustomVideoOpen:
      state.customVideo.isTimelineOpen && state.navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.CustomVideo,
    isSpatialModelOpen:
      state.spatialModel.isTimelineOpen && state.navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.SpatialView,
    is3dModelOpen:
      state.threedeeModel.isTimelineOpen && state.navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.ThreeDModel,
    isBentleyModelOpen:
      state.bentleyModel.isTimelineOpen && state.navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.BentleyModel,
    isSessionSharingPagesListOpen:
      state.shareSessionPagesList.isTimelineOpen &&
      state.navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.ShareSession,
    isStructureModelOpen:
      state.structureModel.isTimelineOpen &&
      state.navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.StructureModel,
    isSessionReviewingPagesListOpen:
      state.reviewSessionPagesList.isTimelineOpen &&
      state.navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.ReviewSession,
    isClassificationAnnotationsOpen:
      state.classificationAnnotationsPagesTimeline.isTimelineOpen &&
      state.navbar.currentPanel[TabPanelLocation.Bottom] === TabPanel.ClassificationAnnotations,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(rawActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FolderContainer)
