/* eslint-disable react/no-array-index-key */
import { memo } from 'react'

import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import { getCenter } from '../../../utils'

import Point from '../Point'
import AddPointButton from '../AddPointButton'

const Points = ({ closed, visible, points, onAdd, onDelete, onUpdate, onUpdateCommit }) => {
  if (!visible) {
    return null
  }

  return (
    <>
      {points.map((point, index) => {
        return (
          <Point
            id={index}
            key={`point_${index}`}
            position={point}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onUpdateCommit={onUpdateCommit}
          />
        )
      })}
      {Boolean(onAdd) &&
        points.length >= 2 &&
        points.map((point, index) => {
          if (index === points.length - 1 && !closed) {
            return null
          }

          return (
            <AddPointButton
              key={`add_point_button_${index}_${index + 1}`}
              position={getCenter(point, points[(index + 1) % points.length])}
              onClick={(newPoint) => onAdd({ index, point: newPoint })}
            />
          )
        })}
    </>
  )
}

Points.defaultProps = {
  closed: false,
}

Points.propTypes = {
  closed: PropTypes.bool,
  visible: PropTypes.bool.isRequired,
  points: PropTypes.arrayOf(PropTypes.instanceOf(Vector3).isRequired).isRequired,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  onUpdateCommit: PropTypes.func,
}

export default memo(Points)
