import { Fab, styled } from '@mui/material'

export const Button = styled(Fab)(({ theme }) => {
  const size = 12

  return {
    width: size,
    height: size,
    minHeight: size,
    borderRadius: '4px',
    background: 'white',
    fontSize: '12px',
    boxShadow: theme.shadows[4],
  }
})

export const Z_INDEX = [2, 2]
