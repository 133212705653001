import PropTypes from 'prop-types'

import { Vector3 } from 'three'
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader'

import { useLoader } from '@react-three/fiber'

import { Defaults } from '../../../constants'
import { useModelRotation, useObjectFocus } from '../../../hooks'

const PcdModel = ({ id, azimuth, url, position, tilt, focus, onClick, onProgress }) => {
  const pcd = useLoader(PCDLoader, url, null, onProgress).clone()

  pcd.material.vertexColors = true
  pcd.material.size = Defaults.POINT_SIZE

  useModelRotation({ model: pcd, azimuth, tilt })
  useObjectFocus(id, pcd, focus)

  return <primitive position={position} object={pcd} onClick={onClick} />
}

PcdModel.propTypes = {
  id: PropTypes.number.isRequired,
  azimuth: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.instanceOf(Vector3).isRequired,
  tilt: PropTypes.number.isRequired,
  focus: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onProgress: PropTypes.func.isRequired,
}

export default PcdModel
