import { memo, useCallback, useEffect, useState } from 'react'

import moment from 'moment'

import {
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'

import { Pagination } from '@tabeeb/uikit'
import { usePagination } from '@tabeeb/shared/utils/hooks'
import PageHeader from '@tabeeb/modules/aiExpressions/components/PageHeader'

import {
  AnnouncementCreateButton,
  AnnouncementScopeChip,
  AnnouncementSeverityChip,
  AnnouncementStatusChip,
} from '../../components'
import { useAnnouncements } from '../../hooks'
import AnnouncementMenu from '../../components/AnnouncementMenu'

const AnnouncementsManagementPage = () => {
  const [announcementsCount, setAnnouncementsCount] = useState(0)

  const {
    current: currentPage,
    pages,
    size,
    onPageNumberChange,
    onPageSizeChange,
  } = usePagination({ total: announcementsCount })
  const {
    announcements,
    total,
    loading,
    onDeleteAnnouncement,
    onUpdateAnnouncementStatus,
    onUpdateAnnouncementSeverity,
  } = useAnnouncements({
    skip: (currentPage - 1) * size,
    take: size,
  })

  useEffect(() => {
    setAnnouncementsCount(total)
  }, [total])

  const onUpdateSeverity = useCallback(
    (id) => (severity) => {
      onUpdateAnnouncementSeverity({ id, severity })
    },
    [onUpdateAnnouncementSeverity]
  )

  return (
    <Paper sx={{ p: 2, pt: 1, m: 2 }}>
      <PageHeader title='Announcements' actions={<AnnouncementCreateButton />} />
      <TableContainer sx={{ position: 'relative' }} variant='outlined' component={Paper}>
        {loading && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, right: 0, zIndex: 3 }} />}
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='center'>Scope</TableCell>
              <TableCell align='center'>Status</TableCell>
              <TableCell align='center'>Severity</TableCell>
              <TableCell align='center'>Created at</TableCell>
              <TableCell align='center'>Display period</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {announcements.length > 0 ? (
              announcements.map((announcement) => (
                <TableRow key={announcement.Id}>
                  <TableCell align='left'>{announcement.Name}</TableCell>
                  <TableCell align='center' width={150}>
                    <AnnouncementScopeChip scope={announcement.Scope} />
                  </TableCell>
                  <TableCell align='center' width={150}>
                    <AnnouncementStatusChip status={announcement.Status} />
                  </TableCell>
                  <TableCell align='center' width={150}>
                    <AnnouncementSeverityChip
                      severity={announcement.Severity}
                      onChange={onUpdateSeverity(announcement.Id)}
                    />
                  </TableCell>
                  <TableCell align='left' width={250}>
                    {moment(announcement.CreatedOn).format('LLL')}
                  </TableCell>
                  <TableCell align='left' width={450}>
                    {`${moment(announcement.StartDate).format('LLL')} - ${moment(announcement.ExpiryDate).format(
                      'LLL'
                    )}`}
                  </TableCell>
                  <TableCell align='right' width={50}>
                    <AnnouncementMenu
                      announcement={announcement}
                      onDelete={onDeleteAnnouncement}
                      onUpdateStatus={onUpdateAnnouncementStatus}
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align='center'>
                  <Typography variant='subtitle1'>{loading ? 'Loading announcements' : 'No announcements'}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <Pagination
          current={currentPage}
          pages={pages}
          pageSize={size}
          onPageNumberChange={onPageNumberChange}
          onPageSizeChange={onPageSizeChange}
        />
      </TableContainer>
    </Paper>
  )
}

export default memo(AnnouncementsManagementPage)
