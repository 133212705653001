import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { InputBase, List, Paper, Popover, Typography, withStyles } from '@material-ui/core'

import ParticipantsListItem from '../ParticipantsListItem'

import { chatSelectors } from '../../..'

import styles, { popoverProps } from './styles'

function filterParticipants(participants, searchText) {
  return participants.filter(
    (user) =>
      (Boolean(user.email) && user.email.includes(searchText)) ||
      (Boolean(user.displayName) && user.displayName.includes(searchText))
  )
}

const ParticipantsList = ({ classes, anchorEl, open, participants, onClose, onSelect }) => {
  const [participantsList, setParticipantsList] = useState(participants)

  useEffect(() => {
    if (open) {
      setParticipantsList(participants)
    }
  }, [open, participants])

  const [searchText, setSearchText] = useState('')

  const handleExited = () => {
    setSearchText('')
  }

  const handleSearchTextChanged = (event) => {
    setSearchText(event.target.value)
  }

  const receivers = filterParticipants(participantsList, searchText)

  const noUsersFound = receivers.length === 0

  return (
    <Popover anchorEl={anchorEl} open={open} onClose={onClose} onExited={handleExited} {...popoverProps}>
      <Paper className={classes.container}>
        <List className={classes.list}>
          <InputBase placeholder='Search...' className={classes.search} onChange={handleSearchTextChanged} />
          {noUsersFound && (
            <Typography className={classes.label} align='center' color='textSecondary'>
              No users found
            </Typography>
          )}
          {receivers.map((user) => (
            <ParticipantsListItem key={user.id} user={user} onSelect={onSelect(user)} />
          ))}
        </List>
      </Paper>
    </Popover>
  )
}

ParticipantsList.propTypes = {
  classes: PropTypes.shape({
    list: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
  }).isRequired,
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
    })
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    participants: chatSelectors.getParticipantsList(state),
  }
}

export default connect(mapStateToProps)(withStyles(styles)(ParticipantsList))
