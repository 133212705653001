import { put, takeLatest, select, all } from 'redux-saga/effects'

import { galleryActions } from '../../gallery'
import { compassActions, compassSelectors } from '..'

function* onSelectGalleryItem() {
  const isOpen = yield select(compassSelectors.getIsOpen)
  if (isOpen) {
    yield put(compassActions.resetCompass())
  }
}

function* compassSaga() {
  yield all([takeLatest(galleryActions.onSelectGalleryItem, onSelectGalleryItem)])
}

export default compassSaga
