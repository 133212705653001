import { put, takeLatest, select, all } from 'redux-saga/effects'
import { ContentSharingType, AnswerStatuses } from '@tabeeb/enums'

import { shareContentForReviewActions } from '@tabeeb/modules/reviewSessionPagesList'
import { addPagesByFormsForMerging, getDataBySharedContentForms } from '@tabeeb/modules/contentSharings/actions'
import { getAvailableContentForms } from '@tabeeb/modules/forms/selectors'
import * as rawActions from '../actions'
import * as notificationActions from '../../notification/actions'
import * as galleryActions from '../../gallery/actions'
import { pageConverter } from '../services/pagesForSharingConverter'
import { getPagesByFolderWithNesting } from '../../gallery/selectors'
import { getFormsWithStatusesList } from '../../sessionsPage/services/getFormsWithStatusesList'
import { rawContentActions } from '../../shared/content'
import { shareContentSelectors } from '..'
import { getContentSharingForms } from '@tabeeb/modules/contentSharings/selectors'

function* addAllFormsForSharing() {
  const { sharingType } = yield select((state) => state.shareSessionPagesList.shareByFormDialogState)
  let contentForms = yield select((state) => state.forms.contentForms)

  if (sharingType === ContentSharingType.ForReview) {
    contentForms = yield select((state) => getAvailableContentForms(state))
  }

  const selectedForms = contentForms.map((form) => {
    const selectedForm = {
      ContentFormId: form.Id,
      SelectedAnswerStatuses: [AnswerStatuses.Approve, AnswerStatuses.Pending, AnswerStatuses.Reject],
    }
    return selectedForm
  })

  yield put(rawActions.setFormsForSharing(selectedForms))
}

function* addStatusToAllFormsForSharing({ payload }) {
  const status = payload
  const { sharingType } = yield select((state) => state.shareSessionPagesList.shareByFormDialogState)
  let contentForms = yield select((state) => state.forms.contentForms)

  if (sharingType === ContentSharingType.ForReview) {
    contentForms = yield select((state) => getAvailableContentForms(state))
  }

  const alreadySelectedForms = yield select(shareContentSelectors.getSelectedFormsList)
  const selectedFormIds = alreadySelectedForms.map((selectedForm) => selectedForm.ContentFormId)

  const selectedForms = contentForms.map((form) => {
    if (!selectedFormIds.includes(form.Id)) {
      const selectedForm = {
        ContentFormId: form.Id,
        SelectedAnswerStatuses: [status],
      }
      return selectedForm
    }

    const formToUpdate = alreadySelectedForms.find(
      (alreadySelectedForm) => alreadySelectedForm.ContentFormId === form.Id
    )

    if (formToUpdate.SelectedAnswerStatuses.includes(status)) {
      return formToUpdate
    }
    return {
      ContentFormId: form.Id,
      SelectedAnswerStatuses: [...formToUpdate.SelectedAnswerStatuses, status],
    }
  })

  yield put(rawActions.setFormsForSharing(selectedForms))
}

function* addFolderForSharing({ payload: { folderId } }) {
  const pages = yield select((state) => getPagesByFolderWithNesting(state, { id: folderId }))

  if (pages.length < 1) {
    yield put(notificationActions.onAddWarningNotification({ message: 'Folder is empty!' }))
    return
  }

  yield put(rawActions.addAllPagesForSharing(pages))
}

function* getDataForSharingByForms() {
  let { selectedForms, sharingType } = yield select((state) => state.shareSessionPagesList.shareByFormDialogState)
  const sharedForms = yield select(getContentSharingForms)

  if (sharingType === ContentSharingType.LinkedContentsMerging) {
    selectedForms = sharedForms
  }

  if (selectedForms.length === 0) {
    yield put(notificationActions.onAddWarningNotification({ message: 'At least one form must be selected.' }))
    return
  }

  if (sharingType === ContentSharingType.ForReview) {
    const isValidFormsList = yield select((state) => shareContentSelectors.getIsSelectedFormsListValid(state))

    if (!isValidFormsList) {
      const message =
        'Selected forms are under review by different reviewers and cannot be exported together. Please select other combination.'

      yield put(notificationActions.onAddWarningNotification({ message }))
      return
    }
  }

  const contentForms = getFormsWithStatusesList(selectedForms)
  yield put(getDataBySharedContentForms.request({ contentForms }))
}

function* getDataForSharingByFormsSuccess(action) {
  const { selectedForms, sharingType } = yield select((state) => state.shareSessionPagesList.shareByFormDialogState)
  let { pages, answers } = action.response.data

  if (pages.length < 1) {
    yield put(notificationActions.onAddWarningNotification({ message: 'There are no pages for these conditions.' }))
    return
  }

  if (sharingType === ContentSharingType.ForReview) {
    if (answers.length === 0) yield put(rawActions.setFormsForSharing([]))
    else {
      const actions = []

      selectedForms.map((selectedForm) => {
        if (!answers.some((answer) => answer.ContentFormId === selectedForm.ContentFormId)) {
          actions.push(put(rawActions.removeFormForSharing(selectedForm.ContentFormId)))
        }
      })

      yield all(actions)
    }
  }

  pages = pages.map((page) => pageConverter(page))

  if (sharingType === ContentSharingType.ForReview) {
    yield put(shareContentForReviewActions.addPagesByFormsForReviewing({ pages, selectedForms }))
  } else if (sharingType === ContentSharingType.LinkedContentsMerging) {
    yield put(addPagesByFormsForMerging(pages))
  } else {
    yield put(rawActions.addPagesByFormsForSharing(pages))
  }

  yield put(rawActions.setIsShareContentByFormDialogOpen({ isOpen: false }))
}

function* resetState() {
  yield put(rawActions.resetState())
}

function* shareSessionPagesSaga() {
  yield all([
    takeLatest(rawActions.addAllFormsForSharing, addAllFormsForSharing),
    takeLatest(rawActions.addStatusToAllFormsForSharing, addStatusToAllFormsForSharing),
    takeLatest(galleryActions.addFolderForSharing, addFolderForSharing),
    takeLatest(rawActions.getDataForSharingByForms, getDataForSharingByForms),
    takeLatest(getDataBySharedContentForms.success, getDataForSharingByFormsSuccess),
    takeLatest(rawContentActions.resetContentState, resetState),
  ])
}

export default shareSessionPagesSaga
