import { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Stage } from 'react-konva'
import { bindActionCreators } from 'redux'
import { connect, Provider, ReactReduxContext } from 'react-redux'

import { Box, withStyles } from '@material-ui/core'

import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist'

import {
  PDFSinglePageViewer,
  PDFFindController,
  PDFLinkService,
  EventBus,
  DefaultTextLayerFactory,
  DefaultAnnotationLayerFactory,
} from 'pdfjs-dist/web/pdf_viewer'

import Annotations from '@tabeeb/modules/annotations'
import { galleryActions, gallerySelectors } from '@tabeeb/modules/gallery'
import { getIsSnippingToolEnabled } from '@tabeeb/modules/playerToolbar/selectors'

import { contentViewerSelectors, contentViewerActions } from '../..'

import SnippingTool from '../SnippingTool'

import styles from './styles'

import 'pdfjs-dist/web/pdf_viewer.css'

GlobalWorkerOptions.workerPort = new Worker(new URL('pdfjs-dist/build/pdf.worker.entry', import.meta.url))

const CSS_UNITS = 96.0 / 72.0

const PdfViewer = ({
  classes,
  containerRef,
  galleryActions,
  contentViewerActions,
  width,
  height,
  pdfSearchText,
  pdfSearchSelectedPageIndex,
  pdfSearchSelectedMatchIndex,
  pdfURL,
  pdfPageNumber,
  contentScale,
  isLoading,
  isDrawing,
  isSelecting,
  isSnippingToolEnabled,
}) => {
  const [pdfView, setPdfView] = useState(null)

  const viewerRef = useRef()

  const pdfURLWithoutSas = pdfURL.split('?')[0]

  const [documentUrl, setDocumentUrl] = useState(pdfURLWithoutSas)
  const [documentLoaded, setDocumentLoaded] = useState(false)

  useEffect(() => {
    if (pdfView) {
      const { findController } = pdfView
      const pageIndex = pdfSearchSelectedPageIndex
      const matchIndex = pdfSearchSelectedMatchIndex

      if (findController.pageMatches[pageIndex] && findController.pageMatches[pageIndex].length >= matchIndex) {
        findController.selected.pageIdx = pageIndex
        findController.selected.matchIdx = matchIndex
        findController._updatePage(pageIndex)
      }
    }
  }, [pdfSearchSelectedPageIndex, pdfSearchSelectedMatchIndex])

  const standardFontsStaticPath = '/libs/pdf_viewer/fonts'

  useEffect(() => {
    setPdfView(null)
    setDocumentLoaded(false)
    setDocumentUrl(null)

    getDocument({
      url: pdfURL,
      standardFontDataUrl: standardFontsStaticPath,
    }).promise.then((pdfDocument) => {
      pdfDocument.getPage(pdfPageNumber).then((pdfPage) => {
        const eventBus = new EventBus()

        const pdfLinkService = new PDFLinkService({
          eventBus,
        })

        const pdfFindController = new PDFFindController({
          eventBus,
          linkService: pdfLinkService,
        })

        const pdfSinglePageViewer = new PDFSinglePageViewer({
          eventBus,
          viewer: viewerRef.current,
          container: containerRef.current,
          scale: 1,
          defaultViewport: pdfPage.getViewport(1),
          linkService: pdfLinkService,
          findController: pdfFindController,
          textLayerFactory: new DefaultTextLayerFactory(),
          annotationLayerFactory: new DefaultAnnotationLayerFactory(),
        })

        pdfSinglePageViewer.setDocument(pdfDocument)

        eventBus.on('pagesinit', function () {
          setDocumentLoaded(true)
          setDocumentUrl(pdfURLWithoutSas)
          pdfSinglePageViewer.currentScaleValue = 'page-fit'
          pdfSinglePageViewer.currentPageNumber = pdfPageNumber
          contentViewerActions.resetLoadingInProgress()
        })

        eventBus.on('pagechanging', () => {
          contentViewerActions.setLoadingInProgress()
        })

        eventBus.on('pagerendered', () => {
          contentViewerActions.resetLoadingInProgress()
        })

        eventBus.on('updatefindmatchescount', (data) => {
          const searchResult = {
            pdfUrl: pdfURL,
            searchText: data.source._query,
            matchIndex: data.matchesCount.current,
            matches: data.source.pageMatches,
            contents: data.source._pageContents,
          }

          galleryActions.updatePdfSearchResults(searchResult)
        })

        pdfLinkService.setDocument(pdfDocument, null)
        pdfLinkService.setViewer(pdfSinglePageViewer)

        setPdfView(pdfSinglePageViewer)
      })
    })

    return () => {
      setPdfView(null)
      setDocumentLoaded(false)
      setDocumentUrl(null)
      galleryActions.clearPdfSearchResults()
      galleryActions.setPdfSearchMatchesCount(0)
      galleryActions.clearSearchText()
    }
  }, [pdfURLWithoutSas])

  useEffect(() => {
    if (pdfView && pdfView._pages[pdfPageNumber - 1] && documentLoaded && documentUrl === pdfURLWithoutSas) {
      pdfView.currentPageNumber = pdfPageNumber
      pdfView.currentScale = contentScale / CSS_UNITS

      contentViewerActions.resetLoadingInProgress()
    }
  }, [pdfView, pdfURL, documentUrl, documentLoaded, pdfPageNumber, contentScale])

  useEffect(() => {
    if (documentLoaded && pdfView && pdfSearchText) {
      pdfView.findController.executeCommand('find', {
        caseSensitive: false,
        findPrevious: undefined,
        highlightAll: true,
        phraseSearch: true,
        query: pdfSearchText,
      })
    }
  }, [pdfView, documentLoaded, pdfSearchText])

  return (
    <Box className={classNames(classes.wrapper, 'viewer-content')} style={{ opacity: isLoading ? 0 : 1 }}>
      <Box
        ref={viewerRef}
        className={classNames(classes.viewer, {
          'text-layer-disabled': isDrawing || isSelecting || isSnippingToolEnabled,
        })}
      />
      {documentLoaded && (
        <ReactReduxContext.Consumer>
          {({ store }) => (
            <Stage
              className={classes.annotations}
              style={{ maxWidth: width * contentScale, maxHeight: height * contentScale }}
              scaleX={contentScale}
              scaleY={contentScale}
              width={width * contentScale || 1}
              height={height * contentScale || 1}
            >
              <Provider store={store}>
                <Annotations />
              </Provider>
            </Stage>
          )}
        </ReactReduxContext.Consumer>
      )}
      {documentLoaded && <SnippingTool width={width * contentScale} height={height * contentScale} />}
    </Box>
  )
}

PdfViewer.propTypes = {
  classes: PropTypes.shape({
    annotations: PropTypes.string.isRequired,
    viewer: PropTypes.string.isRequired,
    wrapper: PropTypes.string.isRequired,
  }).isRequired,
  containerRef: PropTypes.object,
  contentScale: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  pdfURL: PropTypes.string.isRequired,
  pdfPageNumber: PropTypes.number.isRequired,
  pdfSearchSelectedMatchIndex: PropTypes.number,
  pdfSearchSelectedPageIndex: PropTypes.number,
  pdfSearchText: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isDrawing: PropTypes.bool.isRequired,
  isSelecting: PropTypes.bool.isRequired,
  isSnippingToolEnabled: PropTypes.bool.isRequired,

  contentViewerActions: PropTypes.shape({
    resetLoadingInProgress: PropTypes.func.isRequired,
    setLoadingInProgress: PropTypes.func.isRequired,
  }).isRequired,
  galleryActions: PropTypes.shape({
    clearPdfSearchResults: PropTypes.func.isRequired,
    updatePdfSearchResults: PropTypes.func.isRequired,
    setPdfSearchMatchesCount: PropTypes.func.isRequired,
    clearSearchText: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  const selectedGalleryItem = gallerySelectors.getSelectedGalleryItem(state)

  return {
    width: selectedGalleryItem.width,
    height: selectedGalleryItem.height,
    pdfURL: selectedGalleryItem.pdfURL,
    pdfSearchText: gallerySelectors.getPdfSearchText(state),
    pdfSearchSelectedMatchIndex: gallerySelectors.getPdfSearchResult(state).matchIndex,
    pdfSearchSelectedPageIndex: gallerySelectors.getPdfSearchResult(state).pageIndex,
    pdfPageNumber: selectedGalleryItem.pdfPageNumber,
    isLoading: contentViewerSelectors.getIsLoading(state),
    isDrawing: state.playerToolbar.drawing.drawingState.isDrawing,
    isSelecting: state.playerToolbar.drawing.drawingState.isSelecting,
    isSnippingToolEnabled: getIsSnippingToolEnabled(state),
    contentScale: contentViewerSelectors.getContentScale(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    contentViewerActions: bindActionCreators(contentViewerActions, dispatch),
    galleryActions: bindActionCreators(galleryActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PdfViewer))
