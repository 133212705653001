import { chatSelectors } from '@tabeeb/modules/chat'
import { getActiveFileUploadsCount, getIncompleteUploadsCount } from '@tabeeb/modules/fileUploads/selectors'
import { Delete, Share, LocalOfferOutlined } from '@material-ui/icons'
import LinkIcon from '@material-ui/icons/Link'
import { getIsClassificationAnnotationsPagesTimelineOpen } from '@tabeeb/modules/classificationAnnotationsPagesTimeline/selectors'
import { TabPanel } from '../../../Enums'
import {
  showChatTab,
  showSelectedPagesForMergingTab,
  showActivitiesTab,
  showContentSharingsTab,
  showCustomVideoTab,
  showSpatialViewTab,
  showThreeDimensionalModelTab,
  showShareSessionTab,
  showStructureModelTab,
  showReviewSessionTab,
  showTowerSideProfileTab,
  showTwoDLineDrawingTab,
  showBentleyModelTab,
  showBentleyAnalyticsReportTab,
  showFileUploadsTab,
} from '../selectors'

export const TabPanelLocationGroups = {
  left: [TabPanel.Forms, TabPanel.Chat, TabPanel.Activities, TabPanel.ContentSharings],
  bottom: [
    TabPanel.Users,
    TabPanel.CustomVideo,
    TabPanel.ShareSession,
    TabPanel.ReviewSession,
    TabPanel.SpatialView,
    TabPanel.ThreeDModel,
    TabPanel.StructureModel,
    TabPanel.TowerSideProfile,
    TabPanel.TwoDLineDrawing,
    TabPanel.BentleyModel,
    TabPanel.BentleyAnalyticsReport,
    TabPanel.LinkedContentsSelectedPages,
    TabPanel.ClassificationAnnotations,
  ],
  right: [TabPanel.Gallery, TabPanel.FileUploads, TabPanel.TrashBin],
}

export const TabBubbleSelectors = {
  [TabPanel.Chat]: chatSelectors.getTotalUnreadMessagesCount,

  [TabPanel.FileUploads]: getActiveFileUploadsCount,
}

export const TabIcons = {
  [TabPanel.Forms]: <span className='icon-forms' />,
  [TabPanel.Chat]: <span className='icon-chat' />,
  [TabPanel.Activities]: <span className='icon-activities' />,
  [TabPanel.ContentSharings]: (
    <span className='material-ui-icon'>
      <Share />
    </span>
  ),

  [TabPanel.Users]: <span className='icon-users' />,
  [TabPanel.CustomVideo]: <span className='icon-custom-video' />,
  [TabPanel.SpatialView]: <span className='icon-spatial-view' />,
  [TabPanel.ThreeDModel]: <span className='icon-3d-model' />,
  [TabPanel.ShareSession]: <span className='icon-share' />,
  [TabPanel.StructureModel]: <span className='icon-structure-model' />,
  [TabPanel.ReviewSession]: <span className='icon-review' />,
  [TabPanel.TowerSideProfile]: <span className='icon-tower-side-profile' />,
  [TabPanel.TwoDLineDrawing]: <span className='icon-tower-side-profile' />,
  [TabPanel.BentleyModel]: <span className='icon-bentley-model' />,
  [TabPanel.BentleyAnalyticsReport]: <span className='icon-bentley-analytics-report' />,
  [TabPanel.LinkedContentsSelectedPages]: (
    <span className='material-ui-icon'>
      <LinkIcon />
    </span>
  ),
  [TabPanel.FileUploads]: <span className='icon-file-uploads' />,
  [TabPanel.Gallery]: <span className='icon-gallery' />,
  [TabPanel.TrashBin]: (
    <span className='material-ui-icon'>
      <Delete />
    </span>
  ),
  [TabPanel.ClassificationAnnotations]: (
    <span className='material-ui-icon'>
      <LocalOfferOutlined />
    </span>
  ),
}

export const isTabVisibleSelectors = {
  [TabPanel.Forms]: () => true,
  [TabPanel.Chat]: showChatTab,
  [TabPanel.Activities]: showActivitiesTab,
  [TabPanel.ContentSharings]: showContentSharingsTab,
  [TabPanel.Users]: () => true,
  [TabPanel.CustomVideo]: showCustomVideoTab,
  [TabPanel.SpatialView]: showSpatialViewTab,
  [TabPanel.ThreeDModel]: showThreeDimensionalModelTab,
  [TabPanel.ShareSession]: showShareSessionTab,
  [TabPanel.StructureModel]: showStructureModelTab,
  [TabPanel.ReviewSession]: showReviewSessionTab,
  [TabPanel.TowerSideProfile]: showTowerSideProfileTab,
  [TabPanel.TwoDLineDrawing]: showTwoDLineDrawingTab,
  [TabPanel.BentleyModel]: showBentleyModelTab,
  [TabPanel.BentleyAnalyticsReport]: showBentleyAnalyticsReportTab,
  [TabPanel.LinkedContentsSelectedPages]: showSelectedPagesForMergingTab,
  [TabPanel.ClassificationAnnotations]: getIsClassificationAnnotationsPagesTimelineOpen,
  [TabPanel.Gallery]: () => true,
  [TabPanel.FileUploads]: showFileUploadsTab,
  [TabPanel.TrashBin]: () => true,
}

export const isTabInProgressSelectors = {
  [TabPanel.FileUploads]: getIncompleteUploadsCount,
}
