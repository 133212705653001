import { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Popover } from '@material-ui/core'

import { isFireFoxBrowser } from '@tabeeb/modules/shared/browser'
import { getShowCertificates } from '@tabeeb/modules/appConfigState/selectors'
import { getMe } from '@tabeeb/modules/account/selectors'
import CertificatesDialog from '@tabeeb/modules/certificates/components/CertificatesDialog'
import SoundSettings from '../SoundSettings'
import VideoSettings from '../VideoSettings'
import UserOptionButton from '../UserOptionButton'
import HealthInfoDialog from '../../../modules/healthData/components/HealthInfoDialog'

import './styles.less'

const CurrentUserOptionsMenu = ({ anchorEl, isOpen, onClose, isDeviceMenuVisible, isHealthInfoOptionsVisible }) => {
  const [soundSettingOpened, setSoundSettingOpened] = useState(false)
  const [videoSettingOpened, setVideoSettingOpened] = useState(false)
  const [healthDataOpened, setHealthDataOpened] = useState(false)
  const [isCertificatesPopupOpened, setIsCertificatesPopupOpened] = useState(false)
  const currentUser = useSelector(getMe)

  const handleSoundSettingsOpen = useCallback(() => {
    setSoundSettingOpened(true)
    onClose()
  }, [])

  const handleSoundSettingsClose = useCallback(() => {
    setSoundSettingOpened(false)
  }, [])

  const handleVideoSettingsOpen = useCallback(() => {
    setVideoSettingOpened(true)
    onClose()
  }, [])

  const handleVideoSettingsClose = useCallback(() => {
    setVideoSettingOpened(false)
  }, [])

  const handleHealthDataOpen = useCallback(() => {
    setHealthDataOpened(true)
    onClose()
  }, [])

  const handleHealthDataClose = useCallback(() => {
    setHealthDataOpened(false)
  }, [])

  const onOpenCertificates = useCallback(() => {
    setIsCertificatesPopupOpened(true)
    onClose()
  }, [onClose])

  const onCloseCertificates = useCallback(() => {
    setIsCertificatesPopupOpened(false)
  }, [])

  const isShowCertificatesVisible = useSelector(getShowCertificates)
  return (
    <>
      <Popover
        className='user-options-controls-popover'
        anchorEl={anchorEl}
        open={isOpen}
        onClose={onClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <div className='user-options-controls'>
          {isDeviceMenuVisible && (
            <>
              <UserOptionButton
                onClick={handleSoundSettingsOpen}
                buttonText='Audio settings'
                disabled={isFireFoxBrowser}
              />
              <UserOptionButton onClick={handleVideoSettingsOpen} buttonText='Video settings' />
            </>
          )}
          {isHealthInfoOptionsVisible && (
            <UserOptionButton onClick={handleHealthDataOpen} buttonText='Show Health Data' />
          )}
          {isShowCertificatesVisible && <UserOptionButton onClick={onOpenCertificates} buttonText='Certificates' />}
        </div>
      </Popover>
      <SoundSettings isOpen={soundSettingOpened} anchorEl={anchorEl} onClose={handleSoundSettingsClose} />
      <VideoSettings isOpen={videoSettingOpened} anchorEl={anchorEl} onClose={handleVideoSettingsClose} />
      <HealthInfoDialog
        isOpen={healthDataOpened}
        anchorEl={anchorEl}
        onClose={handleHealthDataClose}
        userId={currentUser.IdentityGuid}
      />
      <CertificatesDialog isOpen={isCertificatesPopupOpened} onClose={onCloseCertificates} user={currentUser} />
    </>
  )
}

CurrentUserOptionsMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  isDeviceMenuVisible: PropTypes.bool.isRequired,
  isHealthInfoOptionsVisible: PropTypes.bool.isRequired,
}

export default CurrentUserOptionsMenu
