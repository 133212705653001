import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CreateOutlined } from '@material-ui/icons'

import { AnnotationType } from '@tabeeb/enums'
import { drawingActions, playerToolbarSelectors } from '@tabeeb/modules/playerToolbar'

import ToolbarButton from '../ToolbarButton'

const BUTTON_NAME = 'pen'

const PenButton = () => {
  const dispatch = useDispatch()

  const color = useSelector(playerToolbarSelectors.getSelectedColor)
  const selected = useSelector((state) => state.playerToolbar.drawing.buttonState === BUTTON_NAME)

  const onClick = () => {
    if (selected) {
      dispatch(drawingActions.onChangePressedButton({ pressedButton: '' }))
      dispatch(drawingActions.onDisableDrawing())
    } else {
      dispatch(drawingActions.onSetDrawingType(AnnotationType.Stroke))
      dispatch(drawingActions.onChangePressedButton({ pressedButton: BUTTON_NAME }))
      dispatch(drawingActions.onEnableDrawing())
    }
  }

  return (
    <ToolbarButton
      Icon={CreateOutlined}
      onClick={onClick}
      title='Add Pen annotation'
      htmlColor={selected ? color : undefined}
      selected={selected}
    />
  )
}

export default memo(PenButton)
