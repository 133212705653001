import { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import * as Yup from 'yup'
import moment from 'moment'
import { Field, Form, Formik } from 'formik'

import { KeyboardArrowDownOutlined, KeyboardArrowUpOutlined } from '@mui/icons-material'
import { Box, Button, Collapse, Grid, IconButton, Typography } from '@mui/material'

import { UserRole } from '@tabeeb/enums'
import { Help } from '@tabeeb/uikit'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { getIsInviteSettingsEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { getContentId } from '@tabeeb/shared/content/selectors'
import { FormikCheckbox } from '@tabeeb/shared/forms'

import { generatePassword } from '../../utils'
import { DurationField, PasswordField, PeriodField, UserRoleSelectField, VALIDATION_SCHEMAS } from '../../forms'

import UsersMultiSelect from '../UsersMultiSelect'

const schema = Yup.object().shape({
  Role: VALIDATION_SCHEMAS.Role,
  Users: VALIDATION_SCHEMAS.Users,
  Expiration: VALIDATION_SCHEMAS.Expiration,
  Duration: VALIDATION_SCHEMAS.Duration,
  Period: VALIDATION_SCHEMAS.Period,
  FastRegistrationEnabled: VALIDATION_SCHEMAS.FastRegistrationEnabled,
  Password: VALIDATION_SCHEMAS.OptionalPassword,
})

const initial = {
  Role: UserRole.User,
  Users: [],
  Expiration: false,
  Duration: 28,
  Period: 'day',
  FastRegistrationEnabled: false,
  Password: generatePassword(),
}

const InviteUsersForm = ({ onSendInvites }) => {
  const contentId = useSelector(getContentId)
  const currentUserId = useSelector(getCurrentUserId)
  const inviteSettingsEnabled = useSelector(getIsInviteSettingsEnabled)

  const onSubmit = async (
    { Role, Users, Expiration, Period, Duration, FastRegistrationEnabled, Password },
    { resetForm }
  ) => {
    const now = moment()

    const invites = {
      UserId: currentUserId,
      ContentId: contentId,
      StartDate: now,
      EndDate: Expiration ? now.clone().add(Duration, Period) : null,
      Role,
      Emails: Users.map((user) => user.Email),
      Password: FastRegistrationEnabled ? Password : null,
    }

    await onSendInvites(invites)

    resetForm()
  }

  const [isSettingsVisible, setIsSettingsVisible] = useState(false)

  return (
    <Formik initialValues={initial} validateOnMount validationSchema={schema} onSubmit={onSubmit} enableReinitialize>
      {(formik) => (
        <Form autoComplete='off'>
          <Box display='flex' mb={1} alignItems='flex-start'>
            <Box mr={1} minWidth={0} width='100%'>
              <Field
                name='Users'
                contentId={contentId}
                component={UsersMultiSelect}
                endAdornment={<Field name='Role' component={UserRoleSelectField} />}
              />
            </Box>
            <Button
              disabled={formik.isSubmitting || !formik.dirty || !formik.isValid}
              variant='contained'
              color='primary'
              type='submit'
            >
              Send invite
            </Button>
          </Box>
          {inviteSettingsEnabled && (
            <>
              <Box display='flex' alignItems='center' justifyContent='end'>
                <Typography color='textSecondary' variant='body2'>
                  Invite settings
                </Typography>
                <IconButton size='small' onClick={() => setIsSettingsVisible(!isSettingsVisible)}>
                  {isSettingsVisible ? <KeyboardArrowUpOutlined /> : <KeyboardArrowDownOutlined />}
                </IconButton>
              </Box>
              <Collapse in={isSettingsVisible}>
                <Grid container spacing={1}>
                  <Grid xs={3} item>
                    <DurationField name='Duration' label='Duration' disabled={!formik.values.Expiration} />
                  </Grid>
                  <Grid xs={3} item>
                    <PeriodField name='Period' label='Period' disabled={!formik.values.Expiration} />
                  </Grid>
                  <Grid xs={6} item>
                    <Field
                      name='Expiration'
                      label='Set invite expiration'
                      component={FormikCheckbox}
                      color='primary'
                      size='small'
                    />
                  </Grid>
                  <Grid xs={6} item>
                    <PasswordField name='Password' label='Password' disabled={!formik.values.FastRegistrationEnabled} />
                  </Grid>
                  <Grid xs={6} item>
                    <Field
                      name='FastRegistrationEnabled'
                      label={
                        <>
                          Set password{' '}
                          <Help text='Allows session owner to require a password for selected participant(s) to access a session. Recommended for non registered users or occasional participants.' />
                        </>
                      }
                      component={FormikCheckbox}
                      color='primary'
                      size='small'
                    />
                  </Grid>
                </Grid>
              </Collapse>
            </>
          )}
        </Form>
      )}
    </Formik>
  )
}

InviteUsersForm.propTypes = {
  onSendInvites: PropTypes.func.isRequired,
}

export default memo(InviteUsersForm)
