import { memo } from 'react'
import PropTypes from 'prop-types'

import { useLoader } from '@react-three/fiber'

import { Points, PointsMaterial, Vector3 } from 'three'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'

import { Defaults } from '../../../constants'
import { useModelRotation, useObjectFocus } from '../../../hooks'

const PlyModel = ({ id, azimuth, url, position, tilt, focus, onClick, onProgress }) => {
  const ply = useLoader(PLYLoader, url, null, onProgress)

  const points = new Points(ply, new PointsMaterial({ vertexColors: true, size: Defaults.POINT_SIZE }))

  useModelRotation({ model: points, azimuth, tilt })
  useObjectFocus(id, points, focus)

  return <primitive position={position} object={points} onClick={onClick} />
}

PlyModel.propTypes = {
  id: PropTypes.number.isRequired,
  azimuth: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.instanceOf(Vector3).isRequired,
  tilt: PropTypes.number.isRequired,
  focus: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onProgress: PropTypes.func.isRequired,
}

export default memo(PlyModel)
