import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Divider, Grid, IconButton, Typography } from '@material-ui/core'
import { Pagination } from '@mui/material'
import { OpenInNew } from '@mui/icons-material'
import { isEmpty } from 'lodash'

import UserAvatar from '@tabeeb/shared/userAvatar'
import { validateMobileUserEmail } from '@tabeeb/modules/healthData/helper'
import ContentUserOptionDialog from '@tabeeb/shared/content/components/ContentUserOptionDialog'
import { conditionalPropType } from '@tabeeb/shared/utils/helpers'
import { CertificateVerificationStatusTypes, UserTenantRole } from '@tabeeb/enums'
import { getIsUserInAnyRole } from '@tabeeb/modules/account/selectors'

import * as certificatesActions from '../../actions'
import {
  getCertificatesFilters,
  getCertificatesList,
  getCertificatesPageNumber,
  getCertificatesPageSize,
  getCertificatesSorting,
  getCertificatesTotalCount,
  getCertificateToReview,
  getCertificateToReviewOwner,
} from '../../selectors'
import CertificateSearchBar from '../CertificateSearchBar'
import CertificatesList from '../CertificatesList'
import ReviewCertificate from '../ReviewCertificate'
import ReadOnlyCertificateView from '../ReadOnlyCertificateView'

import useStyles from './styles'

const CertificatesDialog = ({ isOpen, onClose, user, isReview, certificateId }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const certificatesList = useSelector(getCertificatesList)
  const certificatesTotalCount = useSelector(getCertificatesTotalCount)
  const pageNumber = useSelector(getCertificatesPageNumber)
  const certificatesPageSize = useSelector(getCertificatesPageSize)
  const countOfPages = Math.ceil(certificatesTotalCount / certificatesPageSize)
  const [selectedCertificate, setSelectedCertificate] = useState(null)
  const selectCertificateToView = useCallback((certificateToView) => {
    setSelectedCertificate(certificateToView)
  }, [])
  const reviewAvailable = useSelector((state) => getIsUserInAnyRole(state, [UserTenantRole.TenantAdmin]))
  const certificatesFilters = useSelector(getCertificatesFilters)
  const certificatesSorting = useSelector(getCertificatesSorting)
  const certificateToReview = useSelector(getCertificateToReview)
  const certificateToReviewOwner = useSelector(getCertificateToReviewOwner)

  useEffect(() => {
    if (!isEmpty(certificateToReview)) {
      // setTimeout to display animation of dialog opening
      setTimeout(() => {
        selectCertificateToView(certificateToReview)
      }, 150)
    }
  }, [certificateToReview, selectCertificateToView])

  useEffect(() => {
    if (isReview && certificateId > 0 && user?.id) {
      dispatch(certificatesActions.getCertificateById.request(certificateId))
      dispatch(certificatesActions.getCertificateToReviewOwner(user.id))
    }
  }, [certificateId, dispatch, isReview, user?.id])

  const handleCloseDialog = useCallback(() => {
    if (isReview) {
      dispatch(certificatesActions.getCertificateById.resetState())
    }
    selectCertificateToView(null)
    onClose()
  }, [dispatch, isReview, onClose, selectCertificateToView])

  const setPageNumber = useCallback(
    (newPageNumber) => {
      dispatch(certificatesActions.setCertificatesPageNumber(newPageNumber))
      if (user.id) {
        dispatch(
          certificatesActions.getCertificatesByUserId.request({
            userId: user.id,
            pageNumber: newPageNumber,
            filters: certificatesFilters,
            sorting: certificatesSorting,
          })
        )
      } else {
        dispatch(
          certificatesActions.getMyCertificates.request({
            pageNumber: newPageNumber,
            filters: certificatesFilters,
            sorting: certificatesSorting,
          })
        )
      }
    },
    [certificatesFilters, certificatesSorting, dispatch, user.id]
  )

  return (
    <ContentUserOptionDialog
      open={isOpen}
      onClose={handleCloseDialog}
      title='Certificate'
      leftPart={
        <Grid item container direction='column' spacing={1}>
          <Grid item className={classes.avatarGrid}>
            {!isEmpty(certificateToReviewOwner) && (
              <UserAvatar user={certificateToReviewOwner} className={classes.avatarBlock} />
            )}
          </Grid>
          {!validateMobileUserEmail(user.email) && (
            <Grid item className={classes.flexCenteredGrid}>
              <Typography>
                <strong>Email: </strong>
                {user.email}
              </Typography>
              <IconButton color='primary' size='small' title='Write an email' href={`mailto:${user.email}`}>
                <OpenInNew size='small' />
              </IconButton>
            </Grid>
          )}
          <Grid item className={classes.grid}>
            <Divider />
          </Grid>
          {!isReview && isOpen && (
            <Grid item className={classes.grid}>
              <CertificateSearchBar userId={user.id} />
            </Grid>
          )}
          <Grid item className={classes.grid}>
            {!isEmpty(user) && (
              <CertificatesList
                certificates={isReview ? (certificateToReview.Id ? [certificateToReview] : []) : certificatesList}
                direction='column'
                userId={user.id}
                setCertificateToView={selectCertificateToView}
                readOnly
              />
            )}
          </Grid>
          {!isReview && certificatesTotalCount > certificatesPageSize && (
            <Grid item className={classes.grid}>
              <Pagination
                className={classes.paginationUl}
                shape='rounded'
                color='primary'
                count={countOfPages || 1}
                disabled={countOfPages === 0}
                page={pageNumber}
                onChange={(event, page) => setPageNumber(page)}
              />
            </Grid>
          )}
        </Grid>
      }
      rightPart={
        isReview ||
        (reviewAvailable &&
          selectedCertificate?.VerificationStatusId === CertificateVerificationStatusTypes.Pending) ? (
          <ReviewCertificate certificate={selectedCertificate} onClose={handleCloseDialog} />
        ) : (
          <ReadOnlyCertificateView certificate={selectedCertificate} />
        )
      }
      showRightPart={Boolean(selectedCertificate)}
    />
  )
}

CertificatesDialog.defaultProps = {
  isReview: false,
}

CertificatesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
  }),
  isReview: PropTypes.bool,
  certificateId: conditionalPropType(
    (props, propName) => props.isReview === true && typeof props[propName] !== 'number'
  ),
}

export default CertificatesDialog
