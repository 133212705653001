import PropTypes from 'prop-types'

import { Checkbox, Divider, FormControlLabel, Menu, MenuItem, Radio } from '@mui/material'
import { CertificateVerificationStatusTypes } from '@tabeeb/enums'
import { CertificatesPopoverSortingOptions } from '@tabeeb/modules/certificates/constants'

import useStyles from './styles'
import { conditionalPropType } from '@tabeeb/shared/utils/helpers'

const popoverCertificateFilteringOptions = [
  { id: CertificateVerificationStatusTypes.Expired, displayName: 'Expired' },
  { id: CertificateVerificationStatusTypes.Rejected, displayName: 'Rejected' },
  { id: CertificateVerificationStatusTypes.Verified, displayName: 'Approved' },
  { id: CertificateVerificationStatusTypes.Pending, displayName: 'Pending' },
]

const popoverSortingOptions = [
  { id: CertificatesPopoverSortingOptions.LastUpdated, displayName: 'Last updated' },
  { id: CertificatesPopoverSortingOptions.LastCreated, displayName: 'Last created' },
]

const CertificateMenuFilter = ({
  anchorEl,
  open,
  handleClose,
  hasSorting,
  hasFilters,
  updateSorting,
  certificatesSorting,
  verificationTypes,
  updateFilters,
}) => {
  const classes = useStyles()
  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      {hasSorting &&
        popoverSortingOptions.map((sortingOption) => (
          <MenuItem key={sortingOption.id} className={classes.menuItem} onClick={() => updateSorting(sortingOption.id)}>
            <FormControlLabel
              onClick={(e) => e.preventDefault()}
              sx={{ mb: 0 }}
              control={<Radio checked={sortingOption.id === certificatesSorting.Column} sx={{ p: 0.5, pr: 1 }} />}
              label={sortingOption.displayName}
            />
          </MenuItem>
        ))}
      {hasSorting && <Divider className={classes.divider} sx={{ border: 0 }} />}
      {hasFilters &&
        popoverCertificateFilteringOptions.map((filteringOption) => (
          <MenuItem
            key={filteringOption.id}
            className={classes.menuItem}
            onClick={() => updateFilters(filteringOption.id)}
          >
            <FormControlLabel
              onClick={(e) => e.preventDefault()}
              sx={{ mb: 0 }}
              control={<Checkbox checked={verificationTypes.includes(filteringOption.id)} sx={{ p: 0.5, pr: 1 }} />}
              label={filteringOption.displayName}
            />
          </MenuItem>
        ))}
    </Menu>
  )
}

CertificateMenuFilter.defaultProps = {
  hasSorting: true,
  hasFilters: true,
}

CertificateMenuFilter.propTypes = {
  anchorEl: PropTypes.instanceOf(HTMLElement),
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  hasSorting: PropTypes.bool,
  hasFilters: PropTypes.bool,
  updateSorting: conditionalPropType(
    (props, propName) => props.hasSorting === true && typeof props[propName] !== 'function'
  ),
  certificatesSorting: PropTypes.any,
  updateFilters: conditionalPropType(
    (props, propName) => props.hasFilters === true && typeof props[propName] !== 'function'
  ),
  verificationTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default CertificateMenuFilter
