import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const initialState = []

export default handleActions(
  {
    [rawActions.getDashboardsSuccess]: (state, action) => {
      return [...action.response.data]
    },
  },
  initialState
)
