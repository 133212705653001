import { Loader } from 'three'
import { Potree, PointSizeType } from 'potree-core'

import { Defaults } from '../constants'
import { getToken } from '../utils/token'

const potree = new Potree()

class PotreeLoader extends Loader {
  load(url, onLoad, onProgress, onError) {
    const { origin, pathname } = new URL(url)
    const base = origin + pathname.slice(0, pathname.lastIndexOf('/'))
    const rootFile = pathname.split('/').pop()

    onProgress({ loaded: 0, total: 100 })

    return getToken({ pageId: this.pageId })
      .then(
        ({
          response: {
            data: { SasRead: token },
          },
        }) => {
          potree.pointBudget = Defaults.POINT_BUDGET
          potree.maxNumNodesLoading = 1

          return potree.loadPointCloud(rootFile, (file) => `${base}/${file}${token}`)
        }
      )
      .then((pco) => {
        const offset = pco.position.clone()

        // eslint-disable-next-line no-param-reassign
        pco.pointSizeType = PointSizeType.FIXED
        pco.position.set(0, 0, 0)
        pco.setRotationFromEuler(Defaults.ROTATION)
        pco.translateX(offset.x)
        pco.translateY(offset.y)
        pco.translateZ(offset.z)

        onProgress({ loaded: 100, total: 100 })
        onLoad(pco)
      })
      .catch((e) => onError(e))
  }
}

export default PotreeLoader
