import { put, takeLatest, all } from 'redux-saga/effects'
import * as notificationActions from '../../notification/actions'
import getErrorMessageFromResponse from '../../shared/utils/getErrorMessageFromResponse'

import * as aiActions from '../actions'

function* onAddAiClassFailed({ response }) {
  const message = getErrorMessageFromResponse(response.data)
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* addAiObject() {
  yield all([takeLatest(aiActions.addAiClassFailed, onAddAiClassFailed)])
}

export default addAiObject
