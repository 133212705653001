const home = '/home'
const ai = `/ai`
const aiExpressions = `/ai/expressions`
const aiExpressionsCreate = `/ai/expressions/create`
const aiExpressionsGenerate = `/ai/expressions/generate`
const aiExpressionsEdit = `/ai/expressions/:id`
const aiExpressionsTest = `/ai/expressions/:id/test`
const aiModel = `/ai/models/cv/:id`
const aiModelEdit = `/ai/models/cv/:id/edit`
const aiModels = `/ai/models`
const nlpModel = `/ai/models/nlp/:id`
const nlpModelEdit = `/ai/models/nlp/:id/edit`
const vqaModel = `/ai/models/vqa/:id`
const vqaModelEdit = `/ai/models/vqa/:id/edit`
const allUsers = '/users'
const reports = `/reports`
const generatedReports = `${reports}/generated`
const allForms = `${reports}/all-forms`
const tenantSessions = `${reports}/tenant-sessions`
const biDashboardsList = `${reports}/biDashboards`
const biDashboard = `${reports}/biDashboards/:id`
const announcementsBase = `/announcements`
const announcementsManagement = `${announcementsBase}/manage`
const announcementCreateSystem = `${announcementsBase}/create/system`
const announcementCreateTenant = `${announcementsBase}/create/tenant`
const announcementEdit = `${announcementsBase}/:id/edit`
const calendarBase = '/calendar'
const calendar =
  '/calendar/:view(day|week|month|year)/:year([1-2][0-9][0-9][0-9])/:month([1-9]|1[012])/:day([1-9]|[12]\\d|3[01])'
const sessionsFolder = `/sessions-folder/:folderId`
const cvClasses = '/ai/cv-classes'
const analytics = `/analytics`
const answerHistory = `/answerHistory`
const billingSettings = '/settings'
const billingReport = '/admin/billing/report'
const createForm = '/createForm'
const deletedSessions = '/home/deleted'
const devices = '/devices'
const publishedSessions = '/published-sessions'
const tenants = '/admin/tenants'
const forms = `/forms`
const showForm = `/showForm`
const updateForm = `/updateForm`
const geosearch = `/geosearch`
const tracking = `/tracking`
const termsOfUse = `/termsOfUse`
const threeDimensionalModels = '/3d-models'
const privacyPolicy = `/privacyPolicy`
const appPrivacyPolicy = '/app-privacy-policy'
const appTermsOfUse = '/app-terms-of-use'
const tenantTerms = `/tenantTerms/:subdomain`
const tenantConfig = `/tenantConfig`
const users = `/users`
const userSettings = `/usersettings`
const createProfile = `/createProfile`
const joinSessionViaInvite = `/session/join`
const login = `/login`
const modelChaining = '/ai/model-chaining'
const createCvModels = '/ai/models/cv/create'
const createCvModelsCustomVision = `${createCvModels}/custom-vision`
const createCvModelsExternal = `${createCvModels}/general`
const createCvModelsHuggingFace = `${createCvModels}/hugging-face`
const createCvModelsRoboflow = `${createCvModels}/roboflow`
const createNlpModel = '/ai/models/nlp/create'
const createVqaModel = '/ai/models/vqa/create'
const mySessions = '/home'
const allSessions = '/all-sessions'
const allDeletedSessions = '/all-sessions/deleted'
const aiProject = '/ai/projects/:id'
const aiProjects = '/ai/projects'
const session = `/whiteboard/plugin`
const signInRedirectCallback = '/auth/redirect'
const signInSilentCallback = '/auth/refresh'
const signOutRedirectCallback = '/auth/signoutCallback'
const vqaPolicies = '/ai/vqa-policies'
const switchTenant = '/switch-tenant'
const legacyRegister = '/register'
const legacyConfirmEmail = '/confirm-email'
const customIframe = '/iframe'
const root = '/'

export const unauthRoutes = [createProfile, joinSessionViaInvite, login]

export default {
  ai,
  aiExpressions,
  aiExpressionsCreate,
  aiExpressionsGenerate,
  aiExpressionsEdit,
  aiExpressionsTest,
  aiModel,
  aiModelEdit,
  aiModels,
  aiProject,
  aiProjects,
  allForms,
  allUsers,
  allSessions,
  allDeletedSessions,
  analytics,
  announcementCreateSystem,
  announcementCreateTenant,
  announcementEdit,
  announcementsBase,
  announcementsManagement,
  answerHistory,
  appPrivacyPolicy,
  appTermsOfUse,
  biDashboard,
  biDashboardsList,
  billingReport,
  billingSettings,
  calendar,
  calendarBase,
  createCvModels,
  createCvModelsCustomVision,
  createCvModelsExternal,
  createCvModelsHuggingFace,
  sessionsFolder,
  createCvModelsRoboflow,
  createForm,
  createNlpModel,
  createProfile,
  createVqaModel,
  customIframe,
  cvClasses,
  deletedSessions,
  devices,
  forms,
  geosearch,
  home,
  joinSessionViaInvite,
  legacyConfirmEmail,
  legacyRegister,
  login,
  modelChaining,
  nlpModel,
  nlpModelEdit,
  privacyPolicy,
  publishedSessions,
  reports,
  generatedReports,
  root,
  session,
  showForm,
  signInRedirectCallback,
  signInSilentCallback,
  signOutRedirectCallback,
  switchTenant,
  tenantConfig,
  tenants,
  tenantSessions,
  tenantTerms,
  termsOfUse,
  threeDimensionalModels,
  tracking,
  updateForm,
  users,
  userSettings,
  vqaModel,
  vqaModelEdit,
  vqaPolicies,
}
