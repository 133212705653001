export const popoverTypes = {
  Filter: 1,
  Grouping: 2,
}

export const sortingDirections = {
  unset: 0,
  asc: 1,
  desc: 2,
}

export const filterOperators = {
  unset: 0,
  equals: 1,
  contains: 2,
}

export const groupingOperators = {
  unset: 0,
  max: 1,
  count: 2,
}
