import { memo, useCallback } from 'react'
import PropTypes from 'prop-types'

import { useThree } from '@react-three/fiber'

import { Box } from '@mui/material'

import HeadingLabel from './HeadingLabel'
import Dial from './Dial'
import NavigationArrow from './NavigationArrow'
import NorthLabel from './NorthLabel'
import Portal from '../Portal'

import { useCameraAzimuth } from '../../hooks'

const Compass = ({ container }) => {
  const controls = useThree((state) => state.controls)
  const color = '#ffffff'

  const azimuth = useCameraAzimuth(10)
  const heading = (180 + azimuth) % 360

  const onReset = useCallback(() => {
    controls.rotateAzimuthTo(0, true)
  }, [controls])

  return (
    <Portal container={container}>
      <Box position='absolute' bottom={0} right={0} p={1}>
        <Box
          color={color}
          display='flex'
          alignItems='center'
          p={1}
          pt={0.5}
          borderRadius={1}
          bgcolor='rgba(28, 28, 28, 0.4)'
        >
          <Box display='flex' justifyContent='end' pr={1} pt={2} minWidth={55}>
            <HeadingLabel heading={heading} />
          </Box>
          <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            <NorthLabel />
            <Box position='relative'>
              <Dial color={color} />
              <Box position='absolute' top='50%' left='50%' sx={{ transform: 'translate(-50%, -50%)' }}>
                <NavigationArrow heading={heading} onReset={onReset} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Portal>
  )
}

Compass.propTypes = {
  container: PropTypes.instanceOf(Element).isRequired,
}

export default memo(Compass)
