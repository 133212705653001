import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'

import './styles.less'

import { FormInput } from 'modules/shared/forms'
import checkUrlIsValid from 'modules/shared/utils/validators/urlValidator'
import { Dialog, DialogActions, Button, Divider, DialogTitle, DialogContent } from '@material-ui/core'
import { ADD_VIDEO_FROM_URL_DIALOG_FORM } from '../../constants'
import CircularProgressBar from '../../../presentation/components/CircularProgressBar'

const validate = (values) => {
  const errors = {}

  if (!values.url) {
    errors.url = 'Please provide a valid video URL.'
  } else if (!checkUrlIsValid(values.url)) {
    errors.url = 'No video was found. Please provide a valid video URL.'
  } else if (values.url.indexOf('vimeo') >= 0) {
    const id = values.url.split('/').pop()
    if (!id || isNaN(id)) {
      errors.url = 'No video was found. Please provide a valid VIMEO video URL.'
    }
  }

  return errors
}

const AddVideoFromUrlDialog = ({
  displayAddVideoDialog,
  handleСloseAddVideoDialog,
  handleSubmit,
  reset,
  submitting,
  pristine,
  invalid,
}) => {
  return (
    <Dialog open={displayAddVideoDialog}>
      <DialogTitle>Add Video By URL</DialogTitle>
      <Divider />
      <DialogContent className='gallery-add-video-from-url-dialog'>
        <form onSubmit={handleSubmit}>
          <Field name='url' label='Please enter the URL for the video' type='text' component={FormInput} />
        </form>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          onClick={() => {
            handleСloseAddVideoDialog()
            reset()
          }}
        >
          Cancel
        </Button>
        <Button disabled={pristine || submitting || invalid} variant='outlined' color='primary' onClick={handleSubmit}>
          {submitting && <CircularProgressBar size={25} thickness={2} />}
          Add Video
        </Button>
      </DialogActions>
    </Dialog>
  )
}

AddVideoFromUrlDialog.propTypes = {
  displayAddVideoDialog: PropTypes.bool.isRequired,
  handleСloseAddVideoDialog: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
}

export default reduxForm({
  form: ADD_VIDEO_FROM_URL_DIALOG_FORM,
  validate,
})(AddVideoFromUrlDialog)
