import { contentTrashBinActions } from '..'
import { dialogItems } from './dialogItems'
import * as contentTrashBinSelectors from '../selectors'

const deleteMenuItems = [
  {
    title: 'Delete Selected',
    disabledSelector: contentTrashBinSelectors.makeGetIsNoSelectedPage,
    isVisibleSelector: () => () => true,
    action: () => contentTrashBinActions.setContentTrashBinDialogItem(dialogItems.deleteSelected),
  },
  {
    title: 'Delete All',
    disabledSelector: () => () => false,
    isVisibleSelector: contentTrashBinSelectors.makeGetIsFolderNotSelected,
    action: () => contentTrashBinActions.setContentTrashBinDialogItem(dialogItems.deleteAll),
  },
  {
    title: 'Delete Folder',
    disabledSelector: () => () => false,
    isVisibleSelector: contentTrashBinSelectors.makeGetSelectedFolderId,
    action: () => contentTrashBinActions.setContentTrashBinDialogItem(dialogItems.deleteFolder),
  },
]

export default deleteMenuItems
