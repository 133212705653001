import { useCallback, useEffect, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import _ from 'lodash'

import { useApiRequest } from '@tabeeb/shared/utils/hooks'
import { callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import { onAddErrorNotification } from '@tabeeb/modules/notification/actions'

import { addUsersToFolder, editUsersInFolder, getUsersInFolder, leaveFolder } from '../actions'

const EMPTY_LIST = []

export default ({ folderId }) => {
  const dispatch = useDispatch()

  const payload = useMemo(() => ({ folderId }), [folderId])

  const [users, setUsers] = useState(EMPTY_LIST)

  const {
    loading,
    loaded,
    response: fetchedUsers,
  } = useApiRequest({
    defaultResponse: EMPTY_LIST,
    payload,
    enabled: Boolean(folderId),
    request: getUsersInFolder.request,
  })

  useEffect(() => {
    setUsers(fetchedUsers)
  }, [fetchedUsers])

  const onAdd = useCallback(
    async ({ FolderId, Users, Role }) => {
      try {
        await callApiAsync(addUsersToFolder.request({ FolderId, UserIds: Users.map((user) => user.Id), Role }))

        setUsers((prevUsers) =>
          _.uniqBy([...Users.map((user) => ({ ...user, Role })), ...prevUsers], (user) => user.Id)
        )
      } catch {
        dispatch(onAddErrorNotification({ message: 'Failed to add users' }))
      }
    },
    [dispatch]
  )

  const onRemove = useCallback(
    (user) => {
      dispatch(leaveFolder.request({ FolderId: folderId, UserId: user.Id }))

      setUsers((prevUsers) => prevUsers.filter((prevUser) => prevUser.Id !== user.Id))
    },
    [dispatch, folderId]
  )

  const onChangeRole = useCallback(
    (user, role) => {
      dispatch(editUsersInFolder.request({ FolderId: folderId, User: { ...user, Role: role } }))

      setUsers((prevUsers) =>
        prevUsers.map((prevUser) => (prevUser.Id === user.Id ? { ...user, Role: role } : prevUser))
      )
    },
    [dispatch, folderId]
  )

  return { loaded, loading, users, onAdd, onRemove, onChangeRole }
}
