import { createSelector } from 'reselect'
import { getIsControlWithoutOptions } from '@tabeeb/modules/createFormPage/helpers/controlTypesHelper'
import { getFormTitle, getFormTitleLowerCase } from '@tabeeb/modules/appConfigState/selectors'
import {
  FormItemType,
  MobileFormViewMode,
  FormControlType,
  ConditionTypes,
  SendEmailConditionType,
} from '@tabeeb/enums'
import { allQuestionTypes } from '../services/allQuestionsTypes'

export const selectPageMode = (state) => state.createFormPage.mode

export const getControlsList = (state) => state.createFormPage.createForm.controlsList
export const getTabsList = (state) => state.createFormPage.createForm.tabsList
export const getSelectedAIObjectsIds = (state) => state.createFormPage.selectedAIObjectsIds
export const getAvailableAIObjects = (state) => state.createFormPage.availableAIObjects
export const getIsAiEnabled = (state) => state.appConfigState.showMenuAI
export const getFormViewMode = (state) => state.createFormPage.createForm.formViewMode
export const getIsFormViewModeSwitchVisible = (state) => state.appConfigState.isMobileFormViewSwitchEnabled
export const getErrorsList = (state) => state.createFormPage.inputErrorsList
export const getFormName = (state) => state.createFormPage.createForm.formName
export const getIsFormActionVisible = (state) => state.appConfigState.isFormActionEnabled
export const getNlpModels = (state) => state.createFormPage.nlpModels
export const getSelectedNlpModelId = (state) => state.createFormPage.createForm.selectedNLPModelId
export const getIsFormSignatureChecked = (state) => state.createFormPage.createForm.isSignatureEnabled
export const getIsSignatureEnabled = (state) => state.createFormPage.createForm.isSignatureEnabled

export const getIsControlDisabled = createSelector(
  [getControlsList, getTabsList, getAvailableAIObjects, getFormViewMode, (_, { control }) => control],
  (controlsList, tabsList, availableAIObjects, formViewMode, control) => {
    let isDisabled = false

    const counterAdded = controlsList && controlsList.some((control) => control.FormItemType === FormItemType.Counter)

    if (control === 'Counter' && (!availableAIObjects || availableAIObjects.length === 0 || tabsList.length > 0)) {
      isDisabled = true
    } else if (control === 'Tabs' && (counterAdded || formViewMode === MobileFormViewMode.List)) {
      isDisabled = true
    }

    return isDisabled
  }
)

export const getIsControlHidden = createSelector(
  [getIsAiEnabled, getIsFormActionVisible, (_, { control }) => control],
  (showMenuAI, isFormActionVisible, control) => {
    if ((!showMenuAI && control === 'Counter') || (!isFormActionVisible && control === 'Action')) {
      return true
    }
    return false
  }
)

export const getIsFormViewSwitchChecked = createSelector([getFormViewMode], (formViewMode) => {
  return formViewMode === MobileFormViewMode.List
})

export const getIsFormViewSwitchDisabled = createSelector([getTabsList], (tabsList) => {
  const isTabAdded = tabsList && tabsList.length > 0
  return isTabAdded
})

export const getIsFormViewSwitchVisible = createSelector([getIsFormViewModeSwitchVisible], (isVisible) => {
  if (typeof isVisible === 'undefined') isVisible = false
  return isVisible
})

export const getAvailableQuestionTypes = createSelector(
  [getControlsList, (_, { control }) => control],
  (controlsList, control) => {
    const isTemperatureControlAlreadyExists = controlsList.some((c) => c.Type === FormControlType.BodyTemperature)
    const isTemperatureControl = control.Type === FormControlType.BodyTemperature

    const filteredQuestionTypes =
      isTemperatureControlAlreadyExists && !isTemperatureControl
        ? allQuestionTypes.filter((qType) => qType.type !== FormControlType.BodyTemperature)
        : allQuestionTypes

    return filteredQuestionTypes
  }
)

export const getFormBuilderErrorMessage = createSelector(
  [getControlsList, getTabsList, getErrorsList, getFormName, getFormTitle, getFormTitleLowerCase],
  (controlsList, tabsList, errorsList, formName, formTitle, formTitleLower) => {
    let errorMessage = null
    const errorsCount = errorsList.length

    controlsList.some((control) => {
      const isControlWithoutOptions = getIsControlWithoutOptions(control.ControlType)
      let conditions

      if (isControlWithoutOptions) {
        conditions = control.Conditions && control.Conditions.filter((cond) => cond.Type === ConditionTypes.SendEmail)
      } else {
        conditions =
          control.Options &&
          control.Options.filter((opt) => opt.Conditions && opt.Conditions.length !== 0).map(
            (opt) => opt.Conditions.filter((cond) => cond.Type === ConditionTypes.SendEmail)[0]
          )
      }

      if (conditions) {
        const specificUsersEmailConditions = conditions.map(
          (cond) =>
            cond &&
            cond.ConditionsRecipient.filter((recipient) => recipient.Type === SendEmailConditionType.SpecificUsers)[0]
        )
        if (specificUsersEmailConditions.some((cond) => cond && cond.Recipients.length === 0)) {
          errorMessage = `Please add recipients to the email conditions of the "${control.Label}" control`
          return true
        }
      }

      return false
    })

    if (!formName) errorMessage = `${formTitle} name is required`
    else if (tabsList.length > 0 && controlsList.length === 0)
      errorMessage = `Cannot save ${formTitleLower} with only tab components`
    else if (tabsList.length === 0 && controlsList.length === 0)
      errorMessage = `Cannot save ${formTitleLower} without any components`
    else if (errorsCount > 0) {
      errorMessage =
        errorsCount === 1
          ? `There is one invalid input field in the ${formTitleLower}`
          : `There are several invalid input fields in the ${formTitleLower}`
    } else {
      const isEmptyInfo = controlsList.some((control) => control.Info && !control.Info.Description && !control.Info.Url)

      if (isEmptyInfo) {
        errorMessage = `Either a description or a file must be present in ${formTitleLower} control info.`
      }
    }

    return errorMessage
  }
)

export const getControlErrors = createSelector([getErrorsList, (_, { control }) => control], (errorsList, control) => {
  const controlErrors = errorsList.filter(
    (error) => error.ControlType === control.FormItemType && error.ControlId === control.TempId
  )

  return controlErrors
})

export const getSelectedNlpModel = createSelector(
  [getNlpModels, getSelectedNlpModelId],
  (nlpModels, selectedNLPModelId) => {
    const page = nlpModels.find((nlpModels) => nlpModels.Id === selectedNLPModelId)
    return page
  }
)

export const selectAdvancedFormSettings = (state) => state.createFormPage.createForm.settings.advanced
