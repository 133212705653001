import PropTypes from 'prop-types'
import { Grid, TextField } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'
import ImageViewer from 'react-simple-image-viewer'

import { useDialogState } from '@tabeeb/shared/utils/hooks'

import useStyles from './styles'

const ReadOnlyCertificateView = ({ certificate }) => {
  const classes = useStyles()
  const [openImageViewer, onOpenImageViewer, onCloseImageViewer] = useDialogState()
  return (
    <>
      <Grid container wrap='nowrap' direction='column' spacing={1}>
        {certificate?.PictureUrl && (
          <Grid item>
            <input
              alt='Certificate image preview'
              title='Open image'
              className={classes.imagePreview}
              type='image'
              src={certificate.PictureUrl}
              onClick={onOpenImageViewer}
            />
          </Grid>
        )}
        {certificate?.Title && (
          <Grid item>
            <TextField
              variant='outlined'
              className={classes.fullwidthField}
              color='primary'
              label='Title'
              InputProps={{ readOnly: true }}
              value={certificate.Title}
            />
          </Grid>
        )}
        {certificate?.Authority && (
          <Grid item>
            <TextField
              variant='outlined'
              className={classes.fullwidthField}
              color='primary'
              label='Authority'
              InputProps={{ readOnly: true }}
              value={certificate.Authority}
            />
          </Grid>
        )}
        {(certificate?.FirstName || certificate?.LastName) && (
          <Grid item container wrap='nowrap' direction='row' spacing={2}>
            {certificate?.FirstName && (
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  className={classes.fullwidthField}
                  color='primary'
                  label='FirstName'
                  InputProps={{ readOnly: true }}
                  value={certificate.FirstName}
                />
              </Grid>
            )}
            {certificate?.LastName && (
              <Grid item xs={6}>
                <TextField
                  variant='outlined'
                  className={classes.fullwidthField}
                  color='primary'
                  label='LastName'
                  InputProps={{ readOnly: true }}
                  value={certificate.LastName}
                />
              </Grid>
            )}
          </Grid>
        )}
        {(Boolean(certificate?.IssueDate) || Boolean(certificate?.ExpirationDate)) && (
          <Grid item container wrap='nowrap' direction='row' spacing={2}>
            {Boolean(certificate?.IssueDate) && (
              <Grid item xs={!certificate?.ExpirationDate ? 12 : 6}>
                <KeyboardDatePicker
                  readOnly
                  InputProps={{ readOnly: true }}
                  className={classes.fullwidthField}
                  id='date-of-birth-picker'
                  inputVariant='outlined'
                  format='MM/dd/yyyy'
                  label='Start date'
                  value={certificate?.IssueDate}
                  KeyboardButtonProps={{ 'aria-label': 'change date', disabled: true, style: { display: 'none' } }}
                />
              </Grid>
            )}
            {Boolean(certificate?.ExpirationDate) && (
              <Grid item xs={!certificate?.IssueDate ? 12 : 6}>
                <KeyboardDatePicker
                  readOnly
                  InputProps={{ readOnly: true }}
                  className={classes.fullwidthField}
                  id='date-of-birth-picker'
                  inputVariant='outlined'
                  format='MM/dd/yyyy'
                  label='End date'
                  value={certificate.ExpirationDate}
                  KeyboardButtonProps={{ 'aria-label': 'change date', disabled: true, style: { display: 'none' } }}
                  title='The required date format is MM/DD/YYYY'
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {openImageViewer && (
        <ImageViewer
          src={[`${certificate.PictureUrl}`]}
          backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.9)', zIndex: 1110 }}
          closeOnClickOutside
          onClose={onCloseImageViewer}
        />
      )}
    </>
  )
}

ReadOnlyCertificateView.propTypes = {
  certificate: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IsDeleted: PropTypes.bool.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    UserId: PropTypes.number.isRequired,
    VerificationStatusId: PropTypes.number.isRequired,
    Title: PropTypes.string.isRequired,
    Authority: PropTypes.string,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    IssueDate: PropTypes.string,
    ExpirationDate: PropTypes.string,
    Comment: PropTypes.string,
    PictureUrl: PropTypes.string.isRequired,
  }),
}

export default ReadOnlyCertificateView
