import { handleActions, combineActions } from 'redux-actions'
import { accountActions } from '@tabeeb/modules/account'
import * as actions from '../actions'

const defaultState = []

export default handleActions(
  {
    [actions.addCallNotification](state, action) {
      const notification = action.payload
      const mappedNotification = {
        presenterId: notification.PresenterId,
        sessionId: notification.SessionId,
        avatarUrl: notification.AvatarUrl,
        userName: notification.UserName,
        sessionName: notification.SessionName,
      }
      return [...state, mappedNotification]
    },
    [combineActions(actions.hideCallNotification, actions.acceptCallNotification, actions.declineCallNotification)](
      state,
      action
    ) {
      const hiddenSessionId = action.payload
      return state.filter((notification) => notification.sessionId !== hiddenSessionId)
    },
    [actions.showSystemCallNotification](state, action) {
      const { sessionId } = action.payload

      return state.map((notification) => {
        if (notification.sessionId === sessionId) notification.isSystemNotified = true
        return notification
      })
    },
    [accountActions.onLogout](state, action) {
      return defaultState
    },
  },
  defaultState
)
