import { memo, useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { DoubleSide, Vector3 } from 'three'

import { getColorOpacity } from '../../../utils/color'
import { triangulate } from '../../../utils/triangulation'

const Plane = ({ points, color }) => {
  const ref = useRef()

  useLayoutEffect(() => {
    ref.current.setFromPoints(points)
    ref.current.setIndex(triangulate(points))
  }, [points])

  return (
    <mesh>
      <meshBasicMaterial
        color={color}
        depthTest={false}
        depthWrite={false}
        opacity={0.5 * getColorOpacity(color)}
        side={DoubleSide}
        transparent
      />
      <shapeGeometry ref={ref} attach='geometry' />
    </mesh>
  )
}

Plane.propTypes = {
  points: PropTypes.arrayOf(PropTypes.instanceOf(Vector3)).isRequired,
  color: PropTypes.string.isRequired,
}

export default memo(Plane)
