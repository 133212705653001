import { useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroller'
import { Box, Divider } from '@material-ui/core'

import CircularProgressBar from '@tabeeb/shared/tabeebCircularProgressBar'

import Notification from '../Notification'
import NoNotifications from '../NoNotifications'
import { loadNotifications } from '../../actions'
import { getFilteredNotifications, getIsLoading, getHasMoreNotifications } from '../../selectors'

const NotificationsList = ({ onStartCertificateReview }) => {
  const dispatch = useDispatch()
  const containerRef = useRef()

  const notificationsList = useSelector(getFilteredNotifications)
  const isLoading = useSelector(getIsLoading)
  const hasMoreNotifications = useSelector(getHasMoreNotifications)

  const handleLoadNotifications = useCallback(() => {
    if (hasMoreNotifications) {
      dispatch(loadNotifications())
    }
  }, [dispatch, hasMoreNotifications])

  const isListEmpty = notificationsList.length === 0
  return (
    <>
      <Divider />
      <Box ref={containerRef} style={{ overflow: 'auto', maxHeight: 512 }}>
        <InfiniteScroll
          pageStart={0}
          initialLoad={false}
          loadMore={handleLoadNotifications}
          hasMore={hasMoreNotifications}
          useWindow={false}
          threshold={400}
          getScrollParent={() => containerRef.current}
        >
          {notificationsList.map((notification) => (
            <Notification
              key={`${notification.Id}-${notification.Type}-${notification.CertificateId}`}
              notification={notification}
              containmentRef={containerRef}
              onStartCertificateReview={onStartCertificateReview}
            />
          ))}
        </InfiniteScroll>
      </Box>
      {isListEmpty && !isLoading && <NoNotifications />}
      {isLoading && <CircularProgressBar size={40} thickness={6} />}
    </>
  )
}

NotificationsList.propTypes = {
  onStartCertificateReview: PropTypes.func.isRequired,
}

export default NotificationsList
