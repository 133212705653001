import * as constantsModule from '../state/constantsModule'
import * as restRequests from './restRequests'

import { PageType, ContentType, AssetType } from '../Enums'

export function addDrawingPage(paramsObj) {
  const { contentId, documentId, insertionIndex } = paramsObj

  const apiUrl = constantsModule.getApiUrl()
  const page = {
    contentId,
    documentId,
    name: '',
    description: '',
    order: insertionIndex,
    type: PageType.Normal,
    width: 1000,
    height: 750,
  }

  return restRequests.postRequest(`${apiUrl}api/pages`, page)
}

export function renamePage(paramsObj) {
  const { pageId, name } = paramsObj
  const apiUrl = constantsModule.getApiUrl()
  const page = {
    id: pageId,
    name,
  }

  return restRequests.putRequest(`${apiUrl}api/pages/${pageId}`, page)
}

export function deletePage(paramsObj) {
  const { pageId } = paramsObj
  const apiUrl = constantsModule.getApiUrl()

  return restRequests.deleteRequest(`${apiUrl}api/pages/${pageId}`)
}

export function getCurrentPage(paramsObj) {
  const { pageId } = paramsObj
  const apiUrl = constantsModule.getApiUrl()

  return restRequests.getRequest(`${apiUrl}api/pages/${pageId}`)
}

export function isVideoPage(type) {
  const isVideoPage =
    type === ContentType.Video ||
    type === ContentType.VideoByUrl ||
    type === ContentType.RecordedVideo ||
    type === ContentType.ConcatenatedVideo ||
    type === ContentType.UnencodedVideo ||
    type === ContentType.UnencodedRecordedVideo ||
    type === ContentType.UnencodedConcatenatedVideo

  return isVideoPage
}

export function isVideoAsset(type) {
  const isVideoAsset =
    type === AssetType.Video ||
    type === AssetType.RecordedVideo ||
    type === AssetType.ConcatenatedVideo ||
    type === AssetType.UnencodedVideo ||
    type === AssetType.UnencodedRecordedVideo ||
    type === AssetType.UnencodedConcatenatedVideo

  return isVideoAsset
}

export function isImageAsset(type) {
  const isImageAsset =
    type === AssetType.Image ||
    type === AssetType.Snapshot ||
    type === AssetType.StructureModel ||
    type === AssetType.TwoDLineDrawing

  return isImageAsset
}

export function isImagePage(type) {
  const isImagePage =
    type === ContentType.Image ||
    type === ContentType.Snapshot ||
    type === ContentType.StructureModel ||
    type === ContentType.TwoDLineDrawing

  return isImagePage
}

export function isPdfPage(type) {
  const isPdfPage =
    type === ContentType.Pdf ||
    type === ContentType.StructureModelPdf ||
    type === ContentType.TwoDLineDrawingPdf ||
    type === ContentType.BentleyAnalyticsReportPdf

  return isPdfPage
}

export function isSpatialViewPage(type) {
  const isSpatialViewPage = type === ContentType.SpatialModel

  return isSpatialViewPage
}

export function isStructureModelPage(type) {
  return (
    type === ContentType.StructureModel ||
    type === ContentType.StructureModelPdf ||
    type === ContentType.BentleyAnalyticsReportPdf
  )
}

export function is2dLineDrawingPage(type) {
  const is2dLineDrawingPage = type === ContentType.TwoDLineDrawing

  return is2dLineDrawingPage
}

export function isDrawingBoardPage(type) {
  const isDrawingBoardPage = type === ContentType.DrawingBoard

  return isDrawingBoardPage
}

export function isDocumentPage(type) {
  const isDocumentPage =
    type === ContentType.DocDocumentPage ||
    type === ContentType.PptDocumentPage ||
    type === ContentType.ExcelDocumentPage

  return isDocumentPage
}

export function isAutodeskPage(type) {
  return type === ContentType.AutodeskFile
}

export function isWordPage(type) {
  return type === ContentType.DocDocumentPage
}

export function isExcelPage(type) {
  return type === ContentType.ExcelDocumentPage
}

export function isPowerPointPage(type) {
  return type === ContentType.PptDocumentPage
}

export function isWebPage(type) {
  return type === ContentType.WebPage
}

export function is360ViewPage(type) {
  return type === ContentType.SpatialModel
}

export function isFilePage(type) {
  return type === ContentType.File
}

export function isPointCloudPage(type) {
  return type === ContentType.PointCloud
}

export const getImageRatio = (page) => {
  if (!page.height || !page.width) return 1

  switch (page.contentType) {
    case ContentType.WebPage:
    case ContentType.PptDocumentPage:
    case ContentType.DocDocumentPage:
    case ContentType.ExcelDocumentPage:
    case ContentType.File:
      return 1
    case ContentType.Video:
    case ContentType.ConcatenatedVideo:
    case ContentType.UnencodedVideo:
    case ContentType.UnencodedConcatenatedVideo:
    case ContentType.UnencodedRecordedVideo:
      return 360 / 640
    default:
      return page.height / page.width
  }
}

export const getThumbnailUrl = (page) => {
  let { thumbnailUrl } = page

  switch (page.contentType) {
    case ContentType.WebPage:
    case ContentType.SpatialModel:
    case ContentType.DocDocumentPage:
    case ContentType.PptDocumentPage:
    case ContentType.ExcelDocumentPage:
    case ContentType.File:
      break
    default:
      thumbnailUrl += `${thumbnailUrl.includes('?') ? '&' : '?'}b=${page.timestampReceived}`
      break
  }

  return thumbnailUrl
}

export const isBentleyAnalyticsReportPage = (type) => {
  return type === ContentType.BentleyAnalyticsReportPdf
}

export function isSupportRotation(type) {
  return isImagePage(type)
}

export function isSupportCompass(type) {
  return isSpatialViewPage(type)
}

export function isSupportDownload(type) {
  return isDocumentPage(type) || isFilePage(type) || isAutodeskPage(type) || isPointCloudPage(type)
}

export function isSupportZooming(type) {
  return isImagePage(type) || isPdfPage(type) || isSpatialViewPage(type) || isDrawingBoardPage(type)
}

export function isIframePage(type) {
  return isDocumentPage(type) || isWebPage(type) || type === ContentType.VideoByUrl || isAutodeskPage(type)
}

export function isSupportSnippingTool(type) {
  return Boolean(type) && !isIframePage(type) && !isFilePage(type)
}

export function isSupportAnnotations(type) {
  return isDrawingBoardPage(type) || isVideoPage(type) || isPdfPage(type) || isImagePage(type)
}

export function isSupportAnnotationsDisplay(type) {
  return isDrawingBoardPage(type) || isVideoPage(type) || isPdfPage(type) || isImagePage(type) || isPointCloudPage(type)
}

export function isSupportExportAsWebPage(type) {
  return (
    !isSpatialViewPage(type) &&
    !isDocumentPage(type) &&
    !isFilePage(type) &&
    !isWebPage(type) &&
    !isAutodeskPage(type) &&
    !isPointCloudPage(type)
  )
}

export function isSupportMeasurements(type) {
  return isImagePage(type)
}
