import { put, select } from 'redux-saga/effects'

import { accountSelectors } from '@tabeeb/modules/account'
import { contentStateSelectors } from '@tabeeb/shared/content'
import { convertServiceChatMessageToPluginChatMessage } from '../../../services/dataConverter'

import { chatActions, chatSelectors } from '..'

export function* getChatUserId(ownerId, receiverId) {
  if (!receiverId) {
    return null
  }

  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  return currentUserId === ownerId ? receiverId : ownerId
}

export function* addChatMessage(model) {
  const contentId = yield select(contentStateSelectors.getContentId)
  if (contentId !== model.ContentId) {
    return
  }

  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const chatUserId = yield getChatUserId(model.UserId, model.ReceiverId)

  const lastReadedMessageId = yield select((state) =>
    chatSelectors.getLastReadedMessageIdForChat(state, {
      topicId: model.TopicId,
      topicType: model.TopicType,
      userId: chatUserId,
    })
  )

  const message = convertServiceChatMessageToPluginChatMessage(model, { currentUserId, lastReadedMessageId })

  yield put(
    chatActions.addChatMessage({
      topicId: message.topicId,
      topicType: message.topicType,
      userId: chatUserId,
      message,
    })
  )
}
