import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Check, Close, UndoOutlined, OpenInNew } from '@material-ui/icons'
import { Box, Divider, IconButton, CircularProgress, Typography } from '@material-ui/core'

import { ApprovalRequestType, CertificateVerificationStatusTypes } from '@tabeeb/enums'
import { calendarActions } from '@tabeeb/modules/calendar'
import { invitesActions } from '@tabeeb/modules/invites'

import VisibilitySensor from '@tabeeb/shared/utils/imageUtils'

import * as notificationsPageActions from '../../actions'

import NotificationIcon from '../NotificationIcon'

import useStyles from './styles'

const Notification = ({ containmentRef, notification, onStartCertificateReview }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [isControlsDisabled, setIsControlsDisabled] = useState(false)

  const acceptNotification = useCallback(() => {
    setIsControlsDisabled(true)

    if (notification.Type === ApprovalRequestType.CalendarEvent) {
      dispatch(calendarActions.acceptCalendarEventRequest(notification))
      return
    }

    if (notification.Type === ApprovalRequestType.Invitation) {
      dispatch(invitesActions.acceptInvite.request(notification))
      return
    }

    dispatch(notificationsPageActions.acceptNotification(notification))
  }, [dispatch, notification])

  const rejectNotification = useCallback(() => {
    setIsControlsDisabled(true)

    if (notification.Type === ApprovalRequestType.CalendarEvent) {
      dispatch(calendarActions.declineCalendarEventRequest(notification))
      return
    }

    if (notification.Type === ApprovalRequestType.Invitation) {
      dispatch(invitesActions.rejectInvite.request(notification))
      return
    }

    dispatch(notificationsPageActions.rejectNotificationRequest(notification))
  }, [dispatch, notification])

  const revokeSharing = useCallback(() => {
    setIsControlsDisabled(true)
    dispatch(notificationsPageActions.revokeSessionSharingRequest(notification))
  }, [dispatch, notification])

  const markAsViewed = useCallback(
    (isVisible) => {
      dispatch(notificationsPageActions.markAsViewed({ notification, isVisible }))
    },
    [dispatch, notification]
  )

  const handleStartReview = useCallback(() => {
    onStartCertificateReview(notification.Requestor, notification.CertificateId)
  }, [notification.CertificateId, notification.Requestor, onStartCertificateReview])

  return (
    <>
      <VisibilitySensor once scrollDelay={50} containment={containmentRef.current} onChange={markAsViewed}>
        <Box className={classes.container}>
          <Box className={classes.body}>
            <NotificationIcon notification={notification} />
            <Box className={classes.content}>
              <Typography variant='body2'>{notification.Description}</Typography>
              <Typography variant='caption'>{`Sent ${notification.FormatedUpdatedOn}`}</Typography>
            </Box>
            {notification.IsProcessing && (
              <CircularProgress className={classes.progress} title='Copying is in progress' />
            )}
          </Box>
          <Box className={classes.controls}>
            {notification.NeedConfirmation && (
              <>
                <IconButton title='Accept' size='small' onClick={acceptNotification} disabled={isControlsDisabled}>
                  <Check color={isControlsDisabled ? 'disabled' : 'primary'} />
                </IconButton>
                <IconButton title='Decline' size='small' onClick={rejectNotification} disabled={isControlsDisabled}>
                  <Close color={isControlsDisabled ? 'disabled' : 'error'} />
                </IconButton>
              </>
            )}
            {notification.CanBeRevoked && (
              <IconButton title='Revoke' size='small' onClick={revokeSharing} disabled={isControlsDisabled}>
                <UndoOutlined color={isControlsDisabled ? 'disabled' : 'error'} />
              </IconButton>
            )}
            {notification.Type === ApprovalRequestType.CertificatesEvent &&
              notification.CertificateVerificationStatusType === CertificateVerificationStatusTypes.Pending && (
                <IconButton title='Review' size='small' onClick={handleStartReview} disabled={isControlsDisabled}>
                  <OpenInNew color={isControlsDisabled ? 'disabled' : 'primary'} />
                </IconButton>
              )}
          </Box>
        </Box>
      </VisibilitySensor>
      <Divider />
    </>
  )
}

Notification.propTypes = {
  containmentRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  notification: PropTypes.shape({
    IsProcessing: PropTypes.bool.isRequired,
    Description: PropTypes.string,
    FormatedUpdatedOn: PropTypes.string.isRequired,
    NeedConfirmation: PropTypes.bool.isRequired,
    CanBeRevoked: PropTypes.bool.isRequired,
    Type: PropTypes.oneOf(Object.values(ApprovalRequestType)),
    Requestor: PropTypes.shape({
      Id: PropTypes.number.isRequired,
      Email: PropTypes.string.isRequired,
      Name: PropTypes.string.isRequired,
    }),
    CertificateId: PropTypes.number,
    CertificateVerificationStatusType: PropTypes.number,
  }).isRequired,
  onStartCertificateReview: PropTypes.func.isRequired,
}

export default Notification
