import { useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getImageRatio } from '@tabeeb/services/pageService'
import { contentTrashBinSelectors, contentTrashBinActions } from '../..'
import Item from '../Item'
import LazyLoad from '../../../shared/lazyLoadComponent'

const getGalleryItemPlaceholder = (item) => {
  const pageRatio = getImageRatio(item)
  const pageHeight = 160 * pageRatio + 68
  return <div id={item.id} className='gallery-item-placeholder' style={{ height: pageHeight }} />
}

const ItemList = () => {
  const listRef = useRef()
  const dispatch = useDispatch()

  const isTrashBinEmpty = useSelector(contentTrashBinSelectors.getIsTrahBinEmpty)

  const deletedPages = useSelector(contentTrashBinSelectors.getCurrentFolderDeletedPages)

  const dialogPage = useCallback((pageId, dialogItem) => {
    dispatch(contentTrashBinActions.setOperatingPageId(pageId))
    dispatch(contentTrashBinActions.setContentTrashBinDialogItem(dialogItem))
  })

  const onPageClick = useCallback((pageId, isPageSelected) => {
    isPageSelected
      ? dispatch(contentTrashBinActions.deselectContentTrashBinPage({ Id: pageId }))
      : dispatch(contentTrashBinActions.selectContentTrashBinPage({ Id: pageId }))
  })

  return (
    <ul className='gallery-list'>
      {isTrashBinEmpty ? (
        <div className='gallery-help-text' style={{ fontSize: 18 }}>
          <span>Trash bin is empty</span>
        </div>
      ) : (
        deletedPages.map((page, index) => (
          <LazyLoad
            key={page.id}
            offset={2000}
            scroll
            once
            debounce
            scrollContainer='.trash-bin-scrollable'
            placeholder={getGalleryItemPlaceholder(page)}
          >
            <Item item={page} listRef={listRef} key={index} dialogPage={dialogPage} onPageClick={onPageClick} />
          </LazyLoad>
        ))
      )}
    </ul>
  )
}

export default ItemList
