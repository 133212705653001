import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField } from '@material-ui/core'

import { useFormik } from 'formik'
import * as yup from 'yup'

import { tenantSubdomain } from '@tabeeb/shared/forms/helpers/regexps'

import {
  noWhitespaces,
  invalidSubdomain,
  maxLengthWithName,
  requiredFieldWithName,
} from '@tabeeb/shared/utils/validationErrorMessages'
import { useStyles } from './styles'

import { tenantsActions } from '../../actions'

const validationSchema = yup.object({
  Name: yup
    .string('Enter tenant name')
    .max(256, maxLengthWithName('Tenant name', 256))
    .trim(noWhitespaces)
    .required(requiredFieldWithName('Tenant name')),
  Subdomain: yup
    .string('Enter tenant subdomain')
    .max(256, maxLengthWithName('Subdomain', 256))
    .matches(tenantSubdomain, invalidSubdomain)
    .required(requiredFieldWithName('Subdomain')),
})

const CreateTenantDialog = ({ isOpen, onClose }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleTenantSubmit = useCallback(
    (values) => {
      const model = {
        Config: '{}',
        Name: values.Name,
        Subdomain: values.Subdomain,
        SupportsPrograms: true,
      }

      dispatch(tenantsActions.createRequest(model))
    },
    [dispatch]
  )

  const formik = useFormik({
    initialValues: {
      Name: '',
      Subdomain: '',
    },
    validationSchema,
    onSubmit: handleTenantSubmit,
  })

  const handleExited = useCallback(() => {
    formik.resetForm()
  }, [formik])

  return (
    <Dialog open={isOpen} onClose={onClose} onExited={handleExited}>
      <form noValidate autoComplete='off' onSubmit={formik.handleSubmit}>
        <DialogTitle>Add a new tenant</DialogTitle>
        <DialogContent>
          <TextField
            required
            className={classes.formInput}
            fullWidth
            name='Name'
            id='tenant-name'
            label='Tenant name'
            variant='outlined'
            value={formik.values.Name}
            onChange={formik.handleChange}
            error={formik.touched.Name && Boolean(formik.errors.Name)}
            helperText={formik.touched.Name && formik.errors.Name}
          />
          <TextField
            required
            className={classes.formInput}
            fullWidth
            name='Subdomain'
            id='tenant-subdomain'
            label='Subdomain'
            variant='outlined'
            value={formik.values.Subdomain}
            onChange={formik.handleChange}
            error={formik.touched.Subdomain && Boolean(formik.errors.Subdomain)}
            helperText={formik.touched.Subdomain && formik.errors.Subdomain}
            InputProps={{
              endAdornment: <InputAdornment position='end'>.collaborate.center</InputAdornment>,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Close</Button>
          <Button disabled={!(formik.isValid && formik.dirty)} color='primary' type='submit'>
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

CreateTenantDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default CreateTenantDialog
