import { createAction } from 'redux-actions'

export const getDashboardsRequest = createAction('GET_DASHBOARDS_REQUEST')
export const getDashboardsSuccess = createAction('GET_DASHBOARDS_SUCCESS')
export const getDashboardsFailed = createAction('GET_DASHBOARDS_FAILED')
export const resetDashboard = createAction('RESET_DASHBOARD')

export const getDashboardRequest = createAction('GET_DASHBOARD_REQUEST')
export const getDashboardSuccess = createAction('GET_DASHBOARD_SUCCESS')
export const getDashboardFailed = createAction('GET_DASHBOARD_FAILED')

export const getPowerBiEmbedTokenRequest = createAction('GET_POWER_BI_EMBED_TOKEN_REQUEST')
export const getPowerBiEmbedTokenSuccess = createAction('GET_POWER_BI_EMBED_TOKEN_SUCCESS')
