import { createApiActions } from '@tabeeb/shared/utils/actions'

export const createSystemAnnouncement = createApiActions('CREATE_SYSTEM_ANNOUNCEMENT')
export const createTenantAnnouncement = createApiActions('CREATE_TENANT_ANNOUNCEMENT')
export const deleteAnnouncement = createApiActions('DELETE_ANNOUNCEMENT')
export const updateAnnouncement = createApiActions('UPDATE_ANNOUNCEMENT')
export const updateAnnouncementStatus = createApiActions('UPDATE_ANNOUNCEMENT_STATUS')
export const updateAnnouncementSeverity = createApiActions('UPDATE_ANNOUNCEMENT_SEVERITY')
export const getAnnouncement = createApiActions('GET_ANNOUNCEMENT')
export const getAnnouncements = createApiActions('GET_ANNOUNCEMENTS')
export const getUnreadAnnouncements = createApiActions('GET_UNREAD_ANNOUNCEMENTS')
export const markAnnouncementsAsRead = createApiActions('MARK_ANNOUNCEMENTS_AS_READ')
