import { memo } from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'

const ConfigurationSection = ({ title, children }) => {
  return (
    <Box mb={1}>
      <Typography variant='body2' gutterBottom>
        {title}
      </Typography>
      {children}
    </Box>
  )
}

ConfigurationSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  ]).isRequired,
}

export default memo(ConfigurationSection)
