import { Splat, SplatLoader as ProgressiveSplatLoader } from 'drei-splat-utils'

const UPDATE_INTERVAL_MS = 100
const TIMEOUT_MS = 300_000

export default class SplatLoader extends ProgressiveSplatLoader {
  load(url, onLoad, onProgress, onError) {
    super.load(
      url,
      (sharedState) => {
        let ticks = TIMEOUT_MS / UPDATE_INTERVAL_MS
        const splat = new Splat(sharedState, this.camera, { alphaHash: false })

        const intervalId = setInterval(() => {
          onProgress({ loaded: sharedState.loadedVertexCount, total: sharedState.numVertices })

          if (sharedState.loaded) {
            clearInterval(intervalId)
            onLoad([splat, sharedState])
          }

          if (--ticks === 0) {
            clearInterval(intervalId)
          }
        }, UPDATE_INTERVAL_MS)
      },
      null,
      onError
    )
  }
}
