import { useDispatch, useSelector } from 'react-redux'

import { ConfirmationDialog } from '@tabeeb/modules/shared/dialogs'
import { sessionTabsSelectors } from '@tabeeb/modules/sessionTabs'
import { TabPanel } from '@tabeeb/enums'
import FolderList from '../FolderList'
import ItemList from '../ItemList'
import { contentTrashBinActions, contentTrashBinSelectors } from '../..'
import TrashBinControls from '../TrashBinControls'
import { dialogMessages, dialogSubmitActions } from '../../services'
import FolderNavbar from '../FolderNavbar'

const TrashBin = () => {
  const dispatch = useDispatch()

  const dialogItem = useSelector(contentTrashBinSelectors.getDialogItem)

  const isTrashBinOpen = useSelector((state) => sessionTabsSelectors.getIsTabOpen(state, TabPanel.TrashBin))

  const selectedFolderId = useSelector(contentTrashBinSelectors.getSelectedFolderId)
  const isAnyFolderSelected = Boolean(selectedFolderId)

  const onCloseDialog = () => {
    dispatch(contentTrashBinActions.resetContentTrashBinDialogItem())
  }

  const onSubmitDialog = () => {
    dispatch(dialogSubmitActions[dialogItem]())
    onCloseDialog()
  }

  const dialogProps = {
    onCloseDialog,
    onSubmit: () => onSubmitDialog(),
    isOpen: Boolean(dialogItem),
    cancelButtonText: 'Cancel',
    submitButtonText: 'Submit',
    title: dialogMessages[dialogItem],
  }

  return (
    isTrashBinOpen && (
      <>
        <>
          <section className='container-scrollable trash-bin-scrollable'>
            {isAnyFolderSelected && <FolderNavbar />}
            <FolderList />
            <ItemList />
          </section>
          <TrashBinControls />
        </>
        <ConfirmationDialog {...dialogProps} />
      </>
    )
  )
}

export default TrashBin
