import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    fullwidthField: {
      width: '100%',
    },
    imagePreview: {
      maxHeight: 300,
      maxWidth: 300,
    },
  }
})
