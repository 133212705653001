import { useCallback } from 'react'
import PropTypes from 'prop-types'
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux'

import { AttachFile, Transform, OpenInNew, Delete, Edit, Settings } from '@material-ui/icons'
import { Divider, Card, CardContent, MenuItem, CardHeader, Modal, Fade, withStyles } from '@material-ui/core'

import { AnnotationType } from '@tabeeb/enums'
import { contentViewerActions, Portal } from '@tabeeb/modules/contentViewer'

import { annotationsSelectors, annotationsActions, annotationsService } from '../..'

import styles from './styles'

const AnnotationMenu = ({ classes }) => {
  const dispatch = useDispatch()

  const open = useSelector(annotationsSelectors.getIsAnnotationMenuOpen)
  const position = useSelector(annotationsSelectors.getAnnotationMenuPosition)
  const annotations = useSelector(annotationsSelectors.getSelectedAnnotations)
  const annotation = annotations[0]

  const handleDelete = useCallback(() => {
    dispatch(
      annotationsActions.deleteContentAnnotations({
        annotationIds: annotations.map((a) => a.ParentAnnotationId || a.Id),
      })
    )
    dispatch(annotationsActions.resetSelectedAnnotationsIds())

    dispatch(annotationsActions.closeAnnotationMenu())
  }, [annotations, dispatch])

  const handleProceedToLinkedPage = useCallback(() => {
    dispatch(contentViewerActions.goToPage(annotation.LinkedPageId))
    dispatch(annotationsActions.closeAnnotationMenu())
  }, [annotation?.LinkedPageId, dispatch])

  const handleOpenHotspot = useCallback(() => {
    dispatch(annotationsActions.openHotspotDialog({ annotationId: annotation.Id }))
    dispatch(annotationsActions.closeAnnotationMenu())
  }, [annotation?.Id, dispatch])

  const handleTextEditor = useCallback(() => {
    dispatch(annotationsActions.openTextAnnotationEditor({ annotationId: annotation.Id }))
    dispatch(annotationsActions.closeAnnotationMenu())
  }, [annotation?.Id, dispatch])

  const handleTransform = useCallback(() => {
    dispatch(annotationsActions.setSelectedAnnotationsIds([annotation.Id]))
    dispatch(annotationsActions.closeAnnotationMenu())
  }, [annotation?.Id, dispatch])

  const handleCalibrateMeasurements = useCallback(() => {
    dispatch(annotationsActions.openMeasurementCalibrationDialog({ annotation }))
    dispatch(annotationsActions.closeAnnotationMenu())
  }, [annotation, dispatch])

  const handleCloseMenu = useCallback(() => {
    dispatch(annotationsActions.closeAnnotationMenu())
  }, [dispatch])

  if (!open || !annotation) {
    return null
  }

  const multipleAnnotationsSelected = annotations.length > 1

  const annotationName = annotationsService.getAnnotationTypeName(annotation.Type)
  const showGoToPage = annotation.Type === AnnotationType.LinkedPage
  const showEditText = annotation.Type === AnnotationType.Text
  const showTransform = annotationsService.getIsAnnotationSupportsTransformation(annotation.Type)
  const showOpenOption = annotation.Type === AnnotationType.Hotspot || annotation.Type === AnnotationType.AIHotspot
  const showMeasurementSettings = annotation.Type === AnnotationType.Distance

  const iconProps = {
    fontSize: 'small',
    className: classes.icon,
    style: {
      color: annotation.Color,
    },
  }

  return (
    <ReactReduxContext.Consumer>
      {({ store }) => (
        <Portal store={store}>
          <Modal open onClose={handleCloseMenu} BackdropProps={{ invisible: true }}>
            <Fade in>
              <Card className={classes.container} style={{ left: position.x, top: position.y }}>
                <CardHeader
                  title={multipleAnnotationsSelected ? `${annotations.length} annotations selected` : annotationName}
                />
                <Divider />
                <CardContent className={classes.content}>
                  {!multipleAnnotationsSelected && (
                    <>
                      {showGoToPage && (
                        <MenuItem onClick={handleProceedToLinkedPage} title='Proceed to linked page'>
                          <AttachFile {...iconProps} />
                          Proceed to linked page
                        </MenuItem>
                      )}
                      {showEditText && (
                        <MenuItem onClick={handleTextEditor} title='Edit text'>
                          <Edit {...iconProps} />
                          Edit text
                        </MenuItem>
                      )}
                      {showOpenOption && (
                        <MenuItem onClick={handleOpenHotspot} title='Open hotspot'>
                          <OpenInNew {...iconProps} />
                          Open
                        </MenuItem>
                      )}
                      {showTransform && (
                        <MenuItem onClick={handleTransform} title='Edit'>
                          <Transform {...iconProps} />
                          Edit
                        </MenuItem>
                      )}
                      {showMeasurementSettings && (
                        <MenuItem onClick={handleCalibrateMeasurements} title='Calibrate measurements'>
                          <Settings {...iconProps} />
                          Calibrate
                        </MenuItem>
                      )}
                    </>
                  )}
                  <MenuItem onClick={handleDelete} title='Delete annotation'>
                    <Delete {...iconProps} />
                    Delete
                  </MenuItem>
                </CardContent>
              </Card>
            </Fade>
          </Modal>
        </Portal>
      )}
    </ReactReduxContext.Consumer>
  )
}

AnnotationMenu.propTypes = {
  classes: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
  }).isRequired,
}

export default withStyles(styles)(AnnotationMenu)
