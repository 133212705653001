import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import _ from 'lodash'

import { AssetType } from '@tabeeb/enums'
import { getSelectedGalleryItem } from '@tabeeb/modules/gallery/selectors'

import { Extensions } from '../constants'

const getModelExtension = (url) => {
  if (!url) {
    return null
  }

  const { pathname } = new URL(url)

  return `.${pathname.split('.').pop()}`.toLowerCase()
}

export default () => {
  const page = useSelector(getSelectedGalleryItem)
  if (!page) {
    return null
  }

  const assets = useMemo(() => {
    const originalAssets = _.filter(page.assets, (asset) => asset.Type === AssetType.PointCloud)
    const processedAssets = _.filter(page.assets, (asset) => asset.Type === AssetType.PotreePointCloud)

    const processed = originalAssets.length <= processedAssets.length

    return processed ? processedAssets : originalAssets
  }, [page.assets])

  const model = useMemo(
    () => ({
      id: page.id,
      processed: assets[0].Type === AssetType.PotreePointCloud,
      supportProcessing: _.some(assets, (asset) =>
        [Extensions.Las, Extensions.Ply].includes(getModelExtension(asset.Url))
      ),
      urls: _.map(assets, (asset) => asset.Url),
    }),
    [assets, page.id]
  )

  return model
}
