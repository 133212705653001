import { handleActions } from 'redux-actions'

import { calendarActions } from '..'

const defaultState = {
  open: false,
  startDate: undefined,
  endDate: undefined,
}

export default handleActions(
  {
    [calendarActions.openCreateEventDialog](state, action) {
      return {
        open: true,
        ...action.payload,
      }
    },
    [calendarActions.closeCreateEventDialog](state, action) {
      return {
        ...state,
        open: false,
      }
    },
    [calendarActions.resetCalendarState](state, action) {
      return defaultState
    },
  },
  defaultState
)
