import PropTypes from 'prop-types'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { convertAnnotationObjectToComponent } from '../services/annotationsService'
import { annotationsActions as annotationActions, annotationsSelectors } from '..'
import AnnotationContainer from './AnnotationContainer'
import Line from '../components/Line'

class LineContainer extends AnnotationContainer {
  constructor(props) {
    super(props)

    this.selectionProps = {
      onClick: this._handleSelect,
    }

    this.hoverProps = {
      onMouseEnter: this._handleMouseEnter,
      onMouseLeave: this._handleMouseLeave,
    }

    this.contextMenuProps = {
      onContextMenu: this._handleContextMenu,
    }

    this.dragAndDropProps = {
      draggable: true,
      onDragStart: this._handleDragStart,
      onDragEnd: this._handleDragEnd,
    }
  }

  _handleDragEnd = (e) => {
    const { target } = e
    const { annotation } = this.state

    const offsetX = target.x() - Line.getXY(annotation).x
    const offsetY = target.y() - Line.getXY(annotation).y

    const updatedCoordinates = annotation.Coordinates.map((coords) => ({
      Start: { X: coords.Start.X + offsetX, Y: coords.Start.Y + offsetY },
      End: { X: coords.End.X + offsetX, Y: coords.End.Y + offsetY },
    }))

    const newAnnotation = {
      ...annotation,
      Coordinates: updatedCoordinates,
    }
    this.setState({ annotation: newAnnotation })
    this._handleEndUpdate(e)
  }

  render() {
    const { hovered, selected, annotation } = this.state
    const { childAnnotation, opacity } = this.props

    const props = {
      annotation,
      annotationRef: this.annotationRef,
      opacity,
      hovered,
      selected,
      hoverProps: this.hoverProps,
      selectionProps: this.selectionProps,
      contextMenuProps: this.contextMenuProps,
      dragAndDropProps: this.dragAndDropProps,
    }

    return (
      <Line {...props}>
        {convertAnnotationObjectToComponent(childAnnotation, {
          parentHovered: props.hovered,
          parentSelected: props.selected,
        })}
      </Line>
    )
  }
}

LineContainer.propTypes = {
  childAnnotation: PropTypes.object,
  currentAnnotation: PropTypes.object.isRequired,
}

const mapStateToProps = (state, { Id }) => {
  return {
    currentAnnotation: annotationsSelectors.getAnnotationById(state, { Id }),
    childAnnotation: annotationsSelectors.getAnnotationChild(state, { Id }),
    selected: annotationsSelectors.getIsAnnotationSelected(state, { Id }),
    opacity: annotationsSelectors.getAnnotationOpacity(state, { Id }),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    annotationActions: bindActionCreators(annotationActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LineContainer)
