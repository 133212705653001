import { handleActions } from 'redux-actions'

import { calendarActions } from '..'

const defaultState = {
  open: false,
  calendarId: null,
  color: null,
}

export default handleActions(
  {
    [calendarActions.openUpdateSubscriptionColorDialog](state, action) {
      return {
        open: true,
        ...action.payload,
      }
    },
    [calendarActions.closeUpdateSubscriptionColorDialog](state, action) {
      return {
        ...state,
        open: false,
      }
    },
    [calendarActions.resetCalendarState](state, action) {
      return defaultState
    },
  },
  defaultState
)
