import { handleActions } from 'redux-actions'

import _ from 'lodash'

import { CalendarSubscriptionDisplay, FetchStatus } from '@tabeeb/enums'
import { getUserColor } from '@tabeeb/shared/utils/color'

import { calendarActions } from '..'

const defaultState = {
  list: [],
  status: FetchStatus.Idle,
}

export default handleActions(
  {
    [calendarActions.getCalendarsListRequest](state, action) {
      return {
        ...state,
        status: FetchStatus.Loading,
      }
    },
    [calendarActions.getCalendarsListFailed](state, action) {
      return {
        ...state,
        status: FetchStatus.Failed,
      }
    },
    [calendarActions.getCalendarsListSuccess](state, action) {
      return {
        status: FetchStatus.Loaded,
        list: action.response.data.map((calendarSubscription) => ({
          ...calendarSubscription,
          Color: calendarSubscription.Color || getUserColor(calendarSubscription.CalendarOwner),
        })),
      }
    },
    [calendarActions.subscribeToUsersCalendarSuccess](state, action) {
      const calendarSubscription = action.response.data

      return {
        ...state,
        list: _.uniqBy(
          [
            ...state.list,
            {
              ...calendarSubscription,
              Color: calendarSubscription.Color || getUserColor(calendarSubscription.CalendarOwner),
            },
          ],
          (subscription) => subscription.CalendarOwnerId
        ),
      }
    },
    [calendarActions.unsubscribeFromUsersCalendarSuccess](state, action) {
      const { userId } = action.payload

      return {
        ...state,
        list: state.list.filter((calendarSubscription) => calendarSubscription.CalendarOwner.Id !== userId),
      }
    },
    [calendarActions.updateCalendarSubscriptionColorRequest](state, action) {
      const { userId, color } = action.payload

      return {
        ...state,
        list: state.list.map((calendarSubscription) =>
          calendarSubscription.CalendarOwner.Id === userId
            ? { ...calendarSubscription, Color: color }
            : calendarSubscription
        ),
      }
    },
    [calendarActions.updateCalendarSubscriptionDisplayRequest](state, action) {
      const { userId, mode } = action.payload

      return {
        ...state,
        list: state.list.map((calendarSubscription) =>
          calendarSubscription.CalendarOwner.Id === userId
            ? { ...calendarSubscription, Enabled: mode !== CalendarSubscriptionDisplay.Hide }
            : mode === CalendarSubscriptionDisplay.DisplayThisOnly
            ? { ...calendarSubscription, Enabled: false }
            : calendarSubscription
        ),
      }
    },
    [calendarActions.resetCalendarState](state, action) {
      return defaultState
    },
  },
  defaultState
)
