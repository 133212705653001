import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { DeviceCategoryType, DeviceVendorType } from '@tabeeb/enums'
import { getShowTabletsAssignation } from '@tabeeb/modules/appConfigState/selectors'

import * as healthActions from '@tabeeb/modules/healthData/actions'
import { getAssignedLife365DeviceStatus, getHealthDevices } from '@tabeeb/modules/healthData/selectors'
import { getTenoviSensorCodeByDeviceName } from '@tabeeb/modules/admin/devices/components/AddEditDeviceModal'
import { isHealthDevice } from '@tabeeb/modules/admin/devices/helpers'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import TabletsAssignation from '../components/TabletsAssignation'

const TabletsAssignationContainer = () => {
  const dispatch = useDispatch()
  const [addEditDeviceModalIsOpen, setAddEditDeviceModalIsOpen] = useState(false)
  const [deviceToEdit, setDeviceToEdit] = useState(null)
  const [isClearDeviceSubmitted, setIsClearDeviceSubmitted] = useState(false)
  const showTabletsAssignation = useSelector(getShowTabletsAssignation)
  const healthDevices = useSelector(getHealthDevices)
  const assignedLife365DeviceStatus = useSelector(getAssignedLife365DeviceStatus)
  const currentUserId = useSelector(getCurrentUserId)
  const durationTimerRef = useRef(null)
  const [deletedDeviceId, setDeletedDeviceId] = useState(null)

  useEffect(() => {
    dispatch(healthActions.getMyHealthDevicesRequest())
  }, [dispatch])

  useEffect(() => {
    const life365Device = healthDevices.find((e) => e.Type === DeviceVendorType.Life365)
    if (life365Device) {
      dispatch(healthActions.onGetLife365Device())
    }
  }, [dispatch, healthDevices])

  useEffect(() => {
    if (!_.isPlainObject(assignedLife365DeviceStatus) && !assignedLife365DeviceStatus?.TabletId) {
      durationTimerRef.current = setInterval(
        () => dispatch(healthActions.getAssignedLife365DeviceStatusRequest()),
        5000
      )
    } else if (assignedLife365DeviceStatus?.TabletId) {
      clearInterval(durationTimerRef.current)
    }

    return () => {
      clearInterval(durationTimerRef.current)
      durationTimerRef.current = null
    }
  }, [assignedLife365DeviceStatus, dispatch])

  const handleAssignTabletClick = useCallback(() => {
    setAddEditDeviceModalIsOpen(true)
  }, [])

  const openAddEditDeviceModal = useCallback((device) => {
    setAddEditDeviceModalIsOpen(true)
    setDeviceToEdit(device)
  }, [])

  const handleCloseEditDeviceModal = useCallback(() => {
    setAddEditDeviceModalIsOpen(false)
  }, [])

  const handleExitedAddEditDeviceModel = useCallback(() => {
    setDeviceToEdit(null)
  }, [])

  const onClearTabletSubmit = useCallback((id) => {
    setDeletedDeviceId(id)
    setIsClearDeviceSubmitted(true)
  }, [])

  const closeConfirmationDialog = useCallback(() => {
    setIsClearDeviceSubmitted(false)
  }, [])

  const clearTablet = useCallback(() => {
    dispatch(healthActions.handleDeleteHealthDeviceOnProfilePage(deletedDeviceId))
    setDeletedDeviceId(null)
    closeConfirmationDialog()
  }, [closeConfirmationDialog, deletedDeviceId, dispatch])

  const onEditDeviceSubmit = useCallback(
    (values) => {
      const isEditMode = Boolean(values.Id)
      if (!isEditMode && values.Type === DeviceVendorType.Tenovi) {
        values.TenoviSensorCodeType = getTenoviSensorCodeByDeviceName(values.TenoviDeviceNameType)
      }

      if (isHealthDevice(values.Type)) {
        delete values.UnitType
      }

      if (isEditMode) {
        dispatch(healthActions.editHealthDevice.request(values))
      } else {
        dispatch(healthActions.handleCreateHealthDeviceOnProfilePage(values))
      }
      handleCloseEditDeviceModal()
    },
    [dispatch, handleCloseEditDeviceModal]
  )

  const addEditDeviceModalProps = useMemo(
    () => ({
      open: addEditDeviceModalIsOpen,
      selectedDevice: deviceToEdit,
      onClose: handleCloseEditDeviceModal,
      onSubmit: onEditDeviceSubmit,
      showEmailField: false,
      assignedForUserId: currentUserId,
      onExited: handleExitedAddEditDeviceModel,
    }),
    [
      addEditDeviceModalIsOpen,
      currentUserId,
      deviceToEdit,
      handleCloseEditDeviceModal,
      handleExitedAddEditDeviceModel,
      onEditDeviceSubmit,
    ]
  )

  const confirmationDialogProps = useMemo(
    () => ({
      onCloseDialog: closeConfirmationDialog,
      onSubmit: clearTablet,
      isOpen: isClearDeviceSubmitted,
      title: 'Are you sure want to clear your assigned device?',
      submitButtonText: 'Yes',
      cancelButtonText: 'No',
    }),
    [clearTablet, closeConfirmationDialog, isClearDeviceSubmitted]
  )

  return (
    <TabletsAssignation
      handleAssignTabletClick={handleAssignTabletClick}
      onClearTabletSubmit={onClearTabletSubmit}
      assignedLife365DeviceStatus={assignedLife365DeviceStatus}
      confirmationDialogProps={confirmationDialogProps}
      healthDevices={healthDevices}
      showTabletsAssignation={showTabletsAssignation}
      openAddEditDeviceModal={openAddEditDeviceModal}
      addEditDeviceModalProps={addEditDeviceModalProps}
    />
  )
}

export default TabletsAssignationContainer
