/* eslint-disable no-param-reassign */
import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'

import { BufferGeometry, Vector3 } from 'three'

import { useLoader, useThree } from '@react-three/fiber'

import { SplatLoader } from '@tabeeb/modules/pointCloud/loaders'
import shaders from '@tabeeb/modules/pointCloud/shaders'

import { useBoxFocus, useCacheCleanup, usePointsRaycasting } from '../../../hooks'

shaders()

const SplatModel = ({ id, azimuth, url, position, tilt, focus, onClick, onProgress }) => {
  const gl = useThree((state) => state.gl)
  const camera = useThree((state) => state.camera)

  const [splat, splatSharedState] = useLoader(
    SplatLoader,
    url,
    (loader) => {
      loader.gl = gl
      loader.camera = camera
    },
    onProgress
  )
  useCacheCleanup(SplatLoader, url)

  const pointsGeometry = useMemo(() => {
    const { centerAndScaleData } = splatSharedState
    const positions = []

    for (let i = 0; i < centerAndScaleData.length; i += 4) {
      positions.push(new Vector3(centerAndScaleData[i], centerAndScaleData[i + 1], centerAndScaleData[i + 2]))
    }

    const geometry = new BufferGeometry().setFromPoints(positions)
    geometry.computeBoundingBox()

    return geometry
  }, [splatSharedState])

  useBoxFocus(id, pointsGeometry?.boundingBox)
  usePointsRaycasting(splat, pointsGeometry)

  return <primitive position={position} object={splat} onClick={onClick} />
}

SplatModel.propTypes = {
  id: PropTypes.number.isRequired,
  azimuth: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.instanceOf(Vector3).isRequired,
  tilt: PropTypes.number.isRequired,
  focus: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onProgress: PropTypes.func.isRequired,
}

export default memo(SplatModel)
