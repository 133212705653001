import { useContext } from 'react'
import PropTypes from 'prop-types'

import AssignmentIcon from '@material-ui/icons/Assignment'
import AssessmentIcon from '@material-ui/icons/Assessment'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import SettingsIcon from '@material-ui/icons/Settings'
import StorageIcon from '@material-ui/icons/Storage'

import { TenantMenuContext, InlineButton } from '..'

import { useStyles } from './styles'

const InlineTenantMenu = ({ tenantIndex }) => {
  const classes = useStyles()
  const {
    onGoToTenantMenuItemClick,
    onCopyTenantUrlMenuItemClick,
    onEditStorageMenuItemClick,
    onEditConfigMenuItemClick,
    onEditTermsMenuItemClick,
    onGenerateReportMenuItemClick,
  } = useContext(TenantMenuContext)

  return (
    <div className={classes.root}>
      <InlineButton
        key='.1'
        label='Open in new tab'
        icon={<OpenInNewIcon />}
        onClick={() => onGoToTenantMenuItemClick(tenantIndex)}
      />
      <InlineButton
        key='.2'
        label='Copy tenant url'
        icon={<FileCopyIcon />}
        onClick={() => onCopyTenantUrlMenuItemClick(tenantIndex)}
      />
      <InlineButton
        key='.3'
        label='Edit config'
        icon={<SettingsIcon />}
        onClick={() => onEditConfigMenuItemClick(tenantIndex)}
      />
      <InlineButton
        key='.4'
        label='Edit storage'
        icon={<StorageIcon />}
        onClick={() => onEditStorageMenuItemClick(tenantIndex)}
      />
      <InlineButton
        key='.5'
        label='Edit terms of use'
        icon={<AssignmentIcon />}
        onClick={() => onEditTermsMenuItemClick(tenantIndex)}
      />
      <InlineButton
        key='.6'
        label='Generated reports'
        icon={<AssessmentIcon />}
        onClick={() => onGenerateReportMenuItemClick(tenantIndex)}
      />
    </div>
  )
}

InlineTenantMenu.propTypes = {
  tenantIndex: PropTypes.number,
}

export default InlineTenantMenu
