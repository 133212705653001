import { memo, useCallback, useEffect, useRef, useState } from 'react'

import {
  CampaignOutlined,
  CheckOutlined,
  KeyboardArrowLeftOutlined,
  KeyboardArrowRightOutlined,
} from '@mui/icons-material'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MobileStepper } from '@mui/material'

import Announcement from '../Announcement'
import { useUnreadAnnouncements } from '../../hooks'

const AnnouncementsDialog = () => {
  const contentRef = useRef()

  const [selectedAnnouncementIndex, setSelectedAnnouncementIndex] = useState(0)

  const { isSubmitInProgress, hasUnviewedAnnouncements, announcements, onMarkAsRead } = useUnreadAnnouncements()

  useEffect(() => {
    contentRef.current?.scrollTo({ top: 0, behavior: 'instant' })
  }, [selectedAnnouncementIndex])

  const onNext = useCallback(() => {
    setSelectedAnnouncementIndex((prevIndex) => prevIndex + 1)
  }, [])

  const onBack = useCallback(() => {
    setSelectedAnnouncementIndex((prevIndex) => prevIndex - 1)
  }, [])

  if (announcements.length === 0) {
    return null
  }

  const last = selectedAnnouncementIndex === announcements.length - 1

  return (
    <Dialog
      open={hasUnviewedAnnouncements}
      fullWidth
      maxWidth='md'
      PaperProps={{ sx: { height: '100%', maxWidth: 1000 } }}
      TransitionProps={{ unmountOnExit: true }}
    >
      <DialogTitle display='flex' alignItems='center'>
        Announcements <CampaignOutlined sx={{ ml: 1 }} />
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <Announcement announcement={announcements[selectedAnnouncementIndex]} />
      </DialogContent>
      <DialogActions>
        <MobileStepper
          variant='dots'
          position='static'
          steps={announcements.length}
          activeStep={selectedAnnouncementIndex}
          sx={{ flexGrow: 1, padding: 0, pt: 1 }}
          backButton={
            <Button
              size='small'
              variant='outlined'
              sx={{ minWidth: 90 }}
              onClick={onBack}
              disabled={isSubmitInProgress || selectedAnnouncementIndex <= 0}
              startIcon={<KeyboardArrowLeftOutlined />}
            >
              Back
            </Button>
          }
          nextButton={
            <Button
              size='small'
              color='primary'
              variant='outlined'
              sx={{ minWidth: 90 }}
              onClick={last ? onMarkAsRead : onNext}
              disabled={isSubmitInProgress}
              endIcon={last ? <CheckOutlined /> : <KeyboardArrowRightOutlined />}
            >
              {last ? 'Got it' : 'Next'}
            </Button>
          }
        />
      </DialogActions>
    </Dialog>
  )
}

AnnouncementsDialog.propTypes = {}

export default memo(AnnouncementsDialog)
