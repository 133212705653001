import { put, all, takeLatest, select, call, take } from 'redux-saga/effects'

import * as notificationActions from '@tabeeb/modules/notification/actions'
import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { getUserByIdRequest, getUserByIdSuccess, getUserByIdFailed } from '../../../users/actions'

import { getCertificatesFilters, getCertificatesPageNumber } from '../selectors'
import * as certificatesActions from '../actions'

function* requestMyCertificate() {
  const certificatesFilters = yield select(getCertificatesFilters)
  const pageNumber = yield select(getCertificatesPageNumber)
  yield put(certificatesActions.getMyCertificates.request({ pageNumber, filters: certificatesFilters }))
}

function* createCertificateSuccess() {
  const message = `Certificate was successfully added`
  yield put(notificationActions.onAddSuccessNotification({ message }))
  yield call(requestMyCertificate)
}

function* createCertificateFailed() {
  const message = `Certificate was not successfully added`
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* updateCertificateSuccess() {
  const message = `Certificate was successfully updated`
  yield put(notificationActions.onAddSuccessNotification({ message }))
  yield call(requestMyCertificate)
}

function* updateCertificateFailed() {
  const message = `Certificate was not successfully updated`
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* deleteCertificateSuccess() {
  const message = `Certificate was successfully deleted`
  yield put(notificationActions.onAddSuccessNotification({ message }))
  yield call(requestMyCertificate)
}

function* deleteCertificateFailed() {
  const message = `Certificate was not successfully deleted`
  yield put(notificationActions.onAddErrorNotification({ message }))
}

function* onReviewCertificateSuccess({ payload: { OwnerId } }) {
  const currentUserId = yield select(getCurrentUserId)
  if (OwnerId === currentUserId) {
    yield call(requestMyCertificate)
  }
}

function* onGetCertificateToReviewOwner(action) {
  yield put(getUserByIdRequest({ userId: action.payload }))

  const result = yield take([getUserByIdSuccess, getUserByIdFailed])
  if (result.type === getUserByIdSuccess().type) {
    yield put(certificatesActions.setCertificateToReviewOwner(result.response.data))
  }
}

function* certificateSagas() {
  yield all([
    takeLatest(certificatesActions.createCertificate.success, createCertificateSuccess),
    takeLatest(certificatesActions.updateMyCertificate.success, updateCertificateSuccess),
    takeLatest(certificatesActions.deleteCertificate.success, deleteCertificateSuccess),
    takeLatest(certificatesActions.createCertificate.failed, createCertificateFailed),
    takeLatest(certificatesActions.updateMyCertificate.failed, updateCertificateFailed),
    takeLatest(certificatesActions.deleteCertificate.failed, deleteCertificateFailed),
    takeLatest(certificatesActions.reviewCertificate.success, onReviewCertificateSuccess),
    takeLatest(certificatesActions.getCertificateToReviewOwner, onGetCertificateToReviewOwner),
  ])
}

export default certificateSagas
