import { memo } from 'react'
import PropTypes from 'prop-types'

import { Button, ButtonGroup, Menu, MenuItem } from '@mui/material'
import { ArrowDropDownOutlined, SaveAsOutlined, SaveOutlined } from '@mui/icons-material'

import { usePopoverState } from '@tabeeb/modules/shared/utils/hooks'

import { AnnouncementStatus } from '../../enums'

const AnnouncementSaveButton = ({ field: { name, value }, form: { dirty, setFieldValue, isSubmitting, isValid } }) => {
  const [anchorRef, open, onOpen, onClose] = usePopoverState()

  return (
    <>
      <ButtonGroup disabled={isSubmitting || !isValid || !dirty} color='primary'>
        <Button
          startIcon={
            value === AnnouncementStatus.Draft ? <SaveAsOutlined color='primary' /> : <SaveOutlined color='primary' />
          }
          variant='outlined'
          type='submit'
        >
          {value === AnnouncementStatus.Draft ? 'Save as draft' : 'Save and publish'}
        </Button>
        <Button ref={anchorRef} size='small' onClick={onOpen}>
          <ArrowDropDownOutlined />
        </Button>
      </ButtonGroup>
      <Menu
        anchorEl={anchorRef.current}
        open={open}
        onClose={onClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          selected={value === AnnouncementStatus.Draft}
          onClick={() => {
            onClose()
            setFieldValue(name, AnnouncementStatus.Draft)
          }}
        >
          Save as draft
        </MenuItem>
        <MenuItem
          selected={value === AnnouncementStatus.Published}
          onClick={() => {
            onClose()
            setFieldValue(name, AnnouncementStatus.Published)
          }}
        >
          Save and publish
        </MenuItem>
      </Menu>
    </>
  )
}

AnnouncementSaveButton.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOf(Object.values(AnnouncementStatus)).isRequired,
  }).isRequired,
  form: PropTypes.shape({
    dirty: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
  }).isRequired,
}

export default memo(AnnouncementSaveButton)
