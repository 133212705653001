import { takeLatest, put, all, select } from 'redux-saga/effects'

import { sessionTabsActions as navbarActions } from '@tabeeb/modules/sessionTabs'
import { TabPanel, TabPanelLocation } from '@tabeeb/enums'
import * as galleryActions from '../actions'
import * as actions from '../../classificationAnnotationsPagesTimeline/actions'
import { selectors } from '../../classificationAnnotationsPagesTimeline'

function* onGalleryAddClassificationAnnotations(action) {
  const isClassificationAnnotationsPagesTimelineOpen = yield select(
    selectors.getIsClassificationAnnotationsPagesTimelineOpen
  )
  const currentPanel = yield select((state) => state.navbar.currentPanel[TabPanelLocation.Bottom])

  if (!isClassificationAnnotationsPagesTimelineOpen) {
    yield put(actions.openClassificationAnnotationsTimeline())
  }

  if (currentPanel !== TabPanel.ClassificationAnnotations) {
    yield put(navbarActions.switchPanel(TabPanel.ClassificationAnnotations, TabPanelLocation.Bottom))
  }
}

function* addClassificationAnnotations() {
  yield all([takeLatest(galleryActions.galleryAddClassificationAnnotations, onGalleryAddClassificationAnnotations)])
}

export default addClassificationAnnotations
