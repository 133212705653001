import PropTypes from 'prop-types'
import PowerBiDashboardItem from '@tabeeb/modules/powerBiAnalyticsReports/components/PowerBiDashboardItem'
import routes from '@tabeeb/routes'
import { push } from 'connected-react-router'
import { generatePath } from 'react-router-dom'
import { useDispatch } from 'react-redux'

const PowerBiDashboardItemContainer = ({ dashboard }) => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(push(generatePath(routes.biDashboard, { id: dashboard.Id })))
  }

  return <PowerBiDashboardItem dashboard={dashboard} handleClick={handleClick} />
}

PowerBiDashboardItemContainer.propTypes = {
  dashboard: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    Id: PropTypes.string.isRequired,
  }),
}

export default PowerBiDashboardItemContainer
