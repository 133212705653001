import { memo } from 'react'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import { Line as DreiLine } from '@react-three/drei'

import { getColorOpacity } from '../../../utils/color'

/** @typedef {import('three').Vector3} Vector3 */
/** @typedef {{
    points: Vector3[],
    closed: Boolean,
    color: String,
    width: Number,
    strokeWidth: Number,
    opacity: Number,
    visible: Boolean,
    highlighted: Boolean
 }} LinePayload */
/**
 * @param {LinePayload} props
 */
const Line = ({ points, closed, color, width, strokeWidth, opacity, visible, highlighted }) => {
  if (!visible) {
    return null
  }

  const lineWidth = Math.max(width, 1.0) / 2
  const colorAlpha = getColorOpacity(color)

  const lineProps = {
    depthTest: false,
    depthWrite: false,
    opacity: colorAlpha * opacity,
    points: closed ? [...points, points[0]] : points,
    transparent: true,
  }

  return (
    <>
      <DreiLine visible={highlighted} color='white' lineWidth={lineWidth + strokeWidth} {...lineProps} />
      <DreiLine color={color} lineWidth={lineWidth} {...lineProps} />
    </>
  )
}

Line.defaultProps = {
  closed: false,
  color: '#FF0000',
  width: 1.0,
  strokeWidth: 0.5,
  opacity: 1.0,
  visible: true,
  highlighted: false,
}

Line.propTypes = {
  closed: PropTypes.bool,
  color: PropTypes.string,
  width: PropTypes.number,
  strokeWidth: PropTypes.number,
  opacity: PropTypes.number,
  visible: PropTypes.bool,
  highlighted: PropTypes.bool,
  points: PropTypes.arrayOf(PropTypes.instanceOf(Vector3).isRequired).isRequired,
}

export default memo(Line)
