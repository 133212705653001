import { combineReducers } from 'redux'

import aiObjects from './aiObjects'
import aiObjectsForTags from './aiObjectsForTags'
import attachAIModel from './attachAIModel'
import counterValuesList from './counterValuesList'
import deleteAiClass from './deleteAiClass'
import deleteNLPModel from './deleteNLPModel'
import deletePredictionSet from './deletePredictionSet'
import deleteVQAModel from './deleteVQAModel'
import editAIClass from './editAIClass'
import editAIClassDialog from './editAIClassDialog'
import isAIClassesLoading from './isAIClassesLoading'
import isEditAIClassDialogOpen from './isEditAIClassDialogOpen'
import isNLPModelsLoading from './isNLPModelsLoading'
import isNlpTagsWithAIObjectsLoading from './isNlpTagsWithAIObjectsLoading'
import isPredictionSetsLoading from './isPredictionSetsLoading'
import isTagUpdating from './isTagUpdating'
import isTenantConfigUpdating from './isTenantConfigUpdating'
import isUnattachedAIModelsLoading from './isUnattachedAIModelsLoading'
import isVQAModelsLoading from './isVQAModelsLoading'
import loadingCounters from './loadingCounters'
import nlpModels from './nlpModels'
import nlpTagsWithAIObjects from './nlpTagsWithAIObjects'
import predictionSets from './predictionSets'
import selectedAIObject from './selectedAIObject'
import selectedTagForAIObjectAttach from './selectedTagForAIObjectAttach'
import selectedUniqueAIObject from './selectedUniqueAIObject'
import unattachedAIModels from './unattachedAIModels'
import uniqueAIObjects from './uniqueAIObjects'
import vqaModels from './vqaModels'

export default combineReducers({
  aiObjects,
  aiObjectsForTags,
  attachAIModel,
  counterValuesList,
  deleteAiClass,
  deleteNLPModel,
  deletePredictionSet,
  deleteVQAModel,
  editAIClass,
  editAIClassDialog,
  isAIClassesLoading,
  isEditAIClassDialogOpen,
  isNLPModelsLoading,
  isNlpTagsWithAIObjectsLoading,
  isPredictionSetsLoading,
  isTagUpdating,
  isTenantConfigUpdating,
  isUnattachedAIModelsLoading,
  isVQAModelsLoading,
  loadingCounters,
  nlpModels,
  nlpTagsWithAIObjects,
  predictionSets,
  selectedAIObject,
  selectedTagForAIObjectAttach,
  selectedUniqueAIObject,
  unattachedAIModels,
  uniqueAIObjects,
  vqaModels,
})
