import { Route } from 'react-router'
import Routes from '@tabeeb/routes'
import PowerBiDashboardsList from '../components/PowerBiDashboardsList/index'
import PowerBiDashboardContainer from './PowerBiDashboardContainer'

const PowerBiReportsPage = () => {
  return (
    <>
      <Route
        exact
        key={Routes.biDashboardsList}
        path={Routes.biDashboardsList}
        render={() => {
          return <PowerBiDashboardsList />
        }}
      />
      <Route
        key={Routes.biDashboard}
        path={Routes.biDashboard}
        render={({
          match: {
            params: { id },
          },
        }) => {
          return <PowerBiDashboardContainer id={id} />
        }}
      />
    </>
  )
}

export default PowerBiReportsPage
