import { memo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import * as Yup from 'yup'
import { Field, Form, Formik } from 'formik'

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@mui/material'

import { AnnotationType } from '@tabeeb/enums'
import { FormikTextField } from '@tabeeb/modules/shared/forms'
import { withModernTheme } from '@tabeeb/modules/shared/uikit'
import { updateContentAnnotation } from '@tabeeb/modules/annotations/actions'

const hasAzimuth = (type) => {
  return type === AnnotationType.Model
}

const hasTilt = (type) => {
  return type === AnnotationType.Model
}

const hasAnchor = (type) => {
  return type === AnnotationType.Model
}

const schema = Yup.object().shape({
  x: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => hasAnchor(type),
      then: Yup.number().strict().required(),
    }),
  y: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => hasAnchor(type),
      then: Yup.number().strict().required(),
    }),
  z: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => hasAnchor(type),
      then: Yup.number().strict().required(),
    }),
  azimuth: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => hasAzimuth(type),
      then: Yup.number().min(0, 'Min 0°').max(360, 'Max 360°').required('Required'),
    }),
  tilt: Yup.number()
    .nullable()
    .when('type', {
      is: (type) => hasTilt(type),
      then: Yup.number().min(0, 'Min 0°').max(180, 'Max 180°').required('Required'),
    }),
  name: Yup.string().nullable(),
})

const EditAnnotationDialog = ({ open, annotation, onClose }) => {
  const dispatch = useDispatch()

  const onSubmit = ({ x, y, z, azimuth, tilt, name }) => {
    dispatch(
      updateContentAnnotation({
        annotation: {
          ...annotation,
          Anchor: {
            X: x,
            Y: y,
            Z: z,
          },
          Url: annotation.Url,
          Azimuth: azimuth,
          Tilt: tilt,
          Name: name,
        },
      })
    )

    onClose()
  }

  const initial = {
    x: annotation?.Anchor?.X,
    y: annotation?.Anchor?.Y,
    z: annotation?.Anchor?.Z,
    azimuth: annotation?.Azimuth,
    tilt: annotation?.Tilt,
    name: annotation?.Name,
    type: annotation?.Type,
  }

  return (
    <Dialog open={open} fullWidth maxWidth='xs' onClose={onClose}>
      <Formik initialValues={initial} enableReinitialize validationSchema={schema} onSubmit={onSubmit}>
        {({ dirty, isValid }) => (
          <Form id='edit-annotation-form' autoComplete='off'>
            <DialogTitle>Edit annotation</DialogTitle>
            <DialogContent>
              <Grid container spacing={1}>
                {hasAnchor(annotation?.Type) && (
                  <>
                    <Grid xs={4} item>
                      <Field size='small' name='x' label='X' type='number' component={FormikTextField} />
                    </Grid>
                    <Grid xs={4} item>
                      <Field size='small' name='y' label='Y' type='number' component={FormikTextField} />
                    </Grid>
                    <Grid xs={4} item>
                      <Field size='small' name='z' label='Z' type='number' component={FormikTextField} />
                    </Grid>
                  </>
                )}
                <Grid xs={12} item>
                  <Field size='small' name='name' label='Name' component={FormikTextField} />
                </Grid>
                {hasAzimuth(annotation?.Type) && (
                  <Grid xs={12} item>
                    <Field
                      size='small'
                      name='azimuth'
                      label='Azimuth'
                      type='number'
                      inputProps={{ min: 0, max: 360, step: 1 }}
                      component={FormikTextField}
                    />
                  </Grid>
                )}
                {hasTilt(annotation?.Type) && (
                  <Grid xs={12} item>
                    <Field
                      size='small'
                      name='tilt'
                      label='Tilt'
                      type='number'
                      inputProps={{ min: 0, max: 180, step: 1 }}
                      component={FormikTextField}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
              <Button
                disabled={!dirty || !isValid}
                form='edit-annotation-form'
                type='submit'
                color='primary'
                variant='contained'
              >
                Save
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

EditAnnotationDialog.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Anchor: PropTypes.shape({
      X: PropTypes.number.isRequired,
      Y: PropTypes.number.isRequired,
      Z: PropTypes.number.isRequired,
    }),
    Azimuth: PropTypes.number.isRequired,
    Name: PropTypes.string,
    Tilt: PropTypes.number.isRequired,
    Type: PropTypes.oneOf(Object.values(AnnotationType)).isRequired,
    Url: PropTypes.string.isRequired,
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default memo(withModernTheme(EditAnnotationDialog))
