import { handleActions } from 'redux-actions'
import { setUniqueAiObjectForEdit, resetState } from '../actions'

const defaultState = null

export default handleActions(
  {
    [setUniqueAiObjectForEdit](state, { payload }) {
      return setUniqueAiObject(payload)
    },
    [resetState](state, payload) {
      return defaultState
    },
  },
  defaultState
)

function setUniqueAiObject(uniqueAiObject) {
  if (!uniqueAiObject) {
    return defaultState
  }

  const result = { ...uniqueAiObject }

  if (result.Properties && typeof result.Properties === 'string') {
    result.Properties = JSON.parse(result.Properties)
  }

  return result
}
