import { createSelector } from 'reselect'
import _ from 'lodash'
import { TenantPermission } from '@tabeeb/enums'

import { getIsModelTrainingSettingEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'

export const getAiClassToDelete = (state) => state.artificialIntelligence.deleteAiClass
export const getAIObjects = (state) => state.artificialIntelligence.aiObjects
export const getAIObjectsForTags = (state) => state.artificialIntelligence.aiObjectsForTags
export const getAttachModelType = (state) => state.artificialIntelligence.attachAIModel.modelType
export const getEditAIClassDependentModelsDialogState = (state) => state.artificialIntelligence.editAIClassDialog
export const getIsAIClassesLoading = (state) => state.artificialIntelligence.isAIClassesLoading
export const getIsAttachModelDialogOpen = (state) => state.artificialIntelligence.attachAIModel.isDialogOpen
export const getIsNLPModelsLoading = (state) => state.artificialIntelligence.isNLPModelsLoading
export const getIsNlpTagsWithAIObjectsLoading = (state) => state.artificialIntelligence.isNlpTagsWithAIObjectsLoading
export const getIsPredictionSetsLoading = (state) => state.artificialIntelligence.isPredictionSetsLoading
export const getIsTagUpdating = (state) => state.artificialIntelligence.isTagUpdating
export const getIsTenantConfigUpdating = (state) => state.artificialIntelligence.isTenantConfigUpdating
export const getIsUnattachedAIModelsLoading = (state) => state.artificialIntelligence.isUnattachedAIModelsLoading
export const getIsVQAModelsLoading = (state) => state.artificialIntelligence.isVQAModelsLoading
export const getModelTrainingPermission = (state) => hasTenantPermission(state, TenantPermission.ModelTraining)
export const getNLPModels = (state) => state.artificialIntelligence.nlpModels
export const getNLPModelToDelete = (state) => state.artificialIntelligence.deleteNLPModel
export const getNlpTagsWithAIObjects = (state) => state.artificialIntelligence.nlpTagsWithAIObjects
export const getPredictionSets = (state) => state.artificialIntelligence.predictionSets
export const getPredictionSetToDelete = (state) => state.artificialIntelligence.deletePredictionSet
export const getSelectedAIModelIdForEdit = (state) => state.artificialIntelligence.selectedAIModelIdForEdit
export const getSelectedAiObject = (state) => state.artificialIntelligence.selectedAIObject
export const getSelectedTagForAIObjectAttach = (state) => state.artificialIntelligence.selectedTagForAIObjectAttach
export const getUnattachedAIModels = (state) => state.artificialIntelligence.unattachedAIModels
export const getUniqueAIObjects = (state) => state.artificialIntelligence.uniqueAIObjects
export const getVQAModels = (state) => state.artificialIntelligence.vqaModels
export const getVQAModelToDelete = (state) => state.artificialIntelligence.deleteVQAModel

export const getUniqueAIObjectsByAIObjectId = (state, { Id }) => {
  const objects = getUniqueAIObjects(state)[Id]

  return objects || []
}

export const getAIObjectsForSelectedTag = createSelector(
  [getAIObjectsForTags, getSelectedTagForAIObjectAttach],
  (aiObjectsList, selectedTagForAIObjectAttach) => {
    if (!selectedTagForAIObjectAttach) {
      return aiObjectsList
    }

    let attachedToTagButUnavailableForTenant = selectedTagForAIObjectAttach.AIObjects.map((object) => {
      return { ...object, IsAttachedToTag: true }
    })

    const result = aiObjectsList.map((aiObject) => {
      const attachedAIObject = selectedTagForAIObjectAttach.AIObjects.find((ao) => ao.Id === aiObject.Id)
      const isAttached = Boolean(attachedAIObject)

      if (isAttached) {
        attachedToTagButUnavailableForTenant = attachedToTagButUnavailableForTenant.filter(
          (o) => o.Id !== attachedAIObject.Id
        )
      }
      return { ...aiObject, IsAttachedToTag: isAttached }
    })

    return _.sortBy([...result, ...attachedToTagButUnavailableForTenant], 'Name')
  }
)

export const getAIModelForEdit = createSelector(
  [getSelectedAIModelIdForEdit, getPredictionSets],
  (aiModelId, predictionSets) => {
    return predictionSets.find((model) => model.AIModelId === aiModelId)
  }
)

export const getIsModelTrainingEnabled = createSelector(
  [getIsModelTrainingSettingEnabled, getModelTrainingPermission],
  (isSettingEnabled, hasPermission) => {
    return isSettingEnabled && hasPermission
  }
)
