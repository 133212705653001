import { put, select } from 'redux-saga/effects'

import { usersSelectors } from '@tabeeb/modules/../users'
import { getContentId } from '../selectors'

import { signalrActions } from '../../../signalr'

export function* notifyOfNewPages(pages, disableNotificationMessage) {
  const contentId = yield select(getContentId)
  const pageIds = pages.map((page) => page.Id)

  const notification = {
    eventType: 100,
    message: 'A new page has been added.',
    contentId,
    pageIds,
    disableNotificationMessage: disableNotificationMessage || false,
  }

  yield put(signalrActions.invokeHubAction({ method: 'NotifyContent', args: [contentId, notification] }))
}

export function* notifyOfDeletePage({ pageId }) {
  const contentId = yield select(getContentId)

  const notification = {
    eventType: 200,
    message: 'A page has been deleted.',
    contentId,
    pageId,
  }

  yield put(signalrActions.invokeHubAction({ method: 'NotifyContent', args: [contentId, notification] }))
}

export function* notifyOfUpdatePage({ pageId }) {
  const contentId = yield select(getContentId)

  const notification = {
    eventType: 300,
    message: 'A page has been updated.',
    contentId,
    pageId,
  }

  yield put(signalrActions.invokeHubAction({ method: 'NotifyContent', args: [contentId, notification] }))
}

export const notifySystem = (notificationOptions) => {
  let notification
  if (!('Notification' in window)) {
    return null
  }
  if (Notification.permission === 'granted') {
    notification = new Notification(notificationOptions.title, notificationOptions)
    notification.onclick = notificationOptions.onClickHandler
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission(function (permission) {
      if (permission === 'granted') {
        notification = new Notification(notificationOptions.title, notificationOptions)
        notification.onclick = notificationOptions.onClickHandler
      }
    })
  }
}
