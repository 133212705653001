import PropTypes from 'prop-types'

import { Vector3 } from 'three'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'

import { useLoader } from '@react-three/fiber'

import { useModelRotation, useObjectFocus } from '../../../hooks'

const ObjModel = ({ id, azimuth, url, position, tilt, focus, onClick, onProgress }) => {
  const obj = useLoader(OBJLoader, url, null, onProgress).clone()

  useModelRotation({ model: obj, tilt, azimuth })
  useObjectFocus(id, obj, focus)

  return <primitive position={position} object={obj} onClick={onClick} />
}

ObjModel.propTypes = {
  id: PropTypes.number.isRequired,
  azimuth: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.instanceOf(Vector3).isRequired,
  tilt: PropTypes.number.isRequired,
  focus: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onProgress: PropTypes.func.isRequired,
}

export default ObjModel
