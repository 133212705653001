import { handleActions } from 'redux-actions'
import { setAiReportNeedToRefresh, getAiObjectAnalysis } from '../actions'

const defaultState = false

export default handleActions(
  {
    [setAiReportNeedToRefresh](state, action) {
      return action.payload
    },
    [getAiObjectAnalysis.request](state, action) {
      return defaultState
    },
  },
  defaultState
)
