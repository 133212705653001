import PropTypes from 'prop-types'

import { Formik, Form, Field } from 'formik'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormikTextFieldComponent from '../FormikTextFieldComponent'

import './styles.less'

const EditUniqueAIObjectDialog = ({ isOpen, handleClose, handleSubmit, handleValidate, initialValues }) => (
  <Dialog open={isOpen} onClose={handleClose}>
    <DialogTitle>Edit AI Object</DialogTitle>
    <Formik enableReinitialize initialValues={initialValues} validate={handleValidate} onSubmit={handleSubmit}>
      {({ isSubmitting, dirty, values }) => (
        <Form>
          <DialogContent>
            <Field disabled name='Name' placeholder='Name' label='Name' component={FormikTextFieldComponent} />
            {Object.keys(values.Properties).map((key, index) => (
              <Field
                key={index}
                name={`Properties.${key}`}
                placeholder={key}
                label={key}
                type={typeof values.Properties[key] === 'number' ? 'number' : 'string'}
                component={FormikTextFieldComponent}
              />
            ))}
          </DialogContent>
          <DialogActions>
            <Button type='submit' color='default' disabled={!dirty || isSubmitting}>
              Save
            </Button>
            <Button onClick={handleClose} color='primary'>
              Cancel
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  </Dialog>
)

EditUniqueAIObjectDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialValues: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleValidate: PropTypes.func.isRequired,
}

export default EditUniqueAIObjectDialog
