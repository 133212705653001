import { put, takeLatest, select, takeEvery, all } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { signalrActions, signalrConstants, signalrEvents } from '@tabeeb/modules/signalr'
import { rawContentActions, contentStateSelectors } from '@tabeeb/shared/content'

import routes from '@tabeeb/routes'
import { accountSelectors } from '@tabeeb/modules/account'
import { notificationActions } from '@tabeeb/modules/notification'
import { formsActions } from '@tabeeb/modules/forms'
import { recordingActions } from '@tabeeb/modules/recording'
import { chatActions } from '@tabeeb/modules/chat'
import { galleryActions } from '@tabeeb/modules/gallery'
import { fileUploadsActions } from '@tabeeb/modules/fileUploads'
import { leaveContent, leaveVideoCall } from '../services'
import * as deviceStateActions from '../../../state/actions/deviceStateActions'
import * as whiteBoardActions from '../actions'
import { usersActions, usersSelectors } from '../../../users'
import { setLastCallEndTime } from '@tabeeb/modules/shared/content/actions'

function* onRemoveUserFromContent(action) {
  const [contentId, userId] = action.payload

  const currentContentId = yield select(contentStateSelectors.getContentId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  if (contentId === currentContentId) {
    if (userId === currentUserId) yield leaveContent({ force: true })
    else {
      const isReviewer = yield select((state) => usersSelectors.getIsCurrentUserReviewer(state))
      if (isReviewer) return

      yield put(notificationActions.onAddInfoNotification({ message: 'User has left' }))
    }

    yield put(usersActions.removeUser(userId))
  }
}

function* onCallEnded(action) {
  const [model] = action.payload
  const { sessionId } = model

  const contentId = yield select(contentStateSelectors.getContentId)
  if (sessionId === contentId) {
    yield put(setLastCallEndTime(new Date()))
    yield leaveVideoCall()
  }
}

function* onUpdateBatteryLevel(action) {
  const contentId = yield select(contentStateSelectors.getContentId)
  const userId = yield select(accountSelectors.getCurrentUserId)

  const level = action.payload

  yield put(signalrActions.invokeHubAction({ method: 'UpdateChargeLevel', args: [contentId, userId, level] }))
}

function* onLeaveContentAction(action) {
  const payload = action.payload || { force: false, ignoreRedirectToHomePage: false }
  yield leaveContent(payload)
}

function* checkSessionInviteSuccess(action) {
  const { contentId, pageId } = action.payload
  yield put(rawContentActions.getContentRequest({ contentId: parseInt(contentId, 10), pageId: parseInt(pageId, 10) }))
}

function* checkSessionInviteFailed(action) {
  const { data } = action.response
  yield put(notificationActions.onAddErrorNotification({ message: data.Error.Message }))
  yield put(push(routes.home))
}

function* resetContentState(action) {
  yield put(formsActions.resetFormsState())
  yield put(galleryActions.resetGalleryState())
  yield put(fileUploadsActions.clearFileUploads())
  yield put(recordingActions.resetRecordingState())
  yield put(chatActions.resetChatState())
}

function* leaveVideoCallAction() {
  yield leaveVideoCall()
}

function* whiteboardSaga() {
  yield all([
    takeEvery(deviceStateActions.onUpdateBatteryLevel, onUpdateBatteryLevel),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onRemoveUserFromContent, onRemoveUserFromContent),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onCallEnded, onCallEnded),
    takeEvery(whiteBoardActions.checkSessionInviteSuccess, checkSessionInviteSuccess),
    takeEvery(whiteBoardActions.checkSessionInviteFailed, checkSessionInviteFailed),
    takeLatest(rawContentActions.leaveContent, onLeaveContentAction),
    takeLatest(rawContentActions.leaveVideoCall, leaveVideoCallAction),
    takeLatest(rawContentActions.resetContentState, resetContentState),
  ])
}

export default whiteboardSaga
