/* eslint-disable react/no-array-index-key */
import { memo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import _ from 'lodash'

import { Vector3 } from 'three'

import { getCenter } from '@tabeeb/modules/pointCloud/utils'
import { getDistance } from '@tabeeb/modules/pointCloud/utils/measurements'
import { getUnit, getScale } from '@tabeeb/modules/pointCloud/selectors'

import { Defaults } from '../../../constants'
import { toVector } from '../../../utils/vectors'
import { useAnnotationState } from '../../../hooks'

import Line from '../Line'
import Label from '../Label'
import Points from '../Points'

const Height = ({ annotation: sourceAnnotation, hovered, onHoverEnter, onHoverLeave, selected, onSelect }) => {
  const unit = useSelector(getUnit)
  const scale = useSelector(getScale)
  const { annotation, onPointUpdate, onUpdateCommit } = useAnnotationState(sourceAnnotation)

  const points = annotation.Points.map(toVector)
  if (points.length < 1) {
    return null
  }

  const [lowest, highest] = _.orderBy([points[0], points[1] || points[0]], (point) => point.z)
  const bottom = new Vector3(highest.x, highest.y, Math.min(lowest.z, highest.z))

  const lineProps = {
    color: annotation.Color,
    visible: !annotation.Hidden,
    highlighted: selected || hovered,
    width: annotation.Width,
  }

  return (
    <group
      visible={!annotation.Hidden}
      rotation={Defaults.ROTATION}
      onPointerDown={onSelect}
      onPointerEnter={onHoverEnter}
      onPointerLeave={onHoverLeave}
    >
      <Points
        points={[lowest, highest]}
        visible={selected && !annotation.Hidden}
        onUpdate={onPointUpdate}
        onUpdateCommit={onUpdateCommit}
      />

      <Line points={[lowest, highest]} {...lineProps} />
      <Line points={[lowest, bottom, highest]} opacity={0.5} {...lineProps} />

      <Label
        visible={!annotation.Hidden}
        position={getCenter(bottom, highest)}
        text={`${getDistance([bottom, highest], unit, scale).toFixed(Defaults.CALCULATIONS_PRECISION)} ${unit}`}
      />
    </group>
  )
}

Height.defaultProps = {
  selected: false,
}

Height.propTypes = {
  annotation: PropTypes.shape({
    Color: PropTypes.string.isRequired,
    Hidden: PropTypes.bool,
    Points: PropTypes.arrayOf(
      PropTypes.shape({
        X: PropTypes.number.isRequired,
        Y: PropTypes.number.isRequired,
        Z: PropTypes.number.isRequired,
      })
    ).isRequired,
    Width: PropTypes.number.isRequired,
  }).isRequired,
  hovered: PropTypes.bool,
  onHoverEnter: PropTypes.func,
  onHoverLeave: PropTypes.func,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default memo(Height)
