import { createSelector } from 'reselect'

import {
  FolderRoleDisplayName,
  FolderUserRole,
  SessionRoleDisplayName,
  UserRole,
  UserTenantRole,
  UserTenantRoleDisplayName,
  UserTenantRoleId,
} from '@tabeeb/enums'
import { isMobileOnly } from 'react-device-detect'
import { camelCase, toUpper } from 'lodash'
import domainService from '@tabeeb/services/domainService'
import { getMe } from '@tabeeb/modules/account/selectors'
import { DEFAULT_SESSIONS_TITLE } from '@tabeeb/shared/constants'

const _ = require('lodash')
_.mixin(require('lodash-inflection'))

export const getConfig = (state) => state.appConfigState

/**
 * @deprecated use `domainService.subdomain` instead. This selector is only used for a backwards compatibility
 */
export const getSubdomain = () => domainService.subdomain
export const getTenantName = (state) => state.appConfigState.name
export const getBannerUrl = (state) => state.appConfigState.bannerUrl
export const getBannerLink = (state) => state.appConfigState.bannerLink
export const getMainBannerColor = (state) => state.appConfigState.mainBanner || '#101010'
export const getMainBackgroundColor = (state) => state.appConfigState.mainBGColor || '#FFFFFF'
export const getMainTextColor = (state) => state.appConfigState.mainTextColor || '#A0A0A0'
export const getMainSelectedTextColor = (state) => state.appConfigState.mainSelectedTextColor || '#FFFFFF'

export const getIsSwitchTenantFeatureEnabled = (state) =>
  String(state.appConfigState.isSwitchTenantFeatureEnabled).toLowerCase() === 'true'

export const getLoginBackgroundImage = (state) => state.appConfigState.loginBackgroundImage
export const getLoginButtonColor = (state) => state.appConfigState.loginColor
export const getLoginPageTextColor = (state) => state.appConfigState.homeTextColor
export const getLoginPageBackgroundColor = (state) => state.appConfigState.loginBackgroundColor
export const getLoginBoudingBoxBackgroundColor = (state) => state.appConfigState.homeBanner

export const getShowNokiaButton = (state) => Boolean(state.appConfigState.showNokiaButton)
export const getShowGoogleButton = (state) => Boolean(state.appConfigState.showGoogleButton)
export const getShowEricssonButton = (state) => Boolean(state.appConfigState.showEricssonButton)
export const getShowImmucorButton = (state) => Boolean(state.appConfigState.showImmucorButton)
export const getShowTmobileButton = (state) => Boolean(state.appConfigState.showTmobileButton)

export const getMission = (state) => state.appConfigState.mission
export const getBullets = (state) => state.appConfigState.bullets

export const getNotificationColor = (state) => state.appConfigState.notificationColor || '#0089cf'

export const getAddButtonColor = (state) => state.appConfigState.addButtionColor || '#e0e0e0'
export const getExportButtonColor = (state) => state.appConfigState.exportButtonColor || '#e0e0e0'

export const getIsFormSignatureEnabledInTenant = (state) => Boolean(state.appConfigState.isFormSignatureEnabled)

export const getSessionTitle = createSelector(
  [(state) => state.appConfigState.sessionsNavbarTitle],
  (sessionsNavbarTitle) => {
    if (sessionsNavbarTitle) {
      sessionsNavbarTitle = _.trim(sessionsNavbarTitle)
    }

    return sessionsNavbarTitle || DEFAULT_SESSIONS_TITLE
  }
)

export const getIsAccountDeletionEnabled = (state) => Boolean(state.appConfigState.isAccountDeletionEnabled)

export const getIsGeosearchEnabled = (state) => Boolean(state.appConfigState.showMenuGeosearch)

export const getIsTrackingMapEnabled = (state) => Boolean(state.appConfigState.isTrackingMapEnabled)
export const getIsSessionPublishingEnabled = (state) => Boolean(state.appConfigState.isSessionPublishingEnabled)

export const getIsAppConfigBillingEnabled = (state) => state.appConfigState.isBillingEnabled

export const getIsBillingEnabled = createSelector([getIsAppConfigBillingEnabled], (isBillingEnabled) => {
  return String(isBillingEnabled).toLowerCase() === 'true'
})
export const getIsChatEnabled = (state) => Boolean(state.appConfigState.isChatEnabled)
export const getIsChatTranslationEnabled = (state) => Boolean(state.appConfigState.isChatTranslationEnabled)

export const getShowMenuAI = (state) => Boolean(state.appConfigState.showMenuAI)
export const getShowAnalyticsMenu = (state) => Boolean(state.appConfigState.showMenuAnalytics)
export const getShowGlobalViewAnalyticsReports = createSelector([getMe], (account) => {
  const { subdomain } = domainService
  if (subdomain.startsWith('globalview') || subdomain === 'bocaratonregional') {
    const userTenantRole = account.TenantRole
    if (userTenantRole === UserTenantRole.User || userTenantRole === UserTenantRole.SessionAdmin) {
      return true
    }
  }
  return false
})
export const getShowMenuAnalyticsReports = (state) => Boolean(state.appConfigState.showMenuAnalyticsReports)
export const getShowStructureModel = (state) => Boolean(state.appConfigState.showStructureModel)
export const getShowBentleyAnalyticsReport = (state) => Boolean(state.appConfigState.showBentleyAnalyticsReport)
export const getShow2dLineDrawing = (state) => Boolean(state.appConfigState.show2dLineDrawing)
export const getIs3DModelCreationLimitEnabled = (state) => Boolean(state.appConfigState.is3DModelCreationLimitEnabled)

export const getIsDetectObjects = (state) => Boolean(state.appConfigState.isDetectObjects)

export const getIsFormPublishingEnabled = (state) => Boolean(state.appConfigState.isFormPublishingEnabled)
export const getSelectedTenantsForFormPublishing = (state) =>
  state.appConfigState.selectedTenantsForFormPublishing || []

export const getSelectedTenantsForContentSharing = (state) => state.appConfigState.selectedTenantsForSharing || []

export const getShowProcessingByVQAModelButton = (state) => Boolean(state.appConfigState.showProcessingByVQAModelButton)

export const getIsAIExpressionsEnabled = (state) => Boolean(state.appConfigState.isAIExpressionsEnabled)

export const getIsVideoCallsEnabled = (state) => Boolean(state.appConfigState.isVideoCallsEnabled)

export const getIsContentMetadataEnabled = (state) => Boolean(state.appConfigState.isContentMetadataEnabled)
export const getContentMetadataButtonLabel = (state) =>
  state.appConfigState.contentMetadataButtonLabel || 'Content Metadata'
export const getContentMetadataPosition = (state) => state.appConfigState.contentMetadataPosition

export const getIsDeviceSwitchingEnabled = (state) =>
  Boolean(state.appConfigState.isDeviceSwitchingEnabled) && !isMobileOnly
export const getIsSoundNotificationOnCallJoinEnabled = (state) =>
  Boolean(state.appConfigState.soundNotificationOnCallJoin)
export const getShowGridViewButton = (state) => Boolean(state.appConfigState.showGridViewButton)

export const getFormsTitle = (state) => camelCase(state.appConfigState.formsTitle).replace(/^(.)/, toUpper) || 'Forms'
export const getFormsTitleLowerCase = createSelector([getFormsTitle], (formsTitle) => {
  return formsTitle.toLowerCase()
})
export const getFormTitle = createSelector([getFormsTitle], (formsTitle) => {
  return _.singularize(formsTitle)
})
export const getFormTitleLowerCase = createSelector([getFormTitle], (formTitle) => {
  return formTitle.toLowerCase()
})

export const getIsCalendarEnabled = (state) => Boolean(state.appConfigState.isCalendarEnabled && !isMobileOnly)

export const getIsPointCloudModelsEnabled = (state) => Boolean(state.appConfigState.isPointCloudModelsEnabled)

export const getIsAutodeskViewerEnabled = (state) => Boolean(state.appConfigState.isAutodeskViewerEnabled)

export const getIsRecordingEnabled = (state) => Boolean(state.appConfigState.isRecordingEnabled)
export const getIsLocalRecordingEnabled = (state) => Boolean(state.appConfigState.isLocalRecordingEnabled)
export const getIsCloudRecordingEnabled = (state) => Boolean(state.appConfigState.isCloudRecordingEnabled)
export const getIsMultipleRecordingsAvailable = (state) =>
  Boolean(getIsLocalRecordingEnabled(state) && getIsCloudRecordingEnabled(state))

export const getIsInContentReviewEnabled = (state) => Boolean(state.appConfigState.isInContentReviewEnabled)

export const getIsCreatePublishFormRestrictionEnabled = (state) =>
  Boolean(state.appConfigState.isCreatePublishFormRestrictionEnabled)

export const getIsSessionGuestRoleEnabled = (state) => Boolean(state.appConfigState.isSessionGuestRoleEnabled)

export const getisViewingAdminTenantRoleEnabled = (state) =>
  Boolean(state.appConfigState.isViewingAdminTenantRoleEnabled)

export const getisBillingSettingsTenantRoleEnabled = (state) =>
  Boolean(state.appConfigState.isBillingSettingsRoleEnabled)

export const getShowHealthData = (state) => Boolean(state.appConfigState.showHealthData)
export const getShowTabletsAssignation = (state) => Boolean(state.appConfigState.showTabletsAssignation)

export const getIsAdvancedFormSettingsEnabled = (state) => Boolean(state.appConfigState.isAdvancedFormSettingsEnabled)

export const getIsInviteSettingsEnabled = (state) => Boolean(state.appConfigState.isAdvancedInviteSettingsEnabled)

export const getIsMfaEnabled = (state) => Boolean(state.appConfigState.isMfaEnabled)

export const getShowCertificates = (state) => Boolean(state.appConfigState.showCertificates)

export const getIsContentSharingSettingEnabled = (state) => Boolean(state.appConfigState.isSessionSharingEnabled)
export const getIsContentReviewingSettingEnabled = (state) => Boolean(state.appConfigState.isSessionReviewingEnabled)
export const getIsContentsLinkingSettingEnabled = (state) => Boolean(state.appConfigState.isContentLinkingEnabled)
export const getIsShowFormUsersFilter = (state) => Boolean(state.appConfigState.showFormUsers)
export const getIsMergeForReviewEnabled = (state) => Boolean(state.appConfigState.isMergeForReviewEnabled)

export const getCustomTenantRoleDisplayNames = (state) => ({
  [UserTenantRole.Guest]: state.appConfigState.guestRoleDisplayName,
})

const getContentGuestRoleName = (state) => state.appConfigState.sessionGuestRoleDisplayName

const getCustomSessionRoleDisplayNames = createSelector(getContentGuestRoleName, (guestName) => ({
  [UserRole.SessionGuest]: guestName,
}))

const getCustomFolderRoleDisplayNames = (state) => ({
  [FolderUserRole.SessionGuest]: state.appConfigState.sessionGuestRoleDisplayName,
})

export const getTenantRoleDisplayName = (state, { Role }) => {
  const originalRoleName = UserTenantRoleDisplayName[Role]
  const customRoleName = getCustomTenantRoleDisplayNames(state)[Role]

  return customRoleName || originalRoleName
}

export const getContentRoles = createSelector(getCustomSessionRoleDisplayNames, (customNames) => {
  const contentRoles = Object.entries(SessionRoleDisplayName).map(([roleId, roleName]) => ({
    id: roleId,
    name: customNames[roleId] || roleName,
  }))
  return _.keyBy(contentRoles, 'id')
})

export const getSessionRoleDisplayName = createSelector(
  [getContentRoles, (state, { Role: roleId }) => roleId],
  (roles, roleId) => {
    return roles[roleId].name
  }
)

export const getFolderRoleDisplayName = (state, { Role }) => {
  const originalRoleName = FolderRoleDisplayName[Role]
  const customRoleName = getCustomFolderRoleDisplayNames(state)[Role]

  return customRoleName || originalRoleName
}

export const getTenantRoles = (state) => {
  return [
    UserTenantRole.BillingAdmin,
    UserTenantRole.TenantAdmin,
    UserTenantRole.SessionAdmin,
    UserTenantRole.AIAdmin,
    UserTenantRole.LabelingAdmin,
    UserTenantRole.User,
    UserTenantRole.Guest,
    UserTenantRole.ViewingAdmin,
    UserTenantRole.BillingSettingsAdmin,
  ].map((role) => ({
    id: UserTenantRoleId[role],
    name: getTenantRoleDisplayName(state, { Role: role }),
  }))
}

export const getIsPageFolderAuthorizationEnabled = (state) =>
  Boolean(state.appConfigState.isPageFolderAuthorizationEnabled)

export const getIsModelTrainingSettingEnabled = (state) => Boolean(state.appConfigState.isModelTrainingEnabled)

export const getIsFormActionVisible = createSelector(getConfig, (config) => config.isFormActionEnabled)
export const getIsFormViewModeSwitchVisible = createSelector(
  getConfig,
  (config) => config.isMobileFormViewSwitchEnabled
)

export const getShowReminderButton = (state) => Boolean(state.appConfigState.showReminders)

export const getIsCustomIframeEnabled = (state) => Boolean(state.appConfigState.isCustomIframeEnabled)
export const getCustomIframeUrl = (state) => state.appConfigState.customIframeUrl
export const getCustomIframeTitle = (state) => state.appConfigState.customIframeNomenclature

export const getIsAIReportEnabled = (state) => Boolean(state.appConfigState.isAIReportEnabled)
export const getAIReportLabel = (state) => state.appConfigState.aiReportLabel || 'Inventory Analysis'
export const getIsTableRecognitionEnabled = (state) => Boolean(state.appConfigState.isTableRecognitionEnabled)

export const getIsExportCustomFormCsvEnabled = (state) => Boolean(state.appConfigState.isExportCustomFormCsvEnabled)

export const getIsTenantAnnouncementsEnabled = (state) => Boolean(state.appConfigState.isTenantAnnouncementsEnabled)

export const getIsPdfExportEmailRecipientsEnabled = (state) => Boolean(state.appConfigState.showPdfRecieversPopup)
export const getIsImagesExportEmailRecipientsEnabled = (state) => Boolean(state.appConfigState.showImageRecieversPopup)

export const getIsSessionSharingEnabled = (state) => Boolean(state.appConfigState.isSessionSharingEnabled)
export const getIsBulkCopyEnabled = (state) => Boolean(state.appConfigState.isBulkCopyEnabled)

export const getSessionTileBackgroundColor = (state) => state.appConfigState.sessionTileBackgroundColor
export const getSessionTileTextColor = (state) => state.appConfigState.sessionTitleColor
export const getSessionPageTextColor = (state) => state.appConfigState.pageNumberColor
export const getSessionPagePrimaryActionColor = (state) => state.appConfigState.createSessionBackgroundColor
