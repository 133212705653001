import oidcUserManager from '@tabeeb/services/oidcUserManager'

const selector = {
  getApiUrl: (state) => process.env.TABEEB_API_URL,
  getAccessToken: () => oidcUserManager.getUserSync()?.access_token,
  getContent: (state) => state.contentState,
  getUsers: (state) => state.users,
  getDrawing: (state) => state.playerToolbar.drawing,
  getSelectedGalleryItemId: (state) => state.gallery.galleryState.selectedGalleryItemId,
  getGalleryList: (state) => state.gallery.galleryList,
  getConnectionState: (state) => state.presentation.connection,
}

export default selector
