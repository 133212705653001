import { handleActions } from 'redux-actions'

import { annotationsActions } from '..'

const defaultState = null

export default handleActions(
  {
    [annotationsActions.setHighlightedAnnotationId](state, action) {
      const annotationId = action.payload

      return annotationId
    },
    [annotationsActions.resetHighlightedAnnotationId](state, action) {
      return defaultState
    },
    [annotationsActions.resetAnnotationsState](state, action) {
      return defaultState
    },
  },
  defaultState
)
