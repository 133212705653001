import { AnnotationType } from '@tabeeb/enums'

import LineContainer from '../containers/LineContainer'
import TextContainer from '../containers/TextContainer'
import StrokeContainer from '../containers/StrokeContainer'
import DistanceContainer from '../containers/DistanceContainer'
import PolygonContainer from '../containers/PolygonContainer'
import CircleContainer from '../containers/CircleContainer'
import HotspotContainer from '../containers/HotspotContainer'
import AIHotspotContainer from '../containers/AIHotspotContainer'
import ArrowLineContainer from '../containers/ArrowLineContainer'
import RectangleContainer from '../containers/RectangleContainer'
import LinkedPageContainer from '../containers/LinkedPageContainer'

import Line from '../components/Line'
import Text from '../components/Text'
import Stroke from '../components/Stroke'
import Distance from '../components/Distance'
import Polygon from '../components/Polygon'
import Circle from '../components/Circle'
import Hotspot from '../components/Hotspot'
import AIHotspot from '../components/AIHotspot'
import ArrowLine from '../components/ArrowLine'
import Rectangle from '../components/Rectangle'
import LinkedPage from '../components/LinkedPage'

export function getAiAnnotationPropertiesText(propertiesJSON) {
  let propertiesText = ''

  const parsedProperties = JSON.parse(propertiesJSON)

  for (const [key, value] of Object.entries(parsedProperties)) {
    propertiesText += `<br/>${key}: ${value}`
  }

  return propertiesText
}

export const getIsAnnotationSupportsTransformation = (annotationType) => {
  if (
    annotationType === AnnotationType.Rectangle ||
    annotationType === AnnotationType.Rectangle_Filled ||
    annotationType === AnnotationType.Ellipse ||
    annotationType === AnnotationType.Ellipse_Filled
  ) {
    return true
  }

  return false
}

export const getAnnotationTypeName = (annotationType) => {
  switch (annotationType) {
    case AnnotationType.Text:
      return 'Text'
    case AnnotationType.Stroke:
      return 'Stroke'
    case AnnotationType.Distance:
      return 'Distance'
    case AnnotationType.Polygon:
      return 'Polygon'
    case AnnotationType.Line:
      return 'Line'
    case AnnotationType.Hotspot:
      return 'Hotspot'
    case AnnotationType.AIHotspot:
      return 'AI Hotspot'
    case AnnotationType.LinkedPage:
      return 'Linked page'
    case AnnotationType.ArrowEnd:
    case AnnotationType.ArrowBoth:
      return 'Arrow'
    case AnnotationType.Rectangle:
    case AnnotationType.Rectangle_Filled:
      return 'Rectangle'
    case AnnotationType.Ellipse:
    case AnnotationType.Ellipse_Filled:
      return 'Circle'
    default:
      return null
  }
}

export const getAnnotationComponentByType = (annotationType) => {
  switch (annotationType) {
    case AnnotationType.Distance:
      return Distance
    case AnnotationType.Text:
      return Text
    case AnnotationType.Stroke:
      return Stroke
    case AnnotationType.Polygon:
      return Polygon
    case AnnotationType.Line:
      return Line
    case AnnotationType.Hotspot:
      return Hotspot
    case AnnotationType.AIHotspot:
      return AIHotspot
    case AnnotationType.LinkedPage:
      return LinkedPage
    case AnnotationType.ArrowEnd:
    case AnnotationType.ArrowBoth:
      return ArrowLine
    case AnnotationType.Rectangle:
    case AnnotationType.Rectangle_Filled:
      return Rectangle
    case AnnotationType.Ellipse:
    case AnnotationType.Ellipse_Filled:
      return Circle
    default:
      return null
  }
}

export const getStageOffset = (eventTarget) => {
  const stage = eventTarget.getStage()
  const stagePosition = stage.getPosition()

  const { scaleX } = stage.attrs
  const { scaleY } = stage.attrs

  return {
    X: stagePosition.x / scaleX,
    Y: stagePosition.y / scaleY,
  }
}

export const getPointerPosition = (eventTarget) => {
  const stage = eventTarget.getStage()
  const stagePosition = stage.getPosition()
  const pointerPosition = stage.getPointerPosition()

  const { scaleX } = stage.attrs
  const { scaleY } = stage.attrs

  return {
    X: -stagePosition.x / scaleX + pointerPosition.x / scaleX,
    Y: -stagePosition.y / scaleY + pointerPosition.y / scaleY,
  }
}

export const convertAnnotationObjectToComponent = (annotation, props = {}) => {
  if (!annotation) return null

  switch (annotation.Type) {
    case AnnotationType.Distance:
      return <DistanceContainer key={annotation.Id} Id={annotation.Id} {...props} />
    case AnnotationType.Text:
      return <TextContainer key={annotation.Id} Id={annotation.Id} {...props} />
    case AnnotationType.Stroke:
      return <StrokeContainer key={annotation.Id} Id={annotation.Id} {...props} />
    case AnnotationType.Polygon:
      return <PolygonContainer key={annotation.Id} Id={annotation.Id} {...props} />
    case AnnotationType.Line:
      return <LineContainer key={annotation.Id} Id={annotation.Id} {...props} />
    case AnnotationType.Hotspot:
      return <HotspotContainer key={annotation.Id} Id={annotation.Id} {...props} />
    case AnnotationType.AIHotspot:
      return <AIHotspotContainer key={annotation.Id} Id={annotation.Id} {...props} />
    case AnnotationType.LinkedPage:
      return <LinkedPageContainer key={annotation.Id} Id={annotation.Id} {...props} />
    case AnnotationType.ArrowEnd:
    case AnnotationType.ArrowBoth:
      return <ArrowLineContainer key={annotation.Id} Id={annotation.Id} {...props} />
    case AnnotationType.Rectangle:
    case AnnotationType.Rectangle_Filled:
      return <RectangleContainer key={annotation.Id} Id={annotation.Id} {...props} />
    case AnnotationType.Ellipse:
    case AnnotationType.Ellipse_Filled:
      return <CircleContainer key={annotation.Id} Id={annotation.Id} {...props} />
    default:
      return null
  }
}
