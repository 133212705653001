import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup'

import { InfoOutlined, SaveOutlined } from '@mui/icons-material'
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material'

import { getSelectedGalleryItemId } from '@tabeeb/modules/gallery/selectors'

import { updatePointCloudSettings } from '../../../actions'
import { getScale } from '../../../selectors'

import ConfigurationSection from '../ConfigurationSection'

const validationSchema = Yup.object().shape({
  scale: Yup.number()
    .typeError('Scale must be a number')
    .required('Scale is required')
    .positive('Scale must be a positive number'),
})

const MeasurementsSettings = () => {
  const dispatch = useDispatch()

  const pageId = useSelector(getSelectedGalleryItemId)
  const scale = useSelector(getScale)

  const onSave = ({ scale: newScale }) => {
    dispatch(updatePointCloudSettings.request({ pageId, settings: { MeasurementsScaleFactor: newScale } }))
  }

  return (
    <ConfigurationSection title='Measurements settings'>
      <Formik initialValues={{ scale }} validationSchema={validationSchema} enableReinitialize onSubmit={onSave}>
        {({ dirty, isValid }) => (
          <Form>
            <Field name='scale'>
              {({ field }) => (
                <TextField
                  {...field}
                  sx={{ mt: 1, mb: 1 }}
                  label={
                    <>
                      Scale
                      <Tooltip title='The scale number represents the conversion factor between the 3d model scene units and the real world'>
                        <InfoOutlined sx={{ ml: 1, cursor: 'help' }} fontSize='inherit' color='primary' />
                      </Tooltip>
                    </>
                  }
                  fullWidth
                  size='small'
                  type='number'
                  InputProps={{
                    inputProps: {
                      step: 0.01,
                    },
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Tooltip title='Save'>
                          <IconButton disabled={!dirty || !isValid} size='small' type='submit'>
                            <SaveOutlined color={!dirty || !isValid ? 'disabled' : 'primary'} />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Field>
          </Form>
        )}
      </Formik>
    </ConfigurationSection>
  )
}

export default memo(MeasurementsSettings)
