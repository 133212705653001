import { memo, useCallback } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { Box, IconButton, Tooltip, Typography } from '@mui/material'
import { KeyboardArrowLeftOutlined } from '@mui/icons-material'

import { ActionsContainer } from './styles'

const PageHeader = ({ title, actions }) => {
  const history = useHistory()

  const onBack = useCallback(() => {
    history.goBack()
  }, [history])

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between' mb={1} mt={1}>
      <Box display='flex' alignItems='center' minWidth={80}>
        <Tooltip title='Go back'>
          <IconButton sx={{ mr: 1 }} onClick={onBack}>
            <KeyboardArrowLeftOutlined />
          </IconButton>
        </Tooltip>

        <Typography title={title} variant='h6' fontWeight={600} noWrap>
          {title}
        </Typography>
      </Box>
      <ActionsContainer>{actions}</ActionsContainer>
    </Box>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.arrayOf(PropTypes.element.isRequired).isRequired,
  ]),
}

export default memo(PageHeader)
