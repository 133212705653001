import { put, takeEvery, select, take, all } from 'redux-saga/effects'
import { isMobileOnly } from 'react-device-detect'

import * as galleryActions from '../actions'
import { accountSelectors } from '../../account'
import { gallerySelectors } from '..'
import * as zoomingActions from '../../playerToolbar/actions/zooming'
import * as playerActions from '../../player/actions'
import { signalrEvents, signalrActions, signalrConstants } from '../../signalr'

import { presentationSelectors } from '../../presentation'
import * as contentStateSelectors from '../../shared/content/selectors'

function* onSelectGalleryItem({ payload }) {
  const { pageId, timestamp = null, updateWeb = true, force = false, suppressMessage = false } = payload
  if (!pageId) {
    return
  }
  const contentId = yield select(contentStateSelectors.getContentId)
  const presenterId = yield select(contentStateSelectors.getPresenterId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  let isCallStarted = yield select(presentationSelectors.getIsCallStarted)

  const { selectedGalleryItemId, selectedFolderId } = yield select((state) => state.gallery.galleryState)
  const isAvailableSelect = presenterId === currentUserId || !isCallStarted

  let galleryItem = yield select((state) => gallerySelectors.getGalleryItemById(state, { id: pageId }))
  const isLoaded = galleryItem && galleryItem.isLoaded

  if (!isAvailableSelect && !force) {
    return
  }

  if (pageId !== selectedGalleryItemId) {
    if (!isLoaded) {
      yield put(galleryActions.getGalleryItemRequest({ pageId, isSelectAddedGalleryItem: force, suppressMessage }))
      yield take([galleryActions.getGalleryItemSuccess])

      galleryItem = yield select((state) => gallerySelectors.getGalleryItemById(state, { id: pageId }))

      isCallStarted = yield select(presentationSelectors.getIsCallStarted)
    }

    const { foldersList } = yield select((state) => state.gallery)
    const folder = foldersList.find((item) => item.Id === galleryItem?.folderId)

    const isReturnToMainGallery = galleryItem && galleryItem.folderId === null

    if (isReturnToMainGallery || (folder && galleryItem && selectedFolderId !== galleryItem.folderId)) {
      yield put(galleryActions.selectSessionFolder({ folderId: galleryItem.folderId, selectFirstItem: false }))
    }

    yield put(galleryActions.onGalleryItemSwitching())

    yield put(galleryActions.selectGalleryItem(pageId))
    yield put(playerActions.resetCurrentVideoTimestamp())
    yield put(zoomingActions.onZoomValueChecked(100))

    yield put(galleryActions.onGalleryItemSwitched())

    if (updateWeb) {
      const isCurrentUserPresenter = yield select(contentStateSelectors.getIsCurrentUserPresenter)

      if (isCallStarted && isCurrentUserPresenter) {
        const message = {
          pageId,
          timeStamp: Date.now(),
          type: 'setPageById',
          handleObj: {
            guid: Date.now(),
            type: 'setPageById',
            origType: 'setPageById',
          },
        }

        yield put(signalrActions.invokeHubAction({ method: 'PresenterAction', args: [contentId, message] }))
      }
    }
  }

  if (timestamp !== null) {
    yield put(playerActions.seekVideoToTimestamp(timestamp * 1000))
  }
}

function* onPresenterState(action) {
  const [{ pageId }] = action.payload

  yield put(galleryActions.onSelectGalleryItem({ pageId, force: true, updateWeb: true, suppressMessage: false }))
}

function* onPresenterAction(action) {
  if (isMobileOnly) {
    return
  }

  const [spectatorEvent] = action.payload

  const isCurrentUserPresenter = yield select(contentStateSelectors.getIsCurrentUserPresenter)
  if (isCurrentUserPresenter) {
    return
  }

  if (spectatorEvent.type === 'setPageById') {
    const { pageId } = spectatorEvent

    yield put(galleryActions.onSelectGalleryItem({ pageId, force: true, updateWeb: true, suppressMessage: false }))
  }
}

function* selectItem() {
  yield all([
    takeEvery(galleryActions.onSelectGalleryItem, onSelectGalleryItem),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onPresenterState, onPresenterState),
    takeEvery(signalrEvents[signalrConstants.tabeebHubName].onPresenterAction, onPresenterAction),
  ])
}

export default selectItem
