import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { TextField } from '@material-ui/core'
import { IconButton, InputAdornment } from '@mui/material'
import { FilterList, Search } from '@mui/icons-material'

import { useDebouncedValue, usePopoverState } from '@tabeeb/shared/utils/hooks'

import { getCertificatesFilters, getCertificatesSorting } from '../../selectors'
import * as certificatesActions from '../../actions'
import CertificateMenuFilter from '../CertificateMenuFilter'

const CertificateSearchBar = ({ userId }) => {
  const dispatch = useDispatch()
  const [anchorRef, open, handleOpen, handleClose] = usePopoverState()
  const certificatesFilters = useSelector(getCertificatesFilters)
  const [searchText, setSearchText] = useState(certificatesFilters.SearchText)
  const [verificationTypes, setVerificationTypes] = useState(certificatesFilters.VerificationStatusTypes)
  const debouncedVerificationTypes = useDebouncedValue(verificationTypes, 500)
  const debouncedSearchText = useDebouncedValue(searchText, 500)
  const certificatesSorting = useSelector(getCertificatesSorting)

  useEffect(() => {
    return () => {
      dispatch(certificatesActions.resetCertificatesListState())
    }
  }, [dispatch])

  useEffect(() => {
    dispatch(certificatesActions.setCertificatesPageNumber(1))
    dispatch(certificatesActions.setCertificatesFiltersSearchText(debouncedSearchText))
    dispatch(certificatesActions.setCertificatesFiltersStatusTypes(debouncedVerificationTypes))
    if (userId) {
      dispatch(
        certificatesActions.getCertificatesByUserId.request({
          userId,
          pageNumber: 1,
          filters: {
            SearchText: debouncedSearchText,
            VerificationStatusTypes: debouncedVerificationTypes,
          },
          sorting: certificatesSorting,
        })
      )
    } else {
      dispatch(
        certificatesActions.getMyCertificates.request({
          pageNumber: 1,
          filters: {
            SearchText: debouncedSearchText,
            VerificationStatusTypes: debouncedVerificationTypes,
          },
          sorting: certificatesSorting,
        })
      )
    }
  }, [dispatch, debouncedSearchText, certificatesSorting, debouncedVerificationTypes, userId])

  const updateSearchText = useCallback((newSearchValue) => {
    setSearchText(newSearchValue)
  }, [])

  const updateFilters = useCallback((id) => {
    setVerificationTypes((prevState) =>
      !prevState.includes(id) ? [...prevState, Number(id)] : prevState.filter((status) => status !== Number(id))
    )
  }, [])

  const updateSorting = useCallback(
    (newSorting) => {
      dispatch(certificatesActions.setCertificatesSorting({ Column: Number(newSorting) }))
    },
    [dispatch]
  )

  return (
    <>
      <TextField
        variant='outlined'
        name='SearchText'
        placeholder='Search'
        value={searchText}
        onChange={(e) => updateSearchText(e.target.value)}
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton ref={anchorRef} onClick={handleOpen}>
                <FilterList />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <CertificateMenuFilter
        anchorEl={anchorRef.current}
        open={open}
        handleClose={handleClose}
        updateSorting={updateSorting}
        certificatesSorting={certificatesSorting}
        verificationTypes={verificationTypes}
        updateFilters={updateFilters}
      />
    </>
  )
}

CertificateSearchBar.propTypes = {
  userId: PropTypes.number,
}

export default CertificateSearchBar
