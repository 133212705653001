import { memo, useRef } from 'react'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import { Html } from '@react-three/drei'
import { LocalOfferOutlined } from '@mui/icons-material'

import HotspotDialog from '@tabeeb/modules/annotations/containers/HotspotDialogContainer'

import { Defaults } from '../../../constants'
import { useHotspotDialog } from '../../../hooks'

const AILabel = ({ annotation }) => {
  const ref = useRef()

  const { open, onOpen } = useHotspotDialog(annotation.Id)

  const { x, y } = ref.current?.getBoundingClientRect() ?? { x: 0, y: 0 }
  const position = new Vector3(annotation.Anchor.X, annotation.Anchor.Y, annotation.Anchor.Z)

  return (
    <group rotation={Defaults.ROTATION}>
      <Html zIndexRange={Defaults.HTML_Z_INDEX} position={position}>
        <LocalOfferOutlined ref={ref} sx={{ cursor: 'pointer' }} htmlColor={annotation.Color} onClick={onOpen} />
      </Html>
      {open && <HotspotDialog Id={annotation.Id} x={x} y={y} />}
    </group>
  )
}

AILabel.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number,
    Anchor: PropTypes.shape({
      X: PropTypes.number.isRequired,
      Y: PropTypes.number.isRequired,
      Z: PropTypes.number.isRequired,
    }).isRequired,
    Color: PropTypes.string.isRequired,
    ParentAnnotationId: PropTypes.number,
  }).isRequired,
}

export default memo(AILabel)
