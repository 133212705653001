import { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { Grid, Button, TextField, useTheme, Typography } from '@material-ui/core'
import { Card, CardActionArea, CardContent, Collapse } from '@mui/material'
import { alpha } from '@material-ui/core/styles/colorManipulator'

import { CertificateVerificationStatusTypes } from '@tabeeb/enums'

import { ReviewActionOptions } from '../../constants'
import { reviewCertificate } from '../../actions'

import useStyles from './styles'

const options = [
  { id: ReviewActionOptions.Approve, displayName: 'Approve' },
  { id: ReviewActionOptions.Reject, displayName: 'Reject' },
]

const ReviewCertificateControls = ({ certificate, onClose, showCancelButton }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const theme = useTheme()

  const [reviewAction, setReviewAction] = useState(0)
  const [reviewComment, setReviewComment] = useState('')

  const onReview = useCallback(() => {
    const data = {
      Id: certificate.Id,
      VerificationStatusId:
        reviewAction === ReviewActionOptions.Approve
          ? CertificateVerificationStatusTypes.Verified
          : CertificateVerificationStatusTypes.Rejected,
      ...(reviewAction === ReviewActionOptions.Reject && { Comment: reviewComment }),
    }
    dispatch(reviewCertificate.request({ data, OwnerId: certificate.UserId }))
    onClose()
  }, [certificate?.Id, certificate?.UserId, dispatch, onClose, reviewAction, reviewComment])

  return (
    <Grid container wrap='nowrap' direction='column' spacing={2} className={classes.rootGrid}>
      <Grid item container direction='row' justifyContent='flex-start' spacing={2}>
        {options.map((option) => (
          <Grid item xs key={option.id}>
            <Card
              elevation={0}
              className={classes.rootCard}
              style={{
                backgroundColor:
                  reviewAction === 0
                    ? alpha(theme.palette.primary.light, 0.05)
                    : option.id === reviewAction
                    ? alpha(theme.palette.primary.light, 0.15)
                    : 'transparent',
              }}
            >
              <CardActionArea onClick={() => setReviewAction(option.id)}>
                <CardContent>
                  <Typography>{option.displayName}</Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Collapse in={reviewAction === ReviewActionOptions.Reject} orientation='vertical' sx={{ p: 1 }}>
        <Grid item>
          <TextField
            variant='outlined'
            className={classes.fullwidthField}
            color='primary'
            label='Rejection comment'
            value={reviewComment}
            multiline
            minRows={2}
            maxRows={6}
            required
            helperText='At least 10 characters'
            onChange={(event) => setReviewComment(event.target.value)}
          />
        </Grid>
      </Collapse>
      <Grid
        item
        container
        wrap='nowrap'
        direction='row'
        spacing={1}
        justifyContent={showCancelButton ? 'space-between' : 'flex-end'}
        className={classes.bottomButtonsContainer}
      >
        {showCancelButton && (
          <Grid item xs={4}>
            <Button onClick={onClose} size='large' color='primary' disableElevation fullWidth>
              Cancel
            </Button>
          </Grid>
        )}
        <Grid item xs={4}>
          <Button
            disabled={reviewAction === 0 || (reviewAction === ReviewActionOptions.Reject && reviewComment.length < 10)}
            onClick={onReview}
            size='large'
            variant='contained'
            color='primary'
            disableElevation
            fullWidth
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Grid>
  )
}

ReviewCertificateControls.defaultProps = {
  showCancelButton: true,
}

ReviewCertificateControls.propTypes = {
  certificate: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IsDeleted: PropTypes.bool.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    UserId: PropTypes.number.isRequired,
    VerificationStatusId: PropTypes.number.isRequired,
    Title: PropTypes.string.isRequired,
    Authority: PropTypes.string,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    IssueDate: PropTypes.string,
    ExpirationDate: PropTypes.string,
    Comment: PropTypes.string,
    PictureUrl: PropTypes.string.isRequired,
  }),
  onClose: PropTypes.func.isRequired,
  showCancelButton: PropTypes.bool,
}

export default ReviewCertificateControls
