import PropTypes from 'prop-types'

import { IconButton } from '@mui/material'
import { NavigationOutlined } from '@mui/icons-material'

const NavigationArrow = ({ heading, onReset }) => {
  return (
    <IconButton
      sx={{ transform: `rotate(${heading}deg)` }}
      color='inherit'
      size='large'
      title='Reset orientation'
      onClick={onReset}
    >
      <NavigationOutlined color='inherit' />
    </IconButton>
  )
}

NavigationArrow.propTypes = {
  heading: PropTypes.number.isRequired,
  onReset: PropTypes.func.isRequired,
}

export default NavigationArrow
