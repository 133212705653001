import { all, takeLatest, put } from 'redux-saga/effects'
import * as notificationActions from '@tabeeb/modules/notification/actions'
import * as rawActions from '../actions'

function* getDashboardsFailed() {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to retrieve dashboards' }))
}

function* dashboardsSaga() {
  yield all([takeLatest(rawActions.getDashboardsFailed, getDashboardsFailed)])
}

export default dashboardsSaga
