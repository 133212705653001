import { createAction } from 'redux-actions'

export const onPlayerPlay = createAction('ON_PLAYER_PLAY')
export const onPlayerPaused = createAction('ON_PLAYER_PAUSED')
export const onPlayerChangeCurrentTimestamp = createAction('ON_PLAYER_CHANGE_CURRENT_TIMESTAMP')
export const onPlayerChangeVolume = createAction('ON_PLAYER_CHANGE_VOLUME')
export const onPlayerMute = createAction('ON_PLAYER_MUTE')
export const onPlayerUnmute = createAction('ON_PLAYER_UNMUTE')
export const onPlayerFullScreen = createAction('ON_PLAYER_FULL_SCREEN')

export const resetCurrentVideoTimestamp = createAction('RESET_CURRENT_VIDEO_TIMESTAMP')

export const seekVideoToTimestamp = createAction('SEEK_VIDEO_TO_TIMESTAMP')
export const resetSeekVideoToTimestamp = createAction('RESET_SEEK_VIDEO_TO_TIMESTAMP')

export const setPlayerPlaying = createAction('SET_PLAYER_PLAYING')
export const setCurrentVideoTimestamp = createAction('SET_CURRENT_VIDEO_TIMESTAMP')
export const setDurationVideoTimestamp = createAction('SET_DURATION_VIDEO_TIMESTAMP')
export const setCurrentVideoVolume = createAction('SET_CURRENT_VIDEO_VOLUME')
export const setCurrentVideoFullScreen = createAction('SET_CURRENT_VIDEO_FULL_SCREEN')

export const getAnswersTimelineForVideoPageRequest = createAction('GET_ANSWERS_TIMELINE_FOR_VIDEO_PAGE_REQUEST')
export const getAnswersTimelineForVideoPageSuccess = createAction('GET_ANSWERS_TIMELINE_FOR_VIDEO_PAGE_SUCCESS')
export const getAnswersTimelineForVideoPageFailed = createAction('GET_ANSWERS_TIMELINE_FOR_VIDEO_PAGE_FAIELD')
