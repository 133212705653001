import { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Formik, Form, Field } from 'formik'
import { Grid, Button, Fade, Typography, Divider } from '@material-ui/core'
import { Card, CardContent } from '@mui/material'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { get } from 'lodash'
import ImageViewer from 'react-simple-image-viewer'
import { parseISO, format, endOfToday } from 'date-fns'

import { FormikTextField, FormikCheckbox } from '@tabeeb/shared/forms'
import { maxLength, requiredField, invalidFieldWithName } from '@tabeeb/shared/utils/validationErrorMessages'
import { useDialogState } from '@tabeeb/shared/utils/hooks'
import { readUploadedFileAsDataUrl } from '@tabeeb/modules/fileUploads/services'
import { CertificateVerificationStatusTypes, UserTenantRole } from '@tabeeb/enums'
import { getCurrentUserId, getIsUserInAnyRole } from '@tabeeb/modules/account/selectors'

import DragAndDropFiles from '../DragAndDropFiles'
import ReviewCertificateControls from '../ReviewCertificateControls'

import useStyles from './styles'

const initialValues = {
  Title: '',
  Authority: '',
  FirstName: '',
  LastName: '',
  IssueDate: '',
  ExpirationDate: '',
  Indefinite: false,
  Photo: null,
}

const certificateSchema = Yup.object().shape({
  Title: Yup.string().trim().max(256, maxLength(256)).required(requiredField),
  Authority: Yup.string().trim().max(256, maxLength(256)).nullable(),
  FirstName: Yup.string().trim().max(128, maxLength(128)).nullable(),
  LastName: Yup.string().trim().max(128, maxLength(128)).nullable(),
  IssueDate: Yup.date()
    .min(new Date(Date.UTC(1900, 0, 0)), invalidFieldWithName('Issue date'))
    .max(endOfToday(), invalidFieldWithName('Issue date'))
    .typeError(invalidFieldWithName('Issue date'))
    .nullable(),
  ExpirationDate: Yup.date()
    .min(new Date(Date.UTC(1900, 0, 0)), invalidFieldWithName('Expiration date'))
    .typeError(invalidFieldWithName('Expiration date'))
    .nullable()
    .when('Indefinite', {
      is: (indefinite) => indefinite === false,
      then: Yup.date().required(requiredField),
    }),
  Indefinite: Yup.bool().default(false),
  Photo: Yup.mixed()
    .nullable()
    .when('PictureUrl', {
      is: (pictureUrl) => !pictureUrl?.length,
      then: Yup.mixed().required(requiredField),
    }),
})

const AddEditCertificate = ({ onClose, onSubmit, selectedCertificate }) => {
  const classes = useStyles()
  const isEditMode = Boolean(selectedCertificate)
  const reviewAvailable = useSelector((state) => getIsUserInAnyRole(state, [UserTenantRole.TenantAdmin]))
  const currentUserId = useSelector(getCurrentUserId)
  const [openImageViewer, onOpenImageViewer, onCloseImageViewer] = useDialogState()
  const [imagePreview, setImagePreview] = useState(null)

  return (
    <>
      <Fade in>
        <div>
          {isEditMode && selectedCertificate.VerificationStatusId === CertificateVerificationStatusTypes.Rejected && (
            <div className={classes.gridPadding}>
              <Card elevation={0} variant='outlined' sx={{ backgroundColor: '#fff7411f' }}>
                <CardContent sx={{ py: 1 }}>
                  <Typography className={classes.rejectedCommentHeader}>
                    Comment ({format(parseISO(selectedCertificate.UpdatedOn), 'MM-dd-yyyy')})
                  </Typography>
                  <Typography>{selectedCertificate.Comment}</Typography>
                </CardContent>
              </Card>
            </div>
          )}
          {(Boolean(imagePreview) || Boolean(selectedCertificate)) && (
            <div className={classes.gridPadding}>
              <input
                alt='Certificate image preview'
                title='Open image'
                className={classes.imagePreview}
                type='image'
                src={imagePreview || selectedCertificate.PictureUrl}
                onClick={onOpenImageViewer}
              />
            </div>
          )}
          <Formik
            initialValues={
              isEditMode ? { ...selectedCertificate, Indefinite: !selectedCertificate.ExpirationDate } : initialValues
            }
            enableReinitialize
            validationSchema={certificateSchema}
            onSubmit={onSubmit}
          >
            {({ dirty, isValid, values, touched, setFieldTouched, errors, setFieldValue }) => (
              <Form autoComplete='off'>
                <Grid container wrap='nowrap' direction='column' spacing={1}>
                  <Grid item>
                    <Field name='Title' color='primary' label='Title' required component={FormikTextField} />
                  </Grid>
                  <Grid item>
                    <Field name='Authority' color='primary' label='Authority' component={FormikTextField} />
                  </Grid>
                  <Grid item container wrap='nowrap' direction='row' spacing={2}>
                    <Grid item xs={6}>
                      <Field name='FirstName' color='primary' label='FirstName' component={FormikTextField} />
                    </Grid>
                    <Grid item xs={6}>
                      <Field name='LastName' color='primary' label='LastName' component={FormikTextField} />
                    </Grid>
                  </Grid>
                  <Grid item container wrap='nowrap' direction='row' spacing={2}>
                    <Grid item xs={values.Indefinite ? 12 : 6}>
                      <KeyboardDatePicker
                        style={{ width: '100%' }}
                        id='date-of-birth-picker'
                        autoOk
                        inputVariant='outlined'
                        format='MM/dd/yyyy'
                        name='IssueDate'
                        label='Start date'
                        value={(values.IssueDate && new Date(values.IssueDate)) || null}
                        onChange={(date) => setFieldValue('IssueDate', date)}
                        onBlur={(e) => setFieldTouched(e.target.name)}
                        error={Boolean(errors.IssueDate)}
                        helperText={errors.IssueDate}
                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                        title='The required date format is MM/DD/YYYY'
                      />
                    </Grid>
                    {!values.Indefinite && (
                      <Grid item xs={6}>
                        <KeyboardDatePicker
                          style={{ width: '100%' }}
                          id='date-of-birth-picker'
                          autoOk
                          inputVariant='outlined'
                          format='MM/dd/yyyy'
                          name='ExpirationDate'
                          label='End date'
                          value={(values.ExpirationDate && new Date(values.ExpirationDate)) || null}
                          onChange={(date) => setFieldValue('ExpirationDate', date)}
                          onBlur={(e) => setFieldTouched(e.target.name)}
                          error={Boolean(get(touched, 'ExpirationDate') && get(errors, 'ExpirationDate'))}
                          helperText={get(touched, 'ExpirationDate') && get(errors, 'ExpirationDate')}
                          KeyboardButtonProps={{ 'aria-label': 'change date' }}
                          title='The required date format is MM/DD/YYYY'
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item>
                    <Field
                      name='Indefinite'
                      color='primary'
                      label='Indefinite'
                      size='small'
                      component={FormikCheckbox}
                    />
                  </Grid>
                  <Grid item>
                    <DragAndDropFiles
                      file={values.Photo}
                      onDropAccepted={async (file) => {
                        setFieldValue('Photo', file[0])
                        const result = await readUploadedFileAsDataUrl(file[0])
                        setImagePreview(result)
                      }}
                      onClearState={() => {
                        setFieldValue('Photo', null)
                        setImagePreview(null)
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    wrap='nowrap'
                    direction='row'
                    spacing={1}
                    className={classes.bottomButtonsContainer}
                  >
                    <Grid item xs={4}>
                      <Button size='large' color='primary' onClick={onClose} disableElevation fullWidth>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={4}>
                      <Button
                        size='large'
                        variant='contained'
                        color='primary'
                        type='submit'
                        disableElevation
                        disabled={!(isValid && dirty)}
                        fullWidth
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          {isEditMode &&
            selectedCertificate.UserId === currentUserId &&
            selectedCertificate.VerificationStatusId === CertificateVerificationStatusTypes.Pending &&
            reviewAvailable && (
              <>
                <Divider className={classes.divider} />
                <ReviewCertificateControls
                  onClose={onClose}
                  certificate={selectedCertificate}
                  showCancelButton={false}
                />
              </>
            )}
        </div>
      </Fade>
      {openImageViewer && (
        <ImageViewer
          src={[`${imagePreview || selectedCertificate.PictureUrl}`]}
          backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.9)', zIndex: 1110 }}
          closeOnClickOutside
          onClose={onCloseImageViewer}
        />
      )}
    </>
  )
}

AddEditCertificate.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedCertificate: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IsDeleted: PropTypes.bool.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    UpdatedOn: PropTypes.string.isRequired,
    UserId: PropTypes.number.isRequired,
    VerificationStatusId: PropTypes.number.isRequired,
    Title: PropTypes.string.isRequired,
    Authority: PropTypes.string.isRequired,
    FirstName: PropTypes.string.isRequired,
    LastName: PropTypes.string.isRequired,
    IssueDate: PropTypes.string,
    ExpirationDate: PropTypes.string,
    Comment: PropTypes.string,
    PictureUrl: PropTypes.string.isRequired,
  }),
}

export default AddEditCertificate
