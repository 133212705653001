import { makeStyles } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'

export default makeStyles((theme) => {
  return {
    cardActionButton: {
      backgroundColor: `${alpha(theme.palette.background.user.action, 0.03)} !important`,
      color: `${theme.palette.grey[400]} !important`,
      '&:hover': {
        backgroundColor: `${alpha(theme.palette.background.user.action, 0.1)} !important`,
      },
    },
    redText: {
      color: '#FF5555',
    },
    verificationStatusLabel: {
      width: 72,
      height: 18,
    },
    certificateCard: {
      position: 'relative',
      borderWidth: '1px',
      borderStyle: 'solid',
      height: '100%',
    },
    pendingStatusCard: {
      borderColor: 'rgba(64, 123, 255, 0.2)',
    },
    rejectedStatusCard: {
      borderColor: 'rgba(255, 85, 85, 0.32)',
    },
    approvedStatusCard: {
      borderColor: 'rgba(64, 123, 255, 0.2)',
    },
    pendingStatusCardHeader: {
      '& > .MuiCardHeader-avatar': {
        background: '#C8D9FF',
        borderRadius: '20px',
        '& > div > p': {
          color: '#006CFF',
        },
      },
    },
    rejectedStatusCardHeader: {
      '& > .MuiCardHeader-avatar': {
        background: '#FFDBDB',
        borderRadius: '20px',
        '& > div > p': {
          color: '#FF5555',
        },
      },
    },
    approvedStatusCardHeader: {
      '& > .MuiCardHeader-avatar': {
        background: '#C9FFC8',
        borderRadius: '20px',
        '& > div > p': {
          color: '#287626',
        },
      },
    },
    verificationStatusText: {
      fontSize: '12px !important',
      textAlign: 'center',
    },
    cardDatesContent: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    titleText: {
      fontWeight: theme.typography.fontWeightBold,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  }
})
