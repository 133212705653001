import { memo } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { Box, Typography } from '@mui/material'

const DateInfo = ({ date }) => {
  const momentDate = moment(date)

  return (
    <Box display='flex' flexDirection='column' minWidth={0}>
      <Typography variant='body2' fontWeight={500}>
        {momentDate.format('D MMM yyyy')}
      </Typography>
      <Typography variant='caption' noWrap>
        {momentDate.format('h:mm A')}
      </Typography>
    </Box>
  )
}

DateInfo.propTypes = {
  date: PropTypes.string.isRequired,
}

export default memo(DateInfo)
