import { AnnotationType } from '@tabeeb/enums'

import Area from '../components/Annotations/Area'
import AILabel from '../components/Annotations/AILabel'
import Box from '../components/Annotations/Box'
import Distance from '../components/Annotations/Distance'
import ModelAnnotation from '../components/Annotations/Model'
import Height from '../components/Annotations/Height'

export const getAnnotationComponent = (type) => {
  switch (type) {
    case AnnotationType.AIHotspot:
      return AILabel
    case AnnotationType.Area:
      return Area
    case AnnotationType.Box:
      return Box
    case AnnotationType.Distance:
      return Distance
    case AnnotationType.Model:
      return ModelAnnotation
    case AnnotationType.Height:
      return Height
    default:
      return null
  }
}
