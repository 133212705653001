export default (theme) => {
  return {
    formControl: {
      '&.MuiFormControl-root': {
        marginTop: theme.spacing(),
      },
    },
    textField: {
      '& .MuiInputBase-root.Mui-disabled': {
        background: theme.palette.action.hover,
      },
    },
    label: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      maxWidth: 565,
    },
  }
}
