const pointCloudRequests = {
  getPointCloudAccessTokenRequest: ({ pageId } = {}) => ({
    url: `api/pages/${pageId}/potreePointCloudSas`,
    method: 'get',
  }),
  getPointCloudSettingsRequest: ({ pageId }) => ({
    url: `api/pages/${pageId}/pointCloudSettings`,
    method: 'get',
  }),
  updatePointCloudSettingsRequest: ({ pageId, settings }) => ({
    url: `api/pages/${pageId}/pointCloudSettings`,
    method: 'put',
    data: settings,
  }),
  get3DModelsRequest: () => ({
    url: `api/threeDModels`,
    method: 'get',
  }),
  create3DModelRequest: ({ model }) => ({
    url: `api/threeDModels`,
    method: 'post',
    data: model,
  }),
  delete3DModelRequest: ({ id }) => ({
    url: `api/threeDModels/${id}`,
    method: 'delete',
  }),
}

export default pointCloudRequests
