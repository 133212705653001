import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { notificationsPageActions } from '@tabeeb/modules/notificationsPage'

import { Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import CircularProgressBar from '@tabeeb/modules/shared/tabeebCircularProgressBar'

import classnames from 'classnames'
import { remindersActions } from '../..'
import './styles.less'

const ReminderNotificationDialog = ({
  notifications,
  actions: { removeReminderNotification },
  notificationsActions: { acceptNotificationRequest },
  isLoading,
}) => {
  const [selectedNotification, setSelectedNotification] = useState(notifications[0])

  const selectedNotificationIndex = selectedNotification
    ? notifications.findIndex((n) => n.Id === selectedNotification.Id)
    : 0

  useEffect(() => {
    setSelectedNotification(notifications[selectedNotificationIndex])
  }, [notifications.length])

  const isOpen = notifications.length > 0

  const backBtnDisabled = selectedNotificationIndex === 0
  const fwdBtnDisabled = selectedNotificationIndex === notifications.length - 1

  if (!selectedNotification) return null

  return (
    <Dialog open={isOpen} disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>
        {notifications.length > 1 ? `Reminder ${selectedNotificationIndex + 1} of ${notifications.length}` : 'Reminder'}
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgressBar />
        ) : (
          <div className='reminder-notification-content'>
            {notifications.length > 1 && (
              <span
                className={classnames('reminder-notification-btn', 'back-btn', {
                  'reminder-notification-btn-disabled': backBtnDisabled,
                })}
                onClick={
                  backBtnDisabled ? null : () => setSelectedNotification(notifications[selectedNotificationIndex - 1])
                }
              >
                <ArrowBackIosIcon fontSize='inherit' />
              </span>
            )}
            <div className='reminder-notification-message'>{selectedNotification.Description}</div>
            {notifications.length > 1 && (
              <span
                className={classnames('reminder-notification-btn', 'forward-btn', {
                  'reminder-notification-btn-disabled': fwdBtnDisabled,
                })}
                onClick={
                  fwdBtnDisabled ? null : () => setSelectedNotification(notifications[selectedNotificationIndex + 1])
                }
              >
                <ArrowForwardIosIcon fontSize='inherit' />
              </span>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => removeReminderNotification(selectedNotification.Id)}
          color='default'
          variant='outlined'
          disabled={isLoading}
        >
          Close
        </Button>

        <Button
          onClick={() => acceptNotificationRequest(selectedNotification)}
          color='primary'
          variant='contained'
          disabled={isLoading}
        >
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ReminderNotificationDialog.propTypes = {
  notifications: PropTypes.array.isRequired,
  actions: PropTypes.shape({
    removeReminderNotification: PropTypes.func.isRequired,
  }).isRequired,
  notificationsActions: PropTypes.shape({
    acceptNotificationRequest: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  const {
    reminders: {
      remindersNotificationsDialogState: { list, isLoading },
    },
  } = state

  return {
    notifications: list,
    isLoading,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(remindersActions, dispatch),
    notificationsActions: bindActionCreators(notificationsPageActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReminderNotificationDialog)
