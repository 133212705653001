import { createTheme as createV5Theme } from '@mui/material'
import { createTheme as createV4Theme } from '@material-ui/core'

import { KeyboardArrowDownOutlined } from '@material-ui/icons'
import { grey } from '@mui/material/colors'

const spacing = 8

export const modernV4 = createV4Theme({
  palette: {
    type: 'light',
    primary: {
      main: '#1471EB',
    },
    secondary: {
      main: '#ffffff',
    },
    background: {
      message: {
        primary: '#f5f5f5',
        secondary: '#E1F5FD',
        unread: '#82D5F8',
      },
      user: {
        action: 'rgba(0, 0, 0, 0.35)',
      },
      formAnswerStatus: {
        noAction: '#d3d3d3',
        approved: '#4CAF50',
        rejected: '#f44336',
        submitted: '#00BCD4',
      },
    },
    text: {
      primary: '#0E1621',
      secondary: '#8A898E',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Inter,Arial,sans-serif',
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiMenu: {
      getContentAnchorEl: null,
    },
    MuiSelect: {
      variant: 'standard',
      IconComponent: KeyboardArrowDownOutlined,
    },
    MuiTooltip: {
      enterDelay: 500,
      enterNextDelay: 500,
    },
    MuiListItemText: {
      primaryTypographyProps: { variant: 'body2' },
      secondaryTypographyProps: { variant: 'caption' },
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    MuiButton: {
      root: {
        padding: `${spacing}px ${spacing * 2}px`,
        fontWeight: 600,
        minWidth: 'max-content',
        textTransform: 'initial',
      },
      label: {
        whiteSpace: 'nowrap',
      },
      outlined: {
        padding: `${spacing - 1}px ${spacing * 2 - 1}px`,
      },
    },
    MuiDialogActions: {
      root: {
        paddingInline: spacing * 3,
        paddingBlockEnd: spacing * 2,
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: 'overlay',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1em',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiListItemText: {
      multiline: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiFab: {
      root: {
        boxShadow: '0px 2px 3px -1px rgb(0 0 0 / 75%), 0px -1px 1px -1px rgb(0 0 0 / 50%)',
      },
    },
  },
})

export const modernV5 = createV5Theme({
  palette: {
    type: 'light',
    primary: {
      main: '#1471EB',
    },
    secondary: {
      main: '#ffffff',
    },
    grey: {
      main: grey[800],
    },
    background: {
      message: {
        primary: '#f5f5f5',
        secondary: '#E1F5FD',
        unread: '#82D5F8',
      },
      user: {
        action: 'rgba(0, 0, 0, 0.35)',
      },
      formAnswerStatus: {
        noAction: '#d3d3d3',
        approved: '#4CAF50',
        rejected: '#f44336',
        submitted: '#00BCD4',
      },
    },
    text: {
      primary: '#0E1621',
      secondary: '#8A898E',
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Inter,Arial,sans-serif',
  },
  components: {
    MuiButton: {
      defaultProps: {
        color: 'grey',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: `${spacing}px ${spacing * 2}px`,
          fontWeight: 600,
          minWidth: 'max-content',
          textTransform: 'initial',
        },
        outlinedGrey: {
          borderColor: grey[400],

          ':hover': {
            borderColor: grey[400],
          },
        },
        label: {
          whiteSpace: 'nowrap',
        },
        outlined: {
          padding: `${spacing - 1}px ${spacing * 2 - 1}px`,
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'body1',
          noWrap: true,
        },
      },
      styleOverrides: {
        content: {
          minWidth: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingInline: spacing * 3,
          paddingBlockEnd: spacing * 2,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          // https://github.com/mui/material-ui/issues/27851
          paddingTop: `${spacing}px !important`,
          paddingBlock: spacing,
          paddingInline: spacing * 3,
          overflowY: 'overlay',
        },
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          borderBottomLeftRadius: 8,
          borderBottomRightRadius: 8,
          overflow: 'hidden',
        },
      },
    },
    MuiMenu: {
      defaultProps: {},
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
        IconComponent: KeyboardArrowDownOutlined,
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 500,
        enterNextDelay: 500,
      },
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          minWidth: 0,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },
    MuiListItemText: {
      defaultProps: {
        primaryTypographyProps: {
          variant: 'body2',
          noWrap: true,
        },
        secondaryTypographyProps: {
          variant: 'caption',
          noWrap: true,
        },
      },
      styleOverrides: {
        multiline: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          boxShadow: '0px 2px 3px -1px rgb(0 0 0 / 75%), 0px -1px 1px -1px rgb(0 0 0 / 50%)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 700,
        },
      },
    },
  },
  shape: {
    borderRadius: 8,
  },
})
