import { handleActions, combineActions } from 'redux-actions'

import { allFormsActions } from '..'

const defaultState = false

export default handleActions(
  {
    [allFormsActions.getPagedAllFormsRequest](state, action) {
      return true
    },
    [combineActions(allFormsActions.getPagedAllFormsFailed, allFormsActions.getPagedAllFormsSuccess)](state, action) {
      return false
    },
    [allFormsActions.resetAllFormsPage](state, action) {
      return defaultState
    },
  },
  defaultState
)
