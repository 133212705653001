import { memo } from 'react'

import { Typography } from '@mui/material'

const Compass = () => {
  return (
    <Typography color='inherit' variant='caption'>
      N
    </Typography>
  )
}

export default memo(Compass)
