import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { startSubmit, isSubmitting } from 'redux-form'

import * as rawActions from '../actions'
import AddVideoFromUrlDialog from '../components/AddVideoFromUrlDialog'
import { createVideoByUrlPage } from '../services/createVideoByUrlPage'
import { ADD_VIDEO_FROM_URL_DIALOG_FORM } from '../constants'
import { getInsertionIndex } from '../selectors'

class AddVideoFromUrlDialogContainer extends Component {
  handleСloseAddVideoDialog = () => {
    const {
      actions: { changeGalleryDisplayAddVideoDialog },
    } = this.props

    changeGalleryDisplayAddVideoDialog(false)
  }

  onSubmit = (data) => {
    const {
      actions: { uploadGalleryVideoByUrlRequest },
      isSubmitting,
      startSubmit,
      selectedGalleryItemId,
      selectedFolderId,
      galleryList,
      contentId,
      documentId,
      insertionIndex,
    } = this.props
    const url = data.url.trim()
    const page = createVideoByUrlPage(
      contentId,
      documentId,
      galleryList,
      selectedGalleryItemId,
      url,
      selectedFolderId,
      insertionIndex
    )

    if (!isSubmitting) {
      uploadGalleryVideoByUrlRequest({ page, isSelectAddedGalleryItem: true })
      startSubmit()
    }

    this.handleСloseAddVideoDialog()
  }

  render() {
    const { displayAddVideoDialog } = this.props

    const props = {
      displayAddVideoDialog,
      handleСloseAddVideoDialog: this.handleСloseAddVideoDialog,
      onSubmit: this.onSubmit,
    }

    return <AddVideoFromUrlDialog {...props} />
  }
}

AddVideoFromUrlDialogContainer.propTypes = {
  displayAddVideoDialog: PropTypes.bool.isRequired,
  galleryList: PropTypes.array.isRequired,
  selectedGalleryItemId: PropTypes.number,
  selectedFolderId: PropTypes.number,
  contentId: PropTypes.number,
  documentId: PropTypes.number,
  isSubmitting: PropTypes.bool,
  startSubmit: PropTypes.func,
  actions: PropTypes.shape({
    changeGalleryDisplayAddVideoDialog: PropTypes.func.isRequired,
    uploadGalleryVideoByUrlRequest: PropTypes.func.isRequired,
  }).isRequired,
  insertionIndex: PropTypes.number,
}

const mapStateToProps = (state) => {
  return {
    displayAddVideoDialog: state.gallery.controlPannel.displayAddVideoDialog,
    galleryList: state.gallery.galleryList,
    selectedGalleryItemId: state.gallery.galleryState.selectedGalleryItemId,
    selectedFolderId: state.gallery.galleryState.selectedFolderId,
    contentId: state.contentState.contentId,
    documentId: state.contentState.documentId,
    isSubmitting: isSubmitting(ADD_VIDEO_FROM_URL_DIALOG_FORM)(state),
    insertionIndex: getInsertionIndex(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(rawActions, dispatch),
  startSubmit: () => dispatch(startSubmit(ADD_VIDEO_FROM_URL_DIALOG_FORM)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddVideoFromUrlDialogContainer)
