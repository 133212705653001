import PropTypes from 'prop-types'
import classNames from 'classnames'

import { Toolbar, Box, withStyles } from '@material-ui/core'
import { AppBar, Collapse, Skeleton } from '@mui/material'

import Logo from '../Logo'
import Tabs from '../Tabs'
import User from '../../containers/UserContainer'

import ControlsPannel from '../ControlsPannel'
import NavigationContainer from '../../containers/NavigationContainer'

import styles from './styles'

const Navbar = ({ classes, backgroundColor, mainTextColor, displayTabs, isAuthorized }) => (
  <Collapse in={isAuthorized} sx={{ minHeight: 'unset !important' }}>
    <AppBar sx={{ backgroundColor, color: mainTextColor }} position='sticky'>
      <Toolbar className={classes.toolbar}>
        <Box className={classNames(classes.side, classes.left, { [classes.grow]: displayTabs })}>
          <Logo />
        </Box>
        {!displayTabs && <NavigationContainer />}
        <Tabs />
        {!displayTabs && <ControlsPannel />}

        <Box className={classNames(classes.side, classes.right, { [classes.grow]: displayTabs })}>
          {isAuthorized ? (
            <User />
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexWrap: 'nowrap',
                p: 0.5,
                mx: 1,
              }}
            >
              <Skeleton variant='circular' width={40} height={40} />
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  </Collapse>
)

Navbar.propTypes = {
  classes: PropTypes.shape({
    side: PropTypes.string.isRequired,
    left: PropTypes.string.isRequired,
    right: PropTypes.string.isRequired,
    grow: PropTypes.string.isRequired,
    toolbar: PropTypes.string.isRequired,
    container: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
  }).isRequired,
  backgroundColor: PropTypes.string,
  mainTextColor: PropTypes.string,
  displayTabs: PropTypes.bool.isRequired,
  isAuthorized: PropTypes.bool.isRequired,
}

export default withStyles(styles)(Navbar)
