import { memo } from 'react'
import PropTypes from 'prop-types'

import { Mesh, MeshStandardMaterial, Points, PointsMaterial, Vector3 } from 'three'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

import { useLoader } from '@react-three/fiber'

import { Defaults } from '../../../constants'
import { useModelRotation, useObjectFocus } from '../../../hooks'

const StlModel = ({ id, azimuth, url, position, points, tilt, focus, onClick, onProgress }) => {
  const stl = useLoader(STLLoader, url, null, onProgress)

  const model = points
    ? new Points(stl, new PointsMaterial({ vertexColors: true, size: Defaults.POINT_SIZE }))
    : new Mesh(stl, new MeshStandardMaterial())

  useModelRotation({ model, tilt, azimuth })
  useObjectFocus(id, model, focus)

  return <primitive position={position} object={model} onClick={onClick} />
}

StlModel.defaultProps = {
  points: false,
}

StlModel.propTypes = {
  id: PropTypes.number.isRequired,
  azimuth: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  position: PropTypes.instanceOf(Vector3).isRequired,
  points: PropTypes.bool,
  tilt: PropTypes.number.isRequired,
  focus: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onProgress: PropTypes.func.isRequired,
}

export default memo(StlModel)
