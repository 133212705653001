import _ from 'lodash'
import { Vector3 } from 'three'

/**
 * @param {Vector3} start
 * @param {Vector3} end
 * @param {Number} position
 * @returns {Vector3}
 */
export const getIntermediate = (start, end, position = 0.5) => {
  const x = start.x + (end.x - start.x) * position
  const y = start.y + (end.y - start.y) * position
  const z = start.z + (end.z - start.z) * position

  return new Vector3(x, y, z)
}

/**
 * @param {Vector3} start
 * @param {Vector3} end
 * @returns {Vector3}
 */
export const getCenter = (start, end) => {
  return getIntermediate(start, end, 0.5)
}
