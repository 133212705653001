import { memo, useState, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { MoreHoriz } from '@material-ui/icons'
import { Button, withStyles } from '@material-ui/core'

import { getCurrentUserId } from '@tabeeb/modules/account/selectors'
import { getShowCertificates } from '@tabeeb/modules/appConfigState/selectors'
import UserOptionsMenu from '../UserOptionsMenu'
import CurrentUserOptionsMenu from '../CurrentUserOptionsMenu'
import { usersSelectors } from '../..'

import styles from './styles'

const UserOptions = ({ classes, user }) => {
  const optionsVisibilitySelector = useMemo(usersSelectors.makeGetIsUserOptionsButtonVisible, [])
  const isVisible = useSelector((state) => optionsVisibilitySelector(state, { user }))

  const deviceOptionsVisibilitySelector = useMemo(usersSelectors.makeGetIsUserDeviceOptionsButtonVisible, [])
  const isDeviceMenuVisible = useSelector((state) => deviceOptionsVisibilitySelector(state, { user }))

  const healthInfoOptionsVisibilitySelector = useMemo(usersSelectors.makeGetIsHealthInfoOptionsVisible, [])
  const isHealthInfoOptionsVisible = useSelector((state) => healthInfoOptionsVisibilitySelector(state, { user }))
  const showCertificates = useSelector(getShowCertificates)
  const currentUserId = useSelector(getCurrentUserId)

  const isCurrentUserOptionsVisible =
    isDeviceMenuVisible || isHealthInfoOptionsVisible || (showCertificates && user.id === currentUserId)
  const showMenu = isVisible || isCurrentUserOptionsVisible

  const buttonRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return showMenu ? (
    <>
      <Button ref={buttonRef} size='small' className={classes.container} onClick={handleOpen}>
        <MoreHoriz />
      </Button>
      {isCurrentUserOptionsVisible && (
        <CurrentUserOptionsMenu
          isOpen={isOpen}
          anchorEl={buttonRef.current}
          onClose={handleClose}
          isDeviceMenuVisible={isDeviceMenuVisible}
          isHealthInfoOptionsVisible={isHealthInfoOptionsVisible}
        />
      )}
      {isVisible && <UserOptionsMenu isOpen={isOpen} anchorEl={buttonRef.current} onClose={handleClose} user={user} />}
    </>
  ) : null
}

UserOptions.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
  }).isRequired,
  user: PropTypes.object.isRequired,
}

export default memo(withStyles(styles)(UserOptions))
