import { memo } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'

import { Box, Paper, Typography } from '@mui/material'

import { AnnouncementSeverity } from '../../enums'

import AnnouncementText from '../AnnouncementText'
import AnnouncementSeverityChip from '../AnnouncementSeverityChip'

const Announcement = ({ announcement }) => {
  return (
    <Box
      component='section'
      width='100%'
      height='100%'
      display='flex'
      flexDirection='column'
      alignItems='stretch'
      justifyContent='stretch'
    >
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <Box display='flex' flexDirection='column' minWidth={0}>
          <Box display='flex' alignItems='center'>
            <Typography variant='h6' title={announcement.Name} noWrap sx={{ mr: 1 }}>
              {announcement.Name}
            </Typography>
            <AnnouncementSeverityChip severity={announcement.Severity} />
          </Box>

          <Typography variant='caption' noWrap>
            Posted {moment(announcement.CreatedOn).calendar()}
          </Typography>
        </Box>
      </Box>
      <Paper variant='outlined' sx={{ flexGrow: 1, mt: 1 }}>
        <AnnouncementText text={announcement.Content} />
      </Paper>
    </Box>
  )
}

Announcement.propTypes = {
  announcement: PropTypes.shape({
    Name: PropTypes.string.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    Content: PropTypes.string.isRequired,
    Severity: PropTypes.oneOf(Object.values(AnnouncementSeverity)).isRequired,
  }).isRequired,
}

export default memo(Announcement)
