import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import classNames from 'classnames'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'

import { gallerySelectors } from '@tabeeb/modules/gallery'
import { contentViewerActions, contentViewerSelectors } from '@tabeeb/modules/contentViewer'
import { playerSelectors, playerActions } from '../..'

import Error from '../Error'
import PlayerControls from '../PlayerControls'

import './styles.less'

const VideoPlayer = ({
  playerActions,
  contentViewerActions,
  contentUrl,
  isPlaying,
  isAudioMuted,
  isFullscreen,
  seekToTimestamp,
  volume,
  isMuted,
  duration,
  playerSize,
  currentTime,
}) => {
  const container = useRef()
  const playerRef = useRef()
  const [isFailed, setIsFailed] = useState(false)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    screenfull.onchange((e) => {
      playerActions.onPlayerFullScreen(screenfull.isFullscreen)
    })
  }, [])

  useEffect(() => {
    if (isFullscreen === true) {
      screenfull.request(container.current)
    } else {
      screenfull.exit()
    }
  }, [isFullscreen])

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.player.handleEnded()
    }

    setIsFailed(false)
    setIsReady(false)
    playerActions.setCurrentVideoVolume(1)
    playerActions.setCurrentVideoFullScreen(false)
  }, [contentUrl])

  useEffect(() => {
    if (isReady) {
      if (playerRef.current.getCurrentTime() !== seekToTimestamp.value && seekToTimestamp.value >= 0) {
        playerRef.current.seekTo(seekToTimestamp.value, 'seconds')
        playerActions.resetSeekVideoToTimestamp()
      }
    }
  }, [seekToTimestamp, isReady])

  const handleMute = () => {
    isMuted ? playerActions.onPlayerUnmute() : playerActions.onPlayerMute()
  }

  const handleVolume = (_, volume) => {
    if (isMuted) playerActions.onPlayerUnmute()
    playerActions.setCurrentVideoVolume(volume / 100)
  }

  const handleSeek = (_, timestamp) => {
    playerActions.seekVideoToTimestamp(timestamp)
  }

  const handlePlay = () => {
    playerActions.setPlayerPlaying(true)
  }

  const handlePause = () => {
    playerActions.setPlayerPlaying(false)
  }

  const handleError = (error) => {
    let showError = false
    showError |= Boolean(error.target && error.target.error && error.target.error.code !== MediaError.MEDIA_ERR_ABORTED)
    showError |= Boolean(error.code && error.code !== DOMException.MEDIA_ERR_ABORTED)

    if (showError) {
      contentViewerActions.resetLoadingInProgress()
      setIsFailed(true)
      playerActions.onPlayerPaused()
    }
  }

  const handleEnded = () => {
    playerActions.onPlayerPaused()
  }

  const handleProgress = (e) => {
    playerActions.onPlayerChangeCurrentTimestamp(e.playedSeconds * 1000)
  }

  const handleFullscreen = () => {
    playerActions.onPlayerFullScreen(!isFullscreen)
  }

  const handleVideoDuration = (duration) => {
    playerActions.setDurationVideoTimestamp(duration)

    const video = playerRef.current.wrapper.getElementsByTagName('video')[0]
    if (video) {
      contentViewerActions.setContentSize({ height: video.videoHeight, width: video.videoWidth })
    }

    setIsFailed(false)
    setIsReady(true)
    contentViewerActions.resetLoadingInProgress()
  }

  let videoUrl = contentUrl

  const isEncodedVideo = videoUrl.split('?')[0].endsWith('.ism/manifest')
  if (isEncodedVideo) {
    videoUrl = `${videoUrl.split('?')[0]}(format=m3u8-aapl)`
  }

  return (
    <div
      ref={container}
      className={classNames('tabeeb-video-player', 'viewer-content')}
      style={{
        pointerEvents: isFullscreen ? null : 'none',
        width: playerSize.width,
        height: playerSize.height,
      }}
    >
      {isFailed && <Error />}
      <ReactPlayer
        style={{
          visibility: isReady ? 'visible' : 'hidden',
        }}
        config={{
          youtube: {
            playerVars: {
              enablejsapi: 0,
              iv_load_policy: 3,
              disablekb: 1,
              wmode: 'transparent',
              fs: 0,
              controls: 0,
              playsinline: 0,
              showinfo: 0,
              rel: 0,
              autoplay: 0,
              loop: 0,
              hl: 'en',
              modestbranding: 1,
            },
          },
          file: {
            forceHLS: isEncodedVideo,
            attributes: {
              crossOrigin: 'anonymous',
              controlsList: 'nofullscreen',
            },
          },
        }}
        width='100%'
        height='100%'
        ref={playerRef}
        onPlay={handlePlay}
        onPause={handlePause}
        onError={handleError}
        onEnded={handleEnded}
        onProgress={handleProgress}
        onDuration={handleVideoDuration}
        url={videoUrl}
        volume={volume}
        playing={isPlaying}
        muted={isAudioMuted}
        controls={false}
        progressInterval={0.25}
      />
      {isFullscreen && (
        <PlayerControls
          onSeek={handleSeek}
          onPlay={handlePlay}
          onPause={handlePause}
          playing={isPlaying}
          elapsedTime={currentTime}
          totalDuration={duration}
          onMute={handleMute}
          muted={isAudioMuted}
          onToggleFullScreen={handleFullscreen}
          volume={volume}
          onVolumeChange={handleVolume}
        />
      )}
    </div>
  )
}

VideoPlayer.propTypes = {
  volume: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  contentUrl: PropTypes.string.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  currentTime: PropTypes.number.isRequired,
  isMuted: PropTypes.bool.isRequired,
  isAudioMuted: PropTypes.bool.isRequired,
  isFullscreen: PropTypes.bool.isRequired,
  seekToTimestamp: PropTypes.shape({
    value: PropTypes.number.isRequired,
  }).isRequired,
  playerActions: PropTypes.shape({
    resetCurrentVideoTimestamp: PropTypes.func.isRequired,
    onPlayerFullScreen: PropTypes.func.isRequired,
    setDurationVideoTimestamp: PropTypes.func.isRequired,
    onPlayerChangeCurrentTimestamp: PropTypes.func.isRequired,
    resetSeekVideoToTimestamp: PropTypes.func.isRequired,
    onPlayerPaused: PropTypes.func.isRequired,
    setPlayerPlaying: PropTypes.func.isRequired,
    seekVideoToTimestamp: PropTypes.func.isRequired,
    setCurrentVideoVolume: PropTypes.func.isRequired,
    onPlayerUnmute: PropTypes.func.isRequired,
    onPlayerMute: PropTypes.func.isRequired,
    setCurrentVideoFullScreen: PropTypes.func.isRequired,
  }),
  contentViewerActions: PropTypes.shape({
    resetLoadingInProgress: PropTypes.func.isRequired,
    setContentSize: PropTypes.func.isRequired,
  }),
  playerSize: PropTypes.shape({
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }).isRequired,
}

const mapStateToProps = (state) => {
  const selectedGalleryItem = gallerySelectors.getSelectedGalleryItem(state)
  const { contentUrl } = selectedGalleryItem

  return {
    contentUrl,
    volume: playerSelectors.getVolume(state),
    duration: playerSelectors.getDuration(state),
    isPlaying: playerSelectors.getIsVideoPlaying(state),
    isAudioMuted: playerSelectors.getIsAudioMuted(state),
    isFullscreen: playerSelectors.getIsFullscreen(state),
    seekToTimestamp: playerSelectors.getSeekToTimestamp(state),
    currentTime: playerSelectors.getCurrentVideoTimestamp(state),
    playerSize: contentViewerSelectors.getScaledContentSize(state),
    isMuted: playerSelectors.getIsAudioMuted(state),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    playerActions: bindActionCreators(playerActions, dispatch),
    contentViewerActions: bindActionCreators(contentViewerActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer)
