import qs from 'qs'

const usersRequests = {
  kickUserRequest: (data) => ({
    url: `api/contents/removeUser`,
    method: 'post',
    data,
  }),
  searchUserRequest: ({ contentId, searchText, roles }) => ({
    url: `api/users`,
    method: 'get',
    params: {
      search: searchText,
      contentId,
      roles,
    },
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  }),
  getUserByIdRequest: ({ userId }) => ({
    url: `api/users/getuserbyid/${userId}`,
    method: 'get',
  }),
  getUserByGuidRequest: ({ userGuid }) => ({
    url: `api/users/getuserbyGuid/${userGuid}`,
    method: 'get',
  }),
  switchContentUserRoleRequest: (data) => ({
    url: `api/contents/user/role`,
    method: 'post',
    data,
  }),
  searchUsersBaseRequest: (data) => ({
    url: `api/users`,
    method: 'get',
    params: data,
    paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
  }),
  updateUserSignatureRequest: (data) => ({
    url: `api/users/signature`,
    method: 'put',
    data,
  }),
}

export default usersRequests
