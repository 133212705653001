import { all, takeLatest } from 'redux-saga/effects'

import * as aiActions from '../actions'

function* onUpdateAiClassFailed(action) {
  console.error('------------- onAddAiClassFailed:', action)
}

function* updateAiClass() {
  yield all([takeLatest(aiActions.updateAiClassFailed, onUpdateAiClassFailed)])
}

export default updateAiClass
