import { memo } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { Defaults } from '../../../constants'
import { toVector } from '../../../utils/vectors'

import Line from '../Line'

const Box = ({ annotation, children }) => {
  const points = _.flatMap(annotation.Coordinates, (coordinate) => [
    toVector(coordinate.Start),
    toVector(coordinate.End),
  ])

  const props = {
    color: annotation.Color,
    visible: !annotation.Hidden,
    width: annotation.Width,
  }

  return (
    <group rotation={Defaults.ROTATION}>
      <Line points={[points[0], points[1], points[2], points[3]]} {...props} />

      <Line points={[points[4], points[5], points[6], points[7]]} {...props} />

      <Line points={[points[0], points[4]]} {...props} />
      <Line points={[points[1], points[5]]} {...props} />
      <Line points={[points[2], points[6]]} {...props} />
      <Line points={[points[3], points[7]]} {...props} />

      <group position={points[0]}>{children}</group>
    </group>
  )
}

Box.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Color: PropTypes.string.isRequired,
    Coordinates: PropTypes.arrayOf(
      PropTypes.shape({
        Start: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
          Z: PropTypes.number.isRequired,
        }),
        End: PropTypes.shape({
          X: PropTypes.number.isRequired,
          Y: PropTypes.number.isRequired,
          Z: PropTypes.number.isRequired,
        }),
      })
    ).isRequired,
    Hidden: PropTypes.bool,
    Width: PropTypes.number.isRequired,
  }).isRequired,
  children: PropTypes.element,
}

export default memo(Box)
