import { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

import { MuiThemeProvider } from '@material-ui/core'

import { tabeebTheme } from '@tabeeb/modules/shared/uikit'
import PointCloudSettingsButton from '@tabeeb/modules/playerToolbar/components/PointCloudSettingsButton'

import { getPointSize } from '@tabeeb/modules/pointCloud/selectors'
import { setPointSize } from '@tabeeb/modules/pointCloud/actions'

const PointSizeSettings = ({ onChange }) => {
  const dispatch = useDispatch()
  const pointSize = useSelector(getPointSize)

  const onSubmit = useCallback(
    (value) => {
      dispatch(setPointSize(value))
    },
    [dispatch]
  )

  const container = document.getElementById('point-cloud-settings-button-container')
  if (!container) {
    return null
  }

  return createPortal(
    <MuiThemeProvider theme={tabeebTheme}>
      <PointCloudSettingsButton defaultPointSize={pointSize} onChange={onChange} onSubmit={onSubmit} />
    </MuiThemeProvider>,
    container
  )
}

PointSizeSettings.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default memo(PointSizeSettings)
