import { selectGalleryItem } from '@tabeeb/modules/gallery/actions'
import { onDisableSelection } from '@tabeeb/modules/playerToolbar/actions/drawing'
import { combineActions, handleActions } from 'redux-actions'

import { annotationsActions } from '..'

const defaultState = []

export default handleActions(
  {
    [annotationsActions.setSelectedAnnotationsIds](state, action) {
      const annotationIds = action.payload

      return annotationIds
    },
    [annotationsActions.addAnnotationToSelection](state, action) {
      const annotationId = action.payload

      return [...state, annotationId]
    },
    [annotationsActions.removeAnnotationFromSelection](state, action) {
      const annotationId = action.payload

      return state.filter((id) => id !== annotationId)
    },
    [combineActions(
      selectGalleryItem,
      onDisableSelection,
      annotationsActions.resetAnnotationsState,
      annotationsActions.resetSelectedAnnotationsIds
    )](state, action) {
      return defaultState
    },
  },
  defaultState
)
