import { handleActions } from 'redux-actions'

import { calendarActions } from '..'

const defaultState = {
  open: false,
  eventId: undefined,
  eventCreatorId: undefined,
  startDate: undefined,
  endDate: undefined,
}

export default handleActions(
  {
    [calendarActions.openBookAppointmentSlotDialog](state, action) {
      return {
        open: true,
        ...action.payload,
      }
    },
    [calendarActions.closeBookAppointmentSlotDialog](state, action) {
      return {
        ...state,
        open: false,
      }
    },
    [calendarActions.resetCalendarState](state, action) {
      return defaultState
    },
  },
  defaultState
)
