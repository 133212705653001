import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import {
  Card,
  CardMedia,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  CardActionArea,
} from '@mui/material'
import { Typography } from '@material-ui/core'
import { MoreVert, ModeEditOutlineOutlined, DeleteOutlined } from '@mui/icons-material'
import { useCallback, useState } from 'react'
import moment from 'moment'
import classNames from 'classnames'

import { conditionalPropType } from '@tabeeb/shared/utils/helpers'
import { useDialogState, usePopoverState } from '@tabeeb/shared/utils/hooks'
import { CertificateVerificationStatusTypes, CertificateVerificationStatusTypesDisplayNames } from '@tabeeb/enums'
import { ConfirmationDialog } from '@tabeeb/shared/dialogs'

import { CertificatesPopoverActionOptions } from '../../constants'
import { deleteCertificate } from '../../actions'

import useStyles from './styles'

const popoverOptions = [
  { id: CertificatesPopoverActionOptions.Edit, displayName: 'Edit', icon: <ModeEditOutlineOutlined /> },
  { id: CertificatesPopoverActionOptions.Delete, displayName: 'Delete', icon: <DeleteOutlined /> },
]

const cardActionAreaWrapper = (children, condition, onClick) => {
  if (condition) {
    return <CardActionArea onClick={onClick}>{children}</CardActionArea>
  }

  return children
}

const Certificate = ({ certificate, setCertificateToEditing, setCertificateToView, isMyCertificates, readOnly }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [anchorRef, open, handleOpen, handleClose] = usePopoverState()

  const [dialogIdToDelete, setDialogIdToDelete] = useState(null)
  const handleDeleteDialog = useCallback(() => {
    dispatch(deleteCertificate.request(dialogIdToDelete))
  }, [dialogIdToDelete, dispatch])

  const [deleteCertificateOpen, onDeleteCertificateOpen, onDeleteCertificateClose] = useDialogState(false)
  const handleClickPopoverOption = useCallback(
    (actionOption) => {
      switch (actionOption) {
        case CertificatesPopoverActionOptions.Edit: {
          setCertificateToEditing(certificate)
          break
        }
        case CertificatesPopoverActionOptions.Delete: {
          handleClose()
          setDialogIdToDelete(certificate.Id)
          onDeleteCertificateOpen()
          break
        }
        default:
          break
      }
    },
    [certificate, handleClose, onDeleteCertificateOpen, setCertificateToEditing]
  )

  const getExpirationDateTypography = useCallback(
    (expirationDateString) => {
      const now = moment()
      const expirationDate = moment(expirationDateString)
      const diffInDays = expirationDate.diff(now, 'days')
      const moreThan21Day = diffInDays >= 21
      return (
        <Typography variant='subtitle2' className={classNames({ [classes.redText]: !moreThan21Day })}>
          {moreThan21Day || diffInDays <= 0
            ? expirationDate.format('MM/DD/yyyy')
            : `${diffInDays} ${diffInDays > 1 ? 'days' : 'day'} left`}
        </Typography>
      )
    },
    [classes.redText]
  )

  return (
    <>
      <Card
        className={classNames(classes.certificateCard, {
          [classes.pendingStatusCard]: certificate.VerificationStatusId === CertificateVerificationStatusTypes.Pending,
          [classes.rejectedStatusCard]:
            certificate.VerificationStatusId === CertificateVerificationStatusTypes.Rejected ||
            certificate.VerificationStatusId === CertificateVerificationStatusTypes.Expired,
          [classes.approvedStatusCard]:
            certificate.VerificationStatusId === CertificateVerificationStatusTypes.Verified,
        })}
      >
        {cardActionAreaWrapper(
          <>
            <CardHeader
              sx={{ position: 'absolute', width: '100%', padding: 1 }}
              className={classNames({
                [classes.pendingStatusCardHeader]:
                  certificate.VerificationStatusId === CertificateVerificationStatusTypes.Pending,
                [classes.rejectedStatusCardHeader]:
                  certificate.VerificationStatusId === CertificateVerificationStatusTypes.Rejected ||
                  certificate.VerificationStatusId === CertificateVerificationStatusTypes.Expired,
                [classes.approvedStatusCardHeader]:
                  certificate.VerificationStatusId === CertificateVerificationStatusTypes.Verified,
              })}
              avatar={
                <div className={classes.verificationStatusLabel}>
                  <Typography className={classes.verificationStatusText}>
                    {CertificateVerificationStatusTypesDisplayNames[certificate.VerificationStatusId]}
                  </Typography>
                </div>
              }
              action={
                !readOnly && (
                  <IconButton
                    size='small'
                    className={classes.cardActionButton}
                    aria-label='settings'
                    ref={anchorRef}
                    onClick={isMyCertificates ? handleOpen : () => setCertificateToView(certificate)}
                  >
                    <MoreVert />
                  </IconButton>
                )
              }
            />
            <CardMedia component='img' height='95' image={certificate.PictureUrl} alt='Certificate photo' />
            <CardContent sx={{ '&:last-child': { paddingBottom: 1 }, background: '#FAF9F9', height: '100%' }}>
              <Typography variant='body2' className={classes.titleText}>
                {certificate.Title}
              </Typography>
              <div className={classes.cardDatesContent}>
                {certificate.IssueDate && certificate.ExpirationDate ? (
                  <>
                    {getExpirationDateTypography(certificate.ExpirationDate)}
                    <Typography variant='subtitle2'>{moment(certificate.IssueDate).format('MM/DD/yyyy')}</Typography>
                  </>
                ) : certificate.IssueDate ? (
                  <Typography variant='subtitle2'>
                    Since {moment(certificate.IssueDate).format('MM/DD/yyyy')}
                  </Typography>
                ) : (
                  certificate.ExpirationDate && getExpirationDateTypography(certificate.ExpirationDate)
                )}
              </div>
            </CardContent>
          </>,
          readOnly,
          () => setCertificateToView(certificate)
        )}
      </Card>
      <Menu anchorEl={anchorRef.current} open={open} onClose={handleClose}>
        {popoverOptions.map((option) => (
          <MenuItem key={option.id} onClick={() => handleClickPopoverOption(option.id)}>
            <ListItemIcon>{option.icon}</ListItemIcon>
            <Typography noWrap>{option.displayName}</Typography>
          </MenuItem>
        ))}
      </Menu>
      <ConfirmationDialog
        isOpen={deleteCertificateOpen}
        onSubmit={handleDeleteDialog}
        onCloseDialog={onDeleteCertificateClose}
        title='Are you sure you want to delete this certificate?'
      />
    </>
  )
}

Certificate.propTypes = {
  certificate: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    IsDeleted: PropTypes.bool.isRequired,
    CreatedOn: PropTypes.string.isRequired,
    UserId: PropTypes.number.isRequired,
    VerificationStatusId: PropTypes.number.isRequired,
    UpdateOn: PropTypes.string,
    Title: PropTypes.string.isRequired,
    Authority: PropTypes.string.isRequired,
    FirstName: PropTypes.string.isRequired,
    LastName: PropTypes.string.isRequired,
    IssueDate: PropTypes.string,
    ExpirationDate: PropTypes.string,
    Comment: PropTypes.string,
    PictureUrl: PropTypes.string.isRequired,
  }).isRequired,
  setCertificateToEditing: conditionalPropType(
    (props, propName) =>
      props.isMyCertificates === true && props.readOnly === false && typeof props[propName] !== 'function'
  ),
  setCertificateToView: conditionalPropType(
    (props, propName) => props.isMyCertificates === false && typeof props[propName] !== 'function'
  ),
  isMyCertificates: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
}

export default Certificate
