import PropTypes from 'prop-types'
import { memo } from 'react'

import { Circle, Layer, Line, Stage } from 'react-konva'

const Dial = ({ color, size, strokeWidth, markLengthRatio }) => {
  const markLength = size * markLengthRatio

  const strokeProps = {
    stroke: color,
    strokeWidth,
  }

  const marksCommonProps = {
    ...strokeProps,
    points: [-markLength, 0, strokeWidth / 2, 0],
  }

  const radius = size / 2
  const quater = (Math.PI * radius) / 2

  const gap = strokeWidth * 4
  const padding = strokeWidth

  return (
    <Stage width={size + 2 * padding} height={size + 2 * padding}>
      <Layer x={padding} y={padding}>
        <Circle
          x={size / 2}
          y={size / 2}
          radius={radius}
          dashOffset={-gap / 2}
          dash={[quater - gap, gap]}
          {...strokeProps}
        />
        <Line name='East' x={size} y={size / 2} {...marksCommonProps} />
        <Line name='South' x={size / 2} y={size} rotation={90} {...marksCommonProps} />
        <Line name='West' x={0} y={size / 2} rotation={180} {...marksCommonProps} />
        <Line name='North' x={size / 2} y={0} rotation={270} {...marksCommonProps} />
      </Layer>
    </Stage>
  )
}

Dial.defaultProps = {
  color: '#ffffff',
  size: 60,
  markLengthRatio: 0.1,
  strokeWidth: 2,
}

Dial.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  markLengthRatio: PropTypes.number,
  strokeWidth: PropTypes.number,
}

export default memo(Dial)
