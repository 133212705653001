import { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { List } from '@mui/material'

import { deleteContentAnnotations, toggleAnnotationVisibility } from '@tabeeb/modules/annotations/actions'
import { getSelectedPageAnnotations } from '@tabeeb/modules/annotations/selectors'

import { useDialogState } from '@tabeeb/shared/utils/hooks'

import Annotation from './Annotation'
import ConfigurationSection from '../ConfigurationSection'
import EditAnnotationDialog from './EditAnnotationDialog'

const AnnotationsList = () => {
  const dispatch = useDispatch()

  const annotations = useSelector(getSelectedPageAnnotations)

  const [annotationForEdit, setAnnotationForEdit] = useState(null)
  const [editAnnotationDialogOpen, onOpenEditAnnotationDialog, onCloseEditAnnotationDialog] = useDialogState()

  const onDelete = (annotationId) => {
    dispatch(deleteContentAnnotations({ annotationIds: [annotationId] }))
  }

  const onEdit = (annotation) => {
    setAnnotationForEdit(annotation)
    onOpenEditAnnotationDialog()
  }

  const onToggleVisibility = (annotation) => {
    dispatch(toggleAnnotationVisibility(annotation))
  }

  return (
    <>
      <ConfigurationSection key='annotations-list' title='Annotations'>
        <List dense>
          {annotations.map((annotation) =>
            annotation.ParentAnnotationId ? null : (
              <Annotation
                key={annotation.Id}
                annotation={annotation}
                onDelete={onDelete}
                onEdit={onEdit}
                onToggleVisibility={onToggleVisibility}
              />
            )
          )}
        </List>
      </ConfigurationSection>
      <EditAnnotationDialog
        key='edit-annotation-dialog'
        open={editAnnotationDialogOpen}
        annotation={annotationForEdit}
        onClose={onCloseEditAnnotationDialog}
      />
    </>
  )
}

export default memo(AnnotationsList)
