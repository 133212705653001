export const createReminderRequest = (data) => ({
  url: `api/reminders`,
  method: 'post',
  data,
})

export const deleteReminderRequest = (reminderId) => ({
  url: `api/reminders/${reminderId}`,
  method: 'delete',
})

export const updateReminderRequest = (data) => ({
  url: `api/reminders`,
  method: 'put',
  data,
})

export const getRemindersByContentRequest = (contentId) => ({
  url: `api/reminders/contents/${contentId}`,
  method: 'get',
})

export const updateExpirationCertificateReminderRequest = (subdomain) => ({
  url: `api/reminders/certificates/expiration/trigger`,
  method: 'post',
  data: subdomain,
})

export const getFormAnswerAvailableReminderReceiversRequest = ({ contentId, formId }) => ({
  url: `api/reminders/contents/${contentId}/forms/${formId}/receivers`,
  method: 'get',
})
