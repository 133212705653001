import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboard, getIsDashboardsLoading } from '@tabeeb/modules/powerBiAnalyticsReports/selectors'
import { useEffect, useRef, useState } from 'react'
import {
  getDashboardRequest,
  resetDashboard,
  getPowerBiEmbedTokenRequest,
} from '@tabeeb/modules/powerBiAnalyticsReports/actions'
import CircularProgressBar from '@tabeeb/shared/tabeebCircularProgressBar'
import PowerBiDashboard from '@tabeeb/modules/powerBiAnalyticsReports/components/PowerBiDashboard/PowerBiDashboard'
import { getGuidFromString } from '@tabeeb/modules/powerBiAnalyticsReports/services'
import routes from '@tabeeb/routes'
import { powerBiEmbedTokenLifetime } from '@tabeeb/modules/powerBiAnalyticsReports/constants'
import usePrevious from '@tabeeb/shared/utils/hooks/usePreviuos'
import { push } from 'connected-react-router'

const PowerBiDashboardContainer = ({ id }) => {
  const [embeddedDashboard, setEmbeddedDashboard] = useState(null)

  const dispatch = useDispatch()
  const dashboard = useSelector((state) => getDashboard(state))
  const prevDashboard = usePrevious(dashboard)
  const isLoading = useSelector((state) => getIsDashboardsLoading(state))
  const tokenTimerRef = useRef(null)

  useEffect(() => {
    return () => {
      clearInterval(tokenTimerRef.current)
      dispatch(resetDashboard())
    }
  }, [dispatch])

  useEffect(() => {
    ;(async () => {
      if (dashboard && prevDashboard && dashboard.EmbedToken !== prevDashboard.EmbedToken) {
        await embeddedDashboard.setAccessToken(dashboard.EmbedToken)
      }
    })()
  }, [dashboard, embeddedDashboard, prevDashboard])

  useEffect(() => {
    const guid = getGuidFromString(id)
    if (guid) {
      dispatch(getDashboardRequest(guid))
      tokenTimerRef.current = setInterval(() => {
        dispatch(getPowerBiEmbedTokenRequest(guid))
      }, powerBiEmbedTokenLifetime)
    } else {
      dispatch(push(routes.biDashboardsList))
    }
  }, [dispatch, id])

  const dashboardProps = {
    dashboard,
    setEmbeddedDashboard,
  }

  return isLoading ? <CircularProgressBar size={40} thickness={6} /> : <PowerBiDashboard {...dashboardProps} />
}

PowerBiDashboardContainer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default PowerBiDashboardContainer
