import { put, take, takeLatest, select, all } from 'redux-saga/effects'

import { permissionsActions, permissionsSelectors } from '@tabeeb/modules/permissions'
import { ContentSharingType, RecordingType, ServerRecordingStatus, SessionPermission, UserRole } from '@tabeeb/enums'
import { contentReviewsSelectors } from '@tabeeb/modules/contentReviews'
import { isLocalRecordingActive } from '@tabeeb/modules/recording/services/localRecordingService'
import { signalrEvents, signalrActions, signalrConstants } from '../../modules/signalr'
import { contentStateSelectors, rawContentActions } from '../../modules/shared/content'
import { convertServiceUserToPluginUser } from '../../services/dataConverter'
import { recordingSelectors, recordingActions } from '../../modules/recording'
import { whiteboardService } from '../../modules/whiteboard'
import { accountSelectors } from '../../modules/account'
import { notificationActions } from '../../modules/notification'
import { usersSelectors, usersActions } from '..'
import { presentationSelectors } from '../../modules/presentation'
import { isMobileOnly } from 'react-device-detect'
import { setLastCallEndTime } from '@tabeeb/modules/shared/content/actions'

function* joinContent() {
  const contentId = yield select(contentStateSelectors.getContentId)
  if (contentId) {
    const currentUserId = yield select(accountSelectors.getCurrentUserId)
    yield put(signalrActions.invokeHubAction({ method: 'JoinContent', args: [contentId, currentUserId] }))
  }
}

function* onSessionUserJoined(action) {
  const [userId] = action.payload
  const contentUser = yield select((state) => usersSelectors.getUserById(state, { Id: userId }) || {})

  yield put(usersActions.getUserByIdRequest({ userId }))
  const { response } = yield take([usersActions.getUserByIdSuccess])

  const joinedUserReview = yield select((state) => contentReviewsSelectors.getReviewByUserId(state, { userId }))
  if (joinedUserReview?.Type === ContentSharingType.ForReview) {
    contentUser.Role = UserRole.Reviewer
  }

  const ownerId = yield select(contentStateSelectors.getOwnerId)
  const user = convertServiceUserToPluginUser({ ...contentUser, ...response.data }, { ownerId })
  user.isDeleted = false

  yield put(usersActions.addUser(user))
}

function* onMediaUserJoined(action) {
  const [userId, deviceType] = action.payload

  yield put(usersActions.changeUserDeviceType({ userId, deviceType }))

  const isCurrentUserPresenter = yield select(contentStateSelectors.getPresenterId)
  const isRecordingOn = yield select(recordingSelectors.getIsRecordingActive)

  if (isCurrentUserPresenter && isRecordingOn) {
    const recordingStatus = yield select(recordingSelectors.getRecordingState)
    recordingStatus.startDate =
      !!recordingStatus.startDate && recordingStatus.startDate > 0 ? recordingStatus.startDate / 1000 : 0
    recordingStatus.stopDate =
      !!recordingStatus.stopDate && recordingStatus.stopDate > 0 ? recordingStatus.stopDate / 1000 : 0

    let recordingStatusArgs = []

    if (isLocalRecordingActive()) {
      recordingStatusArgs = [
        {
          state: ServerRecordingStatus.processing,
          recordingType: RecordingType.local,
          session: recordingStatus.session,
          selectedUser: recordingStatus.selectedUserId,
          startDate: recordingStatus.startDate,
        },
      ]
    } else {
      recordingStatusArgs = [
        {
          Id: recordingStatus.recordId,
          SelectedUser: recordingStatus.selectedUserId,
          Server: recordingStatus.serverName,
          Error: recordingStatus.errorMessage,
          ErrorCode: recordingStatus.errorCode,
          State: recordingStatus.serverRecordingStatus,
          StartDate: recordingStatus.startDate,
          StopDate: recordingStatus.stopDate,
          Session: recordingStatus.session,
        },
      ]
    }

    yield put(
      signalrActions.invokeHubAction({
        method: 'RecordingStatusUpdate',
        args: recordingStatusArgs,
      })
    )
  }
}

function* onMediaUserLeft(action) {
  const [userId] = action.payload

  const isRecordingOn = yield select(recordingSelectors.getIsRecordingActive)
  if (isRecordingOn) {
    yield put(recordingActions.onStopRecordingForUser({ userId }))
  }

  const isCallStarted = yield select(presentationSelectors.getIsCallStarted)
  if (isCallStarted) {
    const presenterId = yield select(contentStateSelectors.getPresenterId)

    const isDisconnectedUserPresenter = userId === presenterId
    if (isDisconnectedUserPresenter) {
      yield put(setLastCallEndTime(new Date()))
      yield whiteboardService.leaveVideoCall()
      yield put(notificationActions.onAddInfoNotification({ message: 'Call has ended' }))
    }
  }
}

function* onRemoteChargeUpdated(action) {
  const [contentId, userId, level] = action.payload

  const currentContentId = yield select(contentStateSelectors.getContentId)
  if (currentContentId !== contentId) {
    return
  }

  yield put(usersActions.updateChargeLevel({ userId, chargeLevel: level }))
}

function* onPresenterChanged(action) {
  const [model] = action.payload
  const deviceType = getBrowserType()

  const { presenterId } = model
  const contentId = yield select(contentStateSelectors.getContentId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)

  const hasJoinCallPermission = yield select((state) =>
    permissionsSelectors.hasSessionPermission(state, SessionPermission.JoinCall)
  )

  if (!hasJoinCallPermission) return

  const isInPresentationMode = yield select(presentationSelectors.getIsInPresentationMode)
  const isCurrentUserPresenter = presenterId === currentUserId

  if (!presenterId && !isInPresentationMode) {
    return
  }

  if (isCurrentUserPresenter) {
    yield put(rawContentActions.setPresenter(presenterId))

    yield whiteboardService.updatePresenterState()

    yield whiteboardService.enterRoom(contentId, presenterId)
    yield put(signalrActions.invokeHubAction({ method: 'JoinMedia', args: [contentId, currentUserId, deviceType] }))

    return
  }

  if (presenterId && !isInPresentationMode) {
    yield put(rawContentActions.setLastCallStartTime(new Date()))
    yield put(whiteboardActions.openMobileJoinCallDialog())

    if (isMobileOnly) {
      const joinCallResult = yield take([whiteboardActions.onMobileJoinCallDialogResult])
      const joinCallAccepted = joinCallResult.payload
      if (!joinCallAccepted) {
        yield whiteboardService.leaveContent({ force: true })
        return
      }
    }

    yield whiteboardService.enterRoom(contentId, presenterId)
    yield put(rawContentActions.setPresenter(presenterId))
    yield put(signalrActions.invokeHubAction({ method: 'JoinMedia', args: [contentId, currentUserId, deviceType] }))
    return
  }

  if (presenterId) {
    yield whiteboardService.enterRoom(contentId, presenterId)
    yield put(rawContentActions.setPresenter(presenterId))
    yield put(signalrActions.invokeHubAction({ method: 'JoinMedia', args: [contentId, currentUserId, deviceType] }))

    return
  }

  if (isInPresentationMode && !presenterId) {
    yield put(notificationActions.onAddInfoNotification({ message: 'Session has ended' }))
    yield put(setLastCallEndTime(new Date()))
    yield whiteboardService.leaveVideoCall()
  }
}

function* onZoomingValueUpdated(action) {
  let [userId, mobileZoomValue, maxValue] = action.payload

  mobileZoomValue = mobileZoomValue.toString()

  yield put(usersActions.onUpdateMobileZoomValue({ userId, mobileZoomValue, mobileZoomMax: maxValue }))
}

function* onContentUserRoleUpdated(action) {
  const [model] = action.payload

  const contentId = yield select(contentStateSelectors.getContentId)
  if (contentId === model.ContentId) {
    yield put(usersActions.updateContentUserRole(model))

    const currentUserId = yield select(accountSelectors.getCurrentUserId)
    if (currentUserId === model.UserId) {
      yield put(permissionsActions.setSessionPermissions(model.Permissions))
    }
  }
}

function* switchContentUserRole(action) {
  const { userId, role } = action.payload
  const contentId = yield select(contentStateSelectors.getContentId)

  const model = {
    ContentId: contentId,
    UserId: userId,
    Role: role,
  }

  yield put(usersActions.switchContentUserRoleRequest(model))
}

function* onUserLeaveContent(action) {
  const [leftUserId] = action.payload
  yield put(usersActions.removeUser(leftUserId))
}

function* sessionSaga() {
  yield all([
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onReconnected, joinContent),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onUserLeaveContent, onUserLeaveContent),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onSessionUserJoined, onSessionUserJoined),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onMediaUserJoined, onMediaUserJoined),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onMediaUserLeft, onMediaUserLeft),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onRemoteChargeUpdated, onRemoteChargeUpdated),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onPresenterChanged, onPresenterChanged),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onZoomingValueUpdated, onZoomingValueUpdated),
    takeLatest(signalrEvents[signalrConstants.tabeebHubName].onContentUserRoleUpdated, onContentUserRoleUpdated),
    takeLatest(usersActions.switchContentUserRole, switchContentUserRole),
  ])
}

export default sessionSaga
