import { memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ToggleButton, ToggleButtonGroup } from '@mui/material'

import { setUnit } from '../../../actions'
import { Defaults, Units } from '../../../constants'
import { getUnit } from '../../../selectors'

import ConfigurationSection from '../ConfigurationSection'

const UnitSettings = () => {
  const dispatch = useDispatch()
  const unit = useSelector(getUnit)

  const onChange = (_, newUnit) => {
    dispatch(setUnit(newUnit || Defaults.UNIT))
  }

  return (
    <ConfigurationSection title='Unit'>
      <ToggleButtonGroup exclusive fullWidth color='primary' size='small' value={unit} onChange={onChange}>
        <ToggleButton value={Units.Feet}>Feet</ToggleButton>
        <ToggleButton value={Units.Meters}>Meters</ToggleButton>
      </ToggleButtonGroup>
    </ConfigurationSection>
  )
}

export default memo(UnitSettings)
