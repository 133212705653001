import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Radio,
} from '@material-ui/core'

import CircularProgressBar from '@tabeeb/modules/presentation/components/CircularProgressBar'
import { ConfirmationDialog } from '@tabeeb/shared/dialogs'

import { AddRemoveStorageButton } from '..'

import { useStyles } from './styles'

import { storagesActions, tenantStoragesActions } from '../../actions'

import { tenantStoragesSelectors, storagesSelectors } from '../../selectors'

import { fetchingStatus } from '../../constants'

const EditStorageDialog = ({ tenantId, tenantName, isOpen, onClose, onExited }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const storages = useSelector(storagesSelectors.selectData)
  const storagesLoadingStatus = useSelector(storagesSelectors.selectLoadingStatus)
  const tenantStorages = useSelector(tenantStoragesSelectors.selectData)
  const tenantStoragesLoadingStatus = useSelector(storagesSelectors.selectLoadingStatus)
  const tenantStoragesUpdatingStatus = useSelector(tenantStoragesSelectors.selectUpdatingStatus)
  const [removingTenantStorageId, setRemovingTenantStorageId] = useState(null)

  useEffect(() => {
    if (tenantId) {
      dispatch(storagesActions.getRequest())
      dispatch(tenantStoragesActions.getByTenantIdRequest(tenantId))
    }
    return () => {
      dispatch(storagesActions.resetState())
      dispatch(tenantStoragesActions.resetState())
    }
  }, [dispatch, tenantId])

  const mappedTenantStorages = useMemo(() => {
    return storages.map((storage) => {
      const tenantStorage = tenantStorages.find(({ StorageGroupId }) => StorageGroupId === storage.Id)
      if (tenantStorage) {
        return {
          ...storage,
          TenantStorageId: tenantStorage.Id,
          IsAttachedToTenant: true,
          IsDefault: tenantStorage.IsDefault,
        }
      }
      return { ...storage, TenantStorageId: null, IsAttachedToTenant: false, IsDefault: false }
    })
  }, [storages, tenantStorages])

  const handleSetStorageAsDefaultRadioClick = useCallback(
    (storageId) => {
      dispatch(
        tenantStoragesActions.setAsDefaultByTenantIdRequest({
          TenantId: tenantId,
          StorageGroupId: storageId,
        })
      )
    },
    [dispatch, tenantId]
  )

  const handleAddStorageButtonClick = useCallback(
    (storageId) => {
      dispatch(
        tenantStoragesActions.addByTenantIdRequest({
          TenantId: tenantId,
          StorageGroupId: storageId,
        })
      )
    },
    [dispatch, tenantId]
  )

  const handleRemoveStorageButtonClick = (tenantStorageId) => {
    setRemovingTenantStorageId(tenantStorageId)
  }

  const handleConfirmationSubmitClick = useCallback(() => {
    dispatch(tenantStoragesActions.removeByTenantStorageIdRequest(removingTenantStorageId))
    setRemovingTenantStorageId(null)
  }, [dispatch, removingTenantStorageId])

  const handleConfirmationCancelClick = () => {
    setRemovingTenantStorageId(null)
  }

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} onExited={onExited}>
        <DialogTitle>Edit storages for {tenantName}</DialogTitle>
        <Divider />
        <DialogContent className={classes.content} disabled={tenantStoragesUpdatingStatus === fetchingStatus.loading}>
          {storagesLoadingStatus === fetchingStatus.succeeded &&
          tenantStoragesLoadingStatus === fetchingStatus.succeeded ? (
            <TableContainer>
              <Table size='medium'>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Available</TableCell>
                    <TableCell>Default</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {mappedTenantStorages.map((storage) => (
                    <TableRow key={storage.Id}>
                      <TableCell>{storage.Name}</TableCell>
                      <TableCell>
                        <AddRemoveStorageButton
                          isAttachedToTenant={storage.IsAttachedToTenant}
                          storageId={storage.Id}
                          tenantStorageId={storage.TenantStorageId}
                          isDisabled={storage.IsDefault}
                          onAddClick={handleAddStorageButtonClick}
                          onRemoveClick={handleRemoveStorageButtonClick}
                        />
                      </TableCell>
                      <TableCell>
                        <Radio
                          color='primary'
                          checked={storage.IsDefault}
                          onChange={() => handleSetStorageAsDefaultRadioClick(storage.Id)}
                          disabled={!storage.IsAttachedToTenant}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <CircularProgressBar />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        isOpen={removingTenantStorageId !== null}
        onSubmit={handleConfirmationSubmitClick}
        onCloseDialog={handleConfirmationCancelClick}
        title='After storage removal users will still be able to upload files and thus create new pages inside the sessions that are already using the storage.
        Users will not be able to create new sessions attached to the storage. Are you sure you want to remove it?'
      />
    </>
  )
}

EditStorageDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  tenantId: PropTypes.number,
  tenantName: PropTypes.string,
  onExited: PropTypes.func,
}

export default EditStorageDialog
