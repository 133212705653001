const tenantsRequests = {
  getTenantsRequest: () => ({
    url: `tenant-service/api/tenants`,
    method: 'get',
  }),
  createTenantRequest: (data) => ({
    url: `tenant-service/api/tenants`,
    method: 'post',
    data,
  }),
  getStoragesRequest: () => ({
    url: `tenant-service/api/storageGroups/all`,
    method: 'get',
  }),
  /**
   * @deprecated remove all from path. Use getStoragesRequest instead
   */
  getAllStoragesRequest: () => ({
    url: `tenant-service/api/storageGroups/all`,
    method: 'get',
  }),
  getStoragesForTenantRequest: (tenantId) => ({
    url: `tenant-service/api/tenantStorages/${tenantId}`,
    method: 'get',
  }),
  addStorageToTenantRequest: (data) => ({
    url: `tenant-service/api/tenantStorages`,
    method: 'post',
    data,
  }),
  removeStorageFromTenantRequest: (tenantStorageId) => ({
    url: `tenant-service/api/tenantStorages/${tenantStorageId}`,
    method: 'delete',
  }),
  makeStorageDefaultRequest: (data) => ({
    url: `tenant-service/api/tenantStorages/default`,
    method: 'post',
    data,
  }),
}

export default tenantsRequests
