import * as Yup from 'yup'

import { acsii } from './regexps'
import { passwordMaxLength, passwordRequirements } from '../constants/Requirements'

import { Errors } from '..'

Yup.addMethod(Yup.string, 'collaboratePassword', function (args) {
  return this.trim('There should be no spaces at the beginning and at the end of the password')
    .strict(true)
    .matches(acsii, 'Only latin letters, numbers, and common punctuation characters are allowed')
    .max(passwordMaxLength, `Maximum length is ${passwordMaxLength} characters`)
    .required('Required')
    .test('password', {}, function (value) {
      const { path, createError } = this

      if (passwordRequirements.every(({ validator }) => validator(value))) {
        return true
      }

      return createError({ path, message: Errors.empty })
    })
})
