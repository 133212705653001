import { handleActions } from 'redux-actions'

import * as rawActions from '../actions'

const initialState = {
  Id: null,
  Name: null,
  Description: null,
  Image: null,
  EmbedUrl: null,
  EmbedToken: null,
}

export default handleActions(
  {
    [rawActions.getDashboardSuccess]: (state, action) => {
      return { ...action.response.data }
    },
    [rawActions.resetDashboard]: (state, action) => {
      return { ...initialState }
    },
    [rawActions.getPowerBiEmbedTokenSuccess]: (state, action) => {
      return { ...state, EmbedToken: action.response.data }
    },
  },
  initialState
)
