import { put, takeLatest, all, select } from 'redux-saga/effects'
import { getIsControlWithoutOptions, getIsTextControl } from '@tabeeb/modules/createFormPage/helpers/controlTypesHelper'
import * as rawActions from '../actions'
import * as formsActions from '../../forms/actions'
import { ConditionTypes, ControlType, ConditionTriggerTypes, SendEmailConditionType } from '../../../Enums'
import { generateId } from '../../presentationToolbar/services/snapshotService'
import { getControlsList } from '../selectors'

function* onSelectCondition(action) {
  const { condition, control, option, isSelected, triggerType } = action.payload
  const { availableForms } = yield select((state) => state.forms)

  const isTextControl = getIsTextControl(control.ControlType)

  const conditionType = condition.type
  if (!isSelected) {
    if (conditionType === ConditionTypes.AttachForm && availableForms.length === 0) {
      yield put(formsActions.getAvailableFormsRequest({ control, option, allForms: true }))
    } else {
      let condition
      if (conditionType === ConditionTypes.AttachForm && availableForms.length > 0) {
        condition = {
          TempId: generateId(),
          Type: ConditionTypes.AttachForm,
          Solution: `${availableForms[0].FormId}`,
          TriggerValue: '',
        }
      } else {
        condition = { TempId: generateId(), Type: conditionType, Solution: '', TriggerValue: '' }
        if (conditionType === ConditionTypes.SendEmail) {
          condition.ConditionsRecipient = [
            { Type: SendEmailConditionType.AllParticipant },
            { Type: SendEmailConditionType.SessionOwner },
          ]
        }
      }

      if (isTextControl) condition.TriggerType = triggerType || ConditionTriggerTypes.Equals

      if (option) yield put(rawActions.addOptionCondition({ control, option, condition }))
      else yield put(rawActions.addControlCondition({ control, condition }))
    }
  } else if (option) yield put(rawActions.deleteOptionCondition({ control, option, conditionType }))
  else if (triggerType) yield put(rawActions.deleteTextControlCondition({ control, conditionType, triggerType }))
  else yield put(rawActions.deleteControlCondition({ control, conditionType }))
}

function* onSelectEmailCondition(action) {
  const { emailCondition, emailConditionsProps } = action.payload
  const { control, option, addedCondition } = emailConditionsProps
  const isControlWithoutOptions = getIsControlWithoutOptions(control.ControlType)
  const controlList = yield select(getControlsList)
  const options = controlList.find((cont) => cont.TempId === control.TempId)?.Options
  const conditions = controlList.find((cont) => cont.TempId === control.TempId)?.Conditions
  if (options || conditions) {
    const conditionsResult = isControlWithoutOptions
      ? conditions
      : options && options.find((opt) => opt.Id === option.Id)?.Conditions
    if (conditionsResult) {
      const emailConditions = conditionsResult.find(
        (cond) => cond.TempId === addedCondition.TempId
      )?.ConditionsRecipient
      if (emailConditions) {
        const emailConditionsFilteredType = emailConditions.filter((cond) => cond.Type === emailCondition.type)
        const availabilityCheck = emailConditionsFilteredType.length > 0
        let conditionsRecipient = []
        if (availabilityCheck) {
          conditionsRecipient = emailConditions.filter((cond) => cond.Type !== emailCondition.type)
        } else {
          if (
            emailCondition.type === SendEmailConditionType.AllParticipant &&
            emailConditions.filter((cond) => cond.Type === SendEmailConditionType.SessionOwner).length === 0
          ) {
            const sessionOwnerCondition = { Type: SendEmailConditionType.SessionOwner }
            emailConditions.push(sessionOwnerCondition)
          }
          const newEmailCondition = { Type: emailCondition.type }
          if (emailCondition.type === SendEmailConditionType.SpecificUsers) {
            newEmailCondition.Recipients = []
          }
          emailConditions.push(newEmailCondition)
          conditionsRecipient = [...emailConditions]
        }
        if (conditionsRecipient.length === 0) {
          conditionsRecipient = [
            { Type: SendEmailConditionType.AllParticipant },
            { Type: SendEmailConditionType.SessionOwner },
          ]
        }
        if (isControlWithoutOptions) {
          yield put(rawActions.setEmailControlCondition({ control, condition: addedCondition, conditionsRecipient }))
        } else {
          yield put(
            rawActions.setEmailOptionCondition({ control, option, condition: addedCondition, conditionsRecipient })
          )
        }
      }
    }
  }
}

function* onChangeRecipientEmailCondition(action) {
  const { recipients, emailConditionType, emailConditionsProps } = action.payload
  const { control, option, addedCondition } = emailConditionsProps
  const isControlWithoutOptions = getIsControlWithoutOptions(control.ControlType)
  const updatedConditionRecipients = addedCondition?.ConditionsRecipient.map((cond) => {
    if (cond.Type === emailConditionType) {
      cond.Recipients = [...recipients]
    }
    return cond
  })
  if (isControlWithoutOptions) {
    yield put(
      rawActions.setEmailControlCondition({
        control,
        condition: addedCondition,
        conditionsRecipient: updatedConditionRecipients,
      })
    )
  } else {
    yield put(
      rawActions.setEmailOptionCondition({
        control,
        option,
        condition: addedCondition,
        conditionsRecipient: updatedConditionRecipients,
      })
    )
  }
}

function* formControlConditions() {
  yield all([
    takeLatest(rawActions.onSelectCondition, onSelectCondition),
    takeLatest(rawActions.onSelectEmailCondition, onSelectEmailCondition),
    takeLatest(rawActions.onChangeRecipientEmailCondition, onChangeRecipientEmailCondition),
  ])
}

export default formControlConditions
