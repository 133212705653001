export function _getAiClassesRequest() {
  return {
    url: `api/ai/objects`,
    method: 'get',
  }
}

export function _getAiObjectsForVqaPolicy() {
  return {
    url: `api/ai/objects/vqaPolicy`,
    method: 'get',
  }
}

const aiRequests = {
  getAiObjectsRequest: _getAiClassesRequest,
  getAiObjectsForVqaPolicyRequest: _getAiObjectsForVqaPolicy,
  getAiObjectsForTagsRequest: _getAiObjectsForVqaPolicy,

  getAiObjectsForProjectTrainingRequest: (contentId) => ({
    url: `api/ai/objects/content/${contentId}`,
    method: 'get',
  }),
  getAiObjectsWithStatsRequest: () => ({
    url: `api/ai/objects/statistics`,
    method: 'get',
  }),
  getUniqueAiObjectsRequest: ({ aiObjectId, contentId }) => ({
    url: `api/ai/uniqueObjects/${aiObjectId}/${contentId}`,
    method: 'get',
  }),

  addUniqueAiObjectRequest: (data) => ({
    url: `api/ai/uniqueObjects`,
    method: 'post',
    data,
  }),
  getAiCounterInfoRequest: (data) => ({
    url: `api/ai/getAiCounterInfo`,
    method: 'post',
    data,
  }),
  checkConditionsRequest: (data) => ({
    url: `api/formanswers/conditions/check`,
    method: 'post',
    data,
  }),
  getAiObjectsCountRequest: (data) => ({
    url: `api/ai/getAiObjectsCount`,
    method: 'post',
    data,
  }),
  addAiClassRequest: (data) => ({
    url: `api/ai/objects`,
    method: 'post',
    data,
  }),
  deleteAiClassRequest: (classId) => ({
    url: `api/ai/tenantObjects/${classId}`,
    method: 'delete',
  }),
  updateAiClassRequest: (data) => ({
    url: `api/ai/tenantObjects`,
    method: 'put',
    data,
  }),
  getUniqueAiObjectForEditRequest: ({ uniqueAiObjectId }) => ({
    url: `api/ai/uniqueObjects/${uniqueAiObjectId}`,
    method: 'get',
  }),
  updateUniqueAiObjectRequest: (data) => ({
    url: `api/ai/uniqueObjects`,
    method: 'put',
    data,
  }),
  detachStructureModelAiObjectsRequest: (data) => ({
    url: `api/ai/detachStructureModelObjects`,
    method: 'post',
    data,
  }),
  getAiCounterInfoByFormRecordRequest: (data) => ({
    url: `api/ai/getAiCounterInfoByFormRecord`,
    method: 'post',
    data,
  }),
  regenerateStructureModelRequest: (data) => ({
    url: `api/ai/structureModel/regenerate`,
    method: 'post',
    data,
  }),

  getListOfDependentModelsRequest: ({ classId }) => ({
    url: `api/ai/objects/${classId}/dependentModels`,
    method: 'get',
  }),
  createModelToClassDependencyRequest: ({ classId, modelId }) => ({
    url: `api/ai/objects/${classId}/dependentModels/${modelId}`,
    method: 'post',
  }),
  deleteModelToClassDependencyRequest: ({ classId, modelId }) => ({
    url: `api/ai/objects/${classId}/dependentModels/${modelId}`,
    method: 'delete',
  }),
}

export default aiRequests
