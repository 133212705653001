import { useCallback, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Divider, ListItemIcon, MenuItem as MuiMenuItem, MenuList } from '@material-ui/core'

import AssignmentIcon from '@material-ui/icons/Assignment'
import AssessmentIcon from '@material-ui/icons/Assessment'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import SettingsIcon from '@material-ui/icons/Settings'
import StorageIcon from '@material-ui/icons/Storage'

import { TenantMenuContext } from '..'

import { useStyles } from './styles'

const MenuItem = ({ icon, label, onClick }) => {
  return (
    <MuiMenuItem onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      {label}
    </MuiMenuItem>
  )
}

const TenantMenu = ({ tenantIndex, onContextMenu, onMenuItemClick, onMenuKeyDown, autoFocusItem }) => {
  const classes = useStyles()
  const {
    onGoToTenantMenuItemClick,
    onCopyTenantUrlMenuItemClick,
    onEditStorageMenuItemClick,
    onEditConfigMenuItemClick,
    onEditTermsMenuItemClick,
    onGenerateReportMenuItemClick,
  } = useContext(TenantMenuContext)

  const handleMenuItemClick = useMemo(() => onMenuItemClick ?? (() => {}), [onMenuItemClick])

  const handleGoToTenantMenuItemClick = useCallback(() => {
    onGoToTenantMenuItemClick(tenantIndex)
    handleMenuItemClick()
  }, [handleMenuItemClick, onGoToTenantMenuItemClick, tenantIndex])

  const handleCopyTenantUrlMenuItemClick = useCallback(() => {
    onCopyTenantUrlMenuItemClick(tenantIndex)
    handleMenuItemClick()
  }, [handleMenuItemClick, onCopyTenantUrlMenuItemClick, tenantIndex])

  const handleEditStorageMenuItemClick = useCallback(() => {
    onEditStorageMenuItemClick(tenantIndex)
    handleMenuItemClick()
  }, [handleMenuItemClick, onEditStorageMenuItemClick, tenantIndex])

  const handleEditConfigMenuItemClick = useCallback(() => {
    onEditConfigMenuItemClick(tenantIndex)
    handleMenuItemClick()
  }, [handleMenuItemClick, onEditConfigMenuItemClick, tenantIndex])

  const handleEditTermsMenuItemClick = useCallback(() => {
    onEditTermsMenuItemClick(tenantIndex)
    handleMenuItemClick()
  }, [handleMenuItemClick, onEditTermsMenuItemClick, tenantIndex])

  const handleGenerateReportMenuItemClick = useCallback(() => {
    onGenerateReportMenuItemClick(tenantIndex)
    handleMenuItemClick()
  }, [handleMenuItemClick, onGenerateReportMenuItemClick, tenantIndex])

  return (
    <MenuList
      aria-label='Tenant context menu'
      id='tenant-context-menu'
      autoFocusItem={autoFocusItem}
      onKeyDown={onMenuKeyDown}
      onContextMenu={onContextMenu}
    >
      <MenuItem key='.1' label='Open in new tab' icon={<OpenInNewIcon />} onClick={handleGoToTenantMenuItemClick} />
      <MenuItem key='.2' label='Copy tenant url' icon={<FileCopyIcon />} onClick={handleCopyTenantUrlMenuItemClick} />
      <Divider className={classes.menuDivider} />
      <MenuItem key='.3' label='Edit config' icon={<SettingsIcon />} onClick={handleEditConfigMenuItemClick} />
      <MenuItem key='.4' label='Edit storage' icon={<StorageIcon />} onClick={handleEditStorageMenuItemClick} />
      <MenuItem key='.5' label='Edit terms of use' icon={<AssignmentIcon />} onClick={handleEditTermsMenuItemClick} />
      <Divider className={classes.menuDivider} />
      <MenuItem
        key='.6'
        label='Generated reports'
        icon={<AssessmentIcon />}
        onClick={handleGenerateReportMenuItemClick}
      />
    </MenuList>
  )
}

MenuItem.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.string,
  onClick: PropTypes.func,
}

TenantMenu.propTypes = {
  autoFocusItem: PropTypes.bool,
  onMenuKeyDown: PropTypes.func,
  onMenuItemClick: PropTypes.func,
  onContextMenu: PropTypes.func,
  tenantIndex: PropTypes.number,
}

export default TenantMenu
