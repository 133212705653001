import { memo } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

import { MuiThemeProvider } from '@material-ui/core'

import { tabeebTheme } from '@tabeeb/modules/shared/uikit'
import PointCloudCameraNavigationButton from '@tabeeb/modules/playerToolbar/components/PointCloudCameraNavigationButton'

const ViewNavigation = ({ onChange }) => {
  const container = document.getElementById('point-cloud-camera-navigation-button-container')
  if (!container) {
    return null
  }

  return createPortal(
    <MuiThemeProvider theme={tabeebTheme}>
      <PointCloudCameraNavigationButton onChange={onChange} />
    </MuiThemeProvider>,
    container
  )
}

ViewNavigation.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default memo(ViewNavigation)
