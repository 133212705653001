import { handleActions } from 'redux-actions'

import { playerActions } from '@tabeeb/modules/player'
import { signalrEvents, signalrConstants } from '@tabeeb/modules/signalr'

const defaultValue = {
  pageId: undefined,
  list: [],
}

export default handleActions(
  {
    [playerActions.getAnswersTimelineForVideoPageSuccess](state, action) {
      if (action.payload.pageId !== state.pageId) {
        return state
      }

      return {
        ...state,
        list: action.response.data,
      }
    },
    [playerActions.getAnswersTimelineForVideoPageRequest](state, action) {
      const { pageId } = action.payload

      return {
        ...defaultValue,
        pageId,
      }
    },
    [signalrEvents[signalrConstants.tabeebHubName].onAnswersCreated](state, action) {
      const [answer] = action.payload

      return {
        ...state,
        list: [
          ...state.list,
          {
            Id: answer.Id,
            Timestamp: answer.Timestamp,
          },
        ],
      }
    },
    [signalrEvents[signalrConstants.tabeebHubName].onAnswersCleared](state, action) {
      const [{ Id }] = action.payload

      return {
        ...state,
        list: state.list.filter((activity) => activity.Id !== Id),
      }
    },
  },
  defaultValue
)
