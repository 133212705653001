import { memo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Info } from '@material-ui/icons'
import { Dialog, DialogContent, DialogTitle, Typography, withStyles } from '@material-ui/core'

import { gallerySelectors } from '@tabeeb/modules/gallery'
import { isVideoPage } from '@tabeeb/services/pageService'

import { contentViewerSelectors } from '../..'

import PageInfoControl from '../PageInfoControl'

import styles from './styles'

const PageInfoOverlay = ({
  classes,
  name,
  height,
  width,
  latitude,
  longitude,
  owner,
  captureTime,
  uploadTime,
  timeZoneOffset,
  isCaptureTimeInUtc,
}) => {
  const [isOpened, setIsOpened] = useState(false)

  const onOpen = useCallback(() => {
    setIsOpened(true)
  }, [])

  const onClose = useCallback(() => {
    setIsOpened(false)
  }, [])

  return (
    <>
      <PageInfoControl icon={Info} title='Show page information' onClick={onOpen} />
      <Dialog open={isOpened} onClose={onClose} maxWidth='xs'>
        <DialogTitle className={classes.title} title={name}>
          {name}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Typography>Owner: {owner}</Typography>
          <Typography>
            Capture Time:{' '}
            {captureTime ? `${convertDateToString(captureTime)}${isCaptureTimeInUtc ? ' UTC' : ''}` : 'Not Available'}
          </Typography>
          <Typography>
            Upload Time: {`${convertDateToString(uploadTime, true)} ${getTimeZoneOffsetString(timeZoneOffset)}`}
          </Typography>
          <Typography>
            Dimensions: {width} x {height}
          </Typography>
          <Typography>Latitude: {latitude || 'Metadata is not available'}</Typography>
          <Typography>Longitude: {longitude || 'Metadata is not available'}</Typography>
          {latitude && longitude && (
            <iframe
              className={classes.map}
              frameBorder='0'
              title='location'
              src={`https://maps.google.com/maps?q=${latitude},${longitude}&t=k&hl=esz=14&output=embed`}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

PageInfoOverlay.defaultProps = {
  uploadTime: new Date().toLocaleDateString(),
}

PageInfoOverlay.propTypes = {
  classes: PropTypes.shape({
    map: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  }).isRequired,
  name: PropTypes.string,
  owner: PropTypes.string,
  uploadTime: PropTypes.string,
  captureTime: PropTypes.string,
  timeZoneOffset: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  isCaptureTimeInUtc: PropTypes.bool,
}

function convertDateToString(createdOn, useTimezoneOffset = false) {
  const options = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }

  createdOn = createdOn.replace('Z', '')
  const date = new Date(createdOn)
  const timestamp = date.getTime()
  if (useTimezoneOffset) {
    const offset = date.getTimezoneOffset() * 60000
    return new Date(timestamp - offset).toLocaleTimeString('en-us', options)
  }
  return new Date(timestamp).toLocaleTimeString('en-us', options)
}

function getTimeString(elapsedTime) {
  if (!elapsedTime) {
    return '00:00'
  }

  elapsedTime = Math.abs(elapsedTime)

  const addZero = (val) => (val < 10 ? `0${val}` : val)
  const hours = addZero(Math.floor((elapsedTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
  const minutes = addZero(Math.floor((elapsedTime % (1000 * 60 * 60)) / (1000 * 60)))

  return `${hours}:${minutes}`
}

function getTimeZoneOffsetString(timeZoneOffset) {
  let timeZoneOffsetStr = ''
  if (timeZoneOffset) {
    timeZoneOffsetStr = getTimeString(timeZoneOffset * 1000 * 60)

    let utcDelimeter = timeZoneOffset < 0 ? '-' : ''
    utcDelimeter = timeZoneOffset > 0 ? '+' : utcDelimeter

    timeZoneOffsetStr = `(UTC ${utcDelimeter}${timeZoneOffsetStr})`
  }

  return timeZoneOffsetStr
}

const mapStateToProps = (state) => {
  const page = gallerySelectors.getSelectedGalleryItem(state)
  const contentSize = contentViewerSelectors.getContentSize(state)

  const isDefaultSize = page?.height === 750 && page?.width === 1000

  return {
    name: page?.name,
    owner: page?.ownerUserName,
    uploadTime: page?.dateCreated,
    captureTime: page?.originalCreatedOn,
    timeZoneOffset: page?.timeZoneOffset,
    latitude: page?.latitude,
    longitude: page?.longitude,
    isCaptureTimeInUtc: isVideoPage(page?.contentType),
    height: isDefaultSize ? contentSize?.height || page?.height : page?.height,
    width: isDefaultSize ? contentSize?.width || page?.width : page?.width,
  }
}

export default memo(connect(mapStateToProps)(withStyles(styles)(PageInfoOverlay)))
