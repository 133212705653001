import { handleActions } from 'redux-actions'

import {
  openGeoserchThumbnailsDialog,
  closeGeoserchThumbnailsDialog,
  selectGeosearchContent,
  setGeosearchState,
  resetState,
} from '../actions'

const defaultState = {
  selectedSession: { id: -1, pagesIds: [], Description: '' },
  isThumbnailsDialogOpen: false,
  isGeosearchOpen: false,
}

export default handleActions(
  {
    [selectGeosearchContent](state, action) {
      return { ...state, selectedSession: action.payload }
    },
    [openGeoserchThumbnailsDialog](state, action) {
      return { ...state, isThumbnailsDialogOpen: true }
    },
    [closeGeoserchThumbnailsDialog](state, action) {
      return { ...state, isThumbnailsDialogOpen: false }
    },
    [setGeosearchState](state, action) {
      return { ...state, isGeosearchOpen: action.payload }
    },
    [resetState](state, action) {
      return defaultState
    },
  },
  defaultState
)
