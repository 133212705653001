import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { callAPIPromise as callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import { onAddSuccessNotification, onAddErrorNotification } from '@tabeeb/modules/notification/actions'
import getErrorMessageFromResponse from '@tabeeb/modules/shared/utils/getErrorMessageFromResponse'

import {
  copyInviteLinkFailMessage,
  copyInviteLinkSuccessMessage,
  resendInviteFailMessage,
  resendInviteSuccessMessage,
  inviteSuccessMessage,
} from '../common/notificationMessages'

import {
  cancelInvite,
  getContentInvites,
  getInviteUrlById,
  resendInvite,
  revokeQuickAccessUrl,
  sendInvites,
  updateInviteRole,
  updateInviteTime,
  updateQuickAccessUrlPassword,
} from '../actions'

export default ({ open, contentId }) => {
  const dispatch = useDispatch()

  const [loaded, setLoaded] = useState(false)
  const [invites, setInvites] = useState([])

  useEffect(() => {
    if (!open || !contentId) {
      return () => {}
    }

    const fetchInvites = async () => {
      try {
        setLoaded(false)

        const {
          response: { data: contentInvites },
        } = await callApiAsync(getContentInvites.request({ contentId, searchText: '' }))

        setInvites(contentInvites)
        setLoaded(true)
      } catch {
        // ignored
      }
    }

    fetchInvites()

    return () => {
      dispatch(getContentInvites.cancel())
    }
  }, [contentId, dispatch, open])

  const onCancelInvite = async (inviteId) => {
    await callApiAsync(cancelInvite.request({ Id: inviteId }))

    setInvites((prevInvites) => prevInvites.filter((invite) => invite.Id !== inviteId))
  }

  const onCopyInviteLink = async (inviteId) => {
    try {
      const {
        response: { data: inviteLink },
      } = await callApiAsync(getInviteUrlById.request({ Id: inviteId }))

      // eslint-disable-next-line compat/compat
      await navigator.clipboard.writeText(inviteLink)

      dispatch(onAddSuccessNotification({ message: copyInviteLinkSuccessMessage }))
    } catch {
      dispatch(onAddErrorNotification({ message: copyInviteLinkFailMessage }))
    }
  }

  const onResendInvite = async (inviteId) => {
    try {
      await callApiAsync(resendInvite.request({ Id: inviteId }))

      dispatch(onAddSuccessNotification({ message: resendInviteSuccessMessage }))
    } catch {
      dispatch(onAddErrorNotification({ message: resendInviteFailMessage }))
    }
  }

  const onRevokeQuickAccessLink = async (inviteId) => {
    await callApiAsync(revokeQuickAccessUrl.request({ Id: inviteId }))

    setInvites((prevInvitesState) =>
      prevInvitesState.map((invite) => (invite.Id === inviteId ? { ...invite, IdentityProtected: false } : invite))
    )
  }

  const onSetQuickAccessLinkPassword = async ({ Id, Password }) => {
    await callApiAsync(updateQuickAccessUrlPassword.request({ Id, Password }))

    setInvites((prevInvitesState) =>
      prevInvitesState.map((invite) => (invite.Id === Id ? { ...invite, IdentityProtected: true } : invite))
    )
  }

  const onUpdateInviteRole = async ({ Id, Role }) => {
    await callApiAsync(updateInviteRole.request({ Id, Role }))

    setInvites((prevInvitesState) =>
      prevInvitesState.map((invite) => (invite.Id === Id ? { ...invite, Role } : invite))
    )
  }

  const onUpdateInviteTime = async ({ Id, StartDate, EndDate }) => {
    await callApiAsync(updateInviteTime.request({ Id, StartDate, EndDate }))

    setInvites((prevInvitesState) =>
      prevInvitesState.map((invite) => (invite.Id === Id ? { ...invite, StartDate, EndDate } : invite))
    )
  }

  const onSendInvites = async (invitesToCreate) => {
    try {
      const {
        response: { data: createdInvites },
      } = await callApiAsync(sendInvites.request(invitesToCreate))

      setInvites((prevInvitesState) => [...createdInvites, ...prevInvitesState])

      dispatch(onAddSuccessNotification({ message: inviteSuccessMessage }))
    } catch (error) {
      const message = getErrorMessageFromResponse(error?.response?.data)

      dispatch(onAddErrorNotification({ message }))
    }
  }

  return {
    loaded,
    invites,
    onCancelInvite,
    onCopyInviteLink,
    onResendInvite,
    onRevokeQuickAccessLink,
    onSetQuickAccessLinkPassword,
    onSendInvites,
    onUpdateInviteTime,
    onUpdateInviteRole,
  }
}
