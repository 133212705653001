export const MAP_MAX_ZOOM = 18
export const MAP_MIN_ZOOM = 3
export const MAP_DEFAULT_ZOOM = 5
export const MAP_DEFAULT_CENTER = { lat: 38, lng: -100 }

export const CLUSTER_RADIUS = 120

export const fetchingStatus = {
  idle: 'idle',
  loading: 'loading',
  succeeded: 'succeeded',
  failed: 'failed',
}
