import { memo } from 'react'
import PropTypes from 'prop-types'

const Axes = ({ size }) => {
  return <axesHelper args={[size]} />
}

Axes.defaultProps = {
  size: 10000,
}

Axes.propTypes = {
  size: PropTypes.number,
}

export default memo(Axes)
