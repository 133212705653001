import { useCallback, useMemo } from 'react'
import classNames from 'classnames'

import { useDispatch, useSelector } from 'react-redux'
import Folder from '../Folder'

import { contentTrashBinActions, contentTrashBinSelectors } from '../..'

const FoldersList = () => {
  const dispatch = useDispatch()

  const deletedFolders = useSelector(contentTrashBinSelectors.getDeletedFoldersFromCurrent)

  const onFolderClick = useCallback((folder) => {
    dispatch(contentTrashBinActions.selectContentTrashBinFolder(folder))
  }, [])

  const dialogFolder = useCallback((folderId, dialogItem) => {
    dispatch(contentTrashBinActions.setOperatingFolderId(folderId))
    dispatch(contentTrashBinActions.setContentTrashBinDialogItem(dialogItem))
  }, [])

  return (
    <div className={classNames('sessionfolders-container')}>
      <div className={classNames('list-container')}>
        {deletedFolders.map((folder, index) => (
          <Folder key={index} folder={folder} onFolderClick={onFolderClick} dialogFolder={dialogFolder} />
        ))}
      </div>
    </div>
  )
}

export default FoldersList
