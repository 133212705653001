import { takeLatest, put, all, select, takeEvery } from 'redux-saga/effects'

import { sessionTabsActions as navbarActions } from '@tabeeb/modules/sessionTabs'
import { permissionsSelectors } from '@tabeeb/modules/permissions'
import { addAllPagesForMerging, openContentSharingDialog } from '@tabeeb/modules/contentSharings/actions'
import { ContentSharingDialogType } from '@tabeeb/modules/contentSharings/constants/enums'
import { getIsSelectedPagesForMergingNavbarTabActive } from '@tabeeb/modules/contentSharings/selectors'
import { getCurrentUserEmail } from '@tabeeb/modules/account/selectors'
import { getIsPdfExportEmailRecipientsEnabled } from '@tabeeb/modules/appConfigState/selectors'
import * as galleryActions from '../actions'
import * as customVideoActions from '../../customVideo/actions'
import * as shareSessionActions from '../../shareSessionPagesList/actions'
import * as reviewSessionActions from '../../reviewSessionPagesList/actions'

import { addMenuItems, exportMenuItems, shareSessionMenuItems } from '../services/galleryMenus'
import { gallerySelectors } from '..'
import { ContentSharingType, SessionPermission, TabPanel, TabPanelLocation } from '../../../Enums'
import { getIsReviewSessionTimelineOpen } from '../../reviewSessionPagesList/selectors'
import { getPagesByFolder } from '../selectors'

function* handleAddButtonMenuClick(action) {
  const { menuItemType } = action.payload

  switch (menuItemType) {
    case addMenuItems.VideoFromUrl:
      yield put(galleryActions.changeGalleryDisplayAddVideoDialog(true))
      break
    case addMenuItems.DrawingPage:
      yield put(galleryActions.addDrawingPage())
      break
    case addMenuItems.WebPageUsingUrl:
      yield put(galleryActions.showAddWebPageDialog())
      break
    case addMenuItems.StructureModel:
      yield put(galleryActions.galleryAddStructureModel())
      break
    case addMenuItems.TowerSideProfile:
      yield put(galleryActions.galleryAddTowerSideProfile())
      break
    case addMenuItems.TwoDLineDrawing:
      yield put(galleryActions.galleryAdd2dLineDrawing())
      break
    case addMenuItems.Add360View:
      yield put(galleryActions.galleryAddSpatialModel())
      break
    case addMenuItems.Add3dModel:
      yield put(galleryActions.galleryAdd3dModel())
      break
    case addMenuItems.AddBentleyModel:
      yield put(galleryActions.galleryAddBentleyModel())
      break
    case addMenuItems.BentleyAnalyticsReport:
      yield put(galleryActions.galleryAddBentleyAnalyticsReport())
      break
    case addMenuItems.ClassificationAnnotations:
      yield put(galleryActions.galleryAddClassificationAnnotations())
      break
    default:
      break
  }
}

function* handleExportButtonMenuClick(action) {
  const hasPermission = yield select((state) =>
    permissionsSelectors.hasSessionPermission(state, SessionPermission.ExportGalleryContent)
  )

  if (!hasPermission) return

  const menuItemType = action.payload
  const currentPanel = yield select((state) => state.navbar.currentPanel[TabPanelLocation.Bottom])

  switch (menuItemType) {
    case exportMenuItems.PDF: {
      const isPdfExportEmailRecipientsEnabled = yield select((state) => getIsPdfExportEmailRecipientsEnabled(state))

      if (isPdfExportEmailRecipientsEnabled) {
        yield put(galleryActions.setEmailRecipientsDialogState(null))
        yield put(galleryActions.openPdfOptionsDialog())
      } else {
        const currentUserEmail = yield select((state) => getCurrentUserEmail(state))
        yield put(galleryActions.exportAsPdf({ recipients: [currentUserEmail] }))
      }
      break
    }
    case exportMenuItems.CustomVideo: {
      const defaultPageUrl = yield select((state) => state.appConfigState.introImageUrl)
      const { isTimelineOpen, timelineList } = yield select((state) => state.customVideo)

      if (!isTimelineOpen) {
        const defaultPage = { thumbnailUrl: defaultPageUrl }

        if (timelineList.length === 0 && defaultPage.thumbnailUrl) {
          yield put(customVideoActions.addTimelineItem(defaultPage))
        }

        yield put(customVideoActions.openTimeline(true))
      }

      if (currentPanel !== TabPanel.CustomVideo) {
        yield put(navbarActions.switchPanel(TabPanel.CustomVideo, TabPanelLocation.Bottom))
      }
      break
    }
    case exportMenuItems.Images: {
      const selectedFolderId = yield select((state) => gallerySelectors.getSelectedFolderId(state))

      if (selectedFolderId) yield put(galleryActions.exportImagesIfTheyExist())
      else yield put(galleryActions.openExportGalleryOptionsDialog('images'))
      break
    }
    case exportMenuItems.ShareSession: {
      const isSessionSharingPagesListOpen = yield select((state) => state.shareSessionPagesList.isTimelineOpen)

      if (!isSessionSharingPagesListOpen) {
        yield put(shareSessionActions.openShareSessionPagesDialog(true))
      }

      if (currentPanel !== TabPanel.ShareSession) {
        yield put(navbarActions.switchPanel(TabPanel.ShareSession, TabPanelLocation.Bottom))
      }
      break
    }
    case exportMenuItems.ReviewSession: {
      const isSessionReviewingPagesListOpen = yield select((state) => state.reviewSessionPagesList.isTimelineOpen)

      if (!isSessionReviewingPagesListOpen) {
        yield put(reviewSessionActions.openReviewSessionPagesDialog(true))
      }

      if (currentPanel !== TabPanel.ReviewSession) {
        yield put(navbarActions.switchPanel(TabPanel.ReviewSession, TabPanelLocation.Bottom))
      }
      break
    }
    case exportMenuItems.MergeContents:
      yield put(openContentSharingDialog({ sharingDialogType: ContentSharingDialogType.RequestToLinkContent }))
      break
    default:
  }
}

function* handleShareSessionOptionsButtonMenuClick(action) {
  const menuItemType = action.payload
  const isReviewTimeline = yield select((state) => getIsReviewSessionTimelineOpen(state))
  const isContentsMergingTimeline = yield select((state) => getIsSelectedPagesForMergingNavbarTabActive(state))
  const galleryList = yield select((state) => getPagesByFolder(state))
  let sharingType = ContentSharingType.Shared

  switch (menuItemType) {
    case shareSessionMenuItems.AllPages:
      if (isReviewTimeline) {
        yield put(reviewSessionActions.addAllPagesForReviewing(galleryList))
      } else if (isContentsMergingTimeline) {
        yield put(addAllPagesForMerging(galleryList))
      } else {
        yield put(shareSessionActions.addAllPagesForSharing(galleryList))
      }
      break
    case shareSessionMenuItems.ByForm:
      if (isReviewTimeline) {
        sharingType = ContentSharingType.ForReview
      } else if (isContentsMergingTimeline) {
        sharingType = ContentSharingType.LinkedContentsMerging
      }
      yield put(shareSessionActions.setIsShareContentByFormDialogOpen({ isOpen: true, sharingType }))
      break
    default:
  }
}

function* getPagePosterLinkSuccess({ response }) {
  const link = response.data

  const name = new URL(link).pathname.split('/').pop()

  // eslint-disable-next-line no-undef
  const file = yield fetch(link)
  const blob = yield file.blob()
  const blobUrl = URL.createObjectURL(blob)

  const downloadLink = document.createElement('a')
  downloadLink.href = blobUrl
  downloadLink.download = name

  downloadLink.click()
}

function* galleryButtonsMenus() {
  yield all([
    takeLatest(galleryActions.handleAddButtonMenuClick, handleAddButtonMenuClick),
    takeLatest(galleryActions.handleExportButtonMenuClick, handleExportButtonMenuClick),
    takeLatest(galleryActions.handleShareSessionOptionsButtonMenuClick, handleShareSessionOptionsButtonMenuClick),
    takeEvery(galleryActions.getPagePosterLinkSuccess, getPagePosterLinkSuccess),
  ])
}

export default galleryButtonsMenus
