import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import PropTypes from 'prop-types'
import './index.css'

const PowerBiDashboard = ({ dashboard, setEmbeddedDashboard }) => {
  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report',
        id: dashboard.Id,
        embedUrl: dashboard.EmbedUrl,
        accessToken: dashboard.EmbedToken,
        tokenType: models.TokenType.Embed,
        settings: {
          panes: {
            filters: {
              expanded: false,
            },
          },
        },
      }}
      cssClassName='powerbi-embed'
      getEmbeddedComponent={(embeddedComponent) => setEmbeddedDashboard(embeddedComponent)}
    />
  )
}

PowerBiDashboard.propTypes = {
  dashboard: PropTypes.shape({
    Id: PropTypes.string,
    Name: PropTypes.string,
    EmbedUrl: PropTypes.string,
    EmbedToken: PropTypes.string,
  }),
  setEmbeddedDashboard: PropTypes.func.isRequired,
}

export default PowerBiDashboard
