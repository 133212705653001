import { styled } from '@mui/material'

export const Dot = styled('div')(({ theme }) => {
  return {
    width: 10,
    height: 10,
    background: 'black',
    borderRadius: '100%',
    borderColor: 'white',
    borderWidth: 4,
    cursor: 'grab',
    borderStyle: 'solid',
    boxShadow: theme.shadows[4],
  }
})
