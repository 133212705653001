import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as aiActions from '../actions'
import * as notificationActions from '../../notification/actions'

import EditUniqueAIObjectDialog from '../components/EditUniqueAIObjectDialog'

class EditUniqueAIObjectDialogContainer extends Component {
  validate = (values) => {
    const errors = {}

    if (!values.Name) {
      errors.Name = 'Required'
    }

    Object.keys(values.Properties).map((key) => {
      if (
        (!values.Properties[key] && values.Properties[key] !== 0) ||
        (typeof values.Properties[key] === 'string' && !values.Properties[key].trim())
      ) {
        if (!errors.Properties) {
          errors.Properties = {}
        }
        errors.Properties[key] = 'Required'
      }
    })
    return errors
  }

  handleSubmit = (values, actions) => {
    const { aiActions, contentId } = this.props

    const model = { ...values }
    model.Properties = JSON.stringify(model.Properties)
    model.ContentId = contentId

    aiActions.updateUniqueAiObjectRequest(model)
    aiActions.setUniqueAiObjectForEdit(null)
  }

  handleClose = (values, actions) => {
    const { aiActions } = this.props
    aiActions.setUniqueAiObjectForEdit(null)
  }

  render() {
    const { selectedUniqueAIObject } = this.props

    if (!selectedUniqueAIObject || !selectedUniqueAIObject.Properties) {
      return null
    }

    const props = {
      isOpen: !!selectedUniqueAIObject,
      handleSubmit: this.handleSubmit,
      handleValidate: this.validate,
      initialValues: selectedUniqueAIObject,
      handleClose: this.handleClose,
    }
    return <EditUniqueAIObjectDialog {...props} />
  }
}

EditUniqueAIObjectDialogContainer.propTypes = {
  selectedUniqueAIObject: PropTypes.object,
  contentId: PropTypes.number.isRequired,
  aiActions: PropTypes.shape({
    setUniqueAiObjectForEdit: PropTypes.func.isRequired,
    updateUniqueAiObjectRequest: PropTypes.func.isRequired,
  }).isRequired,
}

const mapStateToProps = ({ artificialIntelligence, contentState }) => {
  return {
    selectedUniqueAIObject: artificialIntelligence.selectedUniqueAIObject,
    contentId: contentState.contentId,
  }
}

const mapDispatchToProps = (dispatch) => ({
  aiActions: bindActionCreators(aiActions, dispatch),
  notificationActions: bindActionCreators(notificationActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditUniqueAIObjectDialogContainer)
