import { put, select, take } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import routes from '@tabeeb/routes'
import { contentStateSelectors, rawContentActions } from '../../shared/content'
import { recordingActions, recordingSelectors } from '../../recording'
import { signalrActions } from '../../signalr'
import { presentationSelectors, connectionActions, screenSharingActions, devicesActions } from '../../presentation'
import { accountSelectors } from '../../account'
import { gallerySelectors } from '../../gallery'
import { playerSelectors } from '../../player'
import { annotationsSelectors } from '../../annotations'
import { fileUploadsActions, fileUploadsSelectors } from '../../fileUploads'
import { whiteboardActions } from '..'
import { closeContentMetadataDialog } from '@tabeeb/modules/contentMetadata/actions'
import { closeCallPreviewDialog } from '@tabeeb/modules/presentation/actions/conference'

export function* leaveContent({
  force = false,
  ignoreCallWarning = false,
  ignoreRedirectToHomePage = false,
  redirectLocation = routes.home,
  unblock,
}) {
  const contentId = yield select(contentStateSelectors.getContentId)
  if (!contentId) {
    if (!ignoreRedirectToHomePage) {
      if (unblock) {
        unblock()
      }

      yield put(push(redirectLocation))
    }
    return
  }

  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const isInPresentationMode = yield select(presentationSelectors.getIsInPresentationMode)
  const isCurrentUserPresenter = yield select(contentStateSelectors.getIsCurrentUserPresenter)
  const isRecordingOn = yield select(recordingSelectors.getIsRecordingActive)
  const isUploadingInProgress = (yield select(fileUploadsSelectors.getIncompleteUploadsCount)) > 0
  const areCameraSettingsOpened = (yield select(presentationSelectors.getPreviewTracks)).length > 0

  const isScreenSharingOn = yield select(presentationSelectors.getIsScreenSharing)

  if (isScreenSharingOn) {
    yield put(screenSharingActions.toggleScreenSharing())
  }

  if (!force) {
    const isCallStarted = yield select(presentationSelectors.getIsCallStarted)
    const recordingUserId = yield select(recordingSelectors.getSelectedUserId)

    const showFileUploadsWarning = isUploadingInProgress
    const showCallWarning = !ignoreCallWarning && isCallStarted
    const showRecordingWarning =
      isCallStarted && isRecordingOn && (isCurrentUserPresenter || currentUserId === recordingUserId)

    const showLeavePageDialog = showFileUploadsWarning || showCallWarning || showRecordingWarning

    if (showLeavePageDialog) {
      yield put(
        whiteboardActions.openLeaveSessionPageDialog({
          showFileUploadsWarning,
          showCallWarning,
          showRecordingWarning,
        })
      )

      const leavePageResult = yield take([
        whiteboardActions.onLeaveSessionPageDialogResult,
        whiteboardActions.resetWhiteboardState,
      ])

      const leavePage = leavePageResult.payload
      if (!leavePage) {
        return
      }
    }
  }

  if (unblock) {
    unblock()
  }

  yield put(closeCallPreviewDialog())

  if (isUploadingInProgress) {
    yield put(fileUploadsActions.clearFileUploads())
  }

  if (isRecordingOn) {
    yield put(recordingActions.onStopRecordingForUser({ userId: currentUserId, noDelay: true }))
  }

  if (isInPresentationMode && isCurrentUserPresenter) {
    yield put(rawContentActions.leaveContentRequest({ contentId, userId: currentUserId }))
    yield take([rawContentActions.leaveContentFailed, rawContentActions.leaveContentSuccess])
  }

  if (isInPresentationMode) {
    yield put(connectionActions.disconnect())
  }

  if (areCameraSettingsOpened) {
    yield put(devicesActions.unloadCameraPreviews())
  }

  const systemAudioTrack = yield select((state) => state.presentation.screenSharing.systemAudioTrack)
  if (systemAudioTrack) {
    systemAudioTrack.dispose()
  }

  yield put(signalrActions.invokeHubAction({ method: 'LeaveContent', args: [contentId, currentUserId] }))
  if (isInPresentationMode) {
    yield put(signalrActions.invokeHubAction({ method: 'LeaveMedia', args: [contentId, currentUserId] }))
  }

  yield put(rawContentActions.resetContentState())

  if (!ignoreRedirectToHomePage) {
    yield put(push(redirectLocation))
  }

  yield put(closeContentMetadataDialog())
}

export function* updatePresenterState() {
  const isCurrentUserPresenter = yield select(contentStateSelectors.getIsCurrentUserPresenter)
  const isInPresentationMode = yield select(presentationSelectors.getIsInPresentationMode)
  if (!isCurrentUserPresenter || !isInPresentationMode) {
    return
  }

  const contentId = yield select(contentStateSelectors.getContentId)

  const presenterState = {
    pageId: yield select(gallerySelectors.getSelectedGalleryItemId),
    currentTime: (yield select(playerSelectors.getCurrentVideoTimestamp)) * 1000,
    videoPaused: !(yield select(playerSelectors.getIsVideoPlaying)),
    hotspotDialogAnnotationId: yield select(annotationsSelectors.getHotspotDialogAnnotationId),
  }

  yield put(signalrActions.invokeHubAction({ method: 'UpdatePresenterState', args: [contentId, presenterState, true] }))
}

export function* enterRoom(roomId, presenterId) {
  const isCallStarted = yield select(presentationSelectors.getIsCallStarted)
  if (!isCallStarted) {
    yield put(connectionActions.getJitsiTokenRequest(roomId))
    yield take([connectionActions.getJitsiTokenSuccess])
    yield put(connectionActions.startCall())
    yield put(connectionActions.enterRoom(roomId, presenterId))
  } else {
    yield put(rawContentActions.setPresenter(presenterId))
  }
}

export function* leaveVideoCall() {
  const contentId = yield select(contentStateSelectors.getContentId)
  const currentUserId = yield select(accountSelectors.getCurrentUserId)
  const isInPresentationMode = yield select(presentationSelectors.getIsInPresentationMode)
  const isCurrentUserPresenter = yield select(contentStateSelectors.getIsCurrentUserPresenter)

  const isScreenSharingOn = yield select(presentationSelectors.getIsScreenSharing)
  if (isScreenSharingOn) {
    yield put(screenSharingActions.toggleScreenSharing())
  }

  yield put(closeCallPreviewDialog())

  const isRecordingOn = yield select(recordingSelectors.getIsRecordingActive)
  if (isRecordingOn) {
    yield put(recordingActions.onStopRecordingForUser({ userId: currentUserId, noDelay: true }))
  }

  if (isInPresentationMode) {
    yield put(connectionActions.disconnect())
  }

  const areCameraSettingsOpened = (yield select(presentationSelectors.getPreviewTracks)).length > 0
  if (areCameraSettingsOpened) {
    yield put(devicesActions.unloadCameraPreviews())
  }

  const systemAudioTrack = yield select((state) => state.presentation.screenSharing.systemAudioTrack)
  if (systemAudioTrack) {
    systemAudioTrack.dispose()
  }

  if (isInPresentationMode) {
    yield put(signalrActions.invokeHubAction({ method: 'LeaveMedia', args: [contentId, currentUserId] }))
  }

  if (isInPresentationMode && isCurrentUserPresenter) {
    yield put(rawContentActions.leaveContentRequest({ contentId, userId: currentUserId }))
    yield take([rawContentActions.leaveContentFailed, rawContentActions.leaveContentSuccess])
  }
}
