import { all, takeLatest, put } from 'redux-saga/effects'
import * as notificationActions from '@tabeeb/modules/notification/actions'
import { push } from 'connected-react-router'
import routes from '@tabeeb/routes'
import * as rawActions from '../actions'

function* getDashboardFailed({ response }) {
  yield put(notificationActions.onAddErrorNotification({ message: response.data.Error.Message }))
  yield put(push(routes.biDashboardsList))
}

function* dashboardSaga() {
  yield all([takeLatest(rawActions.getDashboardFailed, getDashboardFailed)])
}

export default dashboardSaga
