import { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { ClickAwayListener, Grow, Paper, Popper } from '@material-ui/core'

import { TenantMenu } from '..'

import { useStyles } from './styles'

const TenantContextMenu = ({ tenantIndex, isOpen, anchorEl, onClose, onExited, onClickAway, popperComponent }) => {
  const classes = useStyles()

  const handleContextMenuForMenuItem = (event) => {
    event.preventDefault()
  }

  const handleMenuKeyDown = useCallback(
    (event) => {
      if (event.key === 'Tab') {
        event.preventDefault()
        onClose()
      } else if (event.key === 'Escape') {
        onClose()
      }
    },
    [onClose]
  )

  const handlePopperExited = useCallback(
    (TransitionProps) => {
      TransitionProps.onExited()
      onExited()
    },
    [onExited]
  )

  const Menu = useMemo(
    () => (
      <TenantMenu
        autoFocusItem={isOpen}
        tenantIndex={tenantIndex}
        onContextMenu={handleContextMenuForMenuItem}
        onMenuItemClick={onClose}
        onMenuKeyDown={handleMenuKeyDown}
      />
    ),
    [handleMenuKeyDown, isOpen, onClose, tenantIndex]
  )

  return popperComponent ? (
    popperComponent(Menu)
  ) : (
    <Popper
      open={isOpen}
      className={classes.contextMenuPopper}
      anchorEl={anchorEl}
      transition
      popperOptions={{
        modifiers: {
          preventOverflow: {
            enabled: true,
          },
          hide: {
            enabled: true,
          },
        },
      }}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} onExited={() => handlePopperExited(TransitionProps)}>
          <Paper elevation={8}>
            <ClickAwayListener onClickAway={onClickAway}>
              <div>{Menu}</div>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

TenantContextMenu.propTypes = {
  tenantIndex: PropTypes.number,
  popperComponent: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  anchorEl: PropTypes.object,
  onClickAway: PropTypes.func,
  onExited: PropTypes.func,
}

export default TenantContextMenu
