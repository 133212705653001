import { memo } from 'react'
import PropTypes from 'prop-types'

import SmoothCameraControls from './SmoothCameraControls'

const CameraControls = ({ autoRotate, rotation, translation, zooming }) => {
  return (
    <SmoothCameraControls
      dollyToCursor
      makeDefault
      autoRotate={autoRotate}
      enableRotate={rotation}
      enableDamping={translation}
      enablePan={translation}
      enableZoom={zooming}
    />
  )
}

CameraControls.defaultProps = {
  autoRotate: false,
  rotation: false,
  translation: false,
  zooming: false,
}

CameraControls.propTypes = {
  autoRotate: PropTypes.bool,
  rotation: PropTypes.bool,
  translation: PropTypes.bool,
  zooming: PropTypes.bool,
}

export default memo(CameraControls)
