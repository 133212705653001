import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { UploadContentType } from '@tabeeb/enums'
import { contentStateSelectors } from '@tabeeb/shared/content'
import { uploadFile } from '@tabeeb/services/uploadService'
import { callAPIPromise as callApiAsync } from '@tabeeb/state/sagas/watchRequest'
import {
  onAddErrorNotification,
  onAddInfoNotification,
  onAddSuccessNotification,
} from '@tabeeb/modules/notification/actions'

import { create3dModel, delete3dModel, get3dModels } from '../actions'

export default () => {
  const dispatch = useDispatch()

  const [loaded, setLoaded] = useState(false)
  const [models, setModels] = useState([])
  const contentId = useSelector(contentStateSelectors.getContentId)

  useEffect(() => {
    if (loaded) {
      return
    }

    const fetchModels = async () => {
      try {
        const {
          response: { data: fetchedModels },
        } = await callApiAsync(get3dModels.request())

        setLoaded(true)
        setModels(fetchedModels)
      } catch {
        // ignored
      }
    }

    fetchModels()
  }, [loaded])

  const onCreateModel = async ({ name, file, thumbnail }) => {
    try {
      dispatch(onAddInfoNotification({ message: 'Model sent for creation' }))

      const { url: modelUrl } = await uploadFile({
        file,
        contentId,
        uploadContentType: UploadContentType.Page,
      })
      const { url: thumbnailUrl } = await uploadFile({
        file: thumbnail,
        contentId,
        uploadContentType: UploadContentType.Page,
      })

      const {
        response: { data: createdModel },
      } = await callApiAsync(
        create3dModel.request({ model: { name, url: modelUrl.split('?')[0], thumbnail: thumbnailUrl.split('?')[0] } })
      )

      setModels((prevModels) => [...prevModels, createdModel])

      dispatch(onAddSuccessNotification({ message: 'Model successully added' }))
    } catch {
      dispatch(onAddErrorNotification({ message: 'Failed to add model' }))
    }
  }

  const onDeleteModel = async (modelId) => {
    try {
      await callApiAsync(delete3dModel.request({ id: modelId }))

      setModels((prevModels) => prevModels.filter((model) => model.Id !== modelId))
    } catch {
      dispatch(onAddErrorNotification({ message: 'Failed to delete model' }))
    }
  }

  return { models, onCreateModel, onDeleteModel }
}
