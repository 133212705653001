import { createAction } from 'redux-actions'
import { createApiActions } from '@tabeeb/shared/utils/actions'

export const adminGetTenantsRequest = createAction('ADMIN_GET_TENANTS_REQUEST')
export const adminGetTenantsSuccess = createAction('ADMIN_GET_TENANTS_SUCCESS')
export const adminGetTenantsFailed = createAction('ADMIN_GET_TENANTS_FAILED')

export const adminGetRolesRequest = createAction('ADMIN_GET_ROLES_REQUEST')
export const adminGetRolesSuccess = createAction('ADMIN_GET_ROLES_SUCCESS')
export const adminGetRolesFailed = createAction('ADMIN_GET_ROLES_FAILED')

export const adminAssignRoleRequest = createAction('ADMIN_ASSIGN_ROLE_REQUEST')
export const adminAssignRoleSuccess = createAction('ADMIN_ASSIGN_ROLE_SUCCESS')
export const adminAssignRoleFailed = createAction('ADMIN_ASSIGN_ROLE_FAILED')

export const adminSearchUsersRequest = createAction('ADMIN_SEARCH_USERS_REQUEST')
export const adminSearchUsersSuccess = createAction('ADMIN_SEARCH_USERS_SUCCESS')
export const adminSearchUsersFailed = createAction('ADMIN_SEARCH_USERS_FAILED')

export const adminUpdateUser = createAction('ADMIN_UPDATE_USER')
export const adminUpdateUserDisabledState = createAction('ADMIN_UPDATE_USER_DISABLED_STATE')

export const adminSetUserDisabledByUserIdRequest = createAction('ADMIN_SET_USER_DISABLED_BY_USER_ID_REQUEST')
export const adminSetUserDisabledByUserIdSuccess = createAction('ADMIN_SET_USER_DISABLED_BY_USER_ID_SUCCESS')
export const adminSetUserDisabledByUserIdFailed = createAction('ADMIN_SET_USER_DISABLED_BY_USER_ID_FAILED')

export const adminSetUserEnabledByUserIdRequest = createAction('ADMIN_SET_USER_ENABLED_BY_USER_ID_REQUEST')
export const adminSetUserEnabledByUserIdSuccess = createAction('ADMIN_SET_USER_ENABLED_BY_USER_ID_SUCCESS')
export const adminSetUserEnabledByUserIdFailed = createAction('ADMIN_SET_USER_ENABLED_BY_USER_ID_FAILED')

export const adminSetIsCreatedByAdminRequest = createAction('ADMIN_SET_IS_CREATED_BY_ADMIN_REQUEST')
export const adminSetIsCreatedByAdminSuccess = createAction('ADMIN_SET_IS_CREATED_BY_ADMIN_SUCCESS')
export const adminSetIsCreatedByAdminFailed = createAction('ADMIN_SET_IS_CREATED_BY_ADMIN_FAILED')

export const adminSaveUserRequest = createAction('ADMIN_SAVE_USER_REQUEST')
export const adminSaveUserSuccess = createAction('ADMIN_SAVE_USER_SUCCESS')
export const adminSaveUserFailed = createAction('ADMIN_SAVE_USER_FAILED')

export const adminCreateUserRequest = createAction('ADMIN_CREATE_USER_REQUEST')
export const adminCreateUserSuccess = createAction('ADMIN_CREATE_USER_SUCCESS')
export const adminCreateUserFailed = createAction('ADMIN_CREATE_USER_FAILED')

export const adminCreateUsersRequest = createAction('ADMIN_CREATE_USERS_REQUEST')
export const adminCreateUsersSuccess = createAction('ADMIN_CREATE_USERS_SUCCESS')
export const adminCreateUsersFailed = createAction('ADMIN_CREATE_USERS_FAILED')

export const adminCleanUsersUpload = createAction('ADMIN_CLEAN_USERS_UPLOAD')

export const updateUser = createApiActions('UPDATE_USER')

export const resetUserPasswordRequest = createAction('ADMIN_RESET_USER_PASSWORD_REQUEST')
export const resetUserPasswordSuccess = createAction('ADMIN_RESET_USER_PASSWORD_SUCCESS')
export const resetUserPasswordFailed = createAction('ADMIN_RESET_USER_PASSWORD_FAILED')

export const resetPage = createAction('RESET_USERS_PAGE')
