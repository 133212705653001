import { createSelector } from 'reselect'

import { TenantPermission } from '@tabeeb/enums'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { getIsTenantAnnouncementsEnabled } from '@tabeeb/modules/appConfigState/selectors'

import { AnnouncementScope } from '../enums'

export const getHasManageSystemAnnouncementsPermission = (state) =>
  hasTenantPermission(state, TenantPermission.ManageSystemAnnouncements)

export const getHasManageTenantAnnouncementsPermission = (state) =>
  hasTenantPermission(state, TenantPermission.ManageTenantAnnouncements)

export const getAvailableAnnouncementScopes = createSelector(
  [
    getHasManageSystemAnnouncementsPermission,
    getHasManageTenantAnnouncementsPermission,
    getIsTenantAnnouncementsEnabled,
  ],
  (hasManageSystemAnnouncementsPermission, hasManageTenantAnnouncementsPermission, isTenantAnnouncementsEnabled) => {
    const scopes = []

    if (hasManageSystemAnnouncementsPermission) {
      scopes.push(AnnouncementScope.System)
    }

    if (hasManageTenantAnnouncementsPermission && isTenantAnnouncementsEnabled) {
      scopes.push(AnnouncementScope.Tenant)
    }

    return scopes
  }
)
