import { memo } from 'react'
import { useSelector } from 'react-redux'

import { getSelectedPageAnnotations } from '@tabeeb/modules/annotations/selectors'
import { getHideAnnotations } from '@tabeeb/modules/playerToolbar/selectors'

import Annotation from './Annotation'
import Drawer from './Drawer'

const Annotations = () => {
  const hidden = useSelector(getHideAnnotations)
  const annotations = useSelector(getSelectedPageAnnotations)

  return (
    <>
      <group>
        {!hidden &&
          annotations.map((annotation) =>
            annotation.ParentAnnotationId ? null : <Annotation key={annotation.Id} annotation={annotation} />
          )}
      </group>
      <Drawer />
    </>
  )
}

export default memo(Annotations)
