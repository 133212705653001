import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { UserTenantRole } from '@tabeeb/enums'
import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'

import { getMeRequest } from '../actions'
import UserSettings from '../components/userSettings'
import { accountSelectors } from '..'

const UserSettingsPage = () => {
  const dispatch = useDispatch()

  const showHealthData = useSelector(appConfigStateSelectors.getShowHealthData)
  const showNotifications = useSelector(appConfigStateSelectors.getIsChatEnabled)
  const showCertificates = useSelector(appConfigStateSelectors.getShowCertificates)

  const showTabs = {
    showHealthData,
    showNotifications,
    showCertificates,
  }
  const showDeleteAccountButton = useSelector((state) => {
    return (
      appConfigStateSelectors.getIsAccountDeletionEnabled(state) &&
      !accountSelectors.getIsUserInAnyRole(state, [UserTenantRole.SystemAdmin])
    )
  })

  useEffect(() => {
    dispatch(getMeRequest())
  }, [dispatch])

  return <UserSettings showTabs={showTabs} showDeleteAccountButton={showDeleteAccountButton} />
}

export default UserSettingsPage
