import { combineReducers } from 'redux'

import points from './points'
import geosearchState from './geosearchState'
import pages from './pages'
import contents from './contents'
import owners from './owners'

export default combineReducers({
  contents,
  geosearchState,
  points,
  pages,
  owners,
})
