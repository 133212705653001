import { memo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Grow } from '@material-ui/core'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import { useWindowFocus } from '@tabeeb/modules/shared/utils/hooks'
import Notification from '../Notification'
import { callNotificationSelectors } from '../..'
import Navigation from '../Navigation'

import './styles.less'

const Notifications = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const isWindowFocused = useWindowFocus()

  const tenantName = useSelector(appConfigStateSelectors.getTenantName)
  const notifications = useSelector(callNotificationSelectors.callNotificationsSelector)

  const isOpen = notifications.length > 0
  const notification = notifications[currentPage] || {}

  const handleNavigationClick = (number) => {
    setCurrentPage(number)
  }

  return isOpen ? (
    <Grow in={isOpen} style={{ transformOrigin: '0 0 0' }} {...(isOpen && { timeout: 500 })}>
      <div className='notification'>
        <div className='navigation-container'>
          <Navigation itemsAmount={notifications.length} onClick={handleNavigationClick} />
        </div>
        <Notification notification={notification} tenantName={tenantName} windowFocused={isWindowFocused} />
      </div>
    </Grow>
  ) : null
}

export default memo(Notifications)
