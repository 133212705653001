import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { appConfigStateSelectors } from '@tabeeb/modules/appConfigState'
import ControlsPanelButton from '@tabeeb/modules/gallery/components/ControlsPanelButton'
import { contentTrashBinServices, contentTrashBinSelectors } from '../..'
import TrashBinControlsMenu from '../TrashBinControlsMenu'

const TrashBinControls = () => {
  const restoreButtonColor = useSelector(appConfigStateSelectors.getAddButtonColor)
  const deleteButtonColor = useSelector(appConfigStateSelectors.getExportButtonColor)

  const restoreText = 'Restore'
  const deleteText = 'Delete'

  const restoreTitle = 'Select option to restore pages'
  const deleteTitle = 'Select option to delete pages'

  const disabledRestoreTitle = 'There are no items, that you can restore'
  const disabledDeleteTitle = 'There are no items, that you can delete'

  const canDeleteRestoreGallery = useSelector(contentTrashBinSelectors.getCanDeleteRestoreGallery)

  return (
    <footer className='gallery-controls-panel'>
      <div className={classNames('gallery-controls-panel-buttons')}>
        <ControlsPanelButton
          button={restoreText}
          backgroundColor={restoreButtonColor}
          isVisible
          menu={<TrashBinControlsMenu itemList={contentTrashBinServices.restoreMenuItems} />}
          disabled={!canDeleteRestoreGallery}
          title={canDeleteRestoreGallery ? restoreTitle : disabledRestoreTitle}
        />

        <ControlsPanelButton
          button={deleteText}
          backgroundColor={deleteButtonColor}
          isVisible
          menu={<TrashBinControlsMenu itemList={contentTrashBinServices.deleteMenuItems} />}
          disabled={!canDeleteRestoreGallery}
          title={canDeleteRestoreGallery ? deleteTitle : disabledDeleteTitle}
        />
      </div>
    </footer>
  )
}

export default TrashBinControls
