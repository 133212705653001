import { memo } from 'react'

import { useSelector } from 'react-redux'

import { IconButton } from '@material-ui/core'
import { Settings } from '@material-ui/icons'

import { navbarSelectors } from '@tabeeb/modules/navbar'
import { useDialogState } from '@tabeeb/shared/utils/hooks'

import SessionSettingsDialog from '@tabeeb/modules/whiteboard/components/SessionSettingsDialog'

const SessionSettingsButton = () => {
  const [open, onOpen, onClose] = useDialogState(false)

  const isVisible = useSelector(navbarSelectors.getIsSessionSettingsButtonVisible)

  if (!isVisible) {
    return null
  }

  return (
    <>
      <IconButton title='Open session settings' onClick={onOpen}>
        <Settings />
      </IconButton>
      <SessionSettingsDialog open={open} onClose={onClose} />
    </>
  )
}

export default memo(SessionSettingsButton)
