import { handleActions } from 'redux-actions'

import { onLogout } from '@tabeeb/modules/account/actions'
import { permissionsActions } from '..'

const defaultValue = 0

export default handleActions(
  {
    [permissionsActions.getTenantPermissionsSuccess](state, { response }) {
      const permissions = response.data.Permissions

      return permissions
    },
    [onLogout]: (state, action) => {
      return defaultValue
    },
  },
  defaultValue
)
