import { memo } from 'react'
import PropTypes from 'prop-types'

import { Vector3 } from 'three'

import { Html } from '@react-three/drei'

import { Typography } from '@mui/material'

import { Container, Z_INDEX } from './styles'

const Label = ({ position, text, visible }) => {
  if (!visible) {
    return null
  }

  return (
    <Html center position={position} style={{ pointerEvents: 'none' }} zIndexRange={Z_INDEX}>
      <Container>
        <Typography variant='body2' color='GrayText' noWrap>
          {text}
        </Typography>
      </Container>
    </Html>
  )
}

Label.defaultProps = {
  visible: true,
}

Label.propTypes = {
  position: PropTypes.instanceOf(Vector3).isRequired,
  text: PropTypes.string.isRequired,
  visible: PropTypes.bool,
}

export default memo(Label)
