import { callAPIPromise } from '@tabeeb/state/sagas/watchRequest'
import { getPointCloudAccessToken } from '../actions'

const cache = new Map()

export const getToken = ({ pageId }) => {
  return new Promise((resolve, reject) => {
    if (!cache.has(pageId)) {
      cache.set(pageId, callAPIPromise(getPointCloudAccessToken.request({ pageId }), { callResultActions: false }))
    }

    resolve(cache.get(pageId))
  })
}
