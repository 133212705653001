import { useCallback, useState } from 'react'
import { Avatar as AvatarIcon, Divider, Typography } from '@mui/material'
import { Grid } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Book } from '@mui/icons-material'

import { useDialogState } from '@tabeeb/shared/utils/hooks'
import { readUploadedFileAsDataUrl } from '@tabeeb/modules/fileUploads/services'

import * as certificatesActions from '../../actions'
import {
  getCertificatesFilters,
  getCertificatesList,
  getCertificatesLoading,
  getCertificatesPageNumber,
  getCertificatesSorting,
  getCertificatesTotalCount,
} from '../../selectors'
import CertificatesProfileList from '../CertificatesProfileList'
import AddEditCertificate from '../AddEditCertificate'
import CertificateSearchBar from '../CertificateSearchBar'
import CertificatesProfileListSkeleton from '../CertificatesProfileListSkeleton'

import useStyles from './styles'

const CertificatesProfileSettings = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [addEditCertificateOpen, onOpenAddEditCertificate, onCloseAddEditCertificate] = useDialogState()
  const [selectedCertificate, setSelectedCertificate] = useState(null)
  const certificatesLoading = useSelector(getCertificatesLoading)

  const certificatesList = useSelector(getCertificatesList)
  const certificatesTotalCount = useSelector(getCertificatesTotalCount)
  const pageNumber = useSelector(getCertificatesPageNumber)
  const certificatesFilters = useSelector(getCertificatesFilters)
  const certificatesSorting = useSelector(getCertificatesSorting)

  const setPageNumber = useCallback(
    (newPageNumber) => {
      dispatch(certificatesActions.setCertificatesPageNumber(newPageNumber))
      dispatch(
        certificatesActions.getMyCertificates.request({
          pageNumber: newPageNumber,
          filters: certificatesFilters,
          sorting: certificatesSorting,
        })
      )
    },
    [certificatesFilters, certificatesSorting, dispatch]
  )

  const handleCloseAddEditCertificate = useCallback(() => {
    onCloseAddEditCertificate()
    setSelectedCertificate(null)
  }, [onCloseAddEditCertificate])

  const selectCertificateToEdit = useCallback(
    (certificateToEdit) => {
      setSelectedCertificate(certificateToEdit)
      onOpenAddEditCertificate()
    },
    [onOpenAddEditCertificate]
  )

  const onAddEditCertificate = useCallback(
    async (certificate) => {
      const isEditMode = Boolean(certificate.Id)
      let photo
      if (certificate.Photo) {
        try {
          const result = await readUploadedFileAsDataUrl(certificate.Photo)
          photo = { PhotoName: certificate.Photo.name, PhotoData: result }
        } catch (e) {
          console.log(`readUploadedFileAsDataUrl ended with error: ${e}`)
        }
      }

      // eslint-disable-next-line no-param-reassign
      delete certificate.Photo
      const dataToSend = {
        ...certificate,
        ...(certificate.IssueDate &&
          certificate.IssueDate.length !== 0 && { IssueDate: new Date(certificate.IssueDate).toDateString() }),
        ...(certificate.Indefinite &&
          certificate.ExpirationDate &&
          certificate.ExpirationDate.length !== 0 && {
            ExpirationDate: new Date(certificate.ExpirationDate).toDateString(),
          }),
        ...photo,
      }

      if (isEditMode) {
        if (certificate.Indefinite) {
          delete dataToSend.ExpirationDate
        }
        dispatch(certificatesActions.updateMyCertificate.request(dataToSend))
      } else {
        dispatch(certificatesActions.createCertificate.request(dataToSend))
      }

      handleCloseAddEditCertificate()
    },
    [dispatch, handleCloseAddEditCertificate]
  )

  return (
    <Grid item xs={12}>
      <Grid container direction='column' spacing={1}>
        <Grid item className={classes.rootGrid}>
          <div className={classes.header}>
            <AvatarIcon sx={{ height: 24, width: 24, mr: 1 }}>
              <Book sx={{ fontSize: 16 }} />
            </AvatarIcon>
            <Typography variant='body1'>Certificates</Typography>
          </div>
          <Divider sx={{ mt: 1, mb: 2, borderStyle: 'none' }} />
          <Grid container wrap='nowrap' direction='column' spacing={1}>
            {addEditCertificateOpen ? (
              <AddEditCertificate
                onClose={handleCloseAddEditCertificate}
                onSubmit={onAddEditCertificate}
                selectedCertificate={selectedCertificate}
              />
            ) : (
              <>
                <Grid item>
                  <CertificateSearchBar />
                </Grid>
                {certificatesLoading ? (
                  <CertificatesProfileListSkeleton />
                ) : (
                  <CertificatesProfileList
                    certificates={certificatesList}
                    totalCount={certificatesTotalCount}
                    onOpenAddEditCertificate={onOpenAddEditCertificate}
                    setCertificateToEditing={selectCertificateToEdit}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

CertificatesProfileSettings.propTypes = {}

export default CertificatesProfileSettings
