import { handleActions, combineActions } from 'redux-actions'

import * as certificatesActions from '@tabeeb/modules/certificates/actions'
import { certificatesPageSize, CertificatesPopoverSortingOptions } from '@tabeeb/modules/certificates/constants'
import { CertificateVerificationStatusTypes } from '@tabeeb/enums'

const defaultState = {
  Certificates: [],
  TotalCount: 0,
  PageNumber: 1,
  PageSize: certificatesPageSize,
  Filters: { SearchText: '', VerificationStatusTypes: Object.values(CertificateVerificationStatusTypes) },
  Sorting: { Column: CertificatesPopoverSortingOptions.LastUpdated },
  Loading: false,
}

export default handleActions(
  {
    [combineActions(
      certificatesActions.getMyCertificates.request,
      certificatesActions.getCertificatesByUserId.request
    )](state, action) {
      return { ...state, Loading: true }
    },
    [combineActions(
      certificatesActions.getMyCertificates.success,
      certificatesActions.getCertificatesByUserId.success
    )](state, action) {
      return {
        ...state,
        Certificates: action.response.data.Certificates,
        TotalCount: action.response.data.TotalCount,
        Loading: false,
      }
    },
    [certificatesActions.setCertificatesPageNumber](state, action) {
      return { ...state, PageNumber: action.payload }
    },
    [certificatesActions.setCertificatesFiltersStatusTypes](state, action) {
      return { ...state, Filters: { ...state.Filters, VerificationStatusTypes: action.payload } }
    },
    [certificatesActions.setCertificatesFiltersSearchText](state, action) {
      return { ...state, Filters: { ...state.Filters, SearchText: action.payload } }
    },
    [certificatesActions.setCertificatesSorting](state, action) {
      return { ...state, Sorting: action.payload }
    },
    [certificatesActions.resetCertificatesListState]() {
      return defaultState
    },
  },
  defaultState
)
