import { put, takeLatest, select, all, delay } from 'redux-saga/effects'

import * as rawActions from '../actions'
import { gallerySelectors } from '..'

import { isPdfPage } from '../../../services/pageService'

function* updateSearchText(action) {
  yield put(rawActions.hidePdfSearchResults())
  yield delay(500)
  yield put(rawActions.showPdfSearchResults())
}

function* setSelectedPdfSearchText(action) {
  let { pageIndex } = action.payload
  pageIndex++

  const selectedPage = yield select(gallerySelectors.getSelectedGalleryItem)

  if (!selectedPage || selectedPage.pdfPageNumber === pageIndex) {
    return
  }

  const galleryItems = yield select(gallerySelectors.getGalleryList)

  const selectedResultPage = galleryItems.find(
    (page) =>
      isPdfPage(page.contentType) && page.contentUrl === selectedPage.contentUrl && page.pdfPageNumber === pageIndex
  )

  if (selectedResultPage) {
    yield put(rawActions.onSelectGalleryItem({ pageId: selectedResultPage.id }))
  }
}

function* searchInPdf() {
  yield all([
    takeLatest(rawActions.updateSearchText, updateSearchText),
    takeLatest(rawActions.setSelectedPdfSearchText, setSelectedPdfSearchText),
  ])
}

export default searchInPdf
